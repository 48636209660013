<template>
    <div class="report-message" ref="carouselContainer">

        <el-image class="reviewImg" :src="getScaleImgLink(reportData.reviewLink, 1280)"
            :preview-src-list="[getScaleImgLink(reportData.reviewLink, 2400)]" @load="setImageHeight"></el-image>

    </div>
</template>
  
<script>
import { marked } from 'marked';

export default {
    name: 'ReportImg',
    props: {
        reportData: {
            type: Object,
            default: () => ({})
        }
    },
    mounted() {
        // console.log(this.reportData);
    },
    data() {
        return {
            carouselHeight: '660px', // 初始高度

        };
    },
    methods: {
        getScaleImgLink(osslink, size = 430) {
            return osslink + `?x-oss-process=image/resize,w_${size},m_lfit`;
        },
        setImageHeight(event) {
            this.$nextTick(() => {
                // 这里进行计算和设置高度
                const containerWidth = this.$refs.carouselContainer.clientWidth;
                const imageAspectRatio = event.target.naturalWidth / event.target.naturalHeight;
                const height = containerWidth / imageAspectRatio;
                this.carouselHeight = `${height}px`;
            });
        }
    },
    computed: {
        renderedMessage() {
            var markdownHtml = this.currentItem ? marked.parse(this.currentItem.insightsContent) : '';
            return markdownHtml;
        }
    }
};
</script>
  
<style scoped>
.report-message {
    margin-bottom: 24px;
}

.reviewImg {
    object-fit: contain;
    display: block;
    margin: auto;
    max-width: 100%;
}

.insight {
    text-align: left;
    margin-top: 8px;
    color: #444;
}

/* 调整图片样式 */
.el-carousel__item img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

::v-deep .replay {
    margin-top: 8px;
    margin-left: 32px;
}
</style>
  