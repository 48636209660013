
<template>
    <div>
        <div @click="handleClick" :id="containerId" class="image-container" :style="containerStyle">
            <BalanceSankey ref="customerSankeyRef" :sankey-data="sankeyData" style="cursor: pointer;" />
        </div>
        <!-- 图像预览模态框 -->
        <div v-if="showModal" class="modal">
            <div class="modal-content" @click="showModal = false">
                <i class="el-icon-close close" @click="showModal = false"></i>
                <img v-if="generatedImage" :src="generatedImage" alt="Generated Image" @click.stop />
            </div>
        </div>
    </div>
</template>

<script>

import Tools from '@/GlobalTools';
import BalanceSankey from '../creater/BalanceSankey.vue';
export default {
    name: 'ReportBalance',
    components: {
        BalanceSankey
    },
    props: {
        viewData: {
            type: Object,
            default: () => ({})
        },
        isCompareMode: {
            type: Boolean,
            default: false
        },
        listId: {
            type: Number,
            default: 0
        }
    },
    watch: {
        isCompareMode(newValue) {
            if (newValue) {
                this.updateContainerSize();
            }
        }
    },
    mounted() {
        this.containerId = 'id-report-balance-' + this.viewData.viewId + '-' + this.listId;
        this.$nextTick(() => {
            this.updateContainerSize();
            window.addEventListener('resize', this.updateContainerSize);
            this.buildWithForm();
        });
    },
    data() {
        return {
            containerId: 'id-report-balance',
            chatName: '',
            containerWidth: 0,
            containerHeight: 0,
            imageWidth: '100vw', // 初始图片宽度，您可以根据需要调整
            imageHeight: '100vh', // 初始图片高度，您可以根据需要调整
            generatedImage: null,
            showModal: false,
            symbol: '',
            sankeyData: {},
            treemap: [],
            config: {

            },
            form: {
                CurrentAssets: {
                    id: 'CurrentAssets',
                    desc: 'Current Assets',
                    v: 0,
                    weight: 0,
                    color: 'darkgreen',
                    yy: '',
                    sort: 3,
                    vi: '',
                },
                extraCurrentAssests: [],
                NonCurrentAssets: {
                    id: 'NonCurrentAssets',
                    desc: 'Non-current Assets',
                    v: 0,
                    weight: 0,
                    color: 'lightgreen',
                    yy: '',
                    sort: 2,
                    vi: '',
                },
                extraNonCurrentAssets: [],
                CurrentLiabilities:
                {
                    id: 'CurrentLiabilities',
                    desc: 'Current Liabilities',
                    v: 0,
                    weight: 0,
                    color: 'darkred',
                    yy: '',
                    sort: 3,
                    vi: '',
                },
                extraCurrentLiabilities: [],
                NonCurrentLiabilities: {
                    id: 'NonCurrentLiabilities',
                    desc: 'Non-Current Liabilities',
                    v: 0,
                    weight: 0,
                    color: 'lightred',
                    yy: '',
                    sort: 2,
                    vi: '',
                },
                extraNonCurrentLiabilities: [],
                ShareholdersEquity: {
                    id: 'ShareholdersEquity',
                    desc: 'Shareholders Equity',
                    v: 0,
                    weight: 0,
                    color: 'blue',
                    yy: '',
                    sort: 1,
                    vi: '',
                },
                extraShareholdersEquity: []
            },
        }
    },
    methods: {
        async handleClick() {
            try {
                const image = await this.$refs.customerSankeyRef.generateImage();
                this.generatedImage = image;
                this.showModal = true; // 显示模态框
            } catch (error) {
                console.error('Error generating image:', error);
            }
        },
        updateContainerSize() {
            // 获取.chat-main元素
            const chatMainElement = document.querySelector('.reportView-tree');
            if (chatMainElement) {
                // 获取.chat-main的宽度
                this.containerWidth = Math.min(1280, chatMainElement.offsetWidth - 80);
                // 如果需要，可以根据比例或其他逻辑来设置高度    height: calc((100vw * 2/3 - 248px)* (2416 / 4296));
                this.containerHeight = this.containerWidth * (2416 / 4296);
            } else {
                console.error('.chat-main元素未找到');
            }
        },
        buildWithForm() {
            this.symbol = this.viewData.symbol;
            this.chatName = this.symbol + '-' + this.listId;
            this.config = this.viewData.config;
            this.companyName = this.viewData.companyName;
            Object.keys(this.viewData.form).forEach(key => {
                // 检查 this.form 中是否存在相应的键
                if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                    // 更新 this.form 中每个键对应的值
                    // 这里假设你只想更新 'v' 和 'weight' 字段
                    this.form[key].v = this.viewData.form[key].v;
                    this.form[key].weight = Math.abs(this.viewData.form[key].v); // 举例，根据需要可能会有所不同
                    this.form[key].desc_cn = this.viewData.form[key].desc_cn;
                }
            });
            this.addExtraItems = function (items, targetArray, color) {
                items.forEach(item => {
                    targetArray.push({
                        id: item.id,
                        v: item.v,
                        weight: Math.abs(item.v),
                        desc: item.desc,
                        desc_cn: item.desc_cn,
                        color
                    });
                });
            };

            // 使用通用函数添加项目
            this.addExtraItems(this.viewData.form.extraCurrentAssests, this.form.extraCurrentAssests, 'darkgreen');
            this.addExtraItems(this.viewData.form.extraNonCurrentAssets, this.form.extraNonCurrentAssets, 'lightgreen');
            this.addExtraItems(this.viewData.form.extraCurrentLiabilities, this.form.extraCurrentLiabilities, 'darkred');
            this.addExtraItems(this.viewData.form.extraNonCurrentLiabilities, this.form.extraNonCurrentLiabilities, 'lightred');
            // this.addExtraItems(this.viewData.form.extraShareholdersEquity, this.form.extraShareholdersEquity, 'blue');

            this.sankeyData = this.generateSankeyData();
            // Tools.logObj('reportBalance view buildWithForm', this.viewData);
            // Tools.logObj('reportBalance view buildSnakey', this.sankeyData);
        },
        generateTreemapData() {
            var result = {
                assets: [
                    {
                        name: this.form.CurrentAssets.desc,
                        children: !Tools.isArrayEmpty(this.form.extraCurrentAssests) ?
                            this.form.extraCurrentAssests.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.CurrentAssets)],
                        color: this.form.CurrentAssets.color,
                        total: this.form.CurrentAssets.v
                    },
                    {
                        name: this.form.NonCurrentAssets.desc,
                        children: !Tools.isArrayEmpty(this.form.extraNonCurrentAssets) ?
                            this.form.extraNonCurrentAssets.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.NonCurrentAssets)],
                        color: this.form.NonCurrentAssets.color,
                        total: this.form.NonCurrentAssets.v

                    }
                ],
                liabilities: [
                    {
                        name: this.form.CurrentLiabilities.desc,
                        children: !Tools.isArrayEmpty(this.form.extraCurrentLiabilities) ?
                            this.form.extraCurrentLiabilities.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.CurrentLiabilities)],
                        color: this.form.CurrentLiabilities.color,
                        total: this.form.CurrentLiabilities.v
                    },
                    {
                        name: this.form.NonCurrentLiabilities.desc,
                        children: !Tools.isArrayEmpty(this.form.extraNonCurrentLiabilities) ?
                            this.form.extraNonCurrentLiabilities.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.NonCurrentLiabilities)],
                        color: this.form.NonCurrentLiabilities.color,
                        total: this.form.NonCurrentLiabilities.v
                    }
                ],
                equity:
                {
                    name: this.form.ShareholdersEquity.desc,
                    children: !Tools.isArrayEmpty(this.form.extraShareholdersEquity) ?
                        this.form.extraShareholdersEquity.map(item => this.createTreemapNode(item)) :
                        [this.createTreemapNode(this.form.ShareholdersEquity)],
                    color: this.form.ShareholdersEquity.v > 0 ? 'blue' : 'lightgray',
                    total: this.form.ShareholdersEquity.v
                }
            };
            return result;
        },
        getFormKeyV(id) {
            if (this.form[id]) {
                return this.form[id].v;
            }
            return 0;
        },
        getFormKeyAbsV(id) {
            if (this.form[id]) {
                return Math.abs(this.form[id].weight);
            }
            return 0;
        },
        generateTitle() {
            this.config.title = `${this.companyName} Balance Sheets ${this.getFormateDate()}`
            this.config.subtitle = `Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
        },
        createTreemapNode(item) {
            // 创建矩形树图的节点
            return {
                name: item.desc,
                value: item.v,
                color: item.color
            };
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.treemap = this.generateTreemapData();
            } catch (e) {
                console.log('generateNodes exception:', e);
            }
            this.generateTitle();
            return {
                symbol: this.symbol,
                containerId: this.containerId,
                chatName:this.chatName,
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                treemap: this.treemap,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName,
            };

        },
        getFormateDate() {
            // 检测日期格式并相应地转换
            let inputDate = this.config.endDate;
            let endDate;

            // 检测是否为"YYYYMMDD"格式
            if (/^\d{8}$/.test(inputDate)) {
                // 将"YYYYMMDD"格式转换为"YYYY-MM-DD"，这样可以直接被Date对象正确解析
                inputDate = `${inputDate.substring(0, 4)}-${inputDate.substring(4, 6)}-${inputDate.substring(6, 8)}`;
                endDate = new Date(inputDate);
            } else {
                // 假设其他情况是有效的ISO 8601格式或其他可以直接被Date解析的格式
                endDate = new Date(inputDate);
            }

            // 使用toLocaleDateString方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号，这里看起来没有必要的替换，所以简化掉了
            formattedDate = formattedDate.replace(',', '');

            return formattedDate;
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateContainerSize);
    },
    computed: {
        containerStyle() {
            return {
                width: this.containerWidth + 'px',
                height: this.containerHeight + 'px',
            };
        },
        imageStyle() {
            return {
                width: this.imageWidth + 'px',
                height: this.imageHeight + 'px',
            };
        },
    },
}


</script>

<style scoped>
.image-container {
    cursor: pointer;
    /* 窗口的最大高度 */
    display: flex;
    /* 使用 Flexbox 布局 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    text-align: center;
    overflow: hidden;
    /* 允许滚动 */
    position: relative;
    /* background-color: #F5F5F5; */
}

.image-container svg {
    cursor: pointer !important;
}

.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.modal-content img {
    width: calc(96vw);
    height: calc((96vw)* (2416 / 4296));
}

.modal-content .close {
    position: absolute;
    /* 绝对定位 */
    top: 0;
    /* 顶部对齐 */
    right: 0;
    /* 右侧对齐 */
    padding: 8px;
    /* 为点击提供足够空间 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
    z-index: 10;
    /* 确保在其他内容之上 */
    margin: 48px;
    color: #FFF;
    float: right;
    font-size: 24px;
    font-weight: 400;
    border-radius: 50%;
    opacity: .8;
    box-sizing: border-box;
    background-color: #606266;
    align-items: center;
    justify-content: center;
}


.modal-content .close:hover,
.modal-content .close:focus {
    color: color;
    text-decoration: none;
    cursor: pointer;
}
</style>