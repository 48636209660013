// axios-config.js
import axios from 'axios';

// 设置基础URL和全局配置（如果有的话）
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log('interceptors request :',config.headers.Authorization);
    return config;
  },
  error => Promise.reject(error)
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 检查是否有新的令牌
    const newToken = response.headers['new-authorization'];
    if (newToken) {
      // 提取令牌并更新本地存储
      const token = newToken.split(' ')[1];
      localStorage.setItem('authToken', token); // 更新令牌
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // 例如，清除token并重定向到登录页
      if (localStorage.getItem('authToken')) {
        localStorage.removeItem('authToken');
        // 判断当前是否已经在首页
        if (window.location.pathname === '/' || window.location.pathname === '/home') {
          // 如果已经在首页，刷新页面以更新状态
          window.location.reload();
        } else {
          // 如果不在首页，跳转到首页
          window.location = '/';
        }
      }
      // 此处应用程序的重定向逻辑

    }
    return Promise.reject(error);
  }
);

export default axios;