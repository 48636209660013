<template>
    <div class="reply-message">
        <div style="display:inline-block; line-height:24px; vertical-align:middle;">
            <MainLogo width="24px" height="24px" style="vertical-align:middle;" />
            <span style="margin-left:8px;font-weight:bold;height:24px; line-height:24px; vertical-align:middle;">Financial
                Report Expert</span>
        </div>

        <!-- <div class="thinking" v-else-if="isLoading">
            <loadingSpinner></loadingSpinner>
        </div> -->
        <div class="message-content">
            <div v-html="renderedMessage">
            </div>
            <!-- 其他按钮组 -->
            <p v-if="error" style="color: #BE0005;">{{ error }}</p>

            <!-- 加载状态显示 -->
            <div v-if="isLoading" class="loading-status button-group">
                <loadingSpinner style="margin-left:-8px;width:120px"></loadingSpinner>
            </div>
            <div v-else class="button-group">
                <i class="el-icon-document-copy" @click="copyMessage"></i>
                <i class="el-icon-thumb"></i>
                <i class="el-icon-thumb" style="transform: rotate(180deg);"></i>
                <i class="el-icon-refresh-right" @click="retryRequest"></i>
            </div>
        </div>

    </div>
</template>

<script>
import { marked } from 'marked';
import MainLogo from '../animate/MainLogo.vue';
import loadingSpinner from '../animate/LoadingSpinner.vue';

export default {
    components: {
        MainLogo,
        loadingSpinner
    },
    props: {
        message: String,
        isLoading: Boolean,
        replayId: String,
        error: String
    },
    data() {
        return {
            errorMessage: '' // 存储错误信息
        };
    },
    computed: {
        renderedMessage() {
            return this.message ? marked.parse(this.message) : '';
        }
    },
    methods: {
        retryRequest() {
            this.$emit('retry'); // 触发重试事件
        },
        interruptRequest() {
            // this.$emit('interrupt'); // 触发重试事件
            this.$emit('interrupt', this.replayId); // 触发重试事件

        },
        copyMessage() {
            if (!this.renderedMessage) {
                console.error("No message to copy");
                return;
            }

            // 使用navigator.clipboard API复制文本
            navigator.clipboard.writeText(this.message).then(() => {
                console.log("Message copied to clipboard");
                // 这里可以添加一些用户反馈，例如显示一个提示或者更改图标状态
                this.$message({
                    message: 'Message copied to clipboard',
                    type: 'success'
                });
            }).catch(err => {
                console.error("Could not copy text: ", err);
                this.$message({
                    message: 'Could not copy text',
                    type: 'error'
                });
            });
        },
    }
};
</script>

<style scoped>
.reply-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.thinking {
    margin-left: 24px;
}

.reply-message .message-content {
    margin-left: 32px;
}

::v-deep .reply-message .message-content table{
    background-color: #FFF;
}

.reply-message .button-group i,
.reply-message .button-group span {
    color: #666;
    margin-right: 8px;
    cursor: pointer;
}


.reply-message .button-group span:hover,
.reply-message .button-group i:hover {
    font-size: 18px;
}
</style>
