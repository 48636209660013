<template>
    <div>
        <!-- 月份标签，具有特殊样式 -->
        <div class="month-node" v-if="node.leaftype === 'month'" :id="listId + '-month-' + symbol + '-' + node.label">
            <!-- <i class="el-icon-date"></i> {{ node.label }} -->
            <div class="line">
                <el-select v-model="selectedMonth" @change="scrollToMonth" style="width: 120px;">
                    <template slot="prefix">
                        <div class="report-group-label">
                            {{ node.label }}
                        </div>
                    </template>
                    <el-option v-for="(month, index) in months" :key="index" :label="month.label"
                        :value="month.id"></el-option>
                </el-select>
            </div>

        </div>

        <!-- 其他类型的节点 -->
        <div v-else class="report-node">
            <div v-if="node.status === 'checked' && node.reviewLink">
                <div class="buttons">
                    <div style="margin-bottom: 12px;">
                        <span style="margin-right: 12px;font-size: 20px;color: #666;font-weight: 500;">{{ node.label
                        }}</span>
                    </div>
                    <div style="display: flex;flex-direction: row;">
                        <el-button class="btn-tree" @click.stop="handleButtonInsights(node)" round size="mini"
                            icon="el-icon-chat-line-round">{{
                                btnTextInsights }}</el-button>
                        <el-button class="btn-tree" @click.stop="handleButtonExplanation(node)" round size="mini"
                            icon="el-icon-chat-line-round">{{ btnTextExplanation }}</el-button>
                        <el-button class="btn-tree" @click.stop="handleButtonReportError(node)" round size="mini"
                            icon="el-icon-s-promotion">{{
                                btnTextReportError }}</el-button>
                    </div>

                </div>
                <report-img :report-data="node" :style="reportImgStyle"></report-img>
            </div>
            <div v-else-if="node.status === 'unchecked' && node.aiformate && Object.keys(node.aiformate).length !== 0"
                class="report-svg">
                <!-- 动态组件，根据报告类型渲染 -->
                <div class="buttons">
                    <div style="margin-bottom: 12px;">
                        <span style="margin-right: 12px;font-size: 20px;color: #666;font-weight: 500;">{{ node.label
                        }}</span>
                    </div>
                    <div>
                        <el-button class="btn-tree" @click.stop="handleButtonInsights(node)" round size="mini"
                            icon="el-icon-chat-line-round">{{
                                btnTextInsights }}</el-button>
                        <el-button class="btn-tree" @click.stop="handleButtonExplanation(node)" round size="mini"
                            icon="el-icon-chat-line-round">{{ btnTextExplanation }}</el-button>
                        <el-button class="btn-tree" @click.stop="handleButtonReportError(node)" round size="mini"
                            icon="el-icon-s-promotion">{{
                                btnTextReportError }}</el-button>
                    </div>

                </div>
                <component :is="reportComponent[node.typeShort]" :view-data="reportData" :is-compare-mode="isCompareMode"
                    :id="node.uniqId" :list-id="listId">
                </component>
            </div>
        </div>

        <div style="height: 80px;margin-top: -64px;" :id="listId + '-nodes-' + symbol + '-' + node.label"></div>
        <!-- 子节点 -->
        <div v-if="node.children && node.children.length > 0" class="children">
            <tree-node v-for="child in node.children" :key="child.id" :node="child" :symbol="symbol" :list-id="listId"
                :companyName="companyName" @button-click="handleButtonClick" :is-compare-mode="isCompareMode"></tree-node>
        </div>
        <report-error-dialog ref="reportErrorDialog"></report-error-dialog>
    </div>
</template>
<script>
import ReportImg from './ReportImg.vue';
import ReportIncome from './ReportIncome.vue';
import ReportBalance from './ReportBalance.vue';
import ReportCashFlow from './ReportCashFlow.vue';
import ReportErrorDialog from './ReportErrorDialog.vue';

export default {
    name: 'TreeNode',
    components: {
        ReportImg,
        ReportIncome,
        ReportBalance,
        ReportCashFlow,
        ReportErrorDialog,
        TreeNode: () => import('./TreeNode.vue') // 递归引用
    },
    props: {
        node: Object,
        months: Array,
        symbol: String, // 新增
        companyName: String, // 新增
        isCompareMode: { Boolean, default: false },
        listId: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            selectedMonth: this.node.label,
        };
    },
    watch: {
        isCompareMode() {
            console.log('treeNode isCompareMode:', this.isCompareMode);
        }
    },
    computed: {
        selectedLanguage() {
            return this.$store.getters.getSelectedLanguage;
        },
        reportComponent() {
            return {
                'ic': ReportIncome,
                'bs': ReportBalance,
                'cs': ReportCashFlow,
            };
        },
        reportData() {
            let viewData;
            if (this.node.typeShort === 'ic') {
                viewData = this.getAiFormatedData(this.node);
            } else if (this.node.typeShort === 'bs') {
                viewData = this.getAiFormatedData(this.node);
            } else if (this.node.typeShort === 'cs') {
                viewData = this.getAiFormatedData(this.node);
            } else {
                viewData = this.node; // 默认情况
            }
            // 直接在这里添加 symbol 和 companyName
            viewData.symbol = this.symbol;
            viewData.companyName = this.companyName;
            return viewData;
        },
        btnTextInsights() {
            return this.selectedLanguage === 'English' ? 'Insights' : '洞察';
        },
        btnTextExplanation() {
            return this.selectedLanguage === 'English' ? 'Explanation' : '解释';
        },
        btnTextReportError() {
            return this.selectedLanguage === 'English' ? 'Report Error' : '报告错误';
        },
        btnTextDownload() {
            return this.selectedLanguage === 'English' ? 'Download' : '下载';
        },
        reportImgStyle() {
            // 使用模板字符串构造calc和min函数的表达式
            const minHeightValue = !this.isCompareMode ? 'calc(min(100%, 480px))' : 'calc(min(100%, 240px))';
            return {
                minHeight: minHeightValue
            };
        },

    },
    methods: {
        handleButtonClick(data, type) {
            data.symbol = this.symbol;
            data.companyName = this.companyName;
            this.$emit('button-click', data, type);
        },
        handleButtonInsights(data) {
            console.log('handleButtonInsights:', data);
            data.symbol = this.symbol;
            data.companyName = this.companyName;
            this.$emit('button-click', data, 'insights');
        },
        handleButtonReportError(data) {
            console.log('handleButtonReportError:', data);
            this.$refs.reportErrorDialog.open();
        },
        handleButtonExplanation(data) {
            this.$emit('button-click', data, 'explanation');
        },
        getAiFormatedData(data) {
            var viewData = data.aiformate; // 假设aiformate是data的直接属性
            // 直接检查viewData.config是否存在，并检查viewData.config.endDate是否未设置
            if (viewData?.config && !viewData.config.endDate) {
                viewData.config.endDate = data.deadline; // 如果endDate未设置，则用data.deadline来设置
            }
            viewData.viewId = data.viewId; // 设置viewId
            return viewData;
        },

        scrollToMonth(monthId) {
            var id = this.listId + '-nodes-' + this.symbol + '-' + monthId;
            // 使用传入的monthId来定位DOM元素，并滚动到它的位置
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },

    }
};
</script>
<style scoped>
.month-node {
    position: sticky;
    top: 0;
    /* 或你想要的任何值，这会决定元素距离顶部的距离 */
    z-index: 99;
    /* 确保月份标签在滚动时显示在其他内容之上 */
    background: rgba(255, 255, 255, 0.5);
    /* 可选，确保文本可读 */
    font-size: 16px;
}

.month-node .line {
    background: rgb(236, 240, 249, 0.75);
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #333;
    text-align: right;
    border-bottom: 1px solid #DDD;
}


.report-node {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    border: 1px solid #DDD;
    padding-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
}

.report-node .buttons {
    background-color: #FFF;
    height: 92px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.report-group-label {
    display: flex;
    width: 100%;
    margin-right: 12px;
    margin-top: -2px;
    margin-left: 8px;
    margin-bottom: 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 18px;
    font-weight: 500;
}

::v-deep .el-input__inner {
    border: none;
    background: transparent;
}

::v-deep .el-select input.el-input__inner {
    color: transparent;
    text-align: center;

}

::v-deep .el-select .el-input__suffix-inner i {
    color: #333;
    font-size: 18px;
    font-weight: 500;
}

.btn-tree {
    font-size: 14px;
    font-weight: 400;
    color: 666;
}
</style>