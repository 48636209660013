import { render, staticRenderFns } from "./CharacterMessage.vue?vue&type=template&id=c2e45ed2&scoped=true"
import script from "./CharacterMessage.vue?vue&type=script&lang=js"
export * from "./CharacterMessage.vue?vue&type=script&lang=js"
import style0 from "./CharacterMessage.vue?vue&type=style&index=0&id=c2e45ed2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e45ed2",
  null
  
)

export default component.exports