<template>
    <div class="flex-container">
        <el-input class="label flex-item" :value="node.desc" @input="updateDesc" placeholder="desc"></el-input>
        <el-input class="value flex-item" :value="node.vi" @input="updateValue"></el-input>
        <el-input class="yy flex-item" :value="node.yy" @input="updateYY" placeholder="y/y">
            <template v-slot:append>%</template>
        </el-input>
    </div>
</template>

<script>
import Tools from '../../GlobalTools';

export default {
    props: {
        node: {
            type: Object,
            required: true
        },
        colorOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            computedWidth: 120, // Initialize with a default min-width
            isEditing: false,
            currentColorIndex: 0,
            isYYInput: false
        };
    },
    watch: {
        'node.desc': function (newVal) {
            this.computedWidth = this.calculateWidth(newVal); // Call a method to calculate width
        }
    },
    methods: {
        toggleEdit() {
            this.isEditing = !this.isEditing;
        },
        updateDesc(newDesc) {
            console.log('updateDesc', newDesc);
            const updatedNode = { ...this.node, desc: newDesc };
            this.$emit('update:node', updatedNode, this.node.id); // 发送更新节点的事件
        },
        updateValue(newValue) {
            var floatV = Tools.convertToNumber(newValue);
            const updatedNode = { ...this.node, vi: newValue, v: floatV, weight: Math.abs(floatV) };
            // console.log('updateValue', newValue, updatedNode);
            this.$emit('update:node', updatedNode, this.node.id); // 发送更新节点的事件
        },
        updateYY(newYY) {
            console.log('updateYY', newYY);
            const updatedNode = { ...this.node, yy: newYY };
            this.$emit('update:node', updatedNode, this.node.id); // 发送更新节点的事件
        },
        changeColor() {
            const newColor = this.colorOptions[(this.currentColorIndex + 1) % this.colorOptions.length];
            const updatedNode = { ...this.node, color: newColor };
            this.$emit('update:node', updatedNode, this.node.id); // 发送更新节点的事件
        },
        toggleYY() {
            this.isYYInput = !this.isYYInput;
        },
        calculateWidth(value) {
            const minWidth = 120; // Set a minimum width
            const maxWidth = 300; // Set a maximum width if needed
            // You would implement logic here to calculate the width based on content,
            // for example by creating an off-screen element, setting its content, and measuring its width
            const calculatedWidth = Math.max(minWidth, Math.min(this.measureTextWidth(value), maxWidth));
            return calculatedWidth;
        },
        measureTextWidth(text) {
            // Implement text width measurement logic here
            // This is just a placeholder function, you will need to use actual DOM measurement
            return text.length * 8; // Example calculation, replace with actual measurement
        }
    }
};
</script>
  
<style scoped>
.flex-container {
    display: flex;
    width: 100%;
}

.flex-item {
    margin-right: 8px;
    /* Spacing between items */
}

.flex-item:last-child {
    margin-right: 0;
    /* No margin for the last item */
}

::v-deep  .el-input__inner,
::v-deep  .el-input__inner {
    padding: 0 8px !important;
    /* Override padding for accurate width */
    margin: 0 !important;
    /* Override margin for accurate width */
}

/* .el-input-group__append {
    margin-left: -30px; 
} */

.label {
    width: 40%;
    /* Set width to 40% for first two items */
}

.value {
    width: 40%;
    /* Set width to 40% for first two items */
}

.yy {
    width: 20%;
    /* Set width to 20% for the last item */
}
</style>