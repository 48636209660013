<template>
    <div>
        <HeaderComponent />
        <div class="analyze-reports-page content">
            <h1>How to Analyze a Balance Sheet</h1>

            <h2>What is a Balance Sheet</h2>
            <ul>
                <li>A balance sheet is a snapshot of a company's financial health at a specific point in time. It's
                    called a
                    "balance sheet" because the assets must equal the liabilities plus the shareholders' equity; in
                    other
                    words, it must balance.</li>
                <li>The basic equation for a balance sheet is: Assets = Liabilities + Shareholders' Equity</li>
                <li>The balance sheet provides valuable information about a company's assets, liabilities, and equity.
                    By
                    analyzing a balance sheet, you can gain a better understanding of a company's ability to pay its
                    debts,
                    grow its business, and return profits to its shareholders.</li>
                <li>
                    Taking Apple Inc. as an example, the balance sheet looks like this:
                    <div class="image-container">
                        <img src="@/assets/about/balance-sheet-example.png" alt="Balance Sheet Example">
                    </div>
                </li>
            </ul>

            <h2>What's included in a Balance Sheet</h2>
            <h3>Key aspects</h3>
            <ul>
                <li>
                    <strong>Assets</strong>: Anything that a company owns.
                    <ul>
                        <li>
                            Current assets:
                            <ul>
                                <li>Cash and cash equivalents: Cash on hand, cash in the bank, and other short-term
                                    investments that can be easily converted to cash.</li>
                                <li>Accounts receivable: Money owed to the company by its customers for goods or
                                    services
                                    that have been sold but not yet paid for.</li>
                                <li>Inventory: Raw materials, work-in-progress, and finished goods that the company
                                    holds
                                    for sale.</li>
                                <li>Prepaid expenses: Expenses paid in advance, such as rent or insurance.</li>
                            </ul>
                        </li>
                        <li>
                            Non-current assets:
                            <ul>
                                <li>Fixed assets: Assets used in the company's operations over a long period, such as
                                    property, plant, and equipment. They are depreciated over time (see Depreciation in
                                    our
                                    income statement review).</li>
                                <li>Long-term investments: Investments in other companies or assets the company plans to
                                    hold for a significant period, such as stocks and bonds.</li>
                                <li>Intangible assets: Assets that have no physical form, such as patents, trademarks,
                                    and
                                    goodwill (value attributed to reputation, brand, and customer base, and premium paid
                                    for
                                    acquisitions). They are amortized over time (see Amortization in our income
                                    statement
                                    review).</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Liabilities</strong>: Anything that a company owes.
                    <ul>
                        <li>
                            Current liabilities:
                            <ul>
                                <li>Accounts payable: Money owed to suppliers for goods or services received but not yet
                                    paid for.</li>
                                <li>Accrued expenses: Expenses that have been incurred but not yet paid for, such as
                                    wages
                                    and taxes.</li>
                                <li>Short-term debt: Loans and debts due to be paid within the next year.</li>
                            </ul>
                        </li>
                        <li>
                            Non-current liabilities:
                            <ul>
                                <li>Long-term debt: Loans and debts due to be paid after one year or more.</li>
                                <li>Deferred revenue: Money received before providing goods or services. As a result,
                                    the
                                    money is owed to the customers until the product is delivered or the services
                                    rendered.
                                </li>
                                <li>Pension liabilities: Obligations of the company to fund its employees' pensions.
                                </li>
                                <li>Other long-term liabilities: Lease obligations, taxes, and other obligations the
                                    company
                                    expects to pay in the future.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Shareholders' Equity</strong>: A company's value after all liabilities are paid off
                    (whatever is
                    left after you subtract liabilities from assets).
                    <p class="formula">Shareholders Equity = Assets - Liabilities</p>
                </li>
            </ul>

            <h3>Key ratios</h3>
            <ul>
                <li>
                    <strong>Current Ratio</strong>: This ratio measures a company's liquidity by comparing its current
                    assets to its current liabilities. It is calculated by dividing current assets by current
                    liabilities.
                    Anything around 1 is considered healthy. The higher, the better.
                    <p class="formula">Current Ratio = Current assets / Current liabilities</p>
                </li>
                <li>
                    <strong>Quick Ratio</strong>: This ratio measures a company's liquidity by considering only the most
                    liquid current assets. It is calculated by subtracting inventory from current assets and dividing
                    the
                    result by current liabilities. Anything around 1 is considered healthy. The higher, the better.
                    <p class="formula">Quick Ratio = [Cash + Short-term investments + Accounts receivable] / Current
                        liabilities</p>
                </li>
                <li>
                    <strong>Asset Turnover Ratio</strong>: This ratio measures a company's efficiency by dividing sales
                    by
                    total assets (the average of a specific period's beginning and ending assets). A higher ratio
                    indicates
                    that a company effectively uses its assets to generate sales. The higher, the better. However, what
                    is
                    considered good or not ultimately depends on the industry. For example, retail companies have a
                    higher
                    asset turnover ratio than utility companies.
                    <p class="formula">Asset Turnover Ratio = Sales / Assets</p>
                </li>
                <li>
                    <strong>Debt-to-Equity Ratio</strong>: This ratio measures a company's solvency by comparing its
                    total
                    liabilities to its total equity. It is calculated by dividing total liabilities by total equity. A
                    lower
                    ratio indicates that a company is less leveraged and more able to pay off its debts. Anything around
                    1
                    is considered healthy. The lower, the better.
                    <p class="formula">Debt-to-equity Ratio = Liability / Equity</p>
                </li>
                <li>
                    <strong>Return on Assets (ROA)</strong>: This ratio measures a company's profitability by comparing
                    its
                    net income to its total assets. It is calculated by dividing net income by total assets. A higher
                    ratio
                    indicates that a company's assets are used more efficiently to generate profits. Generally, the
                    higher,
                    the better. However, what is considered good or not ultimately depends on the industry and the
                    company.
                    For example, utility companies have a lower ROA than technology companies.
                    <p class="formula">Return on Assets (ROA) = Net Income / Assets</p>
                </li>
                <li>
                    <strong>Return on Equity (ROE)</strong>: This ratio measures a company's ability to generate profit
                    in
                    relation to its shareholders' equity. It is calculated by dividing net income by shareholders'
                    equity
                    and is used to evaluate a company's profitability and management efficiency using invested capital.
                    A
                    high ROE indicates that a company is generating a high return on the equity invested by
                    shareholders. At
                    the same time, a low ROE suggests the company is not generating an acceptable return. It's essential
                    to
                    compare the ROE ratio to the industry average and the company's historical data to understand its
                    performance better. Note that a high ROE can also indicate too much leverage.
                    <p class="formula">Return on Equity = Net income / Shareholder's Equity</p>
                </li>
            </ul>

            <h2>How to use visuals to help us digest the Balance Sheet in one minute</h2>
            <ul>
                <li>
                    Let's use the largest company in the world, Apple, using their balance sheet at the end of December
                    2023
                    <div class="image-container">
                        <img src="@/assets/about/balance-sheet-visual.png" alt="Balance Sheet Visual">
                    </div>
                </li>
                <li>
                    <strong>Assets</strong>: In green, I'm showing assets. They can be divided into two categories:
                    <ul>
                        <li>Current assets (assets that can be converted to cash within one year). Examples of current
                            assets include cash, accounts receivable, and inventory.</li>
                        <li>Non-current assets (assets that will take over one year to convert to cash). Examples
                            include
                            fixed assets like property, plant, and equipment.</li>
                    </ul>
                </li>
                <li>
                    <strong>Liabilities</strong>: In red, I'm showing liabilities. They can be divided into two
                    categories:
                    <ul>
                        <li>Current liabilities (liabilities that must be paid within one year). Examples include
                            accounts
                            payable, wages payable, and taxes payable.</li>
                        <li>Non-current liabilities (liabilities that will take over one year to pay off). Examples of
                            long-term liabilities include loans and mortgages.</li>
                    </ul>
                </li>
                <li>
                    <strong>Equity</strong>: In blue, I'm showing equity. It can be further divided into categories:
                    common
                    stock, preferred stock, and retained earnings. Equity is also known as net assets or shareholders'
                    equity.
                </li>
                <li>
                    As you can see from the visual of Apple's balance sheet, the total assets equal $353.5 billion, and
                    the
                    total liabilities and equity equal $353.5 billion ($279.4 billion and $74.1 billion). The balance
                    sheet
                    is balanced!
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue';
export default {
    components: {
        HeaderComponent
    },
    // ... other options ...
}
</script>

<style scoped>
/* Add any additional styles specific to this component */
.analyze-reports-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.content h1 {
    color: #333;
    margin-top: 1em;
}
.content h2 {
    color: #333;
    margin-top: 1em;
    margin-bottom: 12px;
}

.content p {
    color: #666;
    line-height: 1.6;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #666;
}

.router-link-active {
    color: #00aaff;
}

.help-links h2,
.contact h2 {
    margin-top: 2em;
}

.contact p {
    max-width: 800px;
    margin-top: 1em;
}

.image-container {
    text-align: center;
    margin: 20px 0;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

.formula {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
}
</style>