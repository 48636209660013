<template>
    <div>
        <HeaderComponent />
        <div class="analyze-reports-page content">
            <h1>How to Analyze an Income Statement</h1>
            <h2>What is an income statement</h2>
            <ul>
                <li>
                    The income statement, also known as the profit & loss statement or P&L for short. This document
                    shows
                    a company's financial performance over a specific period, usually a year or a quarter. The P&L shows
                    you
                    how much money the company made, how much it spent, and how much it kept as profit.
                </li>
                <li>
                    Let's take Apple as an example. The income statement looks like this：
                    <div class="image-container">
                        <img src="@/assets/about/about-case-income-table.png" alt="income-statement">
                    </div>
                </li>
            </ul>
            <h2>What's included in a P&L</h2>
            <h3>Key aspects</h3>
            <ul>
                <li>
                    <strong>Revenue</strong>: Think of revenue as money coming in the door. There are a few different
                    ways
                    that companies can
                    earn revenue, including: Sales of goods, Sales of services, Royalties, etc.
                </li>
                <li>
                    <strong>Cost of goods sold (COGS)</strong>: The direct cost of producing a product or providing a
                    service. For example, for Apple,
                    COGS might include the cost of raw materials, labor, and delivery. These are variable costs that
                    increase in direct proportion to revenue.
                </li>
                <li>
                    <strong>Expenses</strong>: Think of expenses as money going out the door. These are the indirect
                    costs
                    associated with a
                    company's day-to-day business activities, such as salaries, rent, utilities, and supplies,
                    including: Selling and marketing (S&M), Research and development (R&D), General and administrative
                    (G&A), etc.
                </li>
                <li>
                    <strong>Net income/profit</strong>: The company's profits are calculated by subtracting expenses
                    from
                    revenue. This is
                    the money left over after everything else has been paid for.
                </li>
            </ul>
            <h3>Key ratios</h3>
            <ul>
                <li>
                    <strong>Gross margin</strong>:
                    <ul>
                        <li>Gross margin is an excellent way to measure a company's earnings potential. It represents
                            the
                            percentage of each dollar of revenue the company retains as profit before accounting for
                            other
                            expenses such as selling, general, and administrative (SG&A) expenses.</li>
                        <li>Here's the formula for calculating gross margin:
                            <p class="formula">Gross margin = (Revenue - COGS) / Revenue</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Operating margin</strong>:
                    <ul>
                        <li>Operating margin is a financial metric that shows a company's profitability after accounting
                            for
                            all of its expenses, except for taxes, interests, and extraordinary items that are not part
                            of
                            normal business operations. It's like the "real" profit a company makes from its operations
                            after all the bills have been paid.</li>
                        <li>Here's the formula for calculating the operating margin:
                            <p class="formula">Operating margin = Operating income / Revenue</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Net margin</strong>:
                    <ul>
                        <li>Net margin, also known as "net profit margin," is a financial metric showing how much profit
                            a
                            company makes after accounting for all of its expenses, including taxes, interest, and
                            extraordinary items. It's like the cherry on top of a company's profit sundae - the final
                            profit
                            a company gets to keep.</li>
                        <li>Here's the formula for calculating the net margin:
                            <p class="formula">Net margin = Net income / Revenue</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Earnings per share (EPS)</strong>:
                    <ul>
                        <li>Earnings per share, also known as EPS, is a financial metric that shows how much profit a
                            company makes for each share. It's calculated by dividing a company's net income by the
                            number
                            of shares outstanding.</li>
                        <li>Here's the formula for calculating EPS:
                            <p class="formula">EPS = Net income / Number of shares outstanding</p>
                        </li>
                    </ul>
                </li>
            </ul>
            <h2>How to use visuals to help us digest financial statements in one minute</h2>
            <div class="image-container">
                <img src="@/assets/about/about-case-income-visual.png" alt="income-statement-visual">
            </div>
            <ul>
                <li><strong>Revenue</strong>: In grey, I'm showing the money earned from selling products (iPhone,
                    Macbooks,
                    iPad, Watches, Airpods) or services (digital subscriptions, app store revenue, transaction fees, and
                    more).</li>
                <li><strong>Cost of goods sold (COGS)</strong>: The direct cost of producing a product or providing a
                    service. For example, for Apple, the COGS might include the cost of raw materials, labor, and
                    delivery.
                    These are variable costs that increase in direct proportion to revenue.</li>
                <li>
                    <strong>Operating expenses (OPEX)</strong>: These are the indirect costs associated with a company's
                    day-to-day business activities, such as salaries, rent, utilities, and supplies, including:
                    <ul>
                        <li>Selling and marketing (S&M): Sales and marketing teams, advertising costs, events,
                            conferences,
                            etc.</li>
                        <li>Research and development (R&D): Engineering and technical teams responsible for developing
                            new
                            products or improving existing ones.</li>
                        <li>General and administrative (G&A): Costs incurred to run the business, such as rent,
                            utilities,
                            finance, and human resources personnel.</li>
                    </ul>
                </li>
                <li><strong>Profit/Income</strong>: In green, I'm showing whatever is left after deducting the expenses.
                    Not
                    all P&Ls look the same. Some provide more detail, and some provide less.</li>
                <li>In addition, some industries report their numbers differently (such as banks or insurance
                    companies).
                    Apple's P&L is the most common format.</li>
            </ul>
        </div>
    </div>
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue';
export default {
    components: {
        HeaderComponent
    },
    // ... other options ...
}
</script>

<style scoped>
/* Add any additional styles specific to this component */

.analyze-reports-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.content h1{
    text-align: center;
    font-size: 32px;
    margin: 12px;
}
.content h2 {
    color: #333;
    margin-top: 1em;
    margin-bottom: 12px;
}

.content p {
    color: #666;
    line-height: 1.6;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #666;
}

.router-link-active {
    color: #00aaff;
}

.help-links h2,
.contact h2 {
    margin-top: 2em;
}

.contact p {
    max-width: 800px;
    margin-top: 1em;
}


.image-container {
    text-align: center;
    margin: 20px 0;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

.formula {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
}
</style>