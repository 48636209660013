
<template>
    <div>
        <div @click="handleClick" :id="containerId" class="image-container" :style="containerStyle">
            <CashFlowSankey ref="customerSankeyRef" :sankey-data="sankeyData" style="cursor: pointer;" />
        </div>
        <!-- 图像预览模态框 -->
        <div v-if="showModal" class="modal">
            <div class="modal-content" @click="showModal = false">
                <i class="el-icon-close close" @click="showModal = false"></i>
                <img v-if="generatedImage" :src="generatedImage" alt="Generated Image" @click.stop />
            </div>
        </div>
    </div>
</template>

<script>

import Tools from '@/GlobalTools';
import CashFlowSankey from '../creater/CashFlowSankey.vue';
export default {
    name: 'ReportCashFlow',
    components: {
        CashFlowSankey
    },
    props: {
        viewData: {
            type: Object,
            default: () => ({})
        },
        isCompareMode: {
            type: Boolean,
            default: false
        },
        listId: {
            type: Number,
            default: 0
        }
    },

    mounted() {
        this.containerId = 'id-report-casflow-' + this.viewData.viewId + '-' + this.listId;
        this.$nextTick(() => {
            this.updateContainerSize();
            window.addEventListener('resize', this.updateContainerSize);
            this.buildWithForm();
        });
    },
    data() {
        return {
            containerId: 'id-report-casflow',
            chatName: '',
            containerWidth: 0,
            containerHeight: 0,
            imageWidth: '100vw', // 初始图片宽度，您可以根据需要调整
            imageHeight: '100vh', // 初始图片高度，您可以根据需要调整
            generatedImage: null,
            showModal: false,
            symbol: '',
            sankeyData: {},
            config: {

            },
            form: {
                cashBegingBalance: {
                    id: 'cashBegingBalance',
                    desc: 'Cash Beging Balance',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 0,
                    type: 'desc-node-left',
                    vi: ''
                },
                caseEndingBalance: {
                    id: 'caseEndingBalance',
                    desc: 'Cash Ending Balance',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 0,
                    type: 'desc-node-right',
                    vi: ''
                },
                netCashFlow: {
                    id: 'netCashFlow',
                    desc: 'Net Cash Flow',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 4,
                    vi: '',
                    type: 'rect'
                },

                SubSumInCash: {
                    id: 'SubSumInCash',
                    desc: '',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashOA: {
                    id: 'SubSumIncashOA',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'blue-v1',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashIA: {
                    id: 'SubSumIncashIA',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'green-v1',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashFA: {
                    id: 'SubSumIncashFA',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red-v1',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutCash: {
                    id: 'SubSumOutCash',
                    desc: '',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutcashOA: {
                    id: 'SubSumOutcashOA',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutcashIA: {
                    id: 'SubSumOutcashIA',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutcashFA: {
                    id: 'SubSumOutcashFA',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                operatingActivities: {
                    id: 'operatingActivities',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'blue-v1',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'rect'
                },
                extraOAItems: [],
                investingActivities: {
                    id: 'investingActivities',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'green-v1',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'rect'
                },
                extraIAItems: [],
                financingActivities: {
                    id: 'financingActivities',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red-v1',
                    yy: '',
                    sort: 0,
                    vi: '',
                    type: 'rect'
                },
                extraFAItems: [],

            }
        }
    },
    watch: {
        isCompareMode(newValue) {
            if (newValue) {
                this.updateContainerSize();
            }
        }
    },
    methods: {
        async handleClick() {
            try {
                const image = await this.$refs.customerSankeyRef.generateImage();
                this.generatedImage = image;
                this.showModal = true; // 显示模态框
            } catch (error) {
                console.error('Error generating image:', error);
            }
        },
        updateContainerSize() {
            // 获取.chat-main元素
            const chatMainElement = document.querySelector('.reportView-tree');
            if (chatMainElement) {
                // 获取.chat-main的宽度
                this.containerWidth = Math.min(1280, chatMainElement.offsetWidth - 80);
                // 如果需要，可以根据比例或其他逻辑来设置高度    height: calc((100vw * 2/3 - 248px)* (2416 / 4296));
                this.containerHeight = this.containerWidth * (2416 / 4296);
            } else {
                console.error('.chat-main元素未找到');
            }
        },
        buildWithForm() {
            this.symbol = this.viewData.symbol;
            this.config = this.viewData.config;
            this.chatName = this.symbol + '-' + this.listId;
            this.companyName = this.viewData.companyName;
            Object.keys(this.viewData.form).forEach(key => {
                // 检查 this.form 中是否存在相应的键
                if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                    // 更新 this.form 中每个键对应的值
                    // 这里假设你只想更新 'v' 和 'weight' 字段
                    this.form[key].v = this.viewData.form[key].v;
                    this.form[key].weight = Math.abs(this.viewData.form[key].v); // 举例，根据需要可能会有所不同
                    this.form[key].desc_cn = this.viewData.form[key].desc_cn;
                }
            });
            this.addExtraItems = function (items, targetArray, color) {
                items.forEach(item => {
                    targetArray.push({
                        id: item.id,
                        v: item.v,
                        weight: Math.abs(item.v),
                        desc: item.desc,
                        desc_cn: item.desc_cn,
                        color
                    });
                });
            };

            // 使用通用函数添加项目
            this.addExtraItems(this.viewData.form.extraOAItems, this.form.extraOAItems, 'blue-v1');
            this.addExtraItems(this.viewData.form.extraIAItems, this.form.extraIAItems, 'green-v1');
            this.addExtraItems(this.viewData.form.extraFAItems, this.form.extraFAItems, 'red-v1');

            let netCashFlow = this.form.caseEndingBalance.v - this.form.cashBegingBalance.v;
            this.form.netCashFlow.v = netCashFlow;
            this.form.netCashFlow.weight = Math.abs(netCashFlow);
            this.form.netCashFlow.color = netCashFlow > 0 ? 'green' : 'red';
            this.form.netCashFlow.desc_cn = '净现金流量';

            this.sankeyData = this.generateSankeyData();
            // Tools.logObj('reportCalsFlow view buildWithForm', this.viewData);
            // Tools.logObj('reportCalsFlow view buildSnakey', this.sankeyData);
        },
        newNode(id, desc = '', v = 0, color = 'green', yy = '', sort = 0, vi = '') {
            return {
                id,
                desc,
                v,
                weight: Math.abs(v),
                color,
                yy,
                sort,
                vi
            }
        },
        getFormNodeSort(id, sortDefault) {
            if (id.startsWith('extra-oa')) {
                return 3;
            }
            if (id.startsWith('extra-ia')) {
                return 2;
            }
            if (id.startsWith('extra-fa')) {
                return 1;
            }
            if (id.startsWith('netCashFlow')) {
                return 4;
            }
            if (this.form[id]) {
                return this.form[id].sort;
            }
            return sortDefault;
        },
        getFormKeyV(id) {
            if (this.form[id]) {
                return this.form[id].v;
            }
            return 0;
        },
        getFormKeyAbsV(id) {
            if (this.form[id]) {
                return Math.abs(this.form[id].weight);
            }
            return 0;
        },
        //自动求和收入
        autoCalcNetCashflow() {
            this.form.netCashFlow.v = this.form.caseEndingBalance.v - this.form.cashBegingBalance.v;
            // console.log('autoCalcNetCashflow:', this.form.netCashFlow.v, this.form.caseEndingBalance.v, this.form.cashBegingBalance.v);
            this.form.netCashFlow.vi = this.form.netCashFlow.v + '';
            this.form.netCashFlow.weight = Math.abs(this.form.netCashFlow.v);
            this.form.netCashFlow.color = this.form.netCashFlow.v > 0 ? 'green' : 'red';
        },
        autoCalcGroupSum(groupId) {
            var sum = 0;
            switch (groupId) {
                case 'operatingActivities':
                    sum = this.form.extraOAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.operatingActivities.v = sum;
                    this.form.operatingActivities.vi = sum + '';
                    this.form.operatingActivities.weight = Math.abs(sum);
                    this.form.operatingActivities.color = 'blue-v1';
                    break;
                case 'investingActivities':
                    sum = this.form.extraIAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.investingActivities.v = sum;
                    this.form.investingActivities.vi = sum + '';
                    this.form.investingActivities.weight = Math.abs(sum);
                    this.form.investingActivities.color = 'green-v1';
                    break;
                case 'financingActivities':
                    sum = this.form.extraFAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.financingActivities.v = sum;
                    this.form.financingActivities.vi = sum + '';
                    this.form.financingActivities.weight = Math.abs(sum);
                    this.form.financingActivities.color = 'red-v1';
                    break;
                default: break;
            }
        },
        updateSubSumIncome(sumOA, sumIA, sumFA) {
            if (sumOA > 0) {
                if (!this.form.SubSumIncashOA || this.form.SubSumIncashOA.v !== sumOA) {
                    this.form.SubSumIncashOA = this.newNode('SubSumIncashOA', 'Operating Activities', sumOA, 'blue-v1', '', 3, sumOA + '');
                }
            } else {
                if (this.form.SubSumIncashOA)
                    this.form.SubSumIncashOA.v = 0;
            }
            if (sumIA > 0) {
                if (!this.form.SubSumIncashIA || this.form.SubSumIncashIA.v !== sumIA) {
                    this.form.SubSumIncashIA = this.newNode('SubSumIncashIA', 'Investing Activities', sumIA, 'green-v1', '', 2, sumIA + '');
                }
            } else {
                if (this.form.SubSumIncashIA) {
                    this.form.SubSumIncashIA.v = 0;
                }
            }
            if (sumFA > 0) {
                if (!this.form.SubSumIncashFA || this.form.SubSumIncashFA.v !== sumFA) {
                    this.form.SubSumIncashFA = this.newNode('SubSumIncashFA', 'Financing Activities', sumFA, 'red-v1', '', 1, sumFA + '');
                }
            } else {
                if (this.form.SubSumIncashFA) {
                    this.form.SubSumIncashFA.v = 0;
                }
            }
            let sum = sumOA + sumIA + sumFA;
            if (!this.form.SubSumInCash || this.form.SubSumInCash.v !== sum) {
                this.form.SubSumInCash = this.newNode('SubSumInCash', 'Total Cash Inflows', sum, 'gray', '', 3, sum + '');
            }
        },
        updateSubSumOutcome(sumOA, sumIA, sumFA) {
            if (sumOA < 0) {
                if (!this.form.SubSumOutcashOA || this.form.SubSumOutcashOA.v !== sumOA) {
                    this.form.SubSumOutcashOA = this.newNode('SubSumOutcashOA', 'Operating Activities', sumOA, 'blue-v1', '', 3, sumOA + '');
                }
            } else {
                if (this.form.SubSumOutcashOA) {
                    this.form.SubSumOutcashOA.v = 0;
                }
            }
            if (sumIA < 0) {
                if (!this.form.SubSumOutcashIA || this.form.SubSumOutcashIA.v !== sumIA) {
                    this.form.SubSumOutcashIA = this.newNode('SubSumOutcashIA', 'Investing Activities', sumIA, 'green-v1', '', 2, sumIA + '');
                }
            } else {
                if (this.form.SubSumOutcashIA) {
                    this.form.SubSumOutcashIA.v = 0;
                }
            }
            if (sumFA < 0) {
                if (!this.form.SubSumOutcashFA || this.form.SubSumOutcashFA.v !== sumFA) {
                    this.form.SubSumOutcashFA = this.newNode('SubSumOutcashFA', 'Financing Activities', sumFA, 'red-v1', '', 1, sumFA + '');
                }
            } else {
                if (this.form.SubSumOutcashFA) {
                    this.form.SubSumOutcashFA.v = 0;
                }
            }
            let sum = sumOA + sumIA + sumFA;
            if (!this.form.SubSumOutCash || this.form.SubSumOutCash.v !== sum) {
                this.form.SubSumOutCash = this.newNode('SubSumOutCash', 'Total Cash Outflows', sum, 'gray', '', 3, sum + '');
            }
        },

        generateNodes() {
            const createNode = (item) => { // 使用箭头函数
                let type = item.type;
                if (['netCashFlow', 'operatingActivities', 'investingActivities', 'financingActivities'].includes(item.id)) {
                    type = 'rect';
                }
                return {
                    id: item.id,
                    v: item.v,
                    weight: Math.abs(item.v),
                    color: Tools.parseNodeColor(item.color),
                    name: item.id,
                    desc: item.desc,
                    yy: item.yy,
                    vi: item.vi,
                    type: type,
                    margin: null // Initialize margin
                }

            };
            let leftleaf = 0;
            let rightleaf = 0;
            const nodes = [];
            let cashInOA = 0;
            let cashInIA = 0;
            let cashInFA = 0;
            let cashOutOA = 0;
            let cashOutIA = 0;
            let cashOutFA = 0;
            this.form.extraOAItems.forEach(item => {
                if (item.v > 0) {
                    cashInOA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutOA += item.v;
                    rightleaf++;
                }

            });
            if (cashInOA === 0 && this.form.operatingActivities.v > 0) {
                cashInOA = this.form.operatingActivities.v;
            }
            if (cashOutOA === 0 && this.form.operatingActivities.v < 0) {
                cashOutOA = this.form.operatingActivities.v;
            }

            this.form.extraIAItems.forEach(item => {
                if (item.v > 0) {
                    cashInIA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutIA += item.v;
                    rightleaf++;
                }
            });
            if (cashInIA === 0 && this.form.investingActivities.v > 0) {
                cashInIA = this.form.investingActivities.v;
            }
            if (cashOutIA === 0 && this.form.investingActivities.v < 0) {
                cashOutIA = this.form.investingActivities.v;
            }
            this.form.extraFAItems.forEach(item => {
                if (item.v > 0) {
                    cashInFA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutFA += item.v;
                    rightleaf++;
                }
            });
            if (cashInFA === 0 && this.form.financingActivities.v > 0) {
                cashInFA = this.form.financingActivities.v;
            }
            if (cashOutFA === 0 && this.form.financingActivities.v < 0) {
                cashOutFA = this.form.financingActivities.v;
            }

            this.maxleaf = Math.max(leftleaf, rightleaf);
            this.updateSubSumIncome(cashInOA, cashInIA, cashInFA);
            this.updateSubSumOutcome(cashOutOA, cashOutIA, cashOutFA);
            Object.keys(this.form).forEach(key => {
                const item = this.form[key];
                // 检查是否是数字或可转换字符串并且大于0
                if (item && item.v !== null && !isNaN(parseFloat(item.v)) && parseFloat(item.v) !== 0) {
                    if (!['cashBegingBalance', 'caseEndingBalance', 'operatingActivities', 'investingActivities', 'financingActivities'].includes(item.id)) {
                        nodes.push(createNode(item));
                    }
                }
                // 如果是数组，遍历数组
                else if (item && Array.isArray(item)) {
                    item.forEach(subItem => {
                        if (!isNaN(parseFloat(subItem.v)) && parseFloat(subItem.v) !== 0) {
                            nodes.push(createNode(subItem));
                        }
                    });
                }
            });
            return nodes;
        },
        generateLinks() {
            const createLink = (source, target, value, color, sort = 0) => {
                // console.log('createLink:', source, target, value, color);
                return {
                    source,
                    target,
                    value: Math.abs(value),
                    color: Tools.parseLinkColor(color),
                    sort
                };
            };
            var links = [];
            this.form.extraOAItems.forEach((item) => {
                if (item.v && item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashOA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashOA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            this.form.extraIAItems.forEach((item) => {
                if (item.v && item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashIA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashIA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            this.form.extraFAItems.forEach((item) => {
                if (item.v && item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashFA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashFA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            if (this.getFormKeyV('SubSumIncashOA') > 0) {
                links.push(createLink('SubSumIncashOA', 'SubSumInCash', this.getFormKeyV('SubSumIncashOA'), 'blue-v1', 3));
            } else {
                // console.log('SubSumIncashOA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashOA'));
            }
            if (this.getFormKeyV('SubSumIncashIA') > 0) {
                links.push(createLink('SubSumIncashIA', 'SubSumInCash', this.getFormKeyV('SubSumIncashIA'), 'green-v1', 2));
            } else {
                // console.log('SubSumIncashIA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashIA'));
            }
            if (this.getFormKeyV('SubSumIncashFA') > 0) {
                links.push(createLink('SubSumIncashFA', 'SubSumInCash', this.getFormKeyV('SubSumIncashFA'), 'red-v1', 1));
            } else {
                // console.log('SubSumIncashFA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashFA'));
            }
            if (this.getFormKeyV('SubSumOutcashOA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashOA', this.getFormKeyV('SubSumOutcashOA'), 'blue-v1', 3));
            }
            if (this.getFormKeyV('SubSumOutcashIA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashIA', this.getFormKeyV('SubSumOutcashIA'), 'green-v1', 2));
            }
            if (this.getFormKeyV('SubSumOutcashFA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashFA', this.getFormKeyV('SubSumOutcashFA'), 'red-v1', 1));
            }

            if (this.getFormKeyV('netCashFlow') > 0) {
                links.push(createLink('SubSumInCash', 'netCashFlow', this.getFormKeyV('netCashFlow'), 'green', 4));
                links.push(createLink('SubSumInCash', 'SubSumOutCash', this.getFormKeyAbsV('SubSumOutCash'), 'gray', 4));
            } else {
                links.push(createLink('netCashFlow', 'SubSumOutCash', this.getFormKeyV('netCashFlow'), 'red', 4));
                links.push(createLink('SubSumInCash', 'SubSumOutCash', this.getFormKeyAbsV('SubSumInCash'), 'gray', 4));
            }

            return links;
        },
        generateTitle() {
            this.config.title = `${this.companyName} Cash Flow Statement ${this.getFormateDate()}`
            this.config.subtitle = `${this.config.months} Ended, Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.nodes = this.generateNodes();
            } catch (e) {
                console.log('generateNodes exception:', e);
            }
            try {
                this.links = this.generateLinks();
            } catch (e) {
                console.log('generateLinks exception:', e);
            }
            this.generateTitle();
            // Tools.logObj('generateSankeyData Nodes:', this.nodes);
            // Tools.logObj('generateSankeyData Links:', this.links);
            return {
                symbol: this.symbol,
                containerId: this.containerId,
                chatName: this.chatName,
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                nodes: this.nodes,
                links: this.links,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName,
                cashBegin: this.form.cashBegingBalance.v,
                cashEnd: this.form.caseEndingBalance.v,
                netCash: this.form.netCashFlow.v,
                maxleaf: this.maxleaf,
                activities: [this.form.operatingActivities, this.form.investingActivities, this.form.financingActivities]
            };
        },
        getFormateDate() {
            // 检测日期格式并相应地转换
            let inputDate = this.config.endDate;
            let endDate;

            // 检测是否为"YYYYMMDD"格式
            if (/^\d{8}$/.test(inputDate)) {
                // 将"YYYYMMDD"格式转换为"YYYY-MM-DD"，这样可以直接被Date对象正确解析
                inputDate = `${inputDate.substring(0, 4)}-${inputDate.substring(4, 6)}-${inputDate.substring(6, 8)}`;
                endDate = new Date(inputDate);
            } else {
                // 假设其他情况是有效的ISO 8601格式或其他可以直接被Date解析的格式
                endDate = new Date(inputDate);
            }

            // 使用toLocaleDateString方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号，这里看起来没有必要的替换，所以简化掉了
            formattedDate = formattedDate.replace(',', '');

            return formattedDate;
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateContainerSize);
    },
    computed: {
        containerStyle() {
            return {
                width: this.containerWidth + 'px',
                height: this.containerHeight + 'px',
            };
        },
        imageStyle() {
            return {
                width: this.imageWidth + 'px',
                height: this.imageHeight + 'px',
            };
        },
        currentLanguage() {
            return this.$store.getters.getSelectedLanguage; // 从 Vuex store 获取语言值
        }
    },
}


</script>

<style scoped>
.image-container {
    cursor: pointer;
    /* 窗口的最大高度 */
    display: flex;
    /* 使用 Flexbox 布局 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    text-align: center;
    overflow: hidden;
    /* 允许滚动 */
    position: relative;
    /* background-color: #F5F5F5; */
}

.image-container svg {
    cursor: pointer !important;
}

.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.modal-content img {
    width: calc(96vw);
    height: calc((96vw)* (2416 / 4296));
}

.modal-content .close {
    position: absolute;
    /* 绝对定位 */
    top: 0;
    /* 顶部对齐 */
    right: 0;
    /* 右侧对齐 */
    padding: 8px;
    /* 为点击提供足够空间 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
    z-index: 10;
    /* 确保在其他内容之上 */
    margin: 48px;
    color: #FFF;
    float: right;
    font-size: 24px;
    font-weight: 400;
    border-radius: 50%;
    opacity: .8;
    box-sizing: border-box;
    background-color: #606266;
    align-items: center;
    justify-content: center;
}


.modal-content .close:hover,
.modal-content .close:focus {
    color: color;
    text-decoration: none;
    cursor: pointer;
}
</style>