const companyTags = [
  { name: 'New Energy Vehicles', icon: 'el-icon-bicycle', iconWeb: require('@/assets/industry/tag-car.png'), iconSize: 64, gradientStart: '#6DD5FA', gradientEnd: '#FF758C', description: 'Sustainable transportation using electric vehicles' },
  { name: 'SaaS', icon: 'el-icon-cloudy', gradientStart: '#667eea', gradientEnd: '#764ba2', description: 'Cloud-based software solutions for businesses' },
  { name: 'Software Development', icon: 'el-icon-link', gradientStart: '#88949F', gradientEnd: '#1E4063', description: 'Building and customizing software applications' },
  { name: 'Retail', icon: 'el-icon-shopping-bag-1', gradientStart: '#FF4B1F', gradientEnd: '#1FDDFF', description: 'Selling products to consumers through various channels' },
  { name: 'Tech Hardware', icon: 'el-icon-monitor', gradientStart: '#4b6cb7', gradientEnd: '#182848', description: 'Developing and manufacturing electronic devices' },
  { name: 'Broadcast Media', icon: 'el-icon-headset', gradientStart: '#717EB9', gradientEnd: '#434343', description: 'Creating and distributing audio & video content' },
  { name: 'Healthcare', icon: 'el-icon-first-aid-kit', gradientStart: '#DCE35B', gradientEnd: '#45B649', description: 'Providing medical care and improving patient outcomes' },
  { name: 'Real Estate', icon: 'el-icon-house', gradientStart: '#4CA1AF', gradientEnd: '#C4E0E5', description: 'Buying, selling and managing properties' },
  { name: 'Biotechnology', icon: 'el-icon-aim', iconWeb: require('@/assets/industry/tag-biotech.png'), gradientStart: '#5f2c82', iconSize: 40, gradientEnd: '#49a09d', description: 'Using biology for innovation in medicine, agriculture & more' },
  { name: 'Financial Services', icon: 'el-icon-bank-card', gradientStart: '#36D1DC', gradientEnd: '#5B86E5', description: 'Managing money and investments for individuals & businesses' },
  { name: 'Educational Services', icon: 'el-icon-school', gradientStart: '#f7971e', gradientEnd: '#ffd200', description: 'Providing education and training opportunities' },
  { name: 'Logistics', icon: 'el-icon-truck', gradientStart: '#f2709c', gradientEnd: '#ff9472', description: 'Efficiently moving goods from origin to destination' },
  { name: 'Banking', icon: 'el-icon-money', gradientStart: '#003973', gradientEnd: '#E5E5BE', description: 'Offering financial products and services to customers' },
  { name: 'Investment Management', icon: 'el-icon-money', gradientStart: '#ffd89b', gradientEnd: '#19547b', description: 'Growing wealth through professional investment strategies' },
  { name: 'Digital Currency', icon: 'el-icon-coin', gradientStart: '#2A2A72', gradientEnd: '#009FFD', description: 'Virtual currencies and blockchain technology' },
  { name: 'Natural Resources', icon: 'el-icon-s-opportunity', iconWeb: require('@/assets/industry/tag-water.png'), iconSize: 44, gradientStart: '#134E5E', gradientEnd: '#71B280', description: 'Managing and utilizing natural resources responsibly' },
  { name: 'Data Analytics', icon: 'el-icon-data-analysis', gradientStart: '#06beb6', gradientEnd: '#48b1bf', description: 'Extracting insights from data to inform decisions' },
  { name: 'Cybersecurity', icon: 'el-icon-lock', gradientStart: '#ff416c', gradientEnd: '#ff4b2b', description: 'Protecting systems and data from cyberattacks' },
  { name: 'Manufacturing', icon: 'el-icon-setting', gradientStart: '#F7971E', gradientEnd: '#FFD200', description: 'Producing goods on a large scale' },
  { name: 'Environmental Tech', icon: 'el-icon-sunny', iconWeb: require('@/assets/industry/tag-env.png'), iconSize: 48, gradientStart: '#56ab2f', gradientEnd: '#a8e063', description: 'Developing technologies for environmental sustainability' },
];
const companyIndustries = [
  {
    id: 'Energy',
    name: 'Energy',
    icon: 'el-icon-sunrise',
    description: 'Energy resources exploration, production, and distribution',
    'description-cn': '能源资源勘探、生产和分配'
  },
  {
    id: 'Basic_Materials',
    name: 'Basic Materials',
    icon: 'el-icon-receiving',
    description: 'Discovery, development, and processing of raw materials',
    'description-cn': '原材料的发现、开发和加工'
  },
  {
    id: 'Industrials',
    name: 'Industrials',
    icon: 'el-icon-set-up',
    description: 'Goods production for construction and manufacturing',
    'description-cn': '生产用于建筑和制造业的商品'
  },
  {
    id: 'consumer_goods',
    name: 'Consumer Goods',
    icon: 'el-icon-shopping-bag-1',
    description: 'Manufacturing and distribution of consumer products',
    'description-cn': '消费品的生产和分配'
  },
  {
    id: 'Health_Care',
    name: 'Health Care',
    icon: 'el-icon-first-aid-kit',
    description: 'Medical services, equipment, drugs, or insurance',
    'description-cn': '医疗服务、设备、药品或保险'
  },
  {
    id: 'Consumer_Services',
    name: 'Consumer Services',
    icon: 'el-icon-service',
    description: 'Services for consumers, including retail, entertainment, and others',
    'description-cn': '面向消费者的服务,包括零售、娱乐等'
  },
  {
    id: 'Telecommunications',
    name: 'Telecommunications',
    icon: 'el-icon-mobile-phone',
    description: 'Telecommunications services and equipment',
    'description-cn': '电信服务和设备'
  },
  {
    id: 'Utilities',
    name: 'Utilities',
    icon: 'el-icon-lightning',
    description: 'Electric, gas, and water services',
    'description-cn': '电力、燃气和水务服务'
  },
  {
    id: 'Financials',
    name: 'Financials',
    icon: 'el-icon-bank-card',
    description: 'Financial services, including banking, insurance, and real estate',
    'description-cn': '金融服务,包括银行、保险和房地产'
  },
  {
    id: 'Technology',
    name: 'Technology',
    icon: 'el-icon-cpu',
    description: 'Development, manufacturing, and distribution of technology products and services',
    'description-cn': '技术产品和服务的开发、制造和分配'
  }
];

function getIndustryIdByName(name) {
  const industry = companyIndustries.find(industry => industry.name === name);
  return industry ? industry.id : '';
}

const Global = {
  companyTags,
  companyIndustries,
  getIndustryIdByName
}

export default Global;
