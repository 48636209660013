<template>
    <div class="analyze-reports-page">
        <h1>How to Analyze a Financial Report</h1>

        <div>
            <img src="@/assets/about/Buffett.jpg" />
            <blockquote>
                "Warren Buffett once spoke about his secret to investment success: 'I just sit in my office and read all
                day.'
                Among his favorite reading materials are annual filings, known in the US as 10-Ks. These documents offer
                more
                than just the polished summaries found in marketing materials; they reveal the inner workings of
                companies."
            </blockquote>
        </div>


        <h2>What is a 10-K?</h2>
        <p>A 10-K report is an annual report that publicly traded companies in the United States must submit to the SEC.
            It
            provides a comprehensive overview of a company's financial health, operational strategies, and market risks.
        </p>

        <h2>Navigating the 10-K: Key Sections</h2>
        <p>A 10-K report may seem daunting at first glance due to its length. However, understanding its structure can
            make navigating and extracting essential insights easier.</p>

        <p>The typical structure of a 10-K follows this format:</p>
        <ul>
            <li>PART I: Business and risk factors.</li>
            <li>PART II: Financial statements and management's analysis.</li>
            <li>PART III: Executive compensation and security ownership.</li>
        </ul>

        <p>The table of contents looks like this:</p>
        <img src="@/assets/about/10k-table-of-contents.png" alt="10-K Table of Contents" />

        <h2>Key Sections to Pay Attention to in the 10-K</h2>

        <div v-for="section in keySections" :key="section.title" class="section">
            <h3>{{ section.number }}. {{ section.title }}</h3>
            <p>{{ section.description }}</p>

            <h4>🔎 What to Look For</h4>
            <p>{{ section.lookFor }}</p>

            <h4>✅ Positive Signs</h4>
            <p>{{ section.positiveSigns }}</p>

            <h4>❌ Red Flags</h4>
            <p>{{ section.redFlags }}</p>

            <div v-if="section.statements" class="statements">
                <div v-for="statement in section.statements" :key="statement.name">
                    <p>{{ statement.name }}: {{ statement.description }}</p>
                    <p>💡<router-link :to="statement.link">{{ statement.linkText }}</router-link></p>
                </div>
            </div>

            <p v-if="section.example" class="example">{{ section.example }}</p>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            keySections: [
                {
                    number: 1,
                    title: 'Business',
                    description: `This section provides a comprehensive view of the company's primary operations, including its products and services, key markets, and competitive landscape.Pay attention to how the company generates revenue and any significant changes in its business model or market position.',
            lookFor: 'Understand the company's primary operations, revenue streams, competitive advantages, and strategic direction.',
            positiveSigns: 'A well-diversified product line, significant market share, clear growth strategy, and strong competitive moats.`,
                    redFlags: 'Overdependence on a single product or market, declining industry trends, unclear business direction.',
                    example: `It's critical to understand a business before considering investing.`
                },
                {
                    number: 2,
                    title: 'Risk Factors',
                    description: `This part outlines the risks and challenges the company faces. These risks can be operational, financial, regulatory, or market-related. Understanding these risks helps you assess the company's potential vulnerabilities and how they might impact future performance.',
            lookFor: 'Comprehensive identification of potential business, financial, and industry risks.`,
                    positiveSigns: `Honest, detailed risk assessments with clear mitigation plans, showing management's proactive approach.`,
                    redFlags: 'Generic risk descriptions, ignoring industry-specific threats, overly optimistic views.'
                },
                {
                    number: 3,
                    title: 'Financial Statements',
                    description: 'The core of the 10-K. This section shows the profitability, solvability, and liquidity of the business.',
                    lookFor: 'Review revenue trends, profitability metrics, debt levels, and asset management efficiency.',
                    positiveSigns: 'Sustained revenue growth, stable or improving margins, manageable debt, efficient asset utilization.',
                    redFlags: 'Erratic revenue patterns, shrinking margins, ballooning debt, poor return on assets.',
                    statements: [
                        {
                            name: 'Income Statement',
                            description: 'Revenue, expenses, and profit or loss.',
                            link: '/help/income-statement',
                            linkText: 'How to Analyze an Income Statement'
                        },
                        {
                            name: 'Balance Sheet',
                            description: 'Assets and liabilities snapshot.',
                            link: '/help/balance-sheet',
                            linkText: 'How to Analyze a Balance Sheet'
                        },
                        {
                            name: 'Cash Flow Statement',
                            description: 'How cash has moved over the period.',
                            link: '/help/cash-flow',
                            linkText: 'How to Analyze a Cash Flow Statement'
                        }
                    ]
                },
                {
                    number: 4,
                    title: `Management's Discussion and Analysis(MD&A)`,
                    description: `In MD&A, the company's management provides its perspective on the financial and operational results.This narrative can give context to the numbers in the financial statements, offering insights into management's strategy, future outlook, and areas of focus. It provides critical qualitative insights if you don't have time to listen to earnings calls.',
            lookFor: 'Management's narrative on past performance, future prospects, and strategy execution.`,
                    positiveSigns: 'Clear correlation between strategy and results, realistic future guidance, acknowledgment of challenges with plans to address them.',
                    redFlags: 'Discrepancies between management narrative and financial results, overly aggressive growth projections, lack of clarity on strategy implementation.',
                    example: 'For example, Apple provides details on its product line-up, future expected releases, and explanations of the performance by geography and category.'
                },
                {
                    number: 5,
                    title: 'Notes to Financial Statements',
                    description: 'These notes provide critical details that supplement the financial statements, such as accounting methods, contingencies, legal proceedings, and detailed breakdowns of financial line items. Understanding these notes is crucial for a deeper analysis of the financial data.',
                    lookFor: 'Insight into accounting policies, revenue recognition practices, and significant financial commitments.',
                    positiveSigns: 'Consistent application of accounting principles, transparent revenue recognition methods, and reasonable financial obligations.',
                    redFlags: 'Frequent changes in accounting methods, ambiguous revenue recognition, substantial off-balance sheet liabilities.'
                }
            ]
        }
    }
}
</script>

<style scoped>
.analyze-reports-page {
    max-width: 800px;
    margin: 0 auto;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

h2 {
    font-size: 2em;
    margin-top: 40px;
    margin-bottom: 20px;

}

h3 {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 20px;

}

h4 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 20px;
}

p {
    line-height: 1.6;
    font-size: 16px;
}

blockquote {
    font-style: italic;
    border-left: 4px solid #ccc;
    padding-left: 20px;
    margin: 20px 0;
}

.section {
    margin-bottom: 40px;
}

.statements {
    margin-top: 20px;
}

.example {
    margin-top: 10px;
    font-style: italic;
}

img {
    max-width: 100%;
    margin: 20px 0;
}
</style>