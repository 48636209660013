<template>
    <div class="gradient-background">
        <el-autocomplete class="search-input" v-model="searchText" :fetch-suggestions="querySearchAsync"
            placeholder="Search Company Name or Ticker Here" @select="handleSelect" :trigger-on-focus="false"
            :clearable="false" :highlight-first-item="true" :debounce="800" :hide-loading="true"
            popper-class="searchresult-poperClass" ref="autocomplete">
            <template #prefix>
                <i class="el-icon-search"></i>
            </template>

            <template slot-scope="{ item }">
                <!-- 新增公司条目 -->
                <div v-if="item.addNew" class="add-new-company-item">
                    <i class="el-icon-plus"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-item" v-else-if="!item.loading && !item.notFound"
                    style="display: flex;align-items: center;">
                    <img v-if="!isIconUrlEmpty(item.iconUrl)" :src="item.iconUrl" class="icon-image" alt="icon" />
                    <i v-else :class="item.iconfont" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-empty" v-else-if="item.notFound">
                    <i class="el-icon-warning-outline" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="loading-indicator" v-if="item.loading">
                    <i class="el-icon-loading" style="margin-left:8px;margin-right:12px;font-size:14px"></i>
                    <span>{{ item.value }}</span>
                </div>

            </template>

            <template #empty>
                <div class="autocomplete-empty">
                    No search result
                </div>
            </template>
        </el-autocomplete>
        <NofoundTicker ref="nofoundTicker" :searchText="searchText" @open-login="openLoginDialog"/>
    </div>
</template>

<script>
import SearchListContainer from './SearchListContainer.vue';

export default {
    name: 'SearchListHomeContainer',
    extends: SearchListContainer,
}

</script>

<style scoped>
::v-deep .search-input {
    width: 100%;

}

::v-deep .el-input__prefix {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    left: 8px;
    width: 32px;
}

::v-deep  .el-input__inner {
    width: 100%;
    line-height: 1.5em;
    height: 56px;
    border-radius: 28px ;
    padding: 12px 24px 10px 40px;
    font-size: 16px;
    border: 1.5px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(135deg, #1699E4 30%, #1682EC 50%, #A700F8 70%);
    ;
    transition: box-shadow 0.3s ease-in-out;
    /* 添加过渡效果 */
}

::v-deep .el-input__inner:hover {
    box-shadow:
        -1.5px -1.5px 12px rgba(22, 153, 228, 0.5),
        /* 浅蓝色，透明度50% */
        0px 0px 12px rgba(22, 130, 236, 0.5),
        /* 中蓝色，透明度50% */
        1.5px 1.5px 12px rgba(167, 0, 248, 0.5);
    /* 紫色，透明度50% */

}


::v-deep .autocomplete-item {
    display: flex !important;
    align-items: center !important;
}

::v-deep img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    /* 根据需要调整图标和文本之间的间隙 */
}

.add-new-company-item {
    /* 为新增按钮添加的样式 */
    color: #3489FF;
    /* 示例颜色 */
    display: flex;
    align-items: center;
    font-weight: bold;
}

.add-new-company-item i.el-icon-plus {
    margin-right: 8px;
    /* 示例图标颜色 */
}

</style>