<template>
    <div>
        <el-autocomplete class="search-input" v-model="searchText" :fetch-suggestions="querySearchAsync"
            placeholder="Search Company Name or Ticker Here" @select="handleSelect" :trigger-on-focus="false"
            :clearable="true" :highlight-first-item="true" :debounce="800" :hide-loading="true" ref="autocomplete">
            <template #prefix>
                <i class="el-icon-search"></i>
            </template>

            <template slot-scope="{ item }">
                <!-- 新增公司条目 -->
                <div v-if="item.addNew" class="add-new-company-item">
                    <i class="el-icon-plus"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-item" v-else-if="!item.loading && !item.notFound"
                    style="display: flex;align-items: center;">
                    <img v-if="!isIconUrlEmpty(item.iconUrl)" :src="item.iconUrl" class="icon-image" alt="icon" />
                    <i v-else :class="item.iconfont" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-empty" v-else-if="item.notFound">
                    <i class="el-icon-warning-outline" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="loading-indicator" v-if="item.loading">
                    <i class="el-icon-loading" style="margin-left:8px;margin-right:12px;font-size:14px"></i><span>{{
            item.value }}
                    </span>
                </div>

            </template>

            <template #empty>
                <div class="autocomplete-empty">
                    No search result
                </div>
            </template>
        </el-autocomplete>
        <NofoundTicker ref="nofoundTicker" :searchText="searchText" @open-login="openLoginDialog"/>
    </div>
</template>

<script>
import axios from 'axios';
import Tools from '../../GlobalTools.js';
import NofoundTicker from './NofoundTicker.vue';

export default {
    name: 'SearchListContainer',
    components: {
        NofoundTicker
    },
    props: {
        showAddNew: {
            type: Boolean,
            default: false // 默认不显示新增项
        },
    },
    data() {
        return {
            searchText: '',
            searchTextFeedback: '',
            symbolSearch: {
                searching: false,
                searchResults: [],
                searchedPapermontyReuslts: [],
                timeout: null,
                cancelToken: null,
            },
            isLoadingThirdPartyData: false,
        }
    },
    watch: {
        // 监听 searchText 的变化
        searchText(newVal) {
            // 每当 searchText 改变，向父组件发射一个自定义事件
            this.$emit('update:searchText', newVal);
            this.showNoFoundButton = newVal.trim().length > 0;
            this.handleSearchTextChange();
        },
        searchedPapermontyReuslts(newVal) {
            console.log('searchedPapermontyReuslts', newVal);
            this.$emit('update:searchResults', newVal);
        }
    },
    methods: {
        openLoginDialog() {
            this.$emit('open-login');
        },
        isIconUrlEmpty(iconUrl) {
            return Tools.isEmpty(iconUrl);
        },
        querySearchAsync(queryString, cb) {
            if (this.symbolSearch.timeout) { // 清除上一个延时
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.fetchData(queryString, cb);
            }, 10); // 设置输入延时
        },
        processResults(response, cb, isThirdParty = false) {
            let formattedResults = [];
            var companyArray = (Array.isArray(response) && response.length === 0) || !response?.data?.companies ? [] : response.data.companies;

            if (Tools.isArrayEmpty(companyArray) && isThirdParty) {
                // 如果没有搜索结果，添加一个表示无结果的对象
                formattedResults.push({
                    value: 'No matching companies or tickers found.',
                    iconfont: 'el-icon-warning',
                    notFound: true
                });
            } else {
                // 否则，格式化返回的搜索结果
                formattedResults = companyArray.map(
                    (company) => {
                        var iconUrl = '';
                        var iconfont = '';
                        if (company.source === 'papermoney.ai') {
                            iconUrl = require('@/assets/serch-icon.png');
                        } else {
                            iconfont = 'el-icon-tickets';
                        }
                        return {
                            value: `${company.name} (${company.symbol}) - ${company.source}`,
                            iconUrl: iconUrl,
                            iconfont: iconfont,
                            ...company
                        }
                    });

            }

            if (!isThirdParty) {
                this.searchResults = formattedResults;
                if (this.isLoadingThirdPartyData) {
                    // 添加加载指示器
                    // console.log('添加加载指示器');
                    this.searchResults.push({ value: 'Searching more companies...', loading: true });
                }
            } else {
                // 删除原有的加载指示器
                this.searchResults = this.searchResults.filter(item => !item.loading);
                // 添加新的搜索结果
                this.searchResults = [...this.searchResults, ...formattedResults];
            }
            // 在添加新条目前，移除任何现有的 '新增公司' 条目
            this.searchResults = this.searchResults.filter(item => !item.addNew);
            this.searchedPapermontyReuslts = [...this.searchResults.filter(item => item.source === 'papermoney.ai')];
            // 然后添加新的 '新增公司' 条目
            if (this.showAddNew && this.searchText.trim().length > 0 && this.searchedPapermontyReuslts.length === 0) {
                console.log('Add New Button!!!', this.searchText);
                this.searchResults.unshift({
                    value: `Create New Public Company For "${this.searchText}"`,
                    symbol: 'ADD_NEW', // 添加一个特殊的标识符
                    addNew: true // 添加一个标记，用于在 handleSelect 中识别
                });
            }

            if (cb) {
                // console.log('cb', this.searchResults);
                cb(this.searchResults);
            }
        },

        fetchData(queryString, cb) {
            // 检查是否有之前的请求，如果有，则取消
            if (this.symbolSearch.cancelToken) {
                this.symbolSearch.cancelToken.cancel("Cancelled the previous request");
            }
            this.searchResults = [];
            this.searchResults.push({ value: 'Searching companies...', loading: true });
            if (cb) {
                cb(this.searchResults);
            }


            // 为新请求创建一个 CancelToken
            this.symbolSearch.cancelToken = axios.CancelToken.source();

            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.get(`${apiEndpoint}/searchCompanyFromDb?keywords=${queryString}`, {
                cancelToken: this.symbolSearch.cancelToken.token
            })
                .then(response => {
                    this.isLoadingThirdPartyData = true;
                    this.processResults(response, cb);
                    // 异步请求第三方源
                    axios.get(`${apiEndpoint}/searchCompanyFromThirdSource?keywords=${queryString}`, {
                        cancelToken: this.symbolSearch.cancelToken.token
                    }).then(thirdPartyResponse => {
                        this.isLoadingThirdPartyData = false;
                        this.processResults(thirdPartyResponse, cb, true); // true 表示这是第三方数据
                    })
                        .catch(error => {
                            // 错误处理
                            console.error("Error during search!", error);
                            this.isLoadingThirdPartyData = false;
                            this.processResults([], cb, true); // true 表示这是第三方数据
                        });
                })
                .catch(error => {
                    // 错误处理
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.error("Error during search!", error);
                    }
                    this.processResults([], cb);
                });
        },
        handleSelect(item) {
            if (item) {
                if (item.addNew) {
                    // 通知父组件要添加新公司
                    var value = item.value.replace('Create New Public Company For "', '').replace('"', '');
                    this.$emit('add-new-company', value);
                } else if (!Tools.isEmpty(item.symbol)) {
                    // 通知父组件选中了一个已存在的公司
                    this.$emit('item-selected', { symbol: item.symbol, name: item.name });
                }
            }
        },
        handleSearchTextChange() {
            // 判断是否应该插入或删除自定义的底部div
            this.$nextTick(() => {
                const popper = document.querySelector('.searchresult-poperClass');
                let customDiv = document.querySelector('.searchresult-pop-bottom-div');

                if (!customDiv) {
                    customDiv = document.createElement('div');
                    customDiv.className = 'searchresult-pop-bottom-div';
                    customDiv.style.textAlign = 'center';
                    customDiv.style.borderTop = '1px solid #F5F7FA';
                    customDiv.style.margin = '12px 0px';
                    customDiv.style.paddingTop = '12px';
                }

                // 更新按钮的内容
                customDiv.innerHTML = `<button class="nofond-button">No Found For ${this.searchText}</button>`;
                customDiv.querySelector('.nofond-button').addEventListener('click', this.handleNoFoundButtonClick);


                if (popper && !popper.contains(customDiv)) {
                    popper.appendChild(customDiv); // 插入自定义div
                } else if (!popper && customDiv.parentNode) {
                    customDiv.parentNode.removeChild(customDiv); // 删除自定义div
                }
            });
        },
        handleNoFoundButtonClick() {
            // 清空搜索框内容
            // this.searchText = '';
            this.searchTextFeedback = this.searchText;
            // 关闭联想词弹窗
            console.log('close autocomplete:', this.$refs.autocomplete, this.$refs.autocomplete.$refs.suggestions);
            if (this.$refs.autocomplete && this.$refs.autocomplete.$refs.suggestions) {
                console.log('close autocomplete');
                this.$refs.autocomplete.close();
            }
            // 显示新的弹窗
            console.log('openDialog:', this.$refs.nofoundTicker);
            this.$nextTick(() => {
                this.$refs.nofoundTicker.openDialog();
            });
        },

    },
    beforeDestroy() {
        // 组件销毁前，清除插入的元素
        let customDiv = document.querySelector('.searchresult-pop-bottom-div');
        if (customDiv && customDiv.parentNode) {
            customDiv.parentNode.removeChild(customDiv);
        }
    }
}

</script>

<style>
.searchresult-pop-bottom-div .nofond-button {
    background: #fff;
    border-radius: 6px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    color: #606266;
}

.searchresult-pop-bottom-div .nofond-button:hover {
    background: #fff;
    border-color: #409eff;
    color: #409eff;
    cursor: pointer;
}
</style>


<style scoped>
::v-deep .search-input {
    width: 100%;
}

::v-deep .el-input__prefix {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    left: 8px;
    width: 32px;
}

::v-deep .el-input__inner {
    width: 100%;
    line-height: 1.5em;
    padding: 12px 24px 10px 40px;
    font-size: 16px;
    border-radius: 24px !important;
}

::v-deep .autocomplete-item {
    display: flex !important;
    align-items: center !important;
}

::v-deep img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    /* 根据需要调整图标和文本之间的间隙 */
}

.add-new-company-item {
    /* 为新增按钮添加的样式 */
    color: #3489FF;
    /* 示例颜色 */
    display: flex;
    align-items: center;
    font-weight: bold;
}

.add-new-company-item i.el-icon-plus {
    margin-right: 8px;
    /* 示例图标颜色 */
}


.feedback-button {
    margin: 24px;
    border-radius: 24px;
    transition: transform 0.3s ease;
    /* 添加平滑的过渡效果 */
}

.feedback-button:hover {
    transform: scale(1.1);
    /* 确保整个卡片放大，包括边框和阴影 */
}
</style>