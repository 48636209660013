<template>
    <div class="privacy-policy-container">
        <h1>Privacy Policy for PaperMoney.ai</h1>
        <p>Last Updated: 2023-12-6</p>

        <h2>Introduction</h2>
        <p>Welcome to PaperMoney.ai. We respect your privacy and are committed to protecting your personal information. This
            privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website
            www.papermoney.ai and use our services, including any data received from Google APIs.</p>

        <!-- Information We Collect -->
        <h2>1. Information We Collect</h2>
        <p>We collect various types of information, including:</p>
        <ul>
            <li>Information you provide us directly (e.g., personal identification like name and email address).</li>
            <li>Information from Google services, in accordance with user permissions, such as [specify types of Google user
                data your app accesses, like Gmail messages, Google account information, etc.].</li>
            <li>Web usage data like browsing history, search queries, and interaction with our website.</li>
            <li>Financial reports uploaded by users for analysis.</li>
        </ul>

        <!-- How We Use Your Information -->
        <h2>Google Account</h2>
        <p>If you sign in to our services using your Google account, we may access certain information from your Google
            account profile for account creation, login verification and authentication purposes. This information includes
            your name, email address, and profile photo.</p>
        <p>We do not collect, store or share any non-public information from your Google account without your consent. The
            information accessed from your Google profile is used only for:</p>
        <ul>
            <li>Automating account creation</li>
            <li>Verifying identity</li>
            <li>Personalizing user experience</li>
            <li>Communicating product updates and offers</li>
            <li>Improving our services</li>
            <li>Detecting and preventing fraud</li>
        </ul>
        <p>We do not share any non-public Google account information with third parties without your explicit consent. As a
            user, you retain full control over data from your Google account. You can review, edit or delete data shared
            with our services through your Google account settings and privacy policy.</p>
        <p>Our use of Google account information is governed by Google Terms of Service and Privacy Policy. We remain
            transparent on our practices and compliant with Google data processing requirements.</p>

        <!-- Sharing of Your Information -->
        <h2>3. Sharing of Your Information</h2>
        <p>We do not sell, trade, or rent your personal identification information to others. Information is only shared
            when necessary to provide our services, comply with the law, or protect our rights.</p>

        <!-- Security -->
        <h2>4. Security</h2>
        <p>We adopt advanced data collection, storage, and processing practices and security measures to protect against
            unauthorized access or misuse of your personal information and data, including any data received from Google
            services.</p>

        <!-- Compliance with Google User Data Policy -->
        <h2>5. Compliance with Google User Data Policy</h2>
        <p>We adhere strictly to Google's API Services User Data Policy in our handling of data received from Google
            services, ensuring it's used only for the purposes agreed by the user.</p>

        <!-- Contact Us -->
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at cumtnp@gmail.com or
            support@papermoney.ai.</p>
    </div>
</template>



<script>
export default {
    name: 'PrivacyPolicy'
    // 你可以在这里添加一些组件逻辑
}
</script>

<style scoped>.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
    color: #333;
}

.privacy-policy-container p {
    color: #666;
}</style>  