<template>
    <div class="reply-message">
        <div style="display:inline-block; line-height:24px; vertical-align:middle;">
            <MainLogo width="24px" height="24px" style="vertical-align:middle;" />
            <span style="margin-left:8px;font-weight:bold;height:24px; line-height:24px; vertical-align:middle;">Financial
                Report Expert</span>
        </div>

        <div class="message-content">
            <div v-html="renderedMessage">
            </div>
        </div>
        <div style="margin-left: 12px;">
            <el-button round size="small" @click="handleProfile" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnProfile }}</el-button>
            <el-button round size="small" @click="handleAnalysis" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnAnalysis }}</el-button>
            <el-button round size="small" @click="handleGraspIncome" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewIncome }}</el-button>
            <el-button round size="small" @click="handleGraspBalance" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewBalance }}</el-button>
            <el-button round size="small" @click="handleGraspCashFlow" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewCashFlow }}</el-button>
        </div>

    </div>
</template>

<script>
import MainLogo from '../animate/MainLogo.vue';

export default {
    components: {
        MainLogo,
    },
    props: {
        language: String,
    },
    data() {
        return {
            errorMessage: '' // 存储错误信息
        };
    },
    methods: {
        handleProfile() {
            // 发出公司概况的提示
            const prompt = this.language === 'English' ? 'Issue a prompt for comparing the business profiles of the two companies.' : '对比以下两家公司的业务概况';
            this.$emit('sendPrompt', prompt);
            console.log('Profile prompt sent.');
        },
        handleAnalysis() {
            // 发出财务分析的提示
            const prompt = this.language === 'English' ? 
            `**Perform a comprehensive comparison based on the latest quarterly financial reports of the two companies.**
Please specify the company names in your answer; do not use labels like "Company A" or "Company B".I will provide you with the latest quarterly financial statements for two companies, including: <Income Statement>, <Balance Sheet>, <Cash Flow Statement>. Please carefully review the financial data of these two companies. In your <Draft Book>, jot down your preliminary observations, noting any key insights, positive or negative trends, or areas that require further analysis.
Then, by thoroughly answering each of the following points, perform a comprehensive comparative analysis of the recent financial performance and conditions of these two companies:
1. Profitability Comparison - Analyze the revenue, expenses, and profit margins of the two companies. Discuss trends, annual growth rates, and comparisons of profitability. Highlight any unusual or one-off items that affect profits.
2. Liquidity Comparison - Assess the ability of both companies to meet short-term obligations by examining liquidity ratios, quick ratios, and working capital, and compare these metrics.
3. Leverage Comparison - Evaluate and compare the debt levels, debt-to-equity ratios, and interest coverage ratios of both companies. Discuss each company's capital structure and debt repayment capacity.
4. Efficiency Comparison - Calculate and compare key efficiency ratios, such as inventory turnover, accounts receivable turnover, and asset turnover. Explore how each company effectively utilizes its resources.
5. Cash Flow Comparison - Analyze and compare the operating, investing, and financing cash flows of both companies. Discuss the main drivers of each company and their impact on free cash flow.
6. Key Financial Strengths and Weaknesses - Based on your analysis, summarize and compare the main financial strengths and weaknesses of the two companies. Discuss the implications for business prospects and potential concerns.
7. Overall Financial Health Comparison - Based on your comprehensive analysis, provide a comparative assessment of the current financial health of the two companies. Discuss which company is in a better financial position and whether the trend is improving, stabilizing, or deteriorating.
\nPlease present your complete comparative analysis under the <Analysis> tag. The analysis should be clear, well-structured, and easily understandable to a business audience. Explain your points concisely yet in detail, using specific figures and ratios from the financial statements to support your analysis.`: 
            `**对以下两家公司进行综合对比。**
回答请指明公司名，不要使用AB代指。我将为您提供两家公司的最新季度财务报表，包括：<损益表><资产负债表><现金流量表>。请仔细检查这两家公司的财务数据。在<草稿本>中写出您的初步观察，注意任何关键的收获、积极或消极的趋势，或者需要进一步分析的领域。
然后，通过详细解答以下每一点，对这两家公司近期的财务表现和状况进行全面对比分析：
1. 盈利能力对比 - 分析两家公司的收入、费用和利润率。讨论趋势、年度增长率以及盈利能力的对比。强调影响利润的任何不寻常或一次性项目。
2. 流动性对比 - 通过检查流动比率、速动比率和营运资本，评估两家公司满足短期义务的能力，并对比这些指标的差异。
3. 杠杆作用对比 - 评估并对比两家公司的债务水平、负债与权益比率和利息保障倍数。讨论各自的资本结构和偿债能力。
4. 效率对比 - 计算并对比关键的效率比率，如存货周转率、应收账款周转率和资产周转率。探讨两家公司如何有效利用其资源。
5. 现金流对比 - 分析并对比两家公司的经营、投资和融资现金流。讨论每个公司的主要驱动因素及其对自由现金流的影响。
6. 关键财务优势和劣势 - 根据您的分析，总结两家公司的主要财务优势和劣势，并对比这些因素。讨论对商业前景的含义和潜在关切。
7. 总体财务健康对比 - 根据您的全面分析，提供两家公司当前财务健康状况的对比评估。讨论哪家公司的财务状况较好，以及趋势是改善、稳定还是恶化。
\n请在<分析>标签内呈现您的完整对比分析。分析应清晰、结构良好，易于商业听众理解。简洁但详尽地解释您的观点。使用财务报表中的具体数字和比率支持您的分析。`;
            this.$emit('sendPrompt', prompt);
        },
        handleGraspIncome() {
            // 发出利润表了解的提示
            const prompt = this.language === 'English' ? 
            `**analyze the provided latest income statements of two companies:**
Please specify the company names in your answer; do not use labels like "Company A" or "Company B".Carefully examine the key items and metrics in the income statements of both companies, including:
- Operating Revenue
- Cost of Sales and Gross Profit
- Operating Expenses
- Operating Income
- Interest Expense
- Taxes
- Net Income
- Other Noteworthy Items
\nConduct a comparative analysis of the performance of the two companies in the following areas:
1. Compare the trends in operating revenue over 4 quarters and 2 years to determine if there is growth or reduction in each company.
2. Compare changes in gross and net profit margins, discussing which company has shown more significant expansion or contraction.
3. Analyze trends in operating expenses, interest expenses, and taxes, and discuss differences between the two companies.
4. Discuss the trends in net income and the impact of special events or one-time items on profits.
\nIn the <Analysis Summary> section, summarize your overall analysis and key takeaways. Based on the comparative analysis, provide a high-level assessment of the financial performance and health of these two companies. Discuss which company appears to be financially more robust and the potential challenges or opportunities it may face in the future.
Finally, in the <Additional Questions> section, share any unresolved questions or areas you wish to further investigate to better understand the financial outcomes and future trajectories of the companies. What additional background or data points would help to more clearly depict the financial situation of the two companies? Please provide your complete analysis in a detailed and easy-to-follow format, using clear headings and sections where appropriate.`:
            `**对比两家公司的利润表数据**
回答请指明公司名，不要使用AB代指。仔细检查两家公司损益表中的关键项目和指标，包括：
- 营业收入
- 销售成本和毛利润
- 营业费用
- 营业收入
- 利息支出
- 税收
- 净利润
- 其他值得注意的项目
对比分析两家公司在以下方面的表现：
1. 对比两家公司过去的营业收入的增长或缩减趋势。
2. 比较毛利润率和净利润率的变化，讨论哪家公司的利润率扩大或收缩更明显。
3. 分析营业费用，利息支出和税收的趋势，以及这些成本项在两家公司间的差异。
4. 讨论净利润的趋势和特别事件或一次性项目对利润的影响。
\n在<分析总结>部分，总结您的整体分析和关键收获。基于对比分析的结果，提供对这两家公司财务表现和健康状况的高层次评估。讨论哪家公司在财务上表现更稳健，以及未来可能面临的挑战或机会。
最后，在<额外问题>部分，分享您对于两家公司的财务结果和未来轨迹有待进一步调查的领域。哪些额外的背景或数据点将有助于更清晰地描绘两家公司的财务状况？请以详尽而易于遵循的格式提供您的完整分析，适当使用清晰的标题和部分。`;
            this.$emit('sendPrompt', prompt);
            console.log('Income Statement prompt sent.');
        },
        handleGraspBalance() {
            // 发出资产负债表了解的提示
            const prompt = this.language === 'English' ? 
            `**Compare the latest balance sheets of two companies:**
Please specify the company names in your answer; do not use labels like "Company A" or "Company B".Please carefully analyze these financial statements, observing the trends in assets, liabilities, and equity of both companies over the same period. Pay special attention to changes in key indicators such as cash, debt, and inventory. By comparing these metrics, you can understand the differences in the financial conditions of the two companies, as well as their respective strengths and weaknesses.
First, in the <Analysis> section, provide a detailed analysis of what you observed in the balance sheets. Compare the most significant changes in assets, liabilities, and equity of both companies, identify any potential issues, and highlight each company’s strengths or positive trends. Try to discover insights that could be valuable for company management or investors. Compare the performance of the two companies in terms of cash flow, debt management, and capital structure, assessing their financial flexibility and stability.
Then, in the <Summary> section, succinctly summarize your overall assessment of the financial health and trajectory of both companies based on the balance sheet data. Indicate which company’s financial condition has shown improvement, deterioration, or stability during the period reviewed. Discuss the potential impact of these trends on the companies' future financial performance.`: 
            `**对比俩家公司最新的资产负债表**
回答请指明公司名，不要使用AB代指。请仔细分析这些财务报表，观察同期两家公司的资产、负债和权益的趋势。特别注意现金、债务、库存等关键指标的变化。通过比较这些指标，您可以了解两家公司财务状况的差异以及各自的优劣势。
首先，在<分析>部分，提供您在资产负债表中观察到的详细分析。对比两家公司的资产、负债和权益的最显著变化，标记任何潜在的问题，并突出各自的优势或积极趋势。尝试发现对公司管理层或投资者有价值的洞察。比较两家公司在现金流、债务管理和资本结构方面的表现，评估它们的财务灵活性和稳定性。
然后，在<总结>部分，简洁地总结您基于资产负债表数据对两家公司财务健康状况和轨迹的整体评估。指出哪家公司的财务状况在所示时期内显示出改善、恶化还是保持稳定。讨论这些趋势对未来公司财务表现的可能影响。`;
            this.$emit('sendPrompt', prompt);
            console.log('Balance Sheet prompt sent.');
        },
        handleGraspCashFlow() {
            // 发出现金流量表了解的提示
            const prompt = this.language === 'English' ? `**Compare the latest cash flow statements of the two companies.**
Please specify the company names in your answer; do not use labels like "Company A" or "Company B".Your task is to analyze the cash flow statements of two companies and share your insights. Please follow these steps:
1. Carefully review the quarterly and annual cash flow statements of both companies. Pay close attention to key categories of cash flows such as cash generated from operating activities, investing activities, and financing activities.
2. Identify trends and significant changes for each quarter and year. Consider the following:
   - Is cash flow from operations increasing or decreasing?
   - Is the company heavily investing or retracting its investments?
   - Is the company raising substantial capital or returning capital to shareholders?
   - How do quarterly trends compare to annual trends?
3. In the <Analysis> section, write your analysis. Discuss the key trends you observed and share any insights about the companies' operations and financial health revealed by the cash flows. The goal is to have 4-5 paragraphs.
4. In the <Summary> section, summarize the overall condition and trajectory of the companies' cash flows. In 1-2 paragraphs, concisely explain whether the cash flow statements indicate that the companies are in a strong or weak cash position and whether this position is improving or deteriorating. Provide reasoning for your conclusions.
Remember to focus on significant items and trends in the statements. Avoid getting bogged down in minute details. The aim of the analysis is to understand the financial condition and future prospects revealed by the cash flows.
Now, please begin your analysis. Write your complete answer, including both the <Analysis> and <Summary> sections.`: 
            `**对比最新的现金流量表**
您的任务是分析两家公司的现金流量表并分享您的见解。回答请指明公司名，不要使用AB代指。请按照以下步骤进行：
1. 仔细审查两家公司季度和年度的现金流量表。密切关注关键的现金流类别，如经营活动产生的现金、投资活动产生的现金和筹资活动产生的现金。
2. 识别各季度和年度的趋势和重大变化。考虑如下事项：
- 经营现金流是在增长还是在减少？
- 公司是在大举投资还是在收缩？
- 公司是在筹集大量资本还是在向股东返还资金？
- 季度趋势与年度趋势相比如何？
3. 在<分析>部分撰写您的分析。讨论您观察到的关键趋势，并分享现金流向您透露的关于两家公司业务和财务健康状况的任何见解。目标是撰写4-5段。
4. 在<总结>部分总结两家公司现金流健康状况和轨迹的整体情况。在1-2段中简洁地解释现金流量表是否表明两家公司处于强势或弱势现金位置，以及该位置是在改善还是在恶化。请为您的结论提供理由。
请记住，重点关注报表中的重大项目和趋势。避免陷入细枝末节的细节。分析的目标是了解现金流量透露的两家公司财务状况和未来前景。
现在请开始您的分析。撰写您的完整回答，包括<分析>和<总结>两个部分。`;
            this.$emit('sendPrompt', prompt);
            console.log('Cash Flow Statement prompt sent.');
        },
    },
    computed: {
        renderedMessage() {
            return this.language === 'English' ?
                `Hello! As your financial report analyst, I'm here to assist you in thoroughly comparing the financial conditions of two companies. On the left, you've seen an overview or data of their financial reports. Moving forward, I'm ready to provide further assistance:` :
                `您好，作为您的财报分析师，我将协助您全面对比两家公司的财务状况。在左侧列表中，您已经看到了他们的财报的鸟瞰图或者数据，接下来，我会进一步提供帮助：`;
        },
        btnProfile() {
            return this.language === 'English' ? 'Initiate a comparison of company profiles.' : '对比两家公司的业务概况';
        },
        btnAnalysis() {
            return this.language === 'English' ? 'Perform a detailed analysis using the latest financial reports.' : '给出最新财报综合分析';
        },
        btnViewIncome() {
            return this.language === 'English' ? 'Compare the most recent income statements.' : '对比最新的利润表';
        },
        btnViewBalance() {
            return this.language === 'English' ? 'Compare the most recent balance sheets.' : '对比最新的资产负债表';
        },
        btnViewCashFlow() {
            return this.language === 'English' ? 'Compare the most recent cash flow statements.' : '对比最新的现金流量表';
        },
    },
};
</script>

<style scoped>
.reply-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.thinking {
    margin-left: 24px;
}

.reply-message .message-content {
    margin-left: 32px;
}

.quickmenu {
    background: #FFF;
    margin-left: 12px;
    cursor: pointer;
    height: 32px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 99px;
    margin-top: 8px;
}

::v-deep .quickmenu span {
    cursor: pointer;
    padding: 0px;
    line-height: 1.0em;
}
</style>
