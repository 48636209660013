<template>
    <div>
        <HeaderComponent />
        <div class="analyze-reports-page content">
            <h1>How to Analyze a Cash Flow Statement</h1>
            <h2>What is a Cash Flow Statement</h2>
            <ul>
                <li>A cash flow statement shows a company's cash inflow and outflow over a period. It's like a checking
                    account for a business, but instead of just showing how much money is coming in and going out, it
                    also
                    shows where that money is coming from and where it's going. This information is crucial for
                    understanding a company's financial health and making informed decisions as an investor or business
                    owner.</li>
                <li>Before we analyze a cash flow statement, it's essential to understand that cash and profit are not
                    the
                    same. Cash refers to a company's money on hand, while profit is calculated by subtracting all
                    expenses
                    from revenue. A company can have positive cash flow but be unprofitable, and vice versa.</li>
            </ul>

            <h2>What's included in a Cash Flow Statement</h2>
            <h3>Key aspects</h3>
            <ul>
                <li>
                    The cash flow statement includes three sections: operating activities, investing activities, and
                    financing activities
                    <ul>
                        <li>
                            <strong>Operating activities</strong> are the day-to-day activities of a business. This
                            section
                            shows how much cash a company generates after selling products or services and paying for
                            operating expenses, from rent to salaries.
                            <ul>
                                <li>There are a few key things to look for when analyzing the operating activities
                                    section
                                    of a cash flow statement:</li>
                                <li>
                                    <strong>Net income</strong>: We use the net income as a starting point and add back
                                    the
                                    non-cash transactions to calculate the operating cash flow.
                                </li>
                                <li>
                                    <strong>Non-cash charges</strong>: they are the expenses that don't involve any cash
                                    outflows.
                                    <ul>
                                        <li><strong>Stock-based compensation (SBC)</strong>: SBC is a non-cash expense.
                                            SBC
                                            is a type of benefit that gives employees the right to acquire company stock
                                            at
                                            a discounted price or for free. It's a way for companies to attract and
                                            retain
                                            top talent, and it can be a tax-efficient way for employees to receive a
                                            portion
                                            of their pay. However, if a company gives its employees stock options, it
                                            has to
                                            recognize an expense on its income statement for the estimated fair value of
                                            those options. The company incurs an expense by issuing equity to employees.
                                            This expense is recorded on the income statement as part of cost of revenue
                                            or
                                            operating expenses. So it reduces the net income but is added back when
                                            calculating the operating cash flow. We'll cover SBC in more detail in a
                                            minute.
                                        </li>
                                        <li><strong>Changes in working capital</strong> refer to the movement of current
                                            assets and liabilities over the period.</li>
                                    </ul>
                                </li>
                                <li><strong>Depreciation & Amortization (D&A)</strong>: These are non-cash expenses that
                                    a
                                    company incurs from using long-term assets, such as buildings, equipment, or
                                    patents. In
                                    the income statement, they can be spread across COGS (cost of goods sold) and other
                                    operating expenses. The cash flow statement is where you can find the exact total
                                    amount
                                    of Depreciation & Amortization for the period.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Investing activities</strong> include buying or selling long-term assets, such as
                            property and equipment, or securities. This section shows how much cash a company uses to
                            invest
                            in its future growth.
                            <ul>
                                <li>There are a two key things to look for when analyzing the investing activities
                                    section
                                    of a cash flow statement:</li>
                                <li><strong>Purchase or sale of property, plant and equipment (PP&E)</strong>: These are
                                    long-term assets that the company uses to generate revenue. A high level of PP&E
                                    purchases can be a good sign, as it means the company is investing in its future
                                    growth.
                                    Conversely, a high level of sales could be a red flag, as it means the company may
                                    be
                                    divesting assets. Context is key.</li>
                                <li><strong>Purchase or sale of investments</strong>: These are investments in other
                                    companies or assets that the company uses to generate additional income.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Financing activities</strong> are things like issuing new shares or taking out
                            loans.
                            This section shows how much cash a company is raising from investors or borrowing from
                            lenders.
                            <ul>
                                <li>There are a few key things to look for when analyzing the financing activities
                                    section
                                    of a cash flow statement:</li>
                                <li><strong>Issuance or repayment of debt</strong>: A company can borrows money from
                                    lenders
                                    or pay back money it borrowed from lenders. We want to watch if the company is
                                    taking on
                                    too much debt, which requires to review the balance sheet.</li>
                                <li><strong>Issuance or repurchase of equity</strong>: A company can sell new shares to
                                    raise cash. Alternatively, it can buy back its own shares, which depletes its cash
                                    position but lowers the number of outstanding shares, making each share more
                                    valuable
                                    for existing shareholders.</li>
                                <li><strong>Dividend payments</strong>: A company can distribute a portion of aits
                                    profits
                                    to its shareholders as a reward for investing in the company.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <h3>Key ratios</h3>
            <ul>
                <li>
                    <strong>Operating cash flow</strong>: Also called net cash from operating activities or net cash
                    from
                    operations. This is calculated by subtracting cash outflows from cash inflows in the operating
                    activities section of the cash flow statement.
                    <p class="formula">Operating cash flow = Operating activity cash inflow - Operating activity cash
                        outflow</p>
                </li>
                <li>
                    <strong>Free cash flow</strong>: Free cash flow is calculated by subtracting capital expenditures
                    from
                    the operating cash flow. Free cash flow represents the cash a company has available to pay
                    dividends,
                    repurchase stock, pay off debt, or make acquisitions. The free cash flow margin is expressed in
                    percentage of revenue.
                    <p class="formula">Free cash flow = Operating cash flow - Capital expenditure</p>
                </li>
                <li>
                    <strong>Net cash flow</strong>: This is calculated by adding the net cash flow from operating
                    activities, investing activities, and financing activities. It shows the net cash movement for the
                    period. It's equal to the difference between the starting and the ending cash balance for the
                    period.
                    <p class="formula">Net cash flow = Operating cash flow +/- Net cash from investing activities +/-
                        Net
                        cash from financing activities</p>
                    <p class="formula">Net cash flow = Cash ending balance - Cash starting balance (from balance sheet)
                    </p>
                </li>
                <li>
                    <strong>Operating cash flow to total debt</strong>: As the name implies, this ratio is calculated by
                    dividing the opearating cash flow by the total debt on the balance sheet. A higher ratio indicates
                    that
                    the company is generating enough cash from its operations to pay off its debt. As explained in our
                    review of the balance sheet, we want to find company that could pay off their debt quickly.
                </li>
            </ul>

            <h2>How to use visuals to help us digest the Cash Flow Statement in one minute</h2>
            <div class="image-container">
                <img src="@/assets/about/about-case-cashflow-visual.png" alt="Cash Flow Statement Visual">
            </div>
            <p>Visual representations of financial statements can significantly enhance our understanding and analysis
                by
                illustrating data flows and their relative sizes. Here's how to interpret the visual:</p>
            <ul>
                <li>
                    <strong>Color coding:</strong> Notice the color scheme used for different activities - blue for
                    operating, green for investing, and orange for financing. This immediately gives us a visual cue
                    about
                    the sections of the statement.
                </li>
                <li>
                    <strong>Width of flows:</strong> The width of each flow correlates with the amount of cash involved.
                    Wider flows indicate larger amounts of cash.
                </li>
                <li>
                    <strong>Direction of flows:</strong> Inflows towards the center are cash coming into the business,
                    while
                    outflows moving away from the center are cash going out.
                </li>
                <li>
                    <strong>Net cash position:</strong> The center 'bar' shows the net cash position at the beginning,
                    the
                    net change, and the ending cash position, making it easy to see the overall cash movement at a
                    glance.
                </li>
                <li>
                    <strong>Specific details:</strong> Hovering over (or clicking on) specific flows can reveal
                    additional
                    details such as exact figures and categories for both inflows and outflows.
                </li>
                <li>
                    <strong>Contextual analysis:</strong> Beyond the numbers, consider what the flows represent. For
                    example, a large outflow in investing might mean the company is building for the future.
                </li>
                <li>
                    <strong>Comparative analysis:</strong> By comparing this visual with those of previous periods or
                    other
                    companies, one can assess trends and strategies.
                </li>
            </ul>
            <p>In our example with Apple's cash flow statement, we can quickly identify that the net cash flow is
                positive,
                which is a good sign of liquidity. However, it's also noticeable that a significant portion of financing
                activities is cash going out, mainly due to repurchases of common stock and dividend payments,
                reflecting
                Apple's strategy to return value to shareholders.</p>

            <p>By analyzing this visual representation, stakeholders can efficiently assess the cash flow statement's
                complexities and get a clear picture of a company's cash health. Always cross-reference the visuals with
                the
                actual numbers for a comprehensive analysis.</p>
        </div>
    </div>
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue';
export default {
    components: {
        HeaderComponent
    },
    // ... other options ...
}
</script>

<style scoped>
/* Add any additional styles specific to this component */
.analyze-reports-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.content h2 {
    color: #333;
    margin-top: 1em;
    margin-bottom: 12px;
}

.content p {
    color: #666;
    line-height: 1.6;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #666;
}

.router-link-active {
    color: #00aaff;
}

.help-links h2,
.contact h2 {
    margin-top: 2em;
}

.contact p {
    max-width: 800px;
    margin-top: 1em;
}

.image-container {
    text-align: center;
    margin: 20px 0;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

.formula {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
}
</style>
