
<template>
    <div>
        <div @click="handleClick" :id="containerId" class="image-container" :style="containerStyle">
            <IncomeSankey ref="customerSankeyRef" :sankey-data="sankeyData" style="cursor: pointer;" />
        </div>
        <!-- 图像预览模态框 -->
        <div v-if="showModal" class="modal">
            <div class="modal-content" @click="showModal = false">
                <i class="el-icon-close close" @click="showModal = false"></i>
                <img v-if="generatedImage" :src="generatedImage" alt="Generated Image" @click.stop />
            </div>
        </div>
    </div>
</template>

<script>

import Tools from '@/GlobalTools';
import IncomeSankey from '../creater/IncomeSankey.vue';
export default {
    name: 'ReportIncome',
    components: {
        IncomeSankey
    },
    props: {
        viewData: {
            type: Object,
            default: () => ({})
        },
        isCompareMode: {
            type: Boolean,
            default: false
        },
        listId:{
            type: Number,
            default: 0
        }
    },

    mounted() {
        this.containerId = 'id-report-income-' + this.viewData.viewId+'-'+this.listId;
        this.$nextTick(() => {
            this.updateContainerSize();
            window.addEventListener('resize', this.updateContainerSize);
            this.buildWithForm();
        });
    },
    data() {
        return {
            containerId: 'id-report-income',
            chatName: '',
            containerWidth: 0,
            containerHeight: 0,
            imageWidth: '100vw', // 初始图片宽度，您可以根据需要调整
            imageHeight: '100vh', // 初始图片高度，您可以根据需要调整
            generatedImage: null,
            showModal: false,
            symbol: '',
            sankeyData: {},
            config: {

            },
            form: {
                revenue: {
                    id: "revenue", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "gray", // 初始颜色
                    desc: "Revenue", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                grossProfit: {
                    id: "grossProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Gross Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 1
                },
                cost: {
                    id: "cost", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Cost", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                operatingProfit: {
                    id: "operatingProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Operating Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 1
                },
                operatingExpense: {
                    id: "operatingExpense", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Operating Expense", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                netProfit: {
                    id: "netProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Net Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 2
                },
                tax: {
                    id: "tax", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Tax", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                interestNet: {
                    id: "interestNet", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Interest Net", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                extraIncomeItems: [], // 用于存储额外的收入表单项
                extranExpense: [],
                // netProfit: '',
                // tax: '',
                otherIncomeToOperationProfit: null,
                otherIncomeToNet: null
            }
        }
    },
    watch: {
        isCompareMode(newValue) {
            if (newValue) {
                this.updateContainerSize();
            }
        }
    },
    methods: {
        async handleClick() {
            try {
                const image = await this.$refs.customerSankeyRef.generateImage();
                this.generatedImage = image;
                this.showModal = true; // 显示模态框
            } catch (error) {
                console.error('Error generating image:', error);
            }
        },
        updateContainerSize() {
            // 获取.chat-main元素
            const chatMainElement = document.querySelector('.reportView-tree');
            if (chatMainElement) {
                // 获取.chat-main的宽度
                this.containerWidth = Math.min(1280, chatMainElement.offsetWidth - 80);
                // 如果需要，可以根据比例或其他逻辑来设置高度    height: calc((100vw * 2/3 - 248px)* (2416 / 4296));
                this.containerHeight = this.containerWidth * (2416 / 4296);
            } else {
                console.error('.chat-main元素未找到');
            }
        },
        setColorAndSort(nodeId, newNode) {
            const configMap = {
                'grossProfit': { color: 'green', sort: 1 },
                'cost': { color: 'red', sort: 0 },
                'operatingProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 3 : 1 },
                'otherIncomeToOperationProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? -1 : 4 },
                'otherIncomeToNet': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 1 : 2 },
                'netProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 2 : 1 },
                'interestNet': { color: newNode.v < 0 ? 'red' : 'green', sort: 4 },
                'operatingExpense': { color: 'red', sort: 0 },
                'tax': { color: 'red', sort: 0 },
            };
            if (nodeId.startsWith('extraIncome-')) {
                newNode.color = this.form.revenue.color;
            } else if (nodeId.startsWith('extraExpense-')) {
                newNode.color = 'red';
                newNode.sort = 0;
            } else if (configMap[nodeId]) {
                newNode.color = configMap[nodeId].color;
                newNode.sort = configMap[nodeId].sort;
            }
        },
        newNode(nodeId, node) {
            if (!node) {
                return {
                    id: nodeId,
                    desc: nodeId,
                    desc_cn: nodeId,
                    v: 0,
                    weight: 0
                };
            }
            var newNode = {
                id: nodeId,
                desc: node.desc,
                desc_cn: node.desc_cn,
                v: node.v,
                weight: Math.abs(node.v)
            }
            // console.log('newNode:', nodeId, newNode);
            this.setColorAndSort(nodeId, newNode);
            return newNode;
        },
        buildWithForm() {
            this.symbol = this.viewData.symbol;
            this.chatName = this.symbol+'-'+this.listId;
            this.config = this.viewData.config;
            this.companyName = this.viewData.companyName;
            this.form.revenue = this.newNode('revenue', this.viewData.form.revenue);
            this.form.cost = this.newNode('cost', this.viewData.form.costofRevenue);
            this.form.grossProfit = this.newNode('grossProfit', { desc: 'Gross Profit', v: this.form.revenue.v - this.form.cost.v, desc_cn: '毛利润' })
            this.form.operatingProfit = this.newNode('operatingProfit', this.viewData.form.operatingIncome);
            this.form.otherIncomeToNet = this.newNode('otherIncomeToNet', this.viewData.form.otherIncomeToNet);
            this.form.otherIncomeToOperationProfit = this.newNode('otherIncomeToOperationProfit', this.viewData.form.otherIncomeToOperationProfit);
            this.form.interestNet = this.newNode('interestNet', this.viewData.form.interestNet);
            var totalExpense = 0;
            this.form.extranExpense = [];
            if (this.viewData.form.subExpense) {
                this.viewData.form.subExpense.forEach((item, index) => {
                    var id = 'extraExpense-' + index;
                    // console.log('subExpense:', id, index, item);
                    var subExpense = this.newNode(id, item);
                    if (null !== subExpense) {
                        this.form.extranExpense.push(subExpense);
                        totalExpense += item.v;
                    }

                });
            }
            this.form.operatingExpense = this.newNode('operatingExpense', { desc: 'Operating Expense', v: totalExpense, desc_cn: '经营费用' });
            this.form.netProfit = this.newNode('netProfit', this.viewData.form.netProfit);
            this.form.tax = this.newNode('tax', this.viewData.form.tax);

            this.sankeyData = this.generateSankeyData();

        },
        weight_function(form_1, fun = '-', form_2) {
            var v1 = Tools.convertToNumber(form_1);
            var v2 = Tools.convertToNumber(form_2);
            if (fun === '+') {
                return v1 + v2;
            } else {
                return v1 - v2;
            }
        },
        getFormKeyV(id) {
            if (this.form[id]) {
                return this.form[id].v;
            }
            return 0;
        },
        getFormKeyAbsV(id) {
            if (this.form[id]) {
                return Math.abs(this.form[id].weight);
            }
            return 0;
        },
        getNodeFromNodes(id) {
            return this.nodes.find(node => node.id === id);
        },
        createNode(item) {
            return {
                id: item.id,
                v: item.v,
                weight: Math.abs(item.v),
                fixedValue: Math.abs(item.v),
                color: Tools.parseNodeColor(item.color),
                name: item.id,
                desc: item.desc,
                sort: item.sort,
                yy: item.yy,
                vi: item.vi,
                margin: null // Initialize margin
            }

        },
        generateNodes() {
            const nodes = [];
            Object.keys(this.form).forEach(key => {
                const item = this.form[key];
                // 检查是否是数字或可转换字符串并且大于0
                if (item && !isNaN(parseFloat(item.v)) && parseFloat(item.v) !== 0) {
                    nodes.push(this.createNode(item));
                }
                // 如果是数组，遍历数组
                else if (item && Array.isArray(item)) {
                    item.forEach(subItem => {
                        if (!isNaN(parseFloat(subItem.v)) && parseFloat(subItem.v) !== 0) {
                            nodes.push(this.createNode(subItem));
                        }
                    });
                }
            });

            // 计算收入边距
            const revenue = nodes.find(node => node.id === 'revenue')?.weight || 0;
            if (revenue !== 0) {
                nodes.forEach(node => {
                    if (node.id !== 'revenue' && node.weight > 0) {
                        let marginPercentage = (node.weight / revenue) * 100;
                        node.margin = marginPercentage < 1 ? marginPercentage.toFixed(1) + '%' : Math.round(marginPercentage) + '%';
                    }
                });
            }
            // console.log('generateNodes:', nodes);
            return nodes;
        },
        generateLinks() {
            const createLink = (source, target, value, color, sort = 0) => {
                // console.log('createLink:', source, target, value, color);
                return {
                    source,
                    target,
                    value: Math.abs(value),
                    color: Tools.parseLinkColor(color),
                    sort
                };
            };

            var links = [];

            // 处理额外的收入
            this.form.extraIncomeItems.forEach(item => {
                if (item.id && Tools.convertToNumber(item.v) > 0) {
                    links.push(createLink(item.id, this.form.revenue.id, Math.abs(item.v), this.config.color, item.sort));
                }
            });
            let operatingParent = 'grossProfit';
            if (Tools.convertToNumber(this.form.grossProfit.v) === 0 && Tools.convertToNumber(this.form.cost.v) === 0) {
                operatingParent = 'revenue';
            } else {
                // 初始化links数组
                links.push(
                    createLink('revenue', 'grossProfit', this.form.grossProfit.v, 'green'),
                    createLink('revenue', 'cost', this.form.cost.v, 'red')
                );
            }

            // 经营利润为正时的逻辑
            var operatingProfitV = this.getFormKeyV('operatingProfit');
            if (operatingProfitV >= 0) {
                var weight_gp_op = this.getFormKeyAbsV('operatingProfit');
                let weight_gp_oe = this.getFormKeyAbsV('operatingExpense');
                if (weight_gp_oe > this.getFormKeyAbsV('grossProfit')) {
                    weight_gp_oe = this.getFormKeyAbsV('grossProfit');
                    if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                        weight_gp_op = 0;
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingExpense', this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('grossProfit')), 'red', this.form.otherIncomeToOperationProfit.sort));
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingProfit', this.getFormKeyAbsV('operatingProfit'), 'green', this.form.otherIncomeToOperationProfit.sort));
                    }
                } else {
                    if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingProfit', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'green', this.form.otherIncomeToOperationProfit.sort));
                        weight_gp_op = this.weight_function(this.getFormKeyAbsV('operatingProfit'), '-', this.getFormKeyAbsV('otherIncomeToOperationProfit'));
                    }
                }
                if (this.getFormKeyV('netProfit') >= 0) {
                    var weight_op_net = this.getFormKeyAbsV('netProfit');
                    // console.log('otherIncomeToNet:', this.getFormKeyV('otherIncomeToNet'));
                    if (this.form.otherIncomeToNet) {
                        if (this.form.otherIncomeToNet.v >= 0) {
                            weight_op_net = this.weight_function(this.getFormKeyAbsV('netProfit'), '-', this.getFormKeyAbsV('otherIncomeToNet'));
                            links.push(createLink('otherIncomeToNet', 'netProfit', this.getFormKeyAbsV('otherIncomeToNet'), 'green', this.form.otherIncomeToNet.sort));
                        } else {
                            links.push(createLink('operatingProfit', 'otherIncomeToNet', this.getFormKeyAbsV('otherIncomeToNet'), 'red', this.form.otherIncomeToNet.sort));
                        }
                    }
                    if (this.form.interestNet.v !== 0) {
                        if (this.form.interestNet.v > 0) {
                            links.push(createLink('interestNet', 'netProfit', this.getFormKeyAbsV('interestNet'), 'green', 3));
                            weight_op_net -= this.getFormKeyAbsV('interestNet');
                        } else {
                            links.push(createLink('operatingProfit', 'interestNet', this.getFormKeyAbsV('interestNet'), 'red'));
                        }
                    }
                    if (weight_gp_op > 0) {
                        links.push(createLink(operatingParent, 'operatingProfit', weight_gp_op, 'green', this.form.operatingProfit.sort));
                    } else {
                        links.push(createLink(operatingParent, 'operatingProfit', 0, 'green', this.form.operatingProfit.sort));
                    }
                    if (weight_op_net > 0) {
                        links.push(createLink('operatingProfit', 'netProfit', weight_op_net, 'green', Math.max(this.form.netProfit.sort, 2)));
                    }
                    if (this.form.tax.v !== 0) {
                        if (this.form.tax.v > 0) {
                            links.push(createLink('operatingProfit', 'tax', this.getFormKeyAbsV('tax'), 'red', this.form.tax.sort));
                        } else {
                            links.push(createLink('tax', 'netProfit', this.getFormKeyAbsV('tax'), 'green', this.form.tax.sort));
                        }
                    }
                    links.push(createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red', this.form.operatingExpense.sort));
                } else {
                    if (this.form.otherIncomeToNet && this.form.otherIncomeToNet.v < 0 && this.form.otherIncomeToNet.weight >= this.form.netProfit.weight) {
                        links.push(createLink('netProfit', 'otherIncomeToNet', this.getFormKeyAbsV('netProfit'), 'red', this.form.otherIncomeToNet.sort));
                        links.push(createLink('operatingProfit', 'otherIncomeToNet', (this.getFormKeyAbsV('otherIncomeToNet') - this.getFormKeyAbsV('netProfit')), 'red', this.form.otherIncomeToNet.sort));
                    } else {
                        let operatingLose = (this.getFormKeyAbsV('operatingExpense') - this.getFormKeyAbsV('grossProfit'));
                        if (operatingLose > 0) {
                            this.getNodeFromNodes('netProfit').sort = 3;
                            this.nodes.push(this.createNode({ id: 'operatingLose', v: -1 * operatingLose, weight: operatingLose, color: 'red', desc: 'Operating Lose', sort: 3 }));
                            links.push(createLink('operatingLose', 'operatingExpense', operatingLose, 'red', 3));

                        } else {
                            links.push(createLink('netProfit', 'operatingExpense', this.getFormKeyAbsV('netProfit'), 'red', this.form.netProfit.sort));
                        }
                    }
                    links.push(
                        createLink(operatingParent, 'operatingProfit', weight_gp_op, 'green', this.form.operatingProfit.sort),
                        createLink('operatingProfit', 'tax', this.getFormKeyAbsV('tax'), 'red', this.form.tax.sort),
                        createLink('operatingProfit', 'interestNet', this.getFormKeyAbsV('interestNet'), this.form.interestNet.v > 0 ? 'green' : 'red', this.form.interestNet),
                        createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red', this.form.operatingExpense.sort)
                    );
                }

            } else {
                // 经营利润为负时的逻辑
                // console.log('weight test:', this.getFormKeyAbsV('operatingExpense'), this.getFormKeyAbsV('operatingProfit'), this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('operatingProfit')))
                let weight_gp_oe = this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('operatingProfit'));

                if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                    links.push(createLink('otherIncomeToOperationProfit', 'operatingExpense', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'red', this.form.otherIncomeToOperationProfit.sort));
                    weight_gp_oe -= this.getFormKeyAbsV('otherIncomeToOperationProfit');
                }
                links.push(
                    createLink('operatingProfit', 'operatingExpense', this.getFormKeyAbsV('operatingProfit'), 'red'),
                    createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red')
                );
            }
            if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v < 0) {
                links.push(createLink(operatingParent, 'otherIncomeToOperationProfit', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'red', -1));
            }
            // 处理额外的经营费用
            if (this.form.operatingExpense && this.form.operatingExpense.weight !== 0) {
                // console.log('add 其他费用：this.form.operatingExpense:', this.form.operatingExpense);
                this.form.extranExpense.forEach(item => {
                    if (item.v !== 0) {
                        links.push(createLink('operatingExpense', item.id, Math.abs(item.v), 'red'));
                    }
                });
            }

            // console.log('generateLinks:', links);
            // 过滤出有效的链接
            const currentNodes = this.nodes.map(node => node.id);
            return links.filter(link => currentNodes.includes(link.source) && currentNodes.includes(link.target));
        },
        generateTitle() {
            this.config.title = `${this.companyName} InComeStatement ${this.getFormateDate()}`
            this.config.subtitle = `${this.config.months} Ended, Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.nodes = this.generateNodes();
            } catch (e) {
                console.log('generateNodes exception:', e);
            }
            try {
                this.links = this.generateLinks();
            } catch (e) {
                console.log('generateLinks exception:', e);
            }
            this.generateTitle();
            // Tools.logObj('generateSankeyData Nodes:', this.nodes);
            // Tools.logObj('generateSankeyData Links:', this.links);
            return {
                symbol: this.symbol,
                containerId: this.containerId,
                chatName:this.chatName,
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                nodes: this.nodes,
                links: this.links,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName
            };
        },
        getFormateDate() {
            // 检测日期格式并相应地转换
            let inputDate = this.config.endDate;
            let endDate;

            // 检测是否为"YYYYMMDD"格式
            if (/^\d{8}$/.test(inputDate)) {
                // 将"YYYYMMDD"格式转换为"YYYY-MM-DD"，这样可以直接被Date对象正确解析
                inputDate = `${inputDate.substring(0, 4)}-${inputDate.substring(4, 6)}-${inputDate.substring(6, 8)}`;
                endDate = new Date(inputDate);
            } else {
                // 假设其他情况是有效的ISO 8601格式或其他可以直接被Date解析的格式
                endDate = new Date(inputDate);
            }

            // 使用toLocaleDateString方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号，这里看起来没有必要的替换，所以简化掉了
            formattedDate = formattedDate.replace(',', '');

            return formattedDate;
        },

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateContainerSize);
    },
    computed: {
        containerStyle() {
            return {
                width: this.containerWidth + 'px',
                height: this.containerHeight + 'px',
            };
        },
        imageStyle() {
            return {
                width: this.imageWidth + 'px',
                height: this.imageHeight + 'px',
            };
        },
        currentLanguage() {
            return this.$store.getters.getSelectedLanguage; // 从 Vuex store 获取语言值
        }
    },
}


</script>

<style scoped>
.image-container {
    cursor: pointer;
    /* 窗口的最大高度 */
    display: flex;
    /* 使用 Flexbox 布局 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    text-align: center;
    overflow: hidden;
    /* 允许滚动 */
    position: relative;
    /* background-color: #F5F5F5; */
}

.image-container svg {
    cursor: pointer !important;
}

.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.modal-content img {
    width: calc(96vw);
    height: calc((96vw)* (2416 / 4296));
}

.modal-content .close {
    position: absolute;
    /* 绝对定位 */
    top: 0;
    /* 顶部对齐 */
    right: 0;
    /* 右侧对齐 */
    padding: 8px;
    /* 为点击提供足够空间 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
    z-index: 10;
    /* 确保在其他内容之上 */
    margin: 48px;
    color: #FFF;
    float: right;
    font-size: 24px;
    font-weight: 400;
    border-radius: 50%;
    opacity: .8;
    box-sizing: border-box;
    background-color: #606266;
    align-items: center;
    justify-content: center;
}


.modal-content .close:hover,
.modal-content .close:focus {
    color: color;
    text-decoration: none;
    cursor: pointer;
}
</style>