<template>
    <div>
        <el-autocomplete class="search-input" :class="{ 'button-like': isButtonMode }" v-model="searchText"
            :style="searchInputStyle" @focus="expandInput" @blur="collapseInput" :fetch-suggestions="querySearchAsync"
            :placeholder="placeholderText" @select="handleSelect" @change="handleChange" :trigger-on-focus="false"
            :hide-loading="true" ref="autoCompleteRef">
            <template #prefix>
                <i class="el-icon-search"></i>
            </template>
            <template slot-scope="{ item }">
                <!-- 新增公司条目 -->
                <div v-if="item.addNew" class="add-new-company-item">
                    <i class="el-icon-plus"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-item" v-else-if="!item.loading && !item.notFound"
                    style="display: flex;align-items: center;">
                    <img v-if="!isIconUrlEmpty(item.iconUrl)" :src="item.iconUrl" class="icon-image" alt="icon" />
                    <i v-else :class="item.iconfont" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="autocomplete-empty" v-else-if="item.notFound">
                    <i class="el-icon-warning-outline" style="margin-left:4px;margin-right: 12px;"></i>
                    <span>{{ item.value }}</span>
                </div>
                <div class="loading-indicator" v-if="item.loading">
                    <i class="el-icon-loading" style="margin-left:8px;margin-right:12px;font-size:14px"></i><span>{{
                        item.value
                    }}</span>
                </div>

            </template>
            <template #empty>
                <div class="autocomplete-empty">
                    No search result
                </div>
            </template>
        </el-autocomplete>

    </div>
</template>

<script>
import SearchListContainer from './SearchListContainer.vue';

export default {
    name: 'SearchButtonListContainer',
    extends: SearchListContainer,
    props: {
        defaultMode: {
            type: String,
            default: 'button' // 默认为 'button'，可以设为 'input'
        },
        maxWidth:{
            type: String,
            default: '960px'
        }
    },
    mounted() {
        this.inputExpanded = this.defaultMode === 'input';
    },
    computed: {
        isButtonMode() {
            // 如果 defaultMode 是 'button'，并且 input 没有展开，则伪装成按钮
            return this.defaultMode === 'button' && !this.inputExpanded;
        },
        searchInputStyle() {
            return {
                width: this.isButtonMode ? '120px' : this.maxWidth
            };
        },
        placeholderText() {
            return this.isButtonMode ? 'Search' : 'Enter Company Name or Ticker';
        }
    },
    data() {
        return {
            inputExpanded: this.defaultMode === 'input',
        }
    },
    methods: {
        querySearchAsync(queryString, cb) {
            console.log('querySearchAsync:', queryString);
            if (!queryString.trim()) {
                // 如果搜索词为空，则不显示下拉弹窗
                return;
            }
            if (this.symbolSearch.timeout) { // 清除上一个延时
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.fetchData(queryString, cb);
            }, 10); // 设置输入延时
        },
        expandInput() {
            console.log('expandInput');
            if (this.defaultMode === 'button') {
                this.inputExpanded = true;
                this.$emit('toggle-expand', true); // 通知父组件展开了
                // 这里重置 autoCompleteRef 的状态，确保不显示旧的下拉内容
                if (this.$refs.autoCompleteRef) {
                    // 重置下拉列表的显示状态
                    this.$refs.autoCompleteRef.suggestions = [];
                    // 如果需要，还可以重置其他相关状态
                }
            }
        },
        collapseInput() {
            console.log('collapseInput');
            if (this.defaultMode === 'button') {
                this.inputExpanded = false;
                this.searchText = ''; // 确保在折叠输入框时清空搜索文本
                this.$emit('toggle-expand', false); // 通知父组件收起了
            }
        },
        handleSelect(item) {
            if (item) {
                this.$nextTick(() => {
                    this.searchText = ''; // 确保在DOM更新之后清空搜索文本
                    this.collapseInput(); // 在选择项之后确保输入框被折叠
                    this.$emit('item-selected', { symbol: item.symbol, name: item.name });
                });
            }
        },
        handleChange() {
            console.log('handleChange!!!');
        }
    }
}

</script>


<style scoped>
/* 调整基本的搜索输入框的样式，使其在 button-like 模式下看起来像一个按钮 */
::v-deep .search-input {
    width: 100%;
    /* 默认宽度设置为100%，将会在计算属性中根据条件调整 */
    /* line-height: 1.5em; */
    font-size: 16px;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


/* 图标和输入框的样式 */
::v-deep .el-input__prefix {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    left: 8px;
    width: 32px;
}


::v-deep .el-input__inner {
    padding-left: 40px;
    /* 为图标留出空间 */
}

/* 在 button-like 类存在时，隐藏内部输入框的样式细节 */
::v-deep .search-input .el-input__inner {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #333;
    height: 40px;
    border-radius: 28px;
    border: 1px solid #CCC;
    font-size: 16px;
}

/* button-like 样式仅在该类存在时应用 */
::v-deep .search-input.button-like {
    cursor: pointer;
    /* 根据button模式调整宽度 */
    height: 40px;
    border-radius: 28px;
    /* 按钮颜色 */
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 120px;
    /* 模仿按钮的宽度 */
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: none;
}

::v-deep .search-input.button-like .el-input__inner {
    color: white;
}

::v-deep .search-input.button-like:hover {
    background-color: #EEE;
}


/* 修改搜索图标的样式，以适应 button-like 模式 */
::v-deep .search-input.button-like .el-input__prefix {
    color: white;
}

/* 修改占位符的样式，以适应 button-like 模式 */
::v-deep .search-input.button-like .el-input__inner::placeholder {
    color: rgba(255, 255, 255, 0.8);
}


/* 搜索结果和其他元素的样式 */
::v-deep .autocomplete-item {
    display: flex !important;
    align-items: center !important;
}

::v-deep img.icon-image {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.add-new-company-item {
    color: #3489FF;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.add-new-company-item i.el-icon-plus {
    margin-right: 8px;
}

/* button-like 模式下的图标和占位符样式 */
::v-deep .search-input.button-like .el-input__prefix,
::v-deep .search-input.button-like .el-input__inner::placeholder {
    color: #666;
    font-weight: 400;
}

/* 占位符样式，包括字号、粗细和颜色 */
::v-deep .search-input .el-input__inner::placeholder {
    font-size: 16px;
    color: #AAA;
    opacity: 1;
}
</style>
