<template>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" title="No Found For Company?">
        <div v-if="!showForm" style="padding: 12px 48px 24px 48px;">
            <span style="font-size: 20px;color: 666;">How to Get Report For <b>{{ searchText }}</b></span>
            <div style="display: flex;flex-direction: row;margin-top: 24px;">
                <div @click="handleFeedback" shadow="hover" class="feedback-button" style="width: 40%;">
                    <i class="el-icon-s-promotion"></i>
                    <span>Feedback To Papermoney</span>
                </div>
                <div shadow="hover" class="feedback-button" style="width: 40%;" @click="handleCreateReport">
                    <i class="el-icon-user-solid"></i>
                    <span>Create My Report</span>
                </div>
            </div>
        </div>
        <div v-else-if="!submissionStatus" style="padding: 12px 48px 24px 48px;">
            <span style="font-size: 18px;color: 666;">Feedback To Papermoney to Create Report For
                <b>{{ searchText
                    }}</b></span>
            <div style="font-size: 16px;margin: 12px;color: #AAA;">Jus Three Step to Create New Report <router-link
                    to="/create">Try It</router-link></div>
            <el-form style="margin-top: 24px;" :model="form" label-position="left">
                <el-form-item label="Company Name" label-width="120px">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Company Ticker" label-width="120px">
                    <el-input v-model="form.ticker" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Market" label-width="120px">
                    <el-select v-model="form.region" placeholder="Please Select listed Markdet">
                        <el-option label="United States Listed" value="US">United States Listed</el-option>
                        <el-option label="Hong Kong Listed" value="HK">Hong Kong Listed</el-option>
                        <el-option label="Mainland China Listed" value="MLCN">Mainland China Listed</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Statements" label-width="120px">
                    <el-select v-model="form.statement" placeholder="Please select listed market">
                        <el-option label="Income Statement" value="IC">Income Statement</el-option>
                        <el-option label="Balance Sheet Statement" value="BS">Balance Sheet Statement</el-option>
                        <el-option label="Cash Flow Statement" value="CF">Cash Flow Statement</el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button style="width: 140px;margin-right: 32px;" @click="closeDialog">Cancle</el-button>
                <el-button style="width: 140px;" type="primary" @click="submitFeedback">Feedback</el-button>

            </div>
        </div>
        <!-- Submission Confirmation -->
        <div v-if="submissionStatus">
            <h1>Thank you for your participation and feedback!</h1>
            <p style="margin:24px 120px;font-size: 16px;line-height: 32px;text-align: left;">The Papermoney team will
                review the information received and
                determine if a new report about the company
                "{{ searchText }}" will be created for you. Should we proceed, the report will be published on our
                website within three working days. Please keep an eye on our website for updates. We appreciate your
                involvement.</p>
            <el-button style="width: 120px;margin-right: 24px;" @click="closeDialog">Close</el-button>
        </div>
    </el-dialog>
</template>

<script>
import axios from 'axios';
export default {
    name: 'NofoundTicker',
    data() {
        return {
            dialogVisible: false,
            showForm: false, // 控制表单显示的状态
            submissionStatus: false,
            form: {
                name: '',
                ticker: '',
                region: 'US',
                statement: 'IC'
            }
        }
    },
    methods: {
        openDialog() {
            this.dialogVisible = true;
            this.showForm = false; // 关闭弹窗时重置表单显示状态
            this.submissionStatus = false; // Also reset the submission status when closing the dialog
            this.form.name = this.searchText; // Set the company name in the form
            this.form.ticker = this.searchText; // Reset the ticker
        },
        closeDialog() {
            this.dialogVisible = false;
            this.showForm = false; // 关闭弹窗时重置表单显示状态
            this.submissionStatus = false; // Also reset the submission status when closing the dialog
        },
        openLogin(){
            this.$emit('open-login');
        },
        handleFeedback() {
            // 处理反馈给 Papermoney 团队的逻辑
            console.log(`Feedback to Papermoney for company: ${this.searchText}`);
            if (!this.$store.getters.isAuthenticated) {
                this.openLogin();
                return;
            }
            this.showForm = true; // 将控制表单显示的变量设置为true，以显示表单
        },
        handleCreateReport() {
            // 处理自己创建报告的逻辑
            console.log(`Create report for company: ${this.searchText}`);
            if (!this.$store.getters.isAuthenticated) {
                this.openLogin();
                return;
            }
            this.closeDialog();
            this.$router.push('/create');
        },
        submitFeedback() {
            const feedbackData = {
                name: this.form.name,
                ticker: this.form.ticker,
                region: this.form.region,
                statement: this.form.statement
            };
            // Hypothetical API call to send feedback data
            this.sendFeedbackToServer(feedbackData);
        },
        async sendFeedbackToServer(feedbackData) {
            // Use your actual API endpoint here
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                axios.post(`${apiEndpoint}/feedback-noticker`, feedbackData)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(err => {
                        // Handle any errors that occur during the request here
                        console.error(err);
                    });
            } catch (err) {
                console.log(err);
            }
            this.submissionStatus = true;
        }
    },
    props: {
        searchText: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
::v-deep .el-dialog__title {
    color: #999;
}

::v-deep .el-input__inner {
    width: 100%;
    line-height: 1.5em;
    height: 48px !important;
    border-radius: 4px !important;
    padding: 12px !important;
    font-size: 16px !important;
    border: 1.5px solid #EEE !important;
    background-clip: none !important;
    background-origin: none !important;
    background-image: none !important;
    transition: none !important;
}

::v-deep .el-input__inner:hover {
    box-shadow: none !important;
}

::v-deep .el-select {
    display: block;
}

.feedback-button {
    margin: 24px;
    border-radius: 12px;
    transition: transform 0.3s ease;
    font-size: 16px;
    border: 1px solid #EEE;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.feedback-button:hover {
    transform: scale(1.1);
    /* 确保整个卡片放大，包括边框和阴影 */
}

::v-deep .feedback-button .el-card__body {
    display: flex;
    flex-direction: column
}

.feedback-button i {
    margin-right: 8px;
    font-size: 48px;
    /* 调整图标和文本之间的间隙 */
}

.feedback-button span {
    margin-top: 12px;
    font-size: 16px;
}
</style>