<template>
    <div>
        <el-row :gutter="48" v-loading="loading">

            <el-col :span="7" class="form-container" style="z-index:2">

                <!-- 页面1的内容 -->
                <div v-if="currentPage === 1">
                    <div class="form-step-title">Step1 Company Profile & Report Type</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <!-- <div><i class="el-icon-s-operation" style="margin-right: 12px;"></i>1、Update Company Overview</div> -->
                    <el-card>
                        <el-form :model="config" ref="config" label-width="120px" labelPosition="'right" :rules="rules">
                            <el-form-item label="Company Symbol" prop="companyCode" required>
                                <el-input v-model="config.companyCode" placeholder="Company Symbol" readonly
                                    suffix-icon="el-icon-search"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Name" prop="companyName" required>
                                <el-input v-model="config.companyName" placeholder="Company Name"
                                    :disabled="!config.companyCode"></el-input>
                            </el-form-item>
                            <el-form-item label="Ending Date" prop="endDate" required>
                                <el-date-picker v-model="config.endDate" type="date"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="Currency and Unit">
                                <el-cascader v-model="cascaderOptions" :options="cascaderData"
                                    :props="{ expandTrigger: 'hover' }">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="Title" prop="title" required>
                                <el-input :value="config.title" @input="userTypedTitle"></el-input>
                            </el-form-item>
                            <el-form-item label="Subtitle">
                                <el-input v-model="config.subtitle" clearable></el-input>
                            </el-form-item>

                        </el-form>
                    </el-card>
                </div>

                <div v-if="currentPage === 2">
                    <div class="form-step-title">Step2 Assets</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.CurrentAssets" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraCurrentAssests"
                                :key="`extra-ca-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeItem('extra-ca', index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>

                            <el-form-item>
                                <el-button type="text" @click="addItem('extra-ca')">+ Add Current
                                    Asset</el-button>
                            </el-form-item>

                            <el-form-item>
                                <editable-label :node="form.NonCurrentAssets"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraNonCurrentAssets"
                                :key="`extra-nca-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeItem('extra-nca', index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>

                            <el-form-item>
                                <el-button type="text" @click="addItem('extra-nca')">+ Add Non-Current
                                    Asset</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
                <div v-if="currentPage === 3">
                    <div>
                        <div class="form-step-title">Step3 Liabilities</div>
                        <div style="display: flex;justify-content: flex-end;">
                            <el-button icon="el-icon-chat-line-round" round class="source-button"
                                @click="toggleSourcePannel">View Source Data</el-button>
                        </div>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.CurrentLiabilities"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraCurrentLiabilities"
                                :key="`extra-cl-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeItem('extra-cl', index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addItem('extra-cl')">+ Add Current
                                    Liabilitie</el-button>
                            </el-form-item>

                            <el-form-item>
                                <editable-label :node="form.NonCurrentLiabilities"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraNonCurrentLiabilities"
                                :key="`extra-ncl-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeItem('extra-ncl', index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addItem('extra-ncl')">+ Add Non-Current
                                    Liabilitie</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
                <div v-if="currentPage === 4">
                    <div>
                        <div class="form-step-title">Shareholders Equity</div>
                        <div style="display: flex;justify-content: flex-end;">
                            <el-button icon="el-icon-chat-line-round" round class="source-button"
                                @click="toggleSourcePannel">View Source Data</el-button>
                        </div>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.ShareholdersEquity"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraShareholdersEquity"
                                :key="`extra-sa-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeItem('extra-se', index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addItem('extra-se')">+ Shareholders
                                    Equity</el-button>
                            </el-form-item>
                        </el-form>

                    </el-card>
                </div>
                <!-- 分页器 -->
                <el-pagination layout="prev, pager, next" :total="4" :current-page.sync="currentPage"
                    @current-change="handlePageChange" :page-size="1" background
                    style="display: flex;justify-content: right;margin-top: 24px;">
                </el-pagination>


            </el-col>
            <el-col :span="17" style="z-index=0">
                <div style="display: flex;height:100%;flex-direction: column;align-items: center;">
                    <div
                        style="width: 100%;margin:24px;padding:24px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <div v-show="!edited" style="font-size:24px;color:#333">In Just <b
                                style="color:#FC7A6E;font-size:28px">4</b> Steps
                            To
                            Master the Art of Balance Sheet Analysis for Financial Success</div>
                        <div v-show="!edited" style="font-size:14px;color:#666;margin-top:12px">We pursue the accuracy of
                            data, so please
                            prepare balance sheet data that has been thoroughly reviewed.</div>
                        <div class="steps-container">
                            <div class="view">
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(1)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(1) }">1</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(1) }">Report Profiles</div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(2)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(2) }">2</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(2) }">Assets
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(3)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(3) }">3</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(3) }">Liabilities
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(4)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(4) }">4</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(4) }">Shareholders Equity
                                    </div>
                                </div>
                                <el-divider></el-divider>

                            </div>
                            <div style="display:flex;justify-content: flex-end;margin-left:24px">
                                <el-popconfirm confirm-button-text="New" cancel-button-text="Cancel" icon="el-icon-info"
                                    icon-color="red" title="Create new report?" @confirm="showSearchSymbolDialog">
                                    <el-button slot="reference" icon="el-icon-search" round
                                        style="margin-right: 12px; height:40px">New</el-button>
                                </el-popconfirm>

                                <!-- <el-popconfirm confirm-button-text="Save as  Publish" cancel-button-text="Save as Private"
                                    icon="el-icon-upload" icon-color="green" title="How would you like to save this report?"
                                    @confirm="saveReportData(true)" @cancel="saveReportData(false)"
                                    cancel-button-type="primary" confirm-button-type="success">
                                    <el-button round slot="reference" type="success" style="margin-right: 12px;"
                                        icon="el-icon-upload">
                                        Save
                                    </el-button>
                                </el-popconfirm> -->
                                <el-button round slot="reference" @click="saveReportData" type="success"
                                    style="margin-right: 12px;height:40px" icon="el-icon-upload">
                                    Save
                                </el-button>
                                <el-button round style="height:40px" @click="triggerDownload" type="primary"
                                    icon="el-icon-download">Download
                                </el-button>
                            </div>
                        </div>

                    </div>
                    <el-card class="sankey-container">

                        <div class="image-container" style="flex:1">
                            <BalanceSankey ref="customerSankeyRef" :sankey-data="sankeyData" />
                        </div>
                    </el-card>
                </div>

            </el-col>

        </el-row>
        <el-drawer title="Financial Report Assistant" :visible.sync="showSourcePanal" direction="rtl"
            :before-close="handleClosePannel" :wrapperClosable="false" :modal="false" :append-to-body="true" size="35%">
            <source-panel :source="source" />
        </el-drawer>
    </div>
</template>
<script>
import BalanceSankey from './BalanceSankey.vue';
import BaseForm from './BaseForm.vue';
import Tools from '../../GlobalTools';
import EditableLabel from './EditableLabel.vue';
import SourcePanel from './SourceDataForm.vue';
import axios from 'axios';
export default {
    extends: BaseForm,
    components: {
        SourcePanel,
        EditableLabel,
        BalanceSankey // Correct the component name to match the imported name
    },
    props: {
        report: {
            type: Object,
            default: () => { }
        }
    },
    mounted() {
        this.getReportDetail();
    },
    data() {
        return {
            config: {
                title: '',
                subtitle: '',
                companyName: '',
                companyCode: '',
                reportType: '',
                endDate: '',
                currency: '',
                unit: '',
                color: '',
            },
            form: {
                CurrentAssets: {
                    id: 'CurrentAssets',
                    desc: 'Current Assets',
                    v: 0,
                    weight: 0,
                    color: 'darkgreen',
                    yy: '',
                    sort: 3,
                    vi: '',
                },
                extraCurrentAssests: [],
                NonCurrentAssets: {
                    id: 'NonCurrentAssets',
                    desc: 'Non-current Assets',
                    v: 0,
                    weight: 0,
                    color: 'lightgreen',
                    yy: '',
                    sort: 2,
                    vi: '',
                },
                extraNonCurrentAssets: [],
                CurrentLiabilities:
                {
                    id: 'CurrentLiabilities',
                    desc: 'Current Liabilities',
                    v: 0,
                    weight: 0,
                    color: 'darkred',
                    yy: '',
                    sort: 3,
                    vi: '',
                },
                extraCurrentLiabilities: [],
                NonCurrentLiabilities: {
                    id: 'NonCurrentLiabilities',
                    desc: 'Non-Current Liabilities',
                    v: 0,
                    weight: 0,
                    color: 'lightred',
                    yy: '',
                    sort: 2,
                    vi: '',
                },
                extraNonCurrentLiabilities: [],
                ShareholdersEquity: {
                    id: 'ShareholdersEquity',
                    desc: 'Shareholders Equity',
                    v: 0,
                    weight: 0,
                    color: 'blue',
                    yy: '',
                    sort: 1,
                    vi: '',
                },
                extraShareholdersEquity: []
            },
            treemap: [],
            rules: {
                companyCode: [
                    { required: true, message: 'Please enter the company symbol', trigger: 'blur' }
                ],
                companyName: [
                    { required: true, message: 'Please enter the company name', trigger: 'blur' }
                ],
                reportType: [
                    { required: true, message: 'Please select a report type', trigger: 'change' }
                ],
                endDate: [
                    { required: true, message: 'Please select an end date', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Please enter a title', trigger: 'blur' }
                ],
                cascaderOptions: [
                    { required: true, message: 'Please select a currency', trigger: 'change' }
                ]
                // ... other validation rules
            }
        };

    },
    // 方法和逻辑可以根据需求添加
    methods: {
        getFormNodeById(id) {
            // console.log('id,result:', id, this.form[id]);
            if (this.form[id]) {
                return this.form[id];
            }
            return null;
        },
        addItem(parentid) {
            if (parentid.startsWith('extra-ca')) {
                this.form.extraCurrentAssests.push(this.newNode('extra-ca-' + this.form.extraCurrentAssests.length));
            } else if (parentid.startsWith('extra-nca')) {
                this.form.extraNonCurrentAssets.push(this.newNode('extra-nca-' + this.form.extraNonCurrentAssets.length));
            } else if (parentid.startsWith('extra-cl')) {
                this.form.extraCurrentLiabilities.push(this.newNode('extra-cl-' + this.form.extraCurrentLiabilities.length));
            } else if (parentid.startsWith('extra-ncl')) {
                this.form.extraNonCurrentLiabilities.push(this.newNode('extra-ncl-' + this.form.extraNonCurrentLiabilities.length));
            } else if (parentid.startsWith('extra-se')) {
                this.form.extraShareholdersEquity.push(this.newNode('extra-se-' + this.form.extraShareholdersEquity.length));
            }
        },

        removeItem(parentid, index) {
            if (parentid.startsWith('extra-ca')) {
                this.form.extraCurrentAssests.splice(index, 1);
            } else if (parentid.startsWith('extra-nca')) {
                this.form.extraNonCurrentAssets.splice(index, 1);
            } else if (parentid.startsWith('extra-cl')) {
                this.form.extraCurrentLiabilities.splice(index, 1);
            } else if (parentid.startsWith('extra-ncl')) {
                this.form.extraNonCurrentLiabilities.splice(index, 1);
            } else if (parentid.startsWith('extra-se')) {
                this.form.extraShareholdersEquity.splice(index, 1);
            }
        },
        newNode(id, desc = '', v = 0, color = 'green', yy = '', sort = 0, vi = '') {
            return {
                id,
                desc,
                v,
                weight: Math.abs(v),
                color,
                yy,
                sort,
                vi
            }
        },
        //自动求和收入
        autoCalcGroupSum(groupId) {
            var sum = 0;
            switch (groupId) {
                case 'CurrentAssets':
                    sum = this.form.extraCurrentAssests.reduce((total, item) => {
                        return total + item.v;
                    }, 0);
                    this.form.CurrentAssets.v = sum;
                    this.form.CurrentAssets.weight = Math.abs(sum);
                    this.form.CurrentAssets.vi = sum + '';
                    break;
                case 'NonCurrentAssets':
                    sum = this.form.extraNonCurrentAssets.reduce((total, item) => {
                        return total + item.v;
                    }, 0);
                    this.form.NonCurrentAssets.v = sum;
                    this.form.NonCurrentAssets.weight = Math.abs(sum);
                    this.form.NonCurrentAssets.vi = sum + '';
                    break;
                case 'CurrentLiabilities':
                    sum = this.form.extraCurrentLiabilities.reduce((total, item) => {
                        return total + item.v;
                    }, 0);
                    this.form.CurrentLiabilities.v = sum;
                    this.form.CurrentLiabilities.weight = Math.abs(sum);
                    this.form.CurrentLiabilities.vi = sum + '';
                    break;
                case 'NonCurrentLiabilities':
                    sum = this.form.extraNonCurrentLiabilities.reduce((total, item) => {
                        return total + item.v;
                    }, 0);
                    this.form.NonCurrentLiabilities.v = sum;
                    this.form.NonCurrentLiabilities.weight = Math.abs(sum);
                    this.form.NonCurrentLiabilities.vi = sum + '';
                    break;
                case 'ShareholdersEquity':
                    sum = this.form.extraShareholdersEquity.reduce((total, item) => {
                        return total + item.v;
                    }, 0);
                    this.form.ShareholdersEquity.v = sum;
                    this.form.ShareholdersEquity.weight = Math.abs(sum);
                    this.form.ShareholdersEquity.vi = sum + '';
                    this.form.ShareholdersEquity.color = sum > 0 ? 'blue' : 'lightgray';
                    break;
                default:
                    break;
            }
        },
        handleNodeChange(newNode, nodeId) {
            // 根据 nodeId 找到相应的 node 并更新颜色
            // console.log('handleNodeChange:', newNode, nodeId);
            if (nodeId.startsWith('extra-ca')) {
                let index = nodeId.split('-')[2];
                newNode.sort = 3;
                newNode.color = 'darkgreen';
                this.$set(this.form.extraCurrentAssests, index, newNode);
                this.autoCalcGroupSum('CurrentAssets');
            } else if (nodeId.startsWith('extra-nca')) {
                let index = nodeId.split('-')[2];
                newNode.sort = 2;
                newNode.color = 'lightgreen';
                this.$set(this.form.extraNonCurrentAssets, index, newNode);
                this.autoCalcGroupSum('NonCurrentAssets');
            } else if (nodeId.startsWith('extra-cl')) {
                let index = nodeId.split('-')[2];
                newNode.sort = 3;
                newNode.color = 'darkred';
                this.$set(this.form.extraCurrentLiabilities, index, newNode);
                this.autoCalcGroupSum('CurrentLiabilities');
            } else if (nodeId.startsWith('extra-ncl')) {
                let index = nodeId.split('-')[2];
                newNode.sort = 2;
                newNode.color = 'lightred';
                this.$set(this.form.extraNonCurrentLiabilities, index, newNode);
                this.autoCalcGroupSum('NonCurrentLiabilities');
            } else if (nodeId.startsWith('extra-se')) {
                let index = nodeId.split('-')[2];
                newNode.sort = 1;
                newNode.color = newNode.color > 0 ? 'blue' : 'lightgray';
                this.$set(this.form.extraShareholdersEquity, index, newNode);
                this.autoCalcGroupSum('ShareholdersEquity');
            } else if (this.form[nodeId]) {
                this.form[nodeId] = newNode
                if (nodeId === 'ShareholdersEquity') {
                    this.form[nodeId].color = newNode.v > 0 ? 'blue' : 'lightgray'
                }
            }
        },
        generateTreemapData() {
            var result = {
                assets: [
                    {
                        name: this.form.CurrentAssets.desc,
                        children: !Tools.isArrayEmpty(this.form.extraCurrentAssests) ?
                            this.form.extraCurrentAssests.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.CurrentAssets)],
                        color: this.form.CurrentAssets.color,
                        total: this.form.CurrentAssets.v
                    },
                    {
                        name: this.form.NonCurrentAssets.desc,
                        children: !Tools.isArrayEmpty(this.form.extraNonCurrentAssets) ?
                            this.form.extraNonCurrentAssets.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.NonCurrentAssets)],
                        color: this.form.NonCurrentAssets.color,
                        total: this.form.NonCurrentAssets.v

                    }
                ],
                liabilities: [
                    {
                        name: this.form.CurrentLiabilities.desc,
                        children: !Tools.isArrayEmpty(this.form.extraCurrentLiabilities) ?
                            this.form.extraCurrentLiabilities.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.CurrentLiabilities)],
                        color: this.form.CurrentLiabilities.color,
                        total: this.form.CurrentLiabilities.v
                    },
                    {
                        name: this.form.NonCurrentLiabilities.desc,
                        children: !Tools.isArrayEmpty(this.form.extraNonCurrentLiabilities) ?
                            this.form.extraNonCurrentLiabilities.map(item => this.createTreemapNode(item)) :
                            [this.createTreemapNode(this.form.NonCurrentLiabilities)],
                        color: this.form.NonCurrentLiabilities.color,
                        total: this.form.NonCurrentLiabilities.v
                    }
                ],
                equity:
                {
                    name: this.form.ShareholdersEquity.desc,
                    children: !Tools.isArrayEmpty(this.form.extraShareholdersEquity) ?
                        this.form.extraShareholdersEquity.map(item => this.createTreemapNode(item)) :
                        [this.createTreemapNode(this.form.ShareholdersEquity)],
                    color: this.form.ShareholdersEquity.v > 0 ? 'blue' : 'lightgray',
                    total: this.form.ShareholdersEquity.v
                }
            };
            return result;
        },

        createTreemapNode(item) {
            // 创建矩形树图的节点
            return {
                name: item.desc,
                value: item.v,
                color: item.color
            };
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.treemap = this.generateTreemapData();
            } catch (e) {
                console.log('generateLinks exception:', e);

            }
            return {
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                treemap: this.treemap,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName,
            };
        },
        getFormateDate() {
            let endDate = new Date(this.config.endDate);

            // 使用 toLocaleDateString 方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号
            formattedDate = formattedDate.replace(',', '').replace(' ', ' ');
            return formattedDate;
        },
        generateTitle() {
            // 如果用户没有编辑过标题，则生成新标题
            // console.log('generateTitle:', this.userHasEditedTitle, this.config.title);
            if (!this.userHasEditedTitle) {
                this.config.title = `${this.config.companyName} Balance Sheets ${this.getFormateDate()}`;
            }
        },
        generateSubTitle() {
            if (!this.userHasEditedTitle) {
                let subtitle = `Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
                // 设置标题
                this.config.subtitle = subtitle;
            }
            this.validateForm();

        },

        loadData(config, form) {
            // console.log('loadData:', config, form);
            this.userHasEditedTitle = true;
            this.config.companyName = config.companyName;
            this.config.color = config.color;
            this.config.currency = config.currency;
            this.config.endDate = config.endDate;
            this.config.reportType = config.reportType;
            this.config.subtitle = config.subtitle;
            this.config.unit = config.unit;
            this.config.industry = config.industry;

            this.cascaderOptions = [this.config.currency, this.config.unit];

            this.form = form;

            // 如果加载的数据中没有 title 或 title 为空，则生成新标题
            if (Tools.isEmpty(config.title)) {
                this.userHasEditedTitle = false;
                this.generateTitle();
            } else {
                // 如果加载的数据中有 title，则使用它并设置标志为 true
                // console.log('loadData update title:', config.title);
                this.$nextTick(() => {
                    this.config.title = config.title;
                    this.userHasEditedTitle = false;
                });

            }
        },

        formateDate(date_ending) {
            // Regular expression to check if the date is in 'YYYY-MM-DD' format
            const validDateFormat = /^\d{4}-\d{2}-\d{2}$/;

            // Check if the date is already in a valid format
            if (validDateFormat.test(date_ending)) {
                return date_ending;
            } else {
                // Extract the year, month, and day from the date string
                const year = date_ending.substring(0, 4);
                const month = date_ending.substring(4, 6);
                const day = date_ending.substring(6, 8);

                // Construct a date string in the 'YYYY-MM-DD' format
                const formattedDateString = `${year}-${month}-${day}`;
                return formattedDateString;
            }
        },

        triggerDownload() {
            this.$refs.customerSankeyRef.downloadSankeyImage();
        },


        async saveReportData() {
            // 启用 loading
            this.loading = true;
            let publicReport = this.companyType === 'LC';
            try {
                const imageUrl = await this.$refs.customerSankeyRef.uploadImgToOSS();
                const formattedEndDate = Tools.formatDateToYYYYMMDD(this.config.endDate);
                const dateType = 'Q'    //资产负债表是一个静态的过程，所以这里直接设置为Q
                const reportData = {
                    company: this.config.companyName,
                    symbol: this.config.companyCode,
                    publicReport: publicReport,
                    reportType: 'BALANCE_SHEET',
                    dateType: dateType, // 你可以根据需求调整
                    dateEnding: formattedEndDate,
                    data: {
                        title: this.config.title,
                        config: { ...this.config },
                        form: this.extractFormData()
                    },
                    reviewLink: imageUrl
                };

                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                // 发送请求
                const response = await axios.post(`${apiEndpoint}/updateCompanyReport`, reportData);

                // 请求成功后取消 loading
                this.loading = false;

                if (response.data) {
                    // console.log('Report data saved successfully:', response.data);

                    // 显示弹窗，而不是简单的消息
                    this.$msgbox({
                        title: 'Operation Successful',
                        message: 'The report data has been saved. \n Please select your next action:',
                        showCancelButton: true,
                        confirmButtonText: 'Create New Report',
                        cancelButtonText: 'Return to Editing',
                        type: 'success'
                    }).then(() => {
                        // 如果用户选择新建其他报告，执行相应操作
                        // console.log('用户选择新建其他报告');
                        window.location.reload();
                    }).catch(() => {
                        // 如果用户选择继续编辑，执行相应操作
                        // console.log('用户选择继续编辑');
                    });
                }

            } catch (error) {
                // 请求失败后取消 loading
                this.loading = false;

                if (error.response) {
                    // 打印服务端返回的错误信息
                    console.error('Server error:', error.response.data);
                    // 显示错误消息
                    this.$message.error(`${error.response.data.error || 'Unknown error'}`);
                } else {
                    // 如果没有收到服务端的响应
                    console.error('Error saving report data:', error);
                    this.$message.error('Error occurred while saving the report data.');
                }
            }

        },
        async getReportDetail() {
            // console.log('getReportDetail:', this.report);
            this.config.companyCode = this.report.symbol;
            this.config.companyName = this.report.name;
            this.config.title = this.report.title;
            this.config.reportType = this.report.dateType;
            this.source = this.report;
            if (this.report.deadline) {
                this.loading = true;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const params = {
                    symbol: this.report.symbol,
                    deadline: this.report.deadline,
                    status: this.report.status,
                    type: this.report.type,
                    dateType: this.report.dateType
                };
                axios.post(`${apiEndpoint}/getReportDetail`, params).then(response => {
                    this.loading = false;
                    if (response.data && response.data.result) {
                        var reportDetail = response.data.result;
                        if (reportDetail.source === 'papermoney') {
                            this.companyCode = this.report.symbol;
                            this.companyName = this.report.name;
                            this.loadData(reportDetail.data.config, reportDetail.data.form);
                        }
                    }
                }).catch(error => {
                    console.log('getReportDetail error:', error);
                    this.loading = false;
                });
            }
        },
        async validateForm() {
            if (this.$refs.config) {
                const valid = await this.$refs.config.validate();
                this.isFormValid = valid;
            }
        },

        isStepFinished(step) {
            if (step === 4) {
                return true;
            }
        }
    },
};
</script>
