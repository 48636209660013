// Tools.js
function isArrayEmpty(array) {
  return !(array && array.length > 0);
}

function isEmpty(data) {
  return data === undefined || data === null || data === '';
}


function convertToNumber(str) {
  if (isEmpty(str)) return 0;

  // 移除字符串中的所有逗号
  var cleanedStr = str.toString().replace(/,/g, '');

  // 检查是否有括号，如果有则移除并标记为负数
  var isNegative = cleanedStr.includes('(') && cleanedStr.includes(')');
  cleanedStr = cleanedStr.replace(/[()]/g, '');

  // 转换为数字（整数或浮点数）
  var num = Number(cleanedStr);

  // 如果原始字符串有括号，转换为负数
  if (isNegative) {
    num = -Math.abs(num);
  }

  // 检查转换后是否为有效数字
  if (isNaN(num)) {
    // console.error('转换失败，输入不是有效的数字:', str);
    return 0; // 或者您可以选择返回一个默认值，比如 0.0
  }

  return num;
}


function parseNodeColor(nc) {
  switch (nc) {
    case 'green':
      return '#269322';
    case 'red':
      return '#BE0005';
    case 'gray':
      return '#616161';
    case 'blue':
      return '#1457B1';
    case 'orange':
      return '#FA8C35';
    case 'green-v1':
      return '#89A52C'
    case 'blue-v1':
      return '#6A7AAD';
    case 'red-v1':
      return '#F46545';
    default:
      return '#616161';
  }
}
function parseLinkColor(nc) {
  switch (nc) {
    case 'green':
      return '#81BD80';
    case 'red':
      return '#D06C6E';
    case 'gray':
      return '#A5A5A5';
    case 'blue':
      return '#82A4D2';
    case 'orange':
      return '#FEC37D';
    case 'green-v1':
      return '#9AB931'
    case 'blue-v1':
      return '#879DDE';
    case 'red-v1':
      return '#FDA761';
    default:
      return '#A5A5A5';
  }
}

function parseColorLight(color, delta) {
  let rgb = hexToRgb(color);

  // 使用乘法调整 RGB 分量
  rgb.r = Math.min(255, Math.max(0, Math.round(rgb.r * delta)));
  rgb.g = Math.min(255, Math.max(0, Math.round(rgb.g * delta)));
  rgb.b = Math.min(255, Math.max(0, Math.round(rgb.b * delta)));

  return rgbToHex(rgb.r, rgb.g, rgb.b);
}

// 辅助函数：将十六进制颜色字符串转换为RGB对象
function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// 辅助函数：将RGB值转换回十六进制颜色字符串
function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function parsePaperColor(nc) {
  switch (nc) {
    case 'green':
      return '#81BD80';
    case 'darkgreen':
      return '#269322';
    case 'lightgreen':
      return '#67C23A';
    case 'red':
      return '#D06C6E';
    case 'darkred':
      return '#CC3333';
    case 'lightred':
      return '#FC7A6E';
    case 'gray':
      return '#A5A5A5';
    case 'darkgray':
      return '#616161';
    case 'lightgray':
      return '#DDD';
    case 'blue':
      return '#82A4D2';
    case 'darkblue':
      return '#1457B1';
    case 'lightblue':
      return '#D6E6F2';
    case 'orange':
      return '#FEC37D';
    case 'darkorange':
      return '#FA8C35';
    case 'lightorange':
      return '#FDF2E9';
    case 'yellow':
      return '#FEC37D';
    case 'darkyellow':
      return '#FA8C35';
    case 'lightyellow':
      return '#FDF2E9';
    default:
      return nc;
  }
}

function parseVColor(c) {
  // case 'darkblue':
  //     return '#1457B1';
  //   case 'lightblue':
  //     return '#D6E6F2';
  //   case 'orange':
  //     return '#FEC37D';
  //   case 'darkorange':
  //     return '#FA8C35';
  //   case 'lightorange':
  //     return '#FDF2E9';
  //   case 'yellow':
  //     return '#FEC37D';
  //   case 'darkyellow':
  //     return '#FA8C35';
  //   case 'lightyellow':
  //     return '#FDF2E9';
  const colorMap = {
    'green': '#2E6D44',
    'darkgreen': '#1D4C2D',
    'darkgreen1': '269322',
    'lightgreen': '63B776',
    'lightgreen1': '84C195',
    'lightgreen2': 'BDE7C6',
    'red': '#D06C6E',
    'darkred': 'C62A2B',
    'darkred1': 'E93F1E',
    'lightred': 'FCAEAF',
    'lightred1': 'FCAEAF',
    'blue': '#82A4D2',
    'darkblue': '#1457B1',
    'darkblue1': '#1457B1',
    'lightblue': '#D6E6F2',
    'lightblue1': '#D6E6F2',
    'orange': '#FEC37D',
    'darkorange': '#FA8C35',
    'darkorange1': '#FA8C35',
    'lightorange': '#FDF2E9',
    'lightorange1': '#FDF2E9',
    'yellow': '#FEC37D',
    'darkyellow': '#FA8C35',
    'darkyellow1': '#FA8C35',
    'lightyellow': '#FDF2E9',
    'lightyellow1': '#FDF2E9',
    'gray': '#A5A5A5',

  }
  return colorMap[c] || c;
}

function formatDateToYYYYMMDD(date) {
  if (!date) return '';

  // Create a new Date object using the ISO date
  const dateObj = new Date(date);
  const year = dateObj.getFullYear().toString();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
  const day = dateObj.getDate().toString().padStart(2, '0');

  // Concatenate the strings in 'YYYYMMDD' format
  return year + month + day;
}
function getTextColorByBack(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}
function getColorWithAlpha(hex, alpha = 1.0) {
  hex = hex.replace("#", "");
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


function parseIndustry(value) {
  var industryOptions = [
    { value: 'energy', label: 'Energy' },
    { value: 'basic_materials', label: 'Basic Materials' },
    { value: 'industrials', label: 'Industrials' },
    { value: 'consumer_goods', label: 'Consumer Goods' },
    { value: 'health_care', label: 'Health Care' },
    { value: 'consumer_services', label: 'Consumer Services' },
    { value: 'telecommunications', label: 'Telecommunications' },
    { value: 'utilities', label: 'Utilities' },
    { value: 'financials', label: 'Financials' },
    { value: 'technology', label: 'Technology' }
  ];
  var foundItem = industryOptions.find(item => item.value === value);
  return foundItem ? foundItem.label : '';
}


function logObj(str, obj) {
  const seen = new WeakSet(); // 用于追踪已经遇到的对象

  const replacer = (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        // 如果这个对象已经被处理过了，则跳过它
        return;
      }
      seen.add(value); // 将这个对象标记为已处理
    }
    return value;
  };

  console.log(str, JSON.stringify(obj, replacer, 2));
}


const Tools = {
  isArrayEmpty,
  isEmpty,
  convertToNumber,
  parseNodeColor,
  parseLinkColor,
  parsePaperColor,
  parseVColor,
  parseColorLight,
  formatDateToYYYYMMDD,
  parseIndustry,
  logObj,
  getTextColorByBack,
  getColorWithAlpha
};

export default Tools;
