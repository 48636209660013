<script>

import CustomerSankey from './IncomeSankey.vue';
import * as d3 from 'd3'
import Tools from '../../GlobalTools'
export default {
    name: 'CashFlowSankey',
    extends: CustomerSankey,
    data() {
        return {
            // 新的或重写的数据属性
            containerId: 'balance',
            config: null,
        };
    },
    methods: {
        drawChart(data) {
            // 获取容器元素
            let parentId = data.containerId ? data.containerId : '';
            if (parentId) {
                this.containerId = 'balance' + parentId;
                this.$nextTick(() => {
                    // console.log('balance drawChart', this.containerId);
                    this.drawBalanceChart(data);
                });
            } else {
                this.drawBalanceChart(data);
            }

        },

        drawBalanceChart(data) {
            // var unit = data.unit;
            // var currency = data.currency;
            // var unit_t = data.unit_t;
            // Append a background rect to the SVG
            this.config = {};
            this.config.unit = data.unit;
            this.config.currency = data.currency;
            this.config.unit_t = data.unit_t;

            const titleHeight = 120; // Height of the title area
            const titleMargin = 240; // Margin around the title and icon

            // var nodeWidth = 80;
            // var nodePadding = 260;


            var chartName = data.chatName ? data.chatName : data.symbol;
            var svgParent = `#sankeyDiagram-${this.containerId}`;
            // console.log('drawbalance svg selector:', svgParent,d3.select(`${svgParent}`));
            const svgId = `svg-${chartName}`;
            d3.select(`${svgParent} svg`).remove();
            const svg = d3.select(`${svgParent}`).append('svg')
                .attr('id', svgId)
                .attr('width', this.picWidth)
                .attr('height', this.picHeight)
                .style('cursor', 'pointer');

            // console.log('drawbalance svg:', svgParent, svg, d3.select(`${svgParent}`));

            // Append a background rect to the SVG
            svg.append('rect')
                .attr('width', this.picWidth)
                .attr('height', this.picHeight)
                .attr('fill', '#F5F5F5');

            // Add an icon to the title group



            // 创建包含标题的组
            const titleGroup = svg.append('g')
                .attr('transform', `translate(${this.picWidth / 2}, ${titleMargin})`);

            // let titleWidth = 0;
            if (!Tools.isEmpty(data.title)) {
                // 先添加标题文本
                titleGroup.append('text')
                    .attr('x', 0)
                    .attr('y', titleHeight * 1 / 3)
                    .attr('alignment-baseline', 'middle')
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '120px')
                    .attr('fill', '#444444')
                    .attr('font-weight', 'bold')
                    .attr('font-family', 'Times New Roman')
                    .text(data.title);

            }

            if (data.subtitle) {
                // 添加副标题
                titleGroup.append('text')
                    .attr('x', 0) // 水平居中
                    .attr('y', titleHeight + 12) // 在主标题下方
                    .attr('alignment-baseline', 'middle')
                    .attr('text-anchor', 'middle') // 文本中心对齐
                    .attr('font-size', '48px') // 副标题字体大小，可以根据需要调整
                    .attr('fill', '#aaa') // 副标题字体颜色，可以根据需要调整
                    .text(data.subtitle); // 动态副标题
            }

            //底部添加官方网址
            // 首先，确定 text 的大小（根据字体大小和内容大致估计）
            const textSizeEstimate = 680; // 这个值可能需要调整

            // 计算 text 和 logo 的位置
            const xPosition = this.picWidth / 2;
            const logoWidth = 80; // 假设 logo 宽度为100，根据实际情况调整
            const totalWidth = textSizeEstimate + logoWidth + 80; // text 宽度 + logo 宽度 + 80px 间距

            // 计算 logo 的位置
            const logoX = xPosition - totalWidth / 2;
            const logoY = this.picHeight - logoWidth - 40; // 假设 logo 高度和宽度相同

            // 添加 logo
            svg.append('image')
                .attr('x', logoX)
                .attr('y', logoY)
                .attr('width', logoWidth)
                .attr('height', logoWidth)
                .attr('href', this.logoSrc); // 使用从 data 函数中获取的路径

            // 添加 text（和你的代码一样）
            svg.append('text')
                .attr('x', xPosition)
                .attr('y', this.picHeight - 60)
                .attr('text-anchor', 'middle')
                .attr('font-size', '64px')
                .attr('font-family', 'sans-serif')
                .attr('font-weight', 'bold')
                .attr('fill', '#133D64')
                .text('www.papermoney.ai');

            if (data.treemap) {
                this.drawEntireChart(svg, data.treemap);
            }

            // 获取类名为 'image-container' 的所有元素
            const containers = document.getElementsByClassName('image-container');

            // 检查是否有获取到元素，并获取第一个元素的尺寸
            let containerWidth, containerHeight;
            if (containers.length > 0) {
                containerWidth = containers[0].clientWidth - 16;
                containerHeight = containers[0].clientHeight;
            } else {
                console.log('未找到具有 image-container 类的元素');
                // 可以选择在这里设置一个默认的宽度和高度，或者执行其他操作
            }

            // 计算缩放比例
            const scaleX = containerWidth / this.picWidth;
            const scaleY = containerHeight / this.picHeight;
            const scale = Math.min(scaleX, scaleY); // 选择较小的比例以保持比例

            // console.log('svg transform', scale, containerHeight, containerWidth);

            // 应用缩放和转换
            svg.attr('transform', 'scale(' + scale + ')');
        },
        drawEntireChart(svg, treemapData) {
            const margin = { top: 180, right: 560, bottom: 180, left: 560 };
            const titleHeight = 120; // 预留给标题的高度
            const titleMargin = 240; // 标题的边距
            const chartWidth = this.picWidth - margin.left - margin.right;
            const chartHeight = this.picHeight - margin.top - margin.bottom - titleHeight - titleMargin;

            // 左侧：流动资产和非流动资产
            let currentY = margin.top + titleHeight + titleMargin;

            let totalAssets = treemapData.assets.reduce((sum, group) => sum + group.total, 0);
            let liabilitiesTotal = treemapData.liabilities.reduce((sum, group) => sum + group.total, 0);
            let equityTotal = treemapData.equity.total;
            let totalLiabEquity = liabilitiesTotal;
            let totalAssetQquity = totalAssets;
            if (equityTotal > 0) {
                totalLiabEquity = liabilitiesTotal + treemapData.equity.total;
            } else {
                totalAssetQquity = totalAssets + Math.abs(equityTotal);
            }

            // 在 SVG 画布上添加 "Total" 文本
            svg.append("text")
                .attr("x", (this.picWidth / 2)) // 将总数文本放在画布的中心
                .attr("y", currentY - 24) // 将总数文本放在所有格子的上方，margin.top - 24 为了留出一些空间
                .attr("text-anchor", "middle") // 文本居中对齐
                .attr("dominant-baseline", "bottom") // 确保文本的底部对齐
                .style("font-size", "64px") // 字体大小可以根据您的需要调整
                .style("fill", "#666")
                .style("font-weight", "bold") // 字体粗细可以根据您的需要调整
                .text("Total Assets " + this.formatAmount(totalAssets, this.config.unit, this.config.currency)); // 显示格式化的总数



            treemapData.assets.forEach((group) => {
                if (group.total === 0)
                    return;
                let groupHeight = chartHeight;
                if (group.total != 0 && totalAssetQquity > 0) {
                    groupHeight = chartHeight * (group.total / totalAssetQquity);
                }
                // if (group.total !== 0 && (treemapData.assets[0].total + treemapData.assets[1].total !== 0)) {
                //     groupHeight = chartHeight * (group.total / (treemapData.assets[0].total + treemapData.assets[1].total));
                // }
                // 在每个组的格子旁边添加描述文本
                this.drawGroupTotal(svg, 'l', totalAssets, group, currentY, groupHeight, margin, chartWidth);
                this.drawTreemap(svg, group, margin.left + 6, currentY, chartWidth / 2 - 6, groupHeight, totalAssets);
                currentY += groupHeight;
            });



            // 右侧：短期负债、非短期负债和所有者权益
            let rightStartY = margin.top + titleHeight + titleMargin;

            treemapData.liabilities.forEach((group) => {
                if (group.total === 0)
                    return;
                let groupWidth = chartWidth / 2 - 6;
                let groupHeight = chartHeight;
                if (group.total !== 0 && totalLiabEquity > 0) {
                    groupHeight = chartHeight * (group.total / totalLiabEquity);
                }
                this.drawGroupTotal(svg, 'r', totalAssets, group, rightStartY, groupHeight, margin, chartWidth);
                this.drawTreemap(svg, group, margin.left + chartWidth / 2 + 6, rightStartY, groupWidth, groupHeight, totalAssets);
                rightStartY += groupHeight;
            });
            // 所有者权益单独绘制
            if (treemapData.equity.total > 0 && totalLiabEquity > 0) {
                let group = treemapData.equity;
                let groupHeight = chartHeight * (treemapData.equity.total / totalLiabEquity);
                // 在每个组的格子旁边添加描述文本

                this.drawGroupTotal(svg, 'r', totalAssets, group, rightStartY, groupHeight, margin, chartWidth);
                this.drawTreemap(svg, treemapData.equity, margin.left + chartWidth / 2 + 6, rightStartY, chartWidth / 2 - 6, groupHeight, totalAssets);
            } else if (treemapData.equity.total < 0 && totalAssetQquity > 0) {
                let group = treemapData.equity;
                let groupHeight = chartHeight * (Math.abs(treemapData.equity.total) / totalAssetQquity);
                // 在每个组的格子旁边添加描述文本
                this.drawGroupTotal(svg, 'l', totalAssets, group, currentY, groupHeight, margin, chartWidth);
                this.drawTreemap(svg, treemapData.equity, margin.left + 6, currentY, chartWidth / 2 - 6, groupHeight, totalAssets);
            }

        },
        drawGroupTotal(svg, type, totalAssests, group, currentY, groupHeight, margin, chartWidth) {
            var spanValue = null;
            if (type === 'l') {
                var color = Tools.parsePaperColor(group.color);
                if (group.color === 'lightgray') {
                    color = '#999';
                }
                svg.append('text')
                    .attr('x', margin.left - 24) // 文本的 X 轴位置，减去24以确保与边缘有24像素的距离
                    .attr('y', currentY + groupHeight / 2) // 文本的 Y 轴位置，组的中间
                    .attr('text-anchor', 'end') // 文本右对齐
                    .attr('dominant-baseline', 'central') // 垂直居中
                    .attr('fill', color) // 文本颜色
                    .style('font-size', 48) // 设置标签的字体大小
                    .text(group.name); // 显示组的名称

                // 在描述文本下方添加数值
                spanValue = svg.append('text')
                    .attr('x', margin.left - 24) // 同上
                    .attr('y', currentY + groupHeight / 2 + 60) // 在标签文本下方
                    .attr('text-anchor', 'end') // 文本右对齐
                    .attr('dominant-baseline', 'central') // 垂直居中
                    .attr('fill', color) // 文本颜色
                    .style("font-weight", "bold") // 字体粗细可以根据您的需要调整
                    .style('font-size', 48) // 设置数值的字体大小
                    .text(this.formatAmount(group.total, this.config.unit, this.config.currency)); // 显示组的总数值
            } else {
                svg.append('text')
                    .attr('x', margin.left + chartWidth + 24) // 文本的 X 轴位置，格子的右边界加上间距
                    .attr('y', currentY + groupHeight / 2) // 文本的 Y 轴位置，组的中间
                    .attr('text-anchor', 'start') // 水平居中
                    .attr('dominant-baseline', 'central') // 垂直居中
                    .attr('fill', Tools.parsePaperColor(group.color)) // 文本颜色
                    .style('font-size', 48) // 设置数值的字体大小
                    .text(group.name); // 显示组的名称

                // 在描述文本下方添加数值
                spanValue = svg.append('text')
                    .attr('x', margin.left + chartWidth + 24) // 文本的 X 轴位置，格子的右边界加上间距
                    .attr('y', currentY + groupHeight / 2 + 60) // 在标签文本下方
                    .attr('text-anchor', 'start') // 同上
                    .attr('dominant-baseline', 'central') // 同上
                    .attr('fill', Tools.parsePaperColor(group.color)) // 文本颜色
                    .style('font-size', 48) // 设置数值的字体大小
                    .style("font-weight", "bold") // 字体粗细可以根据您的需要调整
                    .text(this.formatAmount(group.total, this.config.unit, this.config.currency)); // 显示组的总数值
            }
            var marginValue = (group.total / totalAssests * 100).toFixed(0);
            spanValue.append('tspan')
                .attr('dx', '8px')
                .attr('font-size', 40)
                .attr('fill', Tools.parsePaperColor(group.color)) // 文本颜色
                .attr('font-weight', 'normal')
                .text(`(${marginValue}%)`);

        },
        // 添加一个函数来检测文本是否包含中文
        containsChinese(text) {
            return /[\u4e00-\u9fa5]/.test(text);
        },
        drawTreemap(svg, data, x, y, width, height, totalAssets) {
            let vm = this; // 保存 Vue 实例的 this
            const layout = d3.treemap()
                .tile(d3.treemapResquarify.ratio(1.2)) // 使用 resquarify 策略
                .size([width, height])
                .paddingTop(12) // 设置顶部内边距
                .paddingInner(4); // 设置内部间距

            // 计算平均值，自定义排序算法
            const totalValue = d3.sum(data.children, d => d.value);
            const averageValue = totalValue / data.children.length;

            // 自定义的排序函数
            function customSort(a, b) {
                if (a.value >= averageValue && b.value >= averageValue) {
                    return 0;
                }
                if (a.value < averageValue && b.value < averageValue) {
                    return b.value - a.value;
                }
                return a.value >= averageValue ? -1 : 1;
            }

            const root = d3.hierarchy(data)
                .sum(d => d.value)
                .sort(customSort);

            layout(root);

            const group = svg.append('g')
                .attr('transform', `translate(${x},${y})`);

            const nodes = group.selectAll('g')
                .data(root.leaves())
                .enter()
                .append('g')
                .attr('transform', d => `translate(${d.x0},${d.y0})`);

            nodes.append('rect')
                .attr('id', d => d.data.name)
                .attr('width', d => d.x1 - d.x0)
                .attr('height', d => d.y1 - d.y0)
                .attr('fill', d => Tools.parsePaperColor(d.data.color));

            nodes.each(function (d) {
                const node = d3.select(this);
                const padding = 12; // 设置矩形的内边距
                const rectWidth = d.x1 - d.x0 - padding * 2; // 减去左右内边距
                const rectHeight = d.y1 - d.y0 - padding * 2; // 减去上下内边距

                // 根据矩形的宽度和高度动态计算字号
                // 假设最大字号为矩形最小维度的十分之一，并根据文本的实际宽度调整
                const maxFontSize = Math.min(36, rectWidth / 6, rectHeight / 4);
                const minFontSize = 16;
                let fontSize = maxFontSize;
                let nameText = d.data.name;
                let valueText = vm.formatAmount(d.data.value, vm.config.unit, vm.config.currency);
                let percentageText = totalAssets ? ` (${(d.data.value / totalAssets * 100).toFixed(0)}%)` : '';

                // 创建 foreignObject 并直接设置宽高和位置
                const foreignObject = node.append('foreignObject')
                    .attr('width', rectWidth + padding * 2) // 加上内边距
                    .attr('height', rectHeight + padding * 2) // 加上内边距
                    .attr('x', 0) // 设置左内边距
                    .attr('y', 0); // 设置上内边距

                // 使用flex布局的div容器
                const divContainer = foreignObject.append('xhtml:div')
                    .style('height', `${rectHeight}px`)
                    .style('padding', `${padding}px`)
                    .style('display', 'flex')
                    .style('flex-direction', 'column')
                    .style('justify-content', 'center')
                    .style('align-items', 'center')
                    .style('color', '#fff')
                    .style('overflow', 'hidden'); // 防止文本溢出容器

                // 添加名称文本的 div 元素
                const nameDiv = divContainer.append('div')
                    .style('font-size', `${fontSize}px`)
                    .style('word-wrap', 'break-word')
                    .style('word-break', vm.containsChinese(nameText) ? 'break-all' : 'keep-all')
                    .style('text-align', 'center')
                    .style('font-weight','400')
                    .style('hyphens', 'auto') // 允许在适当位置断字
                    .html(nameText);

                // 添加数值和百分比的文本的 div 元素
                const valueDiv = divContainer.append('div')
                    .style('font-size', `${fontSize}px`) // 使用与名称相同的字号
                    .style('word-wrap', 'break-word')
                    .style('word-break', 'keep-all') // 保持英文单词的完整性
                    .style('text-align', 'center')
                    .style('hyphens', 'auto') // 允许在适当位置断字
                    .html(valueText + percentageText);

                // 检查文本是否溢出，如果溢出，减小字号
                while ((nameDiv.node().scrollHeight > rectHeight || valueDiv.node().scrollHeight > rectHeight) && fontSize > minFontSize) {
                    fontSize -= 1; // 每次减小1px
                    nameDiv.style('font-size', `${fontSize}px`);
                    valueDiv.style('font-size', `${fontSize}px`);
                }
            });
        }
    },

};
</script>