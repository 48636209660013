<template>
    <div>
        <HeaderComponent ref="headerComponent" />
        <div class="main">
            <div class="search-container">
                <!-- 搜索框 -->
                <div class="block">
                    <div class="free-query-container"
                        style="margin-top: 50px;  color: #333; margin-bottom: 12px;font-family: Times New Roman;font-weight: 400;    text-wrap: nowrap;">
                        <span style="font-size: 64px; font-family: serif;">Financial Reports Made Easy</span>
                        <!-- <span style="font-size: 64px;margin-left:12px;color:#666;  font-family: serif;">in</span>
                        <span style="font-size: 80px;margin-left:12px;  font-family: serif;">Financial Reports</span> -->
                    </div>
                    <div class="free-query-container"
                        style="font-size: 28px; color: #666; margin-bottom: 28px;font-family: Times New Roman;">
                        <span>Access Free Visualizations and Insights on 5,000+ Listed Companies</span>
                    </div>
                    <template>
                        <div class="main-search-container">
                            <search-list-container @item-selected="onSearchItemSelected" style="width:960px;"
                                @toggle-expand="handleToggleExpand" @open-login="openLogin"></search-list-container>
                        </div>
                    </template>
                    <div style="display: flex; width: 100%; margin-top: 12px; justify-content: center;">
                        <el-card class="card-tab">
                            <router-link to="/list?market=US&tag=">
                                <div class="title">
                                    <img :src="require('@/assets/location/NQ.png')"> United States Listed
                                </div>
                            </router-link>
                        </el-card>
                        <el-card class="card-tab">
                            <router-link to="/list?market=HK&tag=">
                                <div class="title">
                                    <img :src="require('@/assets/location/HK.png')" style="height: 28px; width: 28px;">
                                    Hong Kong Listed
                                </div>
                            </router-link>
                        </el-card>
                        <el-card class="card-tab">
                            <router-link to="/list?market=MLCN&tag=">
                                <div class="title" style="width: 240px;">
                                    <img :src="require('@/assets/location/CN.png')"> Mainland China Listed
                                </div>
                            </router-link>
                        </el-card>
                    </div>

                </div>
            </div>
            <div class="block list" style="margin: 12px 24px ;">
                <div class="list-container">
                    <div class="industry title industry_label">
                        <span>Recent posts</span>
                        <!-- 添加一个按钮来查看所有报告 -->
                        <router-link to="/list?market=ALL&orderBy=last_modified&tag=" class="btn_viewAll">View More
                            Report</router-link>
                        <!-- <span class="btn_viewAll" @click="viewMoreRecent()">View More Report</span> -->
                    </div>
                    <HomeRecent @open-report-detail="openReportViewer" />
                </div>

                <div class="list-container">
                    <div class="industry title industry_label">
                        <span>Report Insights</span>
                        <!-- 添加一个按钮来查看所有报告 -->
                        <router-link to="/list?market=ALL&tag=" class="btn_viewAll">View More Insights</router-link>
                        <!-- <span class="btn_viewAll" @click="gotoCompanyList()">View More Insights</span> -->
                    </div>
                    <HomeRecommend @open-report-detail="openReportViewer" />
                </div>
                <div class="list-container">
                    <div class="industry title industry_label">
                        <span>Popular Companies</span>
                        <!-- 添加一个按钮来查看所有报告 -->
                        <router-link to="/list?market=ALL&tag=" class="btn_viewAll">View All Companies</router-link>
                        <!-- <span class="btn_viewAll" @click="gotoCompanyList()">View All Companies</span> -->
                    </div>
                    <HomePopular @open-report-detail="openReportViewer" />
                </div>
                <div class="list-container">
                    <div class="industry title industry_label">
                        <span>Popular Company Industries</span>
                        <!-- 添加一个按钮来查看所有报告 -->
                        <router-link to="/list?market=ALL&tag=" class="btn_viewAll">View All Industries</router-link>
                        <!-- <span class="btn_viewAll" @click="gotoCompanyList()">View All Industries</span> -->
                    </div>
                    <HomeCompanyTags ref="homeCompanyTags" @handle-click-tag="gotoCompanyList"
                        style="margin-top: 24px;" />
                </div>
            </div>
            <div class="block list border-bottom">
                <div style="font-size: 32px;width: 100%;padding-bottom: 24px;">
                    My Private Company Report
                </div>

                <div style="width: 100%;margin-top: 12px;">
                    <HomePrivateCompany @open-report-detail="openReportViewer" @create-new="goToCreateReport" />
                </div>
            </div>
            <!-- <div class="block list border-bottom" style="padding-bottom: 24px;">
                    <HomeWhy></HomeWhy>
            </div> -->



            <!-- <div class="list-container">
                <div class="grid-container">
                    <div class="report-grid-item" v-for="(report, index) in reports" :key="index"
                        @click="openReportViewer(report)">
                        <el-image class="img-grid" :src="getScaleImgLink(report.reviewLink)" fit="contain"></el-image>
                        <div class="customer-label">
                            <p class="companyName" :ref="`companyName${index}`">{{ report.companyName }}</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div
            style="padding:48px 0px 24px 0px;border-top: 1px solid #EEE;margin-top: 48px;display: flex;flex-direction: row;justify-content: center;align-items: flex-end;">
            <div>
                <span style="font-size: 32px;cursor: pointer;">PaperMoney</span><span>.ai</span>
            </div>
            <span style="margin-left: 48px;cursor: pointer;" @click="dialogConnectVisible = true">Connect Us</span>

            <span style="margin-left: 48px; cursor: pointer;" @click="goToPrivacyPolicy">privacy policy</span>
        </div>
        <el-dialog :visible.sync="dialogConnectVisible" title="Welcome and Contribute">
            <div>
                <el-button @click="openMail('feedback')">Feedback</el-button>
                <el-button @click="openMail('not-found')">Can't Find a Company</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import Tools from '../GlobalTools.js';
import SearchListContainer from './animate/SearchListHome.vue';
import HomeRecommend from './animate/HomeRecommend.vue';
import HomePopular from './animate/HomePopular.vue';
import HomeCompanyTags from './animate/HomeCompanyIndustry.vue';
import HomePrivateCompany from './animate/HomePrivateCompany.vue';
import HomeRecent from './animate/HomeRecent.vue';
// import HomeWhy from './animate/HomeWhy.vue';
export default {
    name: 'ListContainer',
    components: {
        HeaderComponent,
        SearchListContainer,
        HomeRecommend,
        HomePopular,
        HomeCompanyTags,
        HomePrivateCompany,
        HomeRecent
        // HomeWhy
    },
    data() {
        return {
            isSearchExpanded: false, // 控制搜索框是否展开的状态
            loadingInstance: null,
            recommendations: [], // 存储推荐数据
            reports: [],
            viewAllIndustry: {}, // 用于存储用户选择查看所有报告的行业
            industryGroups: {}, // 按行业分组
            industryTopAndBottom: {}, // 每个行业的前4个和后4个报告
            privateReports: [], // 私人的报告
            dialogConnectVisible: false, // 控制弹窗显示
        };
    },
    methods: {
        viewAllTags() {
            console.log('viewAllTags');
            // this.$refs.homeCompanyTags.viewAllTags();
            this.$router.push('/tags');
        },
        handleToggleExpand(expanded) {
            this.isSearchExpanded = expanded; // 更新搜索框的展开状态
        },
        adjustFontSizeForCompanyName() {
            this.$nextTick(() => {
                setTimeout(() => { // 添加一个小的延时
                    this.reports.forEach((report, index) => {
                        const companyNameLength = report.companyName.length;
                        let fontSize = 16; // 默认字号

                        if (companyNameLength > 20) {
                            fontSize = 14; // 如果字符长度大于20，字号为12
                        }
                        // 设置相应的字号
                        if (this.$refs[`companyName${index}`] && this.$refs[`companyName${index}`][0]) {
                            this.$refs[`companyName${index}`][0].style.fontSize = `${fontSize}px`;
                        }
                    });
                }, 100);
            });
        },
        onSearchItemSelected(searchItem) {
            this.handleSelect(searchItem);
        },
        handleSelect(item) {
            if (item && !Tools.isEmpty(item.symbol)) {
                console.log('handleSelect:', item);
                this.gotoReportViewer({ symbol: item.symbol, name: item.name });
            }
        },
        goToCreateReport() {
            // 检查用户是否已登录
            if (this.$store.getters.isAuthenticated) {
                // 如果已登录，跳转到 /create 页面
                this.$router.push('/create');
            } else {
                // 如果未登录，打开登录对话框
                this.$refs.headerComponent.openLoginForm();
            }
        },
        openLogin(){
            this.$refs.headerComponent.openLoginForm();
        },
        goToPrivacyPolicy() {
            this.$router.push('/privacy');
        },
        getIndustryMarginRate(industry) {
            return this.industryAverages[industry] ? (this.industryAverages[industry].averageGrossMarginRate * 100).toFixed(0) + '%' : 'N/A';
        },

        getIndustryNetRate(industry) {
            return this.industryAverages[industry] ? (this.industryAverages[industry].averageNetMarginRate * 100).toFixed(0) + '%' : 'N/A';
        },
        getReportNetRate(report) {
            return report.netMarginRate ? (report.netMarginRate * 100).toFixed(0) + '%' : 'N/A';
        },
        getReportGrossRate(report) {
            return report.grossMarginRate ? (report.grossMarginRate * 100).toFixed(0) + '%' : 'N/A';
        },
        isAboveAverage(report, type) {
            // 确保有平均值和报告的利率数据
            if (!this.industryAverages[report.industry] || report[type] === undefined) {
                return false;
            }

            // 如果报告的利率是 NaN，则视为低于平均值
            if (isNaN(report[type])) {
                return false;
            }

            let averageRate = type === 'netMarginRate' ?
                this.industryAverages[report.industry].averageNetMarginRate :
                this.industryAverages[report.industry].averageGrossMarginRate;

            // 转换为数值进行比较，确保不是 NaN
            let reportRate = parseFloat(report[type]);
            return !isNaN(reportRate) && reportRate > averageRate;
        },
        getScaleImgLink(osslink, size = 430) {
            return osslink + `?x-oss-process=image/resize,w_${size},m_lfit`;
        },

        getSearchIcon() {
            return require('@/assets/serch-icon.png');
        },
        viewMoreRecent() {
            console.log('viewMoreRecent!');
            this.$router.push({ path: '/list', query: { market: 'ALL', orderBy: 'last_modified', tag: '' } });
        },
        gotoCompanyList(market = 'ALL', tag = '') {
            this.$router.push({ path: '/list', query: { market, tag } });
        },
        openReportViewer(report) {
            console.log('openReportViewer:', report);
            this.gotoReportViewer({ symbol: report.symbol, name: report.name, industry: report.industry, industry_list: this.industryGroups[report.industry] });
        },
        gotoReportViewer(report) {
            // console.log('gotoReportViewer:', report);
            this.$router.push({
                name: 'ReportViewer',
                params: {
                    name: report.name,
                    symbol: report.symbol
                }
            });
        },

        formateDesc(report) {
            var type = report.reportType;
            var desc = report.title;
            switch (type) {
                case 'Q':
                    desc = 'Quarterly Report  ' + report.DateEnding;
                    break;
                case 'I':
                    desc = 'Interim Report  ' + report.DateEnding;
                    break;
                case 'Y':
                    desc = 'Annual Report  ' + report.DateEnding;
                    break;
                default:
                    break;
            }
            return desc;
        },

        formateIndustry(industry) {
            var label = Tools.parseIndustry(industry);
            return label ? label : industry;
        },

        formatDate(dateStr) {
            // 解析年、月、日
            const year = dateStr.substring(0, 4);
            const month = dateStr.substring(4, 6);
            const day = dateStr.substring(6, 8);

            // 创建一个新的Date对象
            const date = new Date(year, month - 1, day); // 月份是从0开始的，所以减1

            // 使用toLocaleDateString来格式化日期
            return date.toLocaleDateString('en-US', {
                month: 'short', // 使用缩写月份
                day: 'numeric', // 显示日
            }).toUpperCase(); // 将结果转换为大写
        },
        formatDateTime(datetime) {
            if (!datetime) return '';
            const date = new Date(datetime);

            // 解析年、月、日
            const year = date.getFullYear(); // 使用 getFullYear() 而不是 getYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        freshPage() {

        },
        openMail(type) {
            let subject = '';
            let body = '';
            if (type === 'feedback') {
                subject = encodeURIComponent('Feedback');
                body = encodeURIComponent('Please leave your feedback here...');
            } else if (type === 'not-found') {
                subject = encodeURIComponent(`Can't Find Company`);
                body = encodeURIComponent(`I can't find the company I am looking for.It is...`);
            }

            // Constructing the mailto link with the provided email address
            const mailtoLink = `mailto:cumtnp@gmail.com?subject=${subject}&body=${body}`;
            window.open(mailtoLink, '_blank'); // Opens the email client

            this.dialogConnectVisible = false; // Closes the dialog
        },
    },
    beforeRouteEnter(to, from, next) {
        // console.log('beforeRouteEnter:', to, from);
        next(vm => {
            if (from.path === '/create') {
                vm.freshPage();
            }
        });
    },
    mounted() {
        this.freshPage();
    },
    computed: {
        getIndustryList() {
            // 创建一个新对象来存储调整后的行业列表
            let adjustedIndustryList = {};

            // 遍历每个行业，并根据 viewAllIndustry 的状态决定返回哪些数据
            for (let industry in this.industryGroups) {
                // console.log('getIndustryList:', industry, this.viewAllIndustry[industry]);
                if (this.viewAllIndustry[industry]) {
                    adjustedIndustryList[industry] = this.industryGroups[industry];
                } else {
                    adjustedIndustryList[industry] = this.industryTopAndBottom[industry];
                }
            }

            return adjustedIndustryList;
        }
    }

}
</script>

<style scoped>
.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
}

a {
    text-decoration: none;
    /* 移除下划线 */
}

.main .card-tab {
    margin-top: 24px;
    margin-right: 48px;
    padding: 4px 12px;
    width: 200px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    background: transparent;
    /* box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); */
}

.main .card-tab:hover {
    scale: 1.1;
}

.main .card-tab div {
    cursor: pointer;
}

::v-deep .card-tab .el-card__body {
    padding: 4px;
}

.main .card-tab {
    text-decoration: none;
    /* 这会移除下划线 */
}

.main .card-tab .title {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}


.main .card-tab .title img {
    width: 32px;
    height: 32px;
    margin-right: 4px;
}


.main .card-tab .desc {
    font-size: 16px;
    color: #666;
    font-weight: 300;
    padding-top: 12px;
}

/* You can add styles specific to the ListContainer component here */
.search-container {
    width: 100%;
    height: 480px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom right, rgba(22, 153, 228, 0.15), rgba(167, 0, 248, 0.15));
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1280px;
}

@media (max-width: 1280px) {
    .block.list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
}

.block.list {
    margin-top: 48px;
    padding-bottom: 48px;
    color: #333;
}

.border-bottom {
    border-bottom: 1px solid #EEE;
}

.list-container {
    width: 1280px;
    padding-bottom: 24px;
    border-bottom: 1px solid #DDD;
    padding-top: 24px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    /* 内容先填充行 */
    gap: 16px;
    /* 根据需要调整项目之间的间隙 */
}

.report-grid-item {
    width: 320px;
    /* 固定宽度，根据您的实际需求调整 */
    margin: 0px;
    /* 禁止伸缩 */
    display: inline-block;
}

.more-button {
    width: 400px;
    /* 设置固定宽度 */
    height: 300px;
    /* 设置固定高度，根据内容实际高度调整 */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;
    /* 底部间隔 */
}


.report-grid-item:hover {
    /* 向上位移 */
    transform: translateY(-12px);
}


.report-grid-item .img-grid {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
}

.industry_label {

    padding: 0px 12px;
    display: flex;
    /* 启用 flex 布局 */
    justify-content: space-between;
    /* 文字在左边，按钮在右边 */
    align-items: center;
    /* 垂直居中 */
    width: 100%;
    /* 确保 div 占满整个容器宽度 */
}

.industry.title {
    font-size: 24px;
    color: #666;
    font-weight: 400;
}

.industry.rate {
    margin-left: 12px;
    font-size: 16px;
    color: #666;
}

.industry.rate b {
    font-weight: 500;
    color: #269322;
}

.report-rate {
    font-size: 12px;
    color: #999;
}

.above-average b {
    font-size: 14px;
    color: #269322;
}

.below-average b {
    font-size: 14px;
    color: #BE0005;
}

.fas {
    /* 调整图标样式 */
    margin-left: 5px;
}

.btn_viewAll {
    text-decoration: none;
    /* 这会移除下划线 */
    font-size: 16px;
    color: #88949F;
    cursor: pointer;
    font-weight: 500;
    border-bottom: 1px solid #EEE;
    padding-bottom: 8px;
}

.btn_viewAll:hover {
    color: #333;
}

.customer-label {
    /* padding: 8px; 添加内边距 */
    text-align: center;
    padding: 1em 4px;
}

.customer-label .companyName {
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.customer-label .reportDesc {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出容器的内容 */
    text-overflow: ellipsis;
    /* 超出内容显示为省略号 */
}

.icon-image {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}

/* 动态调整搜索组件和按钮间隔的样式 */
.is-expanded {
    margin-right: 0;
    /* 当搜索框展开时，移除右边距 */
}

.main-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    /* Ensure elements are aligned to the start */
    gap: 48px;
    /* Adjust the gap as needed */
}

.report-button {
    width: 240px;
    font-size: 16px;
    height: 56px;
    border-radius: 28px;
    margin-left: auto;
    /* Push the button to the end of the flex container */
}
</style>