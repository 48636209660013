<template>
    <div>
        <el-row :gutter="48" v-loading="loading">

            <el-col :span="7" class="form-container" style="z-index:2">

                <!-- 页面1的内容 -->
                <div v-if="currentPage === 1">
                    <div class="form-step-title">Step1 Company Profile & Report Type</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <!-- <div><i class="el-icon-s-operation" style="margin-right: 12px;"></i>1、Update Company Overview</div> -->
                    <el-card>
                        <el-form :model="config" ref="config" label-width="120px" labelPosition="'right" :rules="rules">
                            <el-form-item label="Company Symbol" prop="companyCode" required>
                                <el-input v-model="config.companyCode" placeholder="Company Symbol" readonly
                                    suffix-icon="el-icon-search"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Name" prop="companyName" required>
                                <el-input v-model="config.companyName" placeholder="Company Name"
                                    :disabled="!config.companyCode"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Industry">
                                <el-select v-model="config.industry" placeholder="Select Industry">
                                    <el-option v-for="item in industryOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Ending Date" prop="endDate" required>
                                <el-date-picker v-model="config.endDate" type="date"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="Ended Months" prop="months" required>
                                <el-select v-model="config.months" placeholder="Select Months Ended">
                                    <el-option v-for="item in monthsEndedConfig" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Visual Main Color" class="group">
                                <el-popover ref="colorPopover" placement="bottom" width="160" trigger="click"
                                    v-model="popoverVisible">
                                    <div style="text-align: center;">
                                        <el-button v-for="color in predefinedColors" :key="color"
                                            :style="{ backgroundColor: parseNodeColor(color) }" circle
                                            @click="selectColor(color)" style="margin: 5px;"></el-button>
                                    </div>
                                    <el-button slot="reference"
                                        :style="{ backgroundColor: parseNodeColor(this.config.color) }" circle
                                        icon="el-icon-arrow-down" class="dot"></el-button>
                                </el-popover>
                            </el-form-item>
                            <el-form-item label="Title" prop="title" required>
                                <el-input :value="config.title" @input="userTypedTitle"></el-input>
                            </el-form-item>
                            <el-form-item label="Subtitle">
                                <el-input v-model="config.subtitle" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>

                <div v-if="currentPage === 2">
                    <div class="form-step-title">Step2 Revenue Sources & Costs</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <el-card>
                        <el-form>

                            <el-form-item label="Currency and Unit">
                                <el-cascader v-model="cascaderOptions" :options="cascaderData"
                                    :props="{ expandTrigger: 'hover' }">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.revenue" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <!-- 额外的收入表单项 -->
                            <el-form-item class="group" v-for="(item, index) in form.extraIncomeItems"
                                :key="`extra-income-item-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeIncomeItem(index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addIncomeItem">+ Add Income Detail</el-button>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.grossProfit"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.cost" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
                <div v-if="currentPage === 3">
                    <div>
                        <div class="form-step-title">Step3 Expense & NetProfit</div>
                        <div style="display: flex;justify-content: flex-end;">
                            <el-button icon="el-icon-chat-line-round" round class="source-button"
                                @click="toggleSourcePannel">View Source Data</el-button>
                        </div>
                    </div>
                    <el-card>

                        <el-form>

                            <el-form-item class="group">
                                <editable-label :node="form.operatingExpense"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <!-- 额外的费用表单项 -->
                            <el-form-item class="group" v-for="(item, index) in form.extranExpense"
                                :key="`extra-expense-item-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeExpenseItem(index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addExcenseItem">+ Add Expense Detail</el-button>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.operatingProfit"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item v-if="form.otherIncomeToOperationProfit !== null && form.operatingProfit">
                                <el-button type="text" icon="el-icon-delete" @click="removeOtherOperationProfit"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="form.otherIncomeToOperationProfit"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item v-if="form.otherIncomeToOperationProfit === null">
                                <el-button type="text" @click="addOtherToOperationProfit">+ Add Other Non-operating
                                    Income</el-button>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.netProfit" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item v-if="form.otherIncomeToNet !== null && form.operatingProfit">
                                <el-button type="text" icon="el-icon-delete" @click="removeOtherNet"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="form.otherIncomeToNet"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item v-if="form.otherIncomeToNet === null">
                                <el-button type="text" @click="addOthersToNet">+Add Other Net Income</el-button>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.tax" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.interestNet"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item></el-form>
                    </el-card>
                </div>
                <!-- 分页器 -->
                <el-pagination layout="prev, pager, next" :total="3" :current-page.sync="currentPage"
                    @current-change="handlePageChange" :page-size="1" background
                    style="display: flex;justify-content: right;margin-top: 24px;">
                </el-pagination>


            </el-col>
            <el-col :span="17" style="z-index:0">
                <div style="display: flex;height:100%;flex-direction: column;align-items: center;">
                    <div
                        style="width: 100%;margin:24px;padding:24px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <div v-show="!edited" style="font-size:24px;color:#333">In Just <b
                                style="color:#FC7A6E;font-size:28px">3</b> Steps
                            To
                            Unlock Their Money-Making Secrets </div>
                        <div v-show="!edited" style="font-size:14px;color:#666;margin-top:12px">We pursue the accuracy
                            of
                            data, so please
                            prepare financial statement data that has been checked.</div>
                        <div class="steps-container">
                            <div class="view">
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(1)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(1) }">1</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(1) }">Company Profile
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(2)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(2) }">2</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(2) }">Revenue & Cost
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(3)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(3) }">3</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(3) }">Expense & Profit
                                    </div>
                                </div>
                                <el-divider></el-divider>

                            </div>
                            <div style="display:flex;justify-content: flex-end;margin-left:24px">
                                <el-popconfirm confirm-button-text="New" cancel-button-text="Cancel" icon="el-icon-info"
                                    icon-color="red" title="Create new report?" @confirm="showSearchSymbolDialog">
                                    <el-button slot="reference" icon="el-icon-search" round
                                        style="margin-right: 12px; height:40px">New</el-button>
                                </el-popconfirm>

                                <!-- <el-popconfirm confirm-button-text="Save as  Publish" cancel-button-text="Save as Private"
                                    icon="el-icon-upload" icon-color="green" title="How would you like to save this report?"
                                    @confirm="saveReportData(true)" @cancel="saveReportData(false)"
                                    cancel-button-type="primary" confirm-button-type="success">
                                    <el-button round slot="reference" type="success" style="margin-right: 12px;"
                                        :disabled="!isStepFinished(3)" icon="el-icon-upload">
                                        Save
                                    </el-button>
                                </el-popconfirm> -->
                                <el-button round slot="reference" @click="saveReportData" type="success"
                                    style="margin-right: 12px;height:40px" :disabled="!isStepFinished(3)"
                                    icon="el-icon-upload">
                                    Save
                                </el-button>
                                <el-button round style="height:40px" @click="triggerDownload" type="primary"
                                    :disabled="!isStepFinished(3)" icon="el-icon-download">Download
                                </el-button>
                            </div>
                        </div>

                    </div>
                    <el-card class="sankey-container">

                        <div class="image-container" style="flex:1">
                            <CustomerSankey ref="customerSankeyRef" :sankey-data="sankeyData" />
                        </div>
                    </el-card>
                </div>

            </el-col>

        </el-row>
        <el-drawer title="Financial Report Assistant" :visible.sync="showSourcePanal" direction="rtl"
            :before-close="handleClosePannel" :wrapperClosable="false" :modal="false" :append-to-body="true" size="35%">
            <source-panel :source="source" />
        </el-drawer>
    </div>
</template>

<script>
import BaseForm from './BaseForm.vue';
import CustomerSankey from './IncomeSankey.vue';
import Tools from '../../GlobalTools';
import EditableLabel from './EditableLabel.vue';
import SourcePanel from './SourceDataForm.vue';
import axios from 'axios';
export default {
    name: 'IncomeCreaterSec',
    extends: BaseForm,
    components: {
        SourcePanel,
        EditableLabel,
        CustomerSankey // Correct the component name to match the imported name
    },
    data() {
        return {
            config: {
                title: '',
                subtitle: '',
                companyName: '',
                companyCode: '',
                endDate: '',
                months: '3months',
                currency: '',
                unit: '',
                color: '',
                industry: '',
                // logo: ''
            },
            form: {
                revenue: {
                    id: "revenue", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "gray", // 初始颜色
                    desc: "Revenue", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                grossProfit: {
                    id: "grossProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Gross Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 1
                },
                cost: {
                    id: "cost", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Cost", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                operatingProfit: {
                    id: "operatingProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Operating Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 1
                },
                operatingExpense: {
                    id: "operatingExpense", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Operating Expense", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                netProfit: {
                    id: "netProfit", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "green", // 初始颜色
                    desc: "Net Profit", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 2
                },
                tax: {
                    id: "tax", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Tax", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                interestNet: {
                    id: "interestNet", // 根据需要填写
                    vi: "",  //输入框里的输入的值
                    v: "", // 输入框的值转化为数字
                    weight: 0, // v的绝对值
                    color: "red", // 初始颜色
                    desc: "Interest Net", // 初始描述
                    yy: "", // 根据需要填写
                    sort: 0
                },
                extraIncomeItems: [], // 用于存储额外的收入表单项
                extranExpense: [],
                // netProfit: '',
                // tax: '',
                otherIncomeToOperationProfit: null,
                otherIncomeToNet: null
            },
            units: [], // 初始时单位数组为空
            imageSrc: '', // 这里放置您的图片路径
            sankeyData: {
                nodes: [],
                links: [],
                title: '',
                unit: '',
                unit_t: '',
                currency: '',
                date_ending: '',
                companyCode: '',
                coompanySymbol: ''
            },
            nodes: [],
            links: [],
            rules: {
                companyCode: [
                    { required: true, message: 'Please enter the company symbol', trigger: 'blur' }
                ],
                companyName: [
                    { required: true, message: 'Please enter the company name', trigger: 'blur' }
                ],
                months: [
                    { required: true, message: 'Please select months ended', trigger: 'blur' }
                ],
                endDate: [
                    { required: true, message: 'Please select an end date', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Please enter a title', trigger: 'blur' }
                ],
                cascaderOptions: [
                    { required: true, message: 'Please select a currency', trigger: 'change' }
                ]
                // ... other validation rules
            }
        };

    },
    // 方法和逻辑可以根据需求添加
    methods: {
        handleNodeChange(newNode, nodeId) {
            // 根据 nodeId 找到相应的 node 并更新颜色
            // console.log('handleNodeChange:', newNode, nodeId);
            if (nodeId.startsWith('extraIncome-')) {
                let index = nodeId.split('-')[1];
                this.$set(this.form.extraIncomeItems, index, newNode);
                this.setColorAndSort(nodeId, newNode);
            } else if (nodeId.startsWith('extraExpense-')) {
                let index = nodeId.split('-')[1];
                this.$set(this.form.extranExpense, index, newNode);
                this.setColorAndSort(nodeId, newNode);
            } else {
                this.setColorAndSort(nodeId, newNode);
                if (this.form[nodeId]) {
                    this.form[nodeId] = newNode;
                }
            }
        },
        setColorAndSort(nodeId, newNode) {
            const configMap = {
                'operatingProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 3 : 1 },
                'otherIncomeToOperationProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? -1 : 4 },
                'otherIncomeToNet': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 1 : 2 },
                'netProfit': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v < 0 ? 2 : 1 },
                'interestNet': { color: newNode.v < 0 ? 'red' : 'green', sort: newNode.v > 0 ? 3 : 0 },
                'tax': { color: newNode.v > 0 ? 'red' : 'green', sort: newNode.v > 0 ? 0 : 2 }
            };
            if (nodeId.startsWith('extraIncome-')) {
                newNode.color = this.form.revenue.color;
                this.autoCalculateRevenue();
            } else if (nodeId.startsWith('extraExpense-')) {
                newNode.color = 'red';
                this.autoCalculateOperatingExpense();
            } else if (configMap[nodeId]) {
                newNode.color = configMap[nodeId].color;
                newNode.sort = configMap[nodeId].sort;
            }
        },
        //自动求和收入
        autoCalculateRevenue() {
            const sum = this.form.extraIncomeItems.reduce((total, income) => {
                return total + income.v;
            }, 0);
            this.form.revenue.vi = sum + '';
            this.form.revenue.v = sum;
            this.form.revenue.weight = Math.abs(sum);
        },
        // 当额外费用更新时调用这个方法来计算总和
        autoCalculateOperatingExpense() {
            // 计算 extranExpense 中所有项目的 v 属性的和
            const sum = this.form.extranExpense.reduce((total, expense) => {
                return total + expense.v;
            }, 0);

            // 更新 operatingExpense 的 vi 属性
            this.form.operatingExpense.vi = sum + ''; // 转换为字符串
            this.form.operatingExpense.v = sum;
            this.form.operatingExpense.weight = Math.abs(sum);
        },
        newNode(id, desc = '', v = 0, color = 'green', yy = '', sort = 0, vi = '') {
            return {
                id,
                desc,
                v,
                weight: Math.abs(v),
                color,
                yy,
                sort,
                vi
            }
        },
        addIncomeItem() {
            // 添加一个新的对象到数组，每个对象代表一个新的表单项
            this.form.extraIncomeItems.push(this.newNode(`extraIncome-${this.form.extraIncomeItems.length}`));
        },
        removeIncomeItem(index) {
            // Removes the income item at the given index
            this.form.extraIncomeItems.splice(index, 1);
        },
        addExcenseItem() {
            // 添加一个新的对象到数组，每个对象代表一个新的表单项
            this.form.extranExpense.push(this.newNode(`extraExpense-${this.form.extranExpense.length}`));
        },
        removeExpenseItem(index) {
            this.form.extranExpense.splice(index, 1);
        },
        addCost() {
            // 添加成本输入逻辑
        },
        addOtherExpense() {
            this.form.otherExpenses.push({ value: '' });
        },
        addOtherToOperationProfit() {
            if (!this.form.otherIncomeToOperationProfit) {
                // 如果不存在，则添加新项
                this.form.otherIncomeToOperationProfit = this.newNode('otherIncomeToOperationProfit', 'Other Non-Operating Income', '', 'green', '', 4);

            } else {
                // 如果已存在，可以在这里处理错误或显示提示
            }
            // console.log('click addOtherToOperationProfit:', this.form.otherIncomeToOperationProfit);
        },
        addOthersToNet() {
            if (!this.form.otherIncomeToNet) {
                // 如果不存在，则添加新项
                this.form.otherIncomeToNet = this.newNode('otherIncomeToNet', 'Other Income', '', 'green', '', 3);
            } else {
                // 如果已存在，可以在这里处理错误或显示提示
            }
            // console.log('click addOtherToNet:', this.form.otherIncomeToNet);
        },
        removeOtherOperationProfit() {
            this.form.otherIncomeToOperationProfit = null; // 删除项
        },
        removeOtherNet() {
            this.form.otherIncomeToNet = null; // 删除项
        },
        addOthersFromProfit() {

        },
        generateNodes() {
            const createNode = function (item) {
                return {
                    id: item.id,
                    v: item.v,
                    weight: Math.abs(item.v),
                    fixedValue: Math.abs(item.v),
                    color: Tools.parseNodeColor(item.color),
                    name: item.id,
                    desc: item.desc,
                    sort: item.sort,
                    yy: item.yy,
                    vi: item.vi,
                    margin: null // Initialize margin
                }

            };

            const nodes = [];

            Object.keys(this.form).forEach(key => {
                const item = this.form[key];
                // 检查是否是数字或可转换字符串并且大于0
                if (item && !isNaN(parseFloat(item.v)) && parseFloat(item.v) !== 0) {
                    nodes.push(createNode(item));
                }
                // 如果是数组，遍历数组
                else if (item && Array.isArray(item)) {
                    item.forEach(subItem => {
                        if (!isNaN(parseFloat(subItem.v)) && parseFloat(subItem.v) !== 0) {
                            nodes.push(createNode(subItem));
                        }
                    });
                }
            });

            // 计算收入边距
            const revenue = nodes.find(node => node.id === 'revenue')?.weight || 0;
            if (revenue !== 0) {
                nodes.forEach(node => {
                    if (node.id !== 'revenue' && node.weight > 0) {
                        let marginPercentage = (node.weight / revenue) * 100;
                        node.margin = marginPercentage < 1 ? marginPercentage.toFixed(1) + '%' : Math.round(marginPercentage) + '%';
                    }
                });
            }
            // console.log('generateNodes:', nodes);
            return nodes;
        },
        generateLinks() {
            const createLink = (source, target, value, color, sort = 0) => {
                // console.log('createLink:', source, target, value, color);
                return {
                    source,
                    target,
                    value: Math.abs(value),
                    color: Tools.parseLinkColor(color),
                    sort
                };
            };

            var links = [];

            // 处理额外的收入
            this.form.extraIncomeItems.forEach(item => {
                if (item.id && Tools.convertToNumber(item.v) > 0) {
                    links.push(createLink(item.id, this.form.revenue.id, Math.abs(item.v), this.config.color, item.sort));
                }
            });
            let operatingParent = 'grossProfit';
            if (Tools.convertToNumber(this.form.grossProfit.v) === 0 && Tools.convertToNumber(this.form.cost.v) === 0) {
                operatingParent = 'revenue';
            } else {
                // 初始化links数组
                links.push(
                    createLink('revenue', 'grossProfit', this.form.grossProfit.v, 'green'),
                    createLink('revenue', 'cost', this.form.cost.v, 'red')
                );
            }


            // 经营利润为正时的逻辑
            var operatingProfitV = this.getFormKeyV('operatingProfit');
            if (operatingProfitV >= 0) {
                var weight_gp_op = this.getFormKeyAbsV('operatingProfit');
                let weight_gp_oe = this.getFormKeyAbsV('operatingExpense');
                if (weight_gp_oe > this.getFormKeyAbsV('grossProfit')) {
                    weight_gp_oe = this.getFormKeyAbsV('grossProfit');
                    if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                        weight_gp_op = 0;
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingExpense', this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('grossProfit')), 'red', this.form.otherIncomeToOperationProfit.sort));
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingProfit', this.getFormKeyAbsV('operatingProfit'), 'green', this.form.otherIncomeToOperationProfit.sort));
                    }
                } else {
                    if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                        links.push(createLink('otherIncomeToOperationProfit', 'operatingProfit', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'green', this.form.otherIncomeToOperationProfit.sort));
                        weight_gp_op = this.weight_function(this.getFormKeyAbsV('operatingProfit'), '-', this.getFormKeyAbsV('otherIncomeToOperationProfit'));
                    }
                }
                if (this.getFormKeyV('netProfit') >= 0) {
                    var weight_op_net = this.getFormKeyAbsV('netProfit');
                    // console.log('otherIncomeToNet:', this.getFormKeyV('otherIncomeToNet'));
                    if (this.form.otherIncomeToNet) {
                        if (this.form.otherIncomeToNet.v >= 0) {
                            weight_op_net = this.weight_function(this.getFormKeyAbsV('netProfit'), '-', this.getFormKeyAbsV('otherIncomeToNet'));
                            links.push(createLink('otherIncomeToNet', 'netProfit', this.getFormKeyAbsV('otherIncomeToNet'), 'green', this.form.otherIncomeToNet.sort));
                        } else {
                            links.push(createLink('operatingProfit', 'otherIncomeToNet', this.getFormKeyAbsV('otherIncomeToNet'), 'red', this.form.otherIncomeToNet.sort));
                        }
                    }
                    if (this.form.interestNet.v !== 0) {
                        if (this.form.interestNet.v > 0) {
                            links.push(createLink('interestNet', 'netProfit', this.getFormKeyAbsV('interestNet'), 'green', 3));
                            weight_op_net -= this.getFormKeyAbsV('interestNet');
                        } else {
                            links.push(createLink('operatingProfit', 'interestNet', this.getFormKeyAbsV('interestNet'), 'red'));
                        }
                    }
                    if (weight_gp_op > 0) {
                        links.push(createLink(operatingParent, 'operatingProfit', weight_gp_op, 'green', this.form.operatingProfit.sort));
                    } else {
                        links.push(createLink(operatingParent, 'operatingProfit', 0, 'green', this.form.operatingProfit.sort));
                    }
                    if (this.form.tax.v !== 0) {
                        if (this.form.tax.v > 0) {
                            links.push(createLink('operatingProfit', 'tax', this.getFormKeyAbsV('tax'), 'red', this.form.tax.sort));
                        } else {
                            links.push(createLink('tax', 'netProfit', this.getFormKeyAbsV('tax'), 'green', 3));
                            weight_op_net -= this.getFormKeyAbsV('tax');
                        }
                    }
                    if (weight_op_net > 0) {
                        links.push(createLink('operatingProfit', 'netProfit', weight_op_net, 'green', Math.max(this.form.netProfit.sort, 2)));
                    }
                    links.push(createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red', this.form.operatingExpense.sort));
                } else {
                    if (this.form.otherIncomeToNet && this.form.otherIncomeToNet.v < 0 && this.form.otherIncomeToNet.weight >= this.form.netProfit.weight) {
                        links.push(createLink('netProfit', 'otherIncomeToNet', this.getFormKeyAbsV('netProfit'), 'red', this.form.otherIncomeToNet.sort));
                        links.push(createLink('operatingProfit', 'otherIncomeToNet', (this.getFormKeyAbsV('otherIncomeToNet') - this.getFormKeyAbsV('netProfit')), 'red', this.form.otherIncomeToNet.sort));
                    }
                    links.push(
                        createLink(operatingParent, 'operatingProfit', weight_gp_op, 'green', this.form.operatingProfit.sort),
                        createLink('operatingProfit', 'tax', this.getFormKeyAbsV('tax'), 'red', this.form.tax.sort),
                        createLink('operatingProfit', 'interestNet', this.getFormKeyAbsV('interestNet'), this.form.interestNet.v > 0 ? 'green' : 'red', this.form.interestNet),
                        createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red', this.form.operatingExpense.sort)
                    );
                }

            } else {
                // 经营利润为负时的逻辑
                // console.log('weight test:', this.getFormKeyAbsV('operatingExpense'), this.getFormKeyAbsV('operatingProfit'), this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('operatingProfit')))
                let weight_gp_oe = this.weight_function(this.getFormKeyAbsV('operatingExpense'), '-', this.getFormKeyAbsV('operatingProfit'));

                if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v > 0) {
                    links.push(createLink('otherIncomeToOperationProfit', 'operatingExpense', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'red', this.form.otherIncomeToOperationProfit.sort));
                    weight_gp_oe -= this.getFormKeyAbsV('otherIncomeToOperationProfit');
                }
                links.push(
                    createLink('operatingProfit', 'operatingExpense', this.getFormKeyAbsV('operatingProfit'), 'red'),
                    createLink(operatingParent, 'operatingExpense', weight_gp_oe, 'red')
                );
            }
            if (this.getFormKeyAbsV('otherIncomeToOperationProfit') !== 0 && this.form.otherIncomeToOperationProfit.v < 0) {
                links.push(createLink(operatingParent, 'otherIncomeToOperationProfit', this.getFormKeyAbsV('otherIncomeToOperationProfit'), 'red', -1));
            }
            // 处理额外的经营费用
            if (this.form.operatingExpense && this.form.operatingExpense.weight !== 0) {
                // console.log('add 其他费用：this.form.operatingExpense:', this.form.operatingExpense);
                this.form.extranExpense.forEach(item => {
                    if (item.v !== 0) {
                        links.push(createLink('operatingExpense', item.id, Math.abs(item.v), 'red'));
                    }
                });
            }

            // console.log('generateLinks:', links);
            // 过滤出有效的链接
            const currentNodes = this.nodes.map(node => node.id);
            return links.filter(link => currentNodes.includes(link.source) && currentNodes.includes(link.target));
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.nodes = this.generateNodes();
            } catch (e) {
                console.log('generateNodes exception:', e);
            }
            try {
                this.links = this.generateLinks();
            } catch (e) {
                console.log('generateLinks exception:', e);

            }
            // console.log('generateSankeyData:', this.nodes, this.links);
            return {
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                nodes: this.nodes,
                links: this.links,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName,
                months: this.config.months
            };
        },
        getFormateDate() {
            let endDate = new Date(this.config.endDate);

            // 使用 toLocaleDateString 方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号
            formattedDate = formattedDate.replace(',', '').replace(' ', ' ');
            return formattedDate;
        },
        generateTitle() {
            // 如果用户没有编辑过标题，则生成新标题
            // console.log('generateTitle:', this.userHasEditedTitle, this.config.title);
            if (!this.userHasEditedTitle) {
                this.config.title = `${this.config.companyName} Income Statement ${this.getFormateDate()}`;
            }
        },

        generateSubTitle() {
            if (!this.userHasEditedTitle) {
                let subtitle = `${this.config.months} Ended, Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
                // 设置标题
                this.config.subtitle = subtitle;
            }
            this.validateForm();

        },


        loadData(config, form) {
            console.log('loadData:', config, form);
            this.userHasEditedTitle = true;
            this.config.companyName = config.companyName;
            this.config.color = config.color;
            this.config.currency = config.currency;
            this.config.endDate = config.endDate;
            this.config.subtitle = config.subtitle;
            this.config.unit = config.unit;
            this.config.months = config.months ? config.months : (this.report.months ? this.report.months : '3months');
            this.config.industry = config.industry;

            this.cascaderOptions = [this.config.currency, this.config.unit];

            this.form.revenue = form.revenue || this.form.revenue;
            this.form.grossProfit = form.grossProfit || this.form.grossProfit;
            this.form.cost = form.cost || this.form.cost;
            this.form.operatingProfit = form.operatingProfit || this.form.operatingProfit;
            this.form.operatingExpense = form.operatingExpense || this.form.operatingExpense;
            this.form.netProfit = form.netProfit || this.form.netProfit;
            this.form.tax = form.tax || this.form.tax;
            this.form.interestNet = form.interestNet || this.form.interestNet;
            this.form.extraIncomeItems = form.extraIncomeItems || this.form.extraIncomeItems;
            this.form.extranExpense = form.extranExpense || this.form.extranExpense;
            this.form.otherIncomeToOperationProfit = form.otherIncomeToOperationProfit || this.form.otherIncomeToOperationProfit;
            this.form.otherIncomeToNet = form.otherIncomeToNet || this.form.otherIncomeToNet;

            // 如果加载的数据中没有 title 或 title 为空，则生成新标题
            if (Tools.isEmpty(config.title)) {
                this.userHasEditedTitle = false;
                this.generateTitle();
            } else {
                // 如果加载的数据中有 title，则使用它并设置标志为 true
                // console.log('loadData update title:', config.title);
                this.$nextTick(() => {
                    this.config.title = config.title;
                    this.userHasEditedTitle = false;
                });

            }
        },

        formateDate(date_ending) {
            // Regular expression to check if the date is in 'YYYY-MM-DD' format
            const validDateFormat = /^\d{4}-\d{2}-\d{2}$/;

            // Check if the date is already in a valid format
            if (validDateFormat.test(date_ending)) {
                return date_ending;
            } else {
                // Extract the year, month, and day from the date string
                const year = date_ending.substring(0, 4);
                const month = date_ending.substring(4, 6);
                const day = date_ending.substring(6, 8);

                // Construct a date string in the 'YYYY-MM-DD' format
                const formattedDateString = `${year}-${month}-${day}`;
                return formattedDateString;
            }
        },

        getGrossMarginRate() {
            if (this.form.grossProfit.v === 0 || this.form.revenue.v === 0) {
                return null;
            }
            return (this.form.grossProfit.v / this.form.revenue.v).toFixed(2);
        },
        getNetMaginRate() {
            if (this.form.netProfit.v === 0 || this.form.revenue.v === 0) {
                return null;
            }
            return (this.form.netProfit.v / this.form.revenue.v).toFixed(2);
        },

        async saveReportData() {
            // 启用 loading
            this.loading = true;
            let publicReport = this.companyType === 'LC';
            try {

                const imageUrl = await this.$refs.customerSankeyRef.uploadImgToOSS();

                const formattedEndDate = Tools.formatDateToYYYYMMDD(this.config.endDate);
                const dateType = this.config.months !== '12months' ? 'Q' : 'Y';

                const reportData = {
                    company: this.config.companyName,
                    symbol: this.config.companyCode,
                    // logo: this.config.logo,
                    industry: this.config.industry,
                    publicReport: publicReport,
                    reportType: 'INCOME_STATEMENT',
                    dateType: dateType, // 你可以根据需求调整
                    months: this.config.months,
                    dateEnding: formattedEndDate,
                    grossMarginRate: this.getGrossMarginRate(),
                    netMarginRate: this.getNetMaginRate(),
                    data: {
                        title: this.config.title,
                        config: { ...this.config },
                        form: this.extractFormData()
                    },
                    reviewLink: imageUrl
                };

                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                // 发送请求
                const response = await axios.post(`${apiEndpoint}/updateCompanyReport`, reportData);

                // 请求成功后取消 loading
                this.loading = false;

                if (response.data) {
                    // console.log('Report data saved successfully:', response.data);

                    // 显示弹窗，而不是简单的消息
                    this.$msgbox({
                        title: 'Operation Successful',
                        message: 'The report data has been saved. \n Please select your next action:',
                        showCancelButton: true,
                        confirmButtonText: 'Create New Report',
                        cancelButtonText: 'Return to Editing',
                        type: 'success'
                    }).then(() => {
                        // 如果用户选择新建其他报告，执行相应操作
                        // console.log('用户选择新建其他报告');
                        window.location.reload();
                    }).catch(() => {
                        // 如果用户选择继续编辑，执行相应操作
                        // console.log('用户选择继续编辑');
                    });
                }
            } catch (error) {
                // 请求失败后取消 loading
                this.loading = false;

                if (error.response) {
                    // 打印服务端返回的错误信息
                    console.error('Server error:', error.response.data);
                    // 显示错误消息
                    this.$message.error(`${error.response.data.error || 'Unknown error'}`);
                } else {
                    // 如果没有收到服务端的响应
                    console.error('Error saving report data:', error);
                    this.$message.error('Error occurred while saving the report data.');
                }
            }

        },
        getFormNode(item) {
            console.log('getFormNode:', item.id, item);
            if (item) {
                var emptyconfig = this.form[item.id];
                var result = {};
                result.id = item.id;
                result.desc = item.desc;
                result.v = item.v;
                result.vi = item.v + '';
                result.weight = Math.abs(item.v);
                result.yy = '';
                result.sort = emptyconfig ? emptyconfig.sort : 0;
                result.color = emptyconfig ? emptyconfig.color : 'gray';
                this.setColorAndSort(item.id, result);
                return result;
            } else {
                null;
            }

        },
        sumNumbers(arr) {
            return arr.reduce((sum, value) => {
                return sum + (Number(value) || 0);
            }, 0);
        },
        setNodeValue(node, value) {
            node.v = value;
            node.vi = value + '';
            node.weight = Math.abs(value);
        },
        rebuildForm(formData) {
            let { revenue, grossProfit, cost, operatingExpense, operatingProfit, extraIncomeItems, extranExpense } = formData;
            console.log('rebuildForm:\n', JSON.stringify(formData));
            // 计算额外收入和额外费用的总和
            // 确保extraIncomeItems和extranExpense数组存在
            extraIncomeItems = extraIncomeItems || [];
            extranExpense = extranExpense || [];
            //一些基本的数据处理
            // 计算额外收入和额外费用的总和
            let totalExtraIncome = this.sumNumbers(extraIncomeItems.map(item => item.v));
            let totalExtraExpense = this.sumNumbers(extranExpense.map(item => item.v));

            // 首先确定revenue的值
            if (revenue.v === null || revenue.v === '') {
                this.setNodeValue(revenue, totalExtraIncome);
            }

            // 确定grossProfit的值
            if (grossProfit.v === null || grossProfit.v === '') {
                if (revenue.v !== null && revenue.v !== '' && cost.v !== null && cost.v !== '') {
                    this.setNodeValue(grossProfit, revenue.v - cost.v);
                }
            }

            // 确定cost的值
            if (cost.v === null || cost.v === '') {
                if (revenue.v !== null && revenue.v !== '' && grossProfit.v !== null && grossProfit.v !== '') {
                    this.setNodeValue(cost, revenue.v - grossProfit.v);
                }
            }

            // 确定operatingExpense的值
            if (operatingExpense.v === null || operatingExpense.v === '') {
                this.setNodeValue(operatingExpense, totalExtraExpense);
            }

            // 确定operatingProfit的值
            if (operatingProfit.v === null || operatingProfit.v === '') {
                if (grossProfit.v !== null && grossProfit.v !== '' && operatingExpense.v !== null && operatingExpense.v !== '') {
                    this.setNodeValue(operatingProfit, grossProfit.v - operatingExpense.v);
                }
            }
            //一些高级的数据处理
            if (operatingExpense.v != totalExtraExpense) {
                this.setNodeValue(operatingExpense, totalExtraExpense);
            }

            return formData;
        },
        createNodes(data) {
            console.log('createNodes:', data);
            var nodes = {};

            // 遍历对象的所有属性
            for (var key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    var item = data[key];
                    if (item) {
                        // 如果属性是数组，则递归处理数组的每个元素
                        console.log('createNodes array id|array|obj:', item.id, Array.isArray(item), item);
                        if (item.id) {
                            // 如果属性不是数组，直接处理
                            nodes[key] = this.getFormNode(item);
                        }
                        else if (Array.isArray(item)) {
                            nodes[key] = item.map(childItem => {
                                var childNode = this.getFormNode(childItem);
                                // 注意：如果 childItem 的某个属性是数组，则递归调用
                                if (childItem && typeof childItem === 'object') {
                                    for (var childKey in childItem) {
                                        if (Object.prototype.hasOwnProperty.call(childItem, childKey) && Array.isArray(childItem[childKey])) {
                                            childNode[childKey] = this.createNodes(childItem[childKey]);
                                        }
                                    }
                                }
                                return childNode;
                            });
                        }
                    }

                }
            }
            var buildNodes = this.rebuildForm(nodes);
            console.log('createNodes result:', JSON.stringify(buildNodes));
            return buildNodes;
        },
        async getReportDetail() {
            console.log('getReportDetail:', this.report);
            this.config.companyCode = this.report.symbol;
            this.config.companyName = this.report.name;
            this.config.title = this.report.title;
            this.config.months = this.report.months || '3months';
            this.source = this.report;
            if (this.report.deadline) {
                this.loading = true;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const params = {
                    symbol: this.report.symbol,
                    deadline: this.report.deadline,
                    status: this.report.status,
                    type: this.report.type,
                    dateType: this.report.dateType,
                    months: this.report.months,
                };
                axios.post(`${apiEndpoint}/getReportDetail`, params).then(response => {
                    this.loading = false;
                    if (response.data && response.data.result) {
                        var reportDetail = response.data.result;
                        console.log('getReportDetail:', reportDetail);
                        if (reportDetail.source === 'papermoney') {
                            this.companyCode = this.report.symbol;
                            this.companyName = this.report.name;
                            this.loadData(reportDetail.data.config, reportDetail.data.form);
                        } else {
                            var nodes = this.createNodes(reportDetail.data);
                            var config = {
                                companyCode: this.report.symbol,
                                companyName: this.report.name,
                                endDate: reportDetail.dateEnding,
                                unit: reportDetail.unit,
                            };
                            config.currency = reportDetail.currency === '$' ? 'USD' :
                                reportDetail.currency === '¥' ? 'RMB' :
                                    reportDetail.currency === 'HK$' ? 'HKD' : 'Other';
                            this.loadData(config, nodes);
                        }

                    }
                }).catch(error => {
                    console.log('getReportDetail error:', error);
                    this.loading = false;
                });
            }
        },
        isStepFinished(step) {
            if (step === 1) {
                return this.isFormValid;
            }
            if (step === 2) {
                return this.isFormValid && this.form.revenue && this.form.revenue.v !== '';
            }
            if (step === 3) {
                return this.isFormValid && this.form.operatingExpense && this.form.operatingExpense.v !== '';
            }
            return false;
        }
    }
};
</script>