import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from './store';
import './axios-config';

import Home from './components/HomeView.vue'
import CompanyListForm from './components/CompanyListForm.vue'
import UserCreationPage from './components/UserCreationPage.vue'
import PrivacyPolicy from './components/PrivacyPolicy'
import ReporterViewer from './components/ReportDetailViewNew.vue'
import AboutPage from './components/AboutPage.vue'
import HelpPage10k from './components/help/Page-10k.vue'
import HelpPageIncomeStatement from './components/help/Page-income.vue'
import HelpPageBalanceSheet from './components/help/Page-balance.vue'
import HelpPageCashFlow from './components/help/Page-cashflow.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      title: 'Home - PaperMoney',
      description: 'Explore in-depth financial report analysis of top companies across various industries with PaperMoney.'
    }
  },
  {
    path: '/about',
    component: AboutPage,
    meta: {
      title: 'About - PaperMoney',
      description: `Learn about PaperMoney's mission, offerings, and team.PaperMoney provides in-depth financial report analysis for top companies across various industries.`
    }
  },
  {
    path: '/list',
    component: CompanyListForm,
    meta: {
      title: 'Company List - PaperMoney',
      description: 'Browse through our extensive list of companies and access their detailed financial reports.'
    }
  },
  {
    path: '/create',
    component: UserCreationPage,
    meta: {
      title: 'Create User - PaperMoney',
      description: 'Create a new user account to unlock personalized features and save your preferences.'
    }
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy - PaperMoney',
      description: 'Read our privacy policy to understand how we collect, use, and protect your data.'
    }
  },
  {
    path: '/reportViewer',
    component: ReporterViewer,
    name: 'ReportViewer',
    meta: {
      title: 'Report Viewer - PaperMoney',
      description: 'Dive into the details of a company\'s financial report with our interactive viewer.'
    }
  },
  {
    path: '/help/10k',
    component: HelpPage10k,
    meta: {
      title: '10-K Help - PaperMoney',
      description: 'Learn about the 10-K report and how to interpret its contents.'
    }
  },
  {
    path: '/help/income-statement',
    component: HelpPageIncomeStatement,
    meta: {
      title: 'Income Statement Help - PaperMoney',
      description: 'Learn about the income statement and how to interpret its contents.'
    }
  },
  {
    path: '/help/balance-sheet',
    component: HelpPageBalanceSheet,
    meta: {
      title: 'Balance Sheet Help - PaperMoney',
      description: 'Learn about the balance sheet and how to interpret its contents.'
    }
  },
  {
    path: '/help/cash-flow',
    component: HelpPageCashFlow,
    meta: {
      title: 'Cash Flow Help - PaperMoney',
      description: 'Learn about the cash flow statement and how to interpret its contents.'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global error handler:', error);
  return true;
};

Vue.config.productionTip = false;