<template>
    <div :key="treeKey" class="reportView-tree">
        <!-- 根节点的渲染 -->
        <TreeNode v-for="node in filteredTreeData" :key="`${symbol}-${node.label}-${isCompareMode}`" :node="node"
            :symbol="symbol" :months="months" :is-compare-mode="isCompareMode" :companyName="companyName"
            @button-click="handleButtonClick" :list-id="listId">
        </TreeNode>
    </div>
</template>
<script>
import TreeNode from './TreeNode.vue';
// import Tools from '../../GlobalTools.js';

export default {
    name: "ReporterList",
    components: {
        TreeNode
    },
    props: {
        // 其他props...
        isCompareMode: {
            type: Boolean,
            default: false
        },
        listId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            symbol: '',
            companyName: '',
            treeKey: 0,
            treeData: [],
            filteredTreeData: [],
            months: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            currentReportType: 'all', // 'ic', 'bs', 'cs' 或 'all'
            currentFilterType: 'all', // 'q', 'y' 或 'all'
        }
    },
    computed: {
        selectedLanguage() {
            return this.$store.getters.getSelectedLanguage;
        },
    },
    methods: {
        updateView() {
            this.treeKey += 1; // 更改treeKey的值以强制重新渲染
        },
        async setViewEmpty() {
            this.treeData = [];
            this.filteredTreeData = [];
            this.months = [];
        },
        async updateRepoterDetail(symbol, name, data) {
            this.symbol = symbol;
            this.companyName = name;
            this.transformDataToTree(data);
        },
        async filterReportByTypeBk(aqType) {
            this.currentFilterType = aqType; // 更新当前的筛选类型
            let filteredData = [...this.treeData];

            // 首先根据报告类型筛选
            if (this.currentReportType !== 'all') {
                filteredData = filteredData.map(monthNode => {
                    const filteredMonthNode = { ...monthNode, children: [] };
                    monthNode.children.forEach(reportNode => {
                        if (reportNode.typeShort === this.currentReportType) {
                            filteredMonthNode.children.push({ ...reportNode });
                        }
                    });
                    return filteredMonthNode.children.length > 0 ? filteredMonthNode : null;
                }).filter(node => node !== null);
            }

            // 然后根据年报或季报筛选
            if (this.currentFilterType !== 'all') {
                filteredData = filteredData.map(monthNode => {
                    const filteredMonthNode = { ...monthNode, children: [] };
                    monthNode.children.forEach(reportNode => {
                        // 假设 reportNode 包含一个属性 dataType 表示报告类型（'q' 或 'y'）
                        if (reportNode.dataType === this.currentFilterType) {
                            filteredMonthNode.children.push({ ...reportNode });
                        }
                    });
                    return filteredMonthNode.children.length > 0 ? filteredMonthNode : null;
                }).filter(node => node !== null);
            }

            // 更新筛选后的数据
            this.filteredTreeData = filteredData;
        },

        // 统一的筛选方法
        applyFilters() {
            let filteredData = this.treeData.map(monthNode => {
                // 复制月份节点，但不包括其子节点
                const filteredMonthNode = { ...monthNode, children: [] };

                // 筛选符合条件的报告节点
                monthNode.children.forEach(reportNode => {
                    if (this.matchesFilter(reportNode)) {
                        filteredMonthNode.children.push(reportNode);
                    }
                });

                // 只保留至少有一个子节点的月份节点
                return filteredMonthNode.children.length > 0 ? filteredMonthNode : null;
            }).filter(node => node !== null); // 移除空节点

            // 更新筛选后的数据
            this.filteredTreeData = filteredData;
        },

        // 辅助函数，检查报告节点是否符合当前的筛选条件
        matchesFilter(reportNode) {
            const matchesType = this.currentReportType === 'all' || reportNode.typeShort === this.currentReportType;
            // 确保dataType逻辑正确匹配 'q' 或 'y'
            const matchesFilterType = this.currentFilterType === 'all' || (reportNode.dataType && reportNode.dataType === this.currentFilterType);
            return matchesType && matchesFilterType;
        },


        // 设置报告类型筛选条件并应用筛选
        filterReport(type) {
            this.currentReportType = type;
            this.applyFilters();
        },

        // 设置报告周期筛选条件并应用筛选
        filterReportByType(aqType) {
            this.currentFilterType = aqType;
            this.applyFilters();
        },

        async transformDataToTree(reportData) {
            this.filteredTreeData = [];
            this.treeData = [];
            this.months = [];
            const { checkedData, uncheckedData } = reportData;
            const combinedData = {};
            // 正确处理 checkedData 和 uncheckedData
            const uniqueReports = new Set();
            ['ic', 'bs', 'cs'].forEach((type) => {
                ['q', 'y'].forEach((dataType) => {
                    // 处理 checkedData
                    const checkedReports = checkedData && checkedData[type] && checkedData[type][dataType] ? checkedData[type][dataType] : [];
                    const uncheckedReports = uncheckedData && uncheckedData[type] && uncheckedData[type][dataType] ? uncheckedData[type][dataType] : [];

                    (checkedReports).forEach((report) => {
                        const month = report.DateEnding.substring(0, 6); // YYYYMM
                        if (!combinedData[month]) combinedData[month] = [];
                        if (report.months) {
                            var uniqId = `${this.symbol}^${type}^${dataType}^${report.DateEnding}^${report.months}`;
                            if (!uniqueReports.has(uniqId)) { // 如果 uniqueReports 中没有该 uniqId
                                // console.log('checked uniqId:', uniqId);
                                report.status = 'checked';
                                report.uniqId = uniqId;
                                report.type = this.typeToLabel(type);
                                report.typeShort = type;
                                combinedData[month].push(report);
                                report.deadline = report.DateEnding;
                                report.dataType = dataType;
                                uniqueReports.add(uniqId); // 添加 uniqId 到 uniqueReports
                            } else {
                                console.log('checked uniqId jump:', uniqId);
                            }
                        }

                    });
                    // console.log('checked uniqueReports:', uniqueReports);
                    // 处理 uncheckedData
                    (uncheckedReports).forEach((report) => {
                        const month = report.deadline.substring(0, 6); // YYYYMM
                        if (!combinedData[month]) combinedData[month] = [];
                        if (report.months) {
                            var unitId = `${this.symbol}^${type}^${dataType}^${report.deadline}^${report.months}`;
                            // console.log('unchecked unitId:', unitId);
                            if (!uniqueReports.has(unitId)) { // 如果 uniqueReports 中没有该 unitId
                                console.log();
                                report.unitId = unitId;
                                report.status = 'unchecked';
                                report.type = this.typeToLabel(type);
                                report.typeShort = type;
                                combinedData[month].push(report);
                                report.dataType = dataType;
                            } else {
                                console.log('unchecked unitId jump:', unitId);

                            }
                        }
                    });
                });
            });
            // 构建树结构，直接以月份为顶层节点
            const treeData = [];
            Object.keys(combinedData).sort((a, b) => b.localeCompare(a)).forEach((month) => {
                const monthNode = { label: month, children: [], leaftype: 'month' };

                combinedData[month].forEach((report) => {
                    report.viewId = report.deadline + report.months + report.typeShort;
                    if (report.status === 'unchecked' && (!report.data?.aiformate || Object.keys(report.data.aiformate).length === 0)) {
                        // 如果 aiformate 为 null 或空对象，则跳过当前报告，不添加到树中
                        return;
                    }
                    const reportNode = {
                        ...report, // 展开report对象
                        label: this.generateReportTitle(report),
                        id: report._id || report.DateEnding, // 使用唯一标识符
                        leaftype: 'lp-report',
                        // 直接将 aiformate 对象放在顶层以便访问
                        aiformate: report.data?.aiformate
                    };
                    monthNode.children.push(reportNode);
                });
                if (monthNode.children.length > 0) {
                    treeData.push(monthNode);
                }
            });
            this.treeData = treeData;
            this.filteredTreeData = treeData;
            this.months = treeData.map((node) => ({
                id: node.label,
                label: node.label,
                formate: node.deadline
            }));
            // console.log('months:', this.months);
            // Tools.logObj('transformDataToTree:', treeData);

        },
        generateReportTitle(report) {
            if (this.selectedLanguage === 'English') {
                if (report.months && report.months !== 'undefined' && report.months !== 'months') {
                    return `${this.companyName} ${report.type} ${report.months} Ended ${report.deadline}`;
                } else {
                    return `${this.companyName} ${report.type} Ended ${report.deadline}`;
                }
            } else {
                if (report.months && report.months !== 'undefined' && report.months !== 'months') {
                    return `${this.companyName} ${report.type} ${report.months} 截止至 ${report.deadline}`;
                } else {
                    return `${this.companyName} ${report.type} 截止至 ${report.deadline}`;
                }
            }

        },
        loadNode(node, resolve) {
            if (node.level === 0) {
                return resolve(this.treeData);
            }
            if (node.data && Array.isArray(node.data.children)) {
                return resolve(node.data.children);
            }
            return resolve([]);
        },


        typeToLabel(type) {
            const typeLabels = {
                'ic': this.selectedLanguage === 'English' ? 'Income Statement' : '利润表',
                'bs': this.selectedLanguage === 'English' ? 'Balance Sheet' : '资产负债表',
                'cs': this.selectedLanguage === 'English' ? 'Cash Flow Statement' : '现金流量表'
            };
            return typeLabels[type] || type;
        },
        handleButtonClick(node, actionType) {
            console.log('handleButtonClick:', node, actionType);
            if (actionType === 'insights') {
                this.handleButtonInsights(node);
            }
            else if (actionType === 'explanation') {
                this.handleButtonExplanation(node);
            }
        },
        handleButtonInsights(data) {
            // 将用户的输入添加到聊天数组
            console.log('handleButtonInsights:', data);
            var prompt = this.getPromotInsightByType(data.typeShort, this.selectedLanguage, data.label);
            this.$emit('sendPrompt', prompt, data);

        },
        handleButtonExplanation(data) {
            // 将用户的输入添加到聊天数组
            var prompt = this.getPromotExplanationByType(data.typeShort, this.selectedLanguage, data.label);
            this.$emit('sendPrompt', prompt, data);
        },
        dataToMarkdown(data) {
            // 处理外层表格
            let markdown = `| ${data.title} |\n`;
            markdown += `|------|\n`;
            markdown += `| Months: ${data.months} |\n`;
            markdown += `| Deadline: ${data.deadline} |\n`;
            markdown += `| Access Number: ${data.accessNumber} |\n\n`;

            // 处理 form 内部的表格
            markdown += "| Key | Value |\n";
            markdown += "|-----|-------|\n";
            for (const [key, value] of Object.entries(data.form)) {
                markdown += `| ${key} | ${value} |\n`;
            }

            return markdown;
        },
        //类型和语言
        getPromotInsightByType(type, sl, label) {
            switch (type) {
                case 'ic':
                    if (sl === '中文') {
                        return `**洞察 ${label}**\n
你的任务是仔细分析利润表数据，并对数据显示的内容提供深刻的分析。
首先，在<草稿本>部分，记录下您在不同年份和类别中关于收入、费用和利润数字的任何关键观察、趋势和见解。考虑增长率、费用比率、利润率以及其他任何突出的因素。
然后，在<见解>部分，总结从分析这些数据中得出的三个最重要的收获或结论。这些数据透露了公司的财务轨迹、优势和劣势的哪些信息？请解释您的推理。
一些重要的注意事项：
仅基于利润表中提供的信息进行分析。不要做出假设或引入数据中未包含的任何信息。
具体使用数据中的实际数字和百分比来支持您的见解。
不要以项目符号格式呈现您的见解，而应以完整句子书写。`;
                    } else return `**Insight for ${label}**\n
Your task is to carefully analyze this profit table data and provide insightful analysis on what the data shows. 
First, in a <scratchpad> section, write down any key observations, trends, and insights you notice about the revenue, expense, and profit numbers across the different years and categories. Consider things like growth rates, expense ratios, margins, and anything else that stands out.
Then, in an <insights> section, summarize the 3 most important takeaways or conclusions you can draw from analyzing this data. What does the data reveal about the company's financial trajectory, strengths and weaknesses? Explain your reasoning.
Some important notes:
- Base your analysis only on the information provided in the profit table. Do not make assumptions or introduce any information not contained in the data.
- Be specific and use actual numbers and percentages from the data to back up your insights.
- Do not present your insights in bullet point format, but rather write them out in complete sentences.`;
                case 'bs':
                    if (sl === '中文') {
                        return `**洞察 ${label}**\n
请分析给出的财务数据,并提供见解。
请仔细阅读财务报表,重点关注资产负债表数据。
在<scratchpad>部分写下你的初步想法和分析。考虑以下问题:
- 资产负债表中有哪些关键的资产和负债项目? 
- 这些项目的金额和比例反映了公司的哪些特点?
- 与以前的报表相比,有哪些重大变化? 这些变化意味着什么?
- 资产负债表数据总体上反映了公司的财务状况如何?
</scratchpad>
\n然后,在<insights>部分提供你的主要见解,每个见解都以破折号开头并占一行。见解应该简明扼要地总结你的分析。只根据数据得出结论,不要做出没有数据直接支持的声明。
请记住,你的目标是提供清晰、有见地且有数据支持的分析,以帮助读者更好地理解公司的财务状况。如果数据不足以得出确定的结论,也要指出这一点。`;
                    } else return `**Insight for ${label}**\n
Please analyze the provided financial data and offer insights. Carefully read the financial statements, with a particular focus on the balance sheet data. In the <scratchpad> section, write down your initial thoughts and analysis. Consider the following questions:
- What are the key assets and liabilities in the balance sheet?
- What do the amounts and proportions of these items reveal about the characteristics of the company?
- Compared to previous statements, what significant changes are there? What do these changes imply?
- Overall, what does the balance sheet data indicate about the company's financial condition?
\nThen, in the <insights> section, provide your main insights, each starting with a dash and occupying one line. Insights should succinctly summarize your analysis. Draw conclusions based only on the data, and do not make statements that are not directly supported by the data.
Remember, your goal is to provide clear, insightful, and data-supported analysis to help readers better understand the company's financial condition. If the data is insufficient to draw definitive conclusions, also point this out.`;
                case 'cs':
                    if (sl === '中文') {
                        return `**洞察 ${label}**\n
你的任务是分析现金流量表的财报数据,并提供洞察和见解。
请仔细审阅上述现金流量表的内容。在<scratchpad>部分写下您的初步想法和分析:
- 公司的经营活动、投资活动和筹资活动现金流的情况如何?
- 您注意到现金流量表中有哪些关键的大额现金流入和流出项目?
- 将本期现金流量表与上期进行比较,您观察到了哪些变化?这些变化反映了公司财务状况的哪些变化?
- 基于现金流量表,您认为公司目前的现金流状况健康吗?为什么?
\n在对现金流量表进行分析之后,请在<insights>部分提供一份关键洞察的总结,包括:
- 公司现金流的整体健康状况
- 现金流量表反映出的公司经营中的积极信号和潜在风险
- 基于现金流量表,您对公司未来前景和财务状况的预测`;
                    } else return `**Insight for ${label}**\n
Your task is to analyze the financial data from the cash flow statement and provide insights and observations. Please carefully review the content of the aforementioned cash flow statement. In the <scratchpad> section, write down your initial thoughts and analysis:
- How are the company’s cash flows from operating, investing, and financing activities?
- What key large cash inflows and outflows have you noticed in the cash flow statement?
- Comparing this period's cash flow statement to the previous one, what changes have you observed? What do these changes reflect about the company's financial condition?
- Based on the cash flow statement, do you think the company’s current cash flow condition is healthy? Why?
\nAfter analyzing the cash flow statement, please provide a summary of key insights in the <insights> section, including:
- The overall health of the company's cash flows
- Positive signals and potential risks in the company's operations as reflected by the cash flow statement
- Your predictions for the company’s future outlook and financial condition based on the cash flow statement`;
                default:
                    return type;
            }

        },
        getPromotExplanationByType(type, sl, label) {
            console.log('getPromotExplanationByType:', type, sl, label);
            switch (type) {
                case 'ic':
                    if (sl === '中文') {
                        return `**解释 ${label}**\n
对提供的利润表数据中的每个字段进行解释，请提供：
- 该字段代表什么以及如何计算
- 在评估公司财务绩效中该字段的作用和重要性
- 针对该字段应考虑或注意的关键点，如趋势、比率或与行业基准的比较
\n请以清晰、结构良好的方式提供您的分析，按逻辑顺序讨论每个字段。利用您的财务专业知识，根据所提供的数据提供见解并得出关于公司盈利能力和财务健康的结论。
请在<分析>标签内输出您的完整回答。`;
                    } else {
                        return `**Explanation For ${label}**\n
For each field in the provided profit table data, please provide:
- What the field represents and how it is calculated
- The role and importance of the field in assessing the company's financial performance
- Key points to consider or pay attention to for that field, such as trends, ratios, or comparisons to industry benchmarks
\nProvide your analysis in a clear, well-structured manner, discussing each field in logical order. Utilize your financial expertise to offer insights and draw conclusions about the company's profitability and financial health based on the provided data.
Please output your entire response within <analysis> tags.`;
                    }

                case 'cs':
                    if (sl === '中文') {
                        return `**解释 ${label}**\n
现金流量表是财务报表的重要组成部分,它详细说明了一个会计期间内公司现金和现金等价物的流入和流出情况。通过分析现金流量表,可以洞察公司的经营质量、偿债能力、盈利质量等重要信息。
请你仔细研究上述现金流量表的各个部分,并提供专业的见解,具体包括:
1. 解释每个字段/会计科目所代表的具体含义
2. 讨论关键字段的重要性及其反映的财务状况
3. 指出现金流量表中值得关注的趋势,包括积极的和潜在风险的
4. 总体评估该公司的现金流和资金流动性状况
\n请用条理清晰、言之有物的方式呈现你的分析。可以先在<分析要点>标签中梳理你的思路,最后再将完整的分析写在<answer>标签中。`;
                    }else {
                        return `**Eexplanation For ${label}**\n
The cash flow statement is a crucial part of financial statements, detailing the inflows and outflows of cash and cash equivalents within an accounting period. By analyzing the cash flow statement, one can gain insights into the company's operational quality, debt repayment capacity, and the quality of earnings.
Please carefully study the various sections of the above cash flow statement and provide professional insights, including:
1. Explain the specific meanings represented by each field/account item.
2. Discuss the importance of key fields and the financial conditions they reflect.
3. Identify trends in the cash flow statement that are worthy of attention, including both positive aspects and potential risks.
4. Overall assess the company's cash flow and liquidity situation.
\nPresent your analysis in a clear, substantive manner. You may begin by organizing your thoughts in the <analysis points> tag and then provide the complete analysis in the <answer> tag.`;
                    }
                case 'bs':
                    if (sl === '中文') {
                        return `**解释 ${label}**\n
请仔细分析上述提供的财务报表数据。对于资产负债表中的每一个主要项目，提供详细的解释，包括：
- 该项目的含义和重要性
- 在该项目中需要特别注意或警惕的关键事项
- 该项目如何与财务报表的其他部分相关或产生影响
\n请将每个项目的解释放在<explanation>标签内。
确保涵盖资产负债表中的所有关键项目，包括资产、负债和所有者权益。提供彻底、深入的解释，展示对财务报表分析和会计原则的深刻理解。将不同的项目相互关联，并解释它们如何融入整体财务状况。
如果任何项目的值看起来不寻常或令人关注，请在您的解释中指出，并讨论这可能意味着什么。
请以深思熟虑、组织有序的方式提供您的解释。使用清晰的语言，使具有基本会计知识的人也能理解。`;
                    }else{
                        return `**Eexplanation For ${label}**\n
Please carefully analyze the financial statement data provided above. For each major line item in the balance sheet, provide a detailed explanation that covers:
- The meaning and significance of that line item 
- Key things to pay attention to or watch out for in that line item
- How the line item relates to or impacts other parts of the financial statement
\nPut your explanation for each line item inside <explanation> tags.
Be sure to cover all the key line items in the balance sheet, including assets, liabilities, and equity. Provide thorough, in-depth explanations that demonstrate a strong understanding of financial statement analysis and accounting principles. Relate the different line items to each other and explain how they fit into the overall financial picture.
If any of the values for a line item seem unusual or concerning, call that out in your explanation and discuss what it might mean.
Provide your explanations in a thoughtful, well-organized manner. Use clear language that would be understandable to someone with a basic knowledge of accounting.`;
                    }
                default:
                    return `**解释 ${label}**\n`;
            }
        }
    },
}
</script>

<style scoped>
.scrollable-content {
    height: 100%;
    overflow-y: scroll;
    /* 总是显示滚动条 */
    /* 确保内容不会被滚动条遮挡 */
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE和Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.scrollable-content:hover {
    overflow-y: scroll;
}
</style>