<template>
  <div class="loader" id="ld4">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'loadingSpinner'
  // 在这里可以添加一些组件逻辑
}
</script>

<style scoped>
.loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

#ld4 {
  position: relative;
  display: flex;
  width: 120px;
  justify-content: space-between;
}

#ld4 div {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #D91E36;
}

#ld4 div:nth-child(1) {
  animation: ld4 3s linear infinite 0s;
}

#ld4 div:nth-child(2) {
  animation: ld4 3s linear infinite 0.15s;
}

#ld4 div:nth-child(3) {
  animation: ld4 3s linear infinite 0.3s;
}

#ld4 div:nth-child(4) {
  animation: ld4 3s linear infinite 0.45s;
}

@keyframes ld4 {
  0% {
    opacity: 0;
    transform: scale(0.3);
    background: #FDBF5A;
  }

  25% {
    opacity: 1;
    transform: scale(1.5);
    background: #EE5C56;
  }

  50% {
    opacity: 0;
    transform: scale(0.3);
    background: #A61D82;
  }

  75% {
    opacity: 1;
    transform: scale(1.5);
    background: #7FC4EE;
  }

  100% {
    opacity: 0;
  }
}</style>
