<template>
    <div>
        <HeaderComponent></HeaderComponent>
        <div class="about-page-container">
            <section class="hero">
                <h1 style="font-size: 32px;">Welcome to papermoney.ai</h1>
                <p>Your Expert in Financial Report Visualization.</p>
            </section>

            <section class="content">
                <div class="mission">
                    <h2>Our Mission</h2>
                    <p>At papermoney.ai, our mission is to help investors, analysts, and business enthusiasts quickly
                        and
                        intuitively understand the financial health of a company. We gather and organize publicly
                        available
                        corporate financial data and employ cutting-edge AI technology to transform it into easily
                        understandable visual charts.</p>
                </div>

                <div class="offerings">
                    <h2>Our Offerings</h2>
                    <ul>
                        <li>Customized visual financial charts, including income statements, balance sheets, and cash
                            flow
                            statements.</li>
                        <li>In-depth financial analysis with insights into company operations.</li>
                        <li>A variety of case studies to enhance understanding of financial visuals.</li>
                    </ul>
                </div>

                <div class="features">
                    <h2>Our Unique Features</h2>
                    <ul>
                        <li>Visualization of complex financial data in an intuitive format.</li>
                        <li>Advanced AI technology for trend analysis and future predictions.</li>
                        <li>Extensive case studies that provide real-world application of our analyses.</li>
                        <li>A user-friendly platform experience.</li>
                    </ul>
                </div>

                <div>
                    <Page10k></Page10k>
                </div>
                <div class="help-links">
                    <h2>Learn More</h2>
                    <ul>
                        <li><router-link to="/help/10k">Understanding the 10-K Report</router-link></li>
                        <li><router-link to="/help/income-statement">Deciphering the Income Statement</router-link></li>
                        <li><router-link to="/help/balance-sheet">Analyzing the Balance Sheet</router-link></li>
                        <li><router-link to="/help/cash-flow">Interpreting the Cash Flow Statement</router-link></li>
                    </ul>
                </div>
                <div class="contact">
                    <h2>Contact Us</h2>
                    <p>We invite you to contact us at any time to explore the mysteries of financial data together.
                        Thank
                        you for choosing papermoney.ai — let's embark on a new chapter of intelligent investing
                        together.
                    </p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Page10k from './help/Page-10k.vue';
import HeaderComponent from './HeaderComponent.vue';
export default {
    name: 'AboutPage',
    components: {
        Page10k,
        HeaderComponent
    },
    // ... other options ...
}
</script>

<style scoped>
.about-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.hero {
    text-align: center;
    padding: 2em 0;
    margin-top: 12px;
    font-size: 24px;
}

.content h2 {
    color: #333;
    margin-top: 1em;
    margin-bottom: 12px;
}

.content p {
    color: #666;
    line-height: 1.6;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    font-size: 16px;

}

.router-link-active {
    color: #00aaff;
}

.help-links h2,
.contact h2 {
    margin-top: 2em;
}

.contact p {
    max-width: 800px;
    margin-top: 1em;
}

/* Add more styles as needed for better appearance */
</style>