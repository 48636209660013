<template>
    <el-dialog :visible.sync="loginDialogVisible" class="custom-dialog">
        <div class="dialog-container">
            <!-- 登录表单部分 -->
            <div v-if="currentView === 'login'" class="login-section">
                <div class="header-container">
                    <h1 style="color: #666;">Welcome back!</h1>
                    <h1 style="color: #666;margin-top: 12px;">Please sign in Papermoney.ai</h1>
                    <div style="margin-top: 12px;">New to Account<el-link type="primary" @click="goToCreatePage"
                            style="margin-left: 12px;">Create
                            an account</el-link></div>
                </div>
                <el-divider></el-divider>

                <div id="gSignInWrapper" style="margin-top:12px">
                    <div ref="customBtn" class="customGPlusSignIn">
                        <span class="icon"></span>
                        <span class="buttonText">Sign in with Google</span>
                    </div>
                </div>
                <div style="display: flex;justify-content: center;margin-top: 12px;">
                    <p class="or-text">or</p>
                </div>
                <el-form ref="loginForm" class="login-form" style="margin-top: 12px;">
                    <el-form-item>
                        <el-input v-model="form.login.email" placeholder="Enter your email address"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.login.password" autocomplete="off" placeholder="Password"
                            show-password></el-input>
                    </el-form-item>
                    <div class="bottom-container">
                        <el-checkbox>Remember me</el-checkbox>
                        <el-button type="text" style="margin-left: 12px;" @click="goToForgotPasswordPage">Forget your
                            password?</el-button>
                    </div>
                    <el-button :loading="loading.signIn" class="sign-in-button" type="primary" @click="signIn">Sign
                        in</el-button>
                </el-form>
            </div>
            <!-- 注册表单部分 -->
            <div v-if="currentView === 'register'" class="register-section">
                <div class="header-container">
                    <h1 style="color: #666;">Create new account</h1>
                    <h1 style="color: #666;margin-top: 12px;">Join Papermoney.ai for Free Now!</h1>

                </div>
                <el-divider></el-divider>
                <div>Already have an account?<el-link type="primary" @click="goToSignInPage" style="margin-left: 12px;">Sign
                        in</el-link></div>
                <el-divider></el-divider>
                <div id="gSignInWrapper" style="margin-top:12px">
                    <div id="customBtn" class="customGPlusSignIn">
                        <span class="icon"></span>
                        <span class="buttonText">Sign up with Google</span>
                    </div>
                </div>
                <div style="display: flex;justify-content: center;margin-top: 12px;">
                    <p class="or-text">or</p>
                </div>
                <el-form ref="registerForm" class="register-form" style="margin-top: 12px;">
                    <el-form-item>
                        <el-input v-model="form.register.email" placeholder="Enter your email address"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.register.password" autocomplete="off" placeholder="Password"
                            show-password></el-input>
                    </el-form-item>
                    <div style="margin-top:24px;height: 40px;">
                        <el-checkbox>By signing up, I agree to Terms of Use & Privacy Policy</el-checkbox>
                    </div>
                    <el-button :loading="loading.signUp" class="sign-up-button" type="primary" @click="signUp"
                        style="margin-top: 12px;">Continue</el-button>
                </el-form>
            </div>
            <!-- 忘记密码表单部分 -->
            <div v-if="currentView === 'forgotPassword'" class="forgot-password-section">
                <el-card class="forgot-password-card" shadow="never">
                    <div class="header-container">
                        <h1>Forget your password?</h1>
                        <p>Please enter the email address associated with your PapeyMoney.ai account.</p>
                    </div>
                    <el-form ref="forgotPasswordForm" class="forgot-password-form">
                        <el-form-item>
                            <el-input v-model="form.forget.email" placeholder="Enter your email address"></el-input>
                        </el-form-item>
                        <el-button class="continue-button" type="primary" @click="resetPassword">Continue</el-button>
                        <el-button type="text" @click="goToSignInPage">Back to sign in</el-button>
                    </el-form>
                </el-card>
            </div>
            <div class="info-section">
                <h1 style="color: #EEE;font-size: 32px;">Financial Analysis</h1>
                <h2 style="color: #CCC;font-size: 24px;">Unlock Insights with Ease!</h2>

                <ul>
                    <li><i class="el-icon-s-data" style="margin-right: 12px;"></i>One-look Financial Reports</li>
                    <li><i class="el-icon-chat-dot-square" style="margin-right: 12px;"></i>AI-Driven Analysis</li>
                    <li><i class="el-icon-time" style="margin-right: 12px;"></i>Verified Reliable Data</li>
                    <li><i class="el-icon-unlock" style="margin-right: 12px;"></i>Free & Accessible Tools</li>
                </ul>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import axios from 'axios';

export default {
    name: 'LoginForm',

    mounted() {
        // 加载Google Identity服务脚本
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            // 脚本加载后设置标志，表示Google库已经加载
            this.googleApiLoaded = true;
            console.log('Google Identity API loaded');
        };
        document.head.appendChild(script);
    },
    data() {
        return {
            loginDialogVisible: false,
            currentView: 'login', // 可以是 'login', 'register', 'forgotPassword', 或 'info'
            form: {
                login: {
                    email: '',
                    password: ''
                },
                register: {
                    email: '',
                    password: ''
                },
                forget: {
                    email: ''
                }
            },
            loading: {
                signIn: false,
                signUp: false,
                resetPassword: false
            },

        }
    },
    methods: {
        // 在方法中使用ref
        initializeGoogleAPI() {
            this.$nextTick(() => {
                // 首先确保Google库已加载
                if (window.google) {
                    const buttonElement = this.$refs.customBtn;
                    // 然后确保按钮元素已经渲染
                    if (buttonElement) {
                        window.google.accounts.id.initialize({
                            client_id: '857300924412-q0qsfjuv8suvkkhatc21d9haa5iblkcn.apps.googleusercontent.com',
                            callback: this.handleCredentialResponse,
                        });
                        window.google.accounts.id.renderButton(
                            buttonElement,
                            { theme: 'outline', size: 'large', width: 480}
                        );
                    } else {
                        console.error('Google Sign-in button element not found');
                    }
                } else {
                    console.error('Google library not loaded');
                }
            });
        },
        goToCreatePage() {
            this.currentView = 'register';
        },
        goToSignInPage() {
            this.currentView = 'login';
        },
        goToForgotPasswordPage() {
            this.currentView = 'forgotPassword';
        },
        async signIn() {
            this.loading.signIn = true;
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.post(apiEndpoint + '/login', {
                    email: this.form.login.email,
                    password: this.form.login.password
                });
                // Handle successful response
                console.log('Login successful:', response);
                this.$message.success('Login successful!');
                // 登录成功处理
                this.$store.commit('SET_AUTH', {
                    token: response.data.token,
                    email: response.data.email
                });
                this.closeDialog(); // Close the dialog
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Login failed due to an unknown error.';
                this.$message.error(errorMessage);
            } finally {
                this.loading.signIn = false;
            }
        },

        async signUp() {
            this.loading.signUp = true;
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.post(apiEndpoint + '/register', {
                    email: this.form.register.email,
                    password: this.form.register.password
                });
                // Handle successful response
                console.log('Registration successful:', response);
                // 登录成功处理
                this.$store.commit('SET_AUTH', {
                    token: response.data.token,
                    email: response.data.email
                });
                this.closeDialog(); // Close the dialog
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Registration failed due to an unknown error.';
                this.$message.error(errorMessage);
            } finally {
                this.loading.signUp = false;
            }
        },

        async resetPassword() {
            this.loading.resetPassword = true;
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.post(apiEndpoint + '/forgot-password', {
                    email: this.form.forget.email
                });
                // Handle successful response
                console.log('Reset password request successful:', response);
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Password reset failed due to an unknown error.';
                this.$message.error(errorMessage);
            } finally {
                this.loading.resetPassword = false;
            }
        },
        async handleCredentialResponse(autoResponse) {
            // 示例：将ID令牌发送到后端进行验证
            // console.log('handleCredentialResponse ID token:', autoResponse.credential);

            this.loading.signIn = true;
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.post(apiEndpoint + '/googleAuth', {
                    googleToken: autoResponse.credential
                });
                // Handle successful response
                console.log('Login successful:', response);
                this.$message.success('Login successful!');
                // 登录成功处理
                this.$store.commit('SET_AUTH', {
                    token: response.data.token,
                    email: response.data.email
                });
                this.closeDialog(); // Close the dialog
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Login failed due to an unknown error.';
                this.$message.error(errorMessage);
            } finally {
                this.loading.signIn = false;
            }
        },
        openDialog() {
            this.currentView = 'login';
            this.loginDialogVisible = true;
        },
        openRegisterForm() {
            this.currentView = 'register'; // 设置视图为注册
            this.loginDialogVisible = true; // 打开对话框
        },
        closeDialog() {
            this.loginDialogVisible = false;

        }
    },
    watch: {
        // 监听弹窗的可见性
        loginDialogVisible(newVal) {
            if (newVal === true) {
                this.initializeGoogleAPI();
            }
        }
    },
}
</script>

<style scoped>
::v-deep .el-dialog {
    /* 使用最大值函数 */
    background: none;
    width: min(1280px, 60vw) !important;
    border-radius: 0px;
    box-shadow: none;
    /* 使用最小值函数 */
}

.dialog-container {
    display: flex;
    justify-content: space-between;
    border-radius: 12px !important;
    align-items: stretch;
    background: #F5F5F5;
    width: 100%;
    /* 使用最小值函数 */
    height: 100%;
    /* 使子元素填满容器的高度 */
}

.login-section,
.register-section {
    flex: 1;
    /* 使登录部分占据可用空间 */
    display: flex;
    /* 使其成为flex容器 */
    flex-direction: column;
    /* 子元素垂直排列 */
    height: min(640px, 60vh) !important;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 12px 24px;
    background: #FFF;
    justify-content: center;
    height: 100%;
}

.info-section {
    flex: 1;
    /* 使介绍部分占据相同的空间 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* 垂直居中内容 */
    padding-left: 48px;
    background-color: #6a5acd;
    color: white;
    text-align: left;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.login-card {
    flex: 1;
    /* 允许卡片填满登录部分的高度 */
    display: flex;
    flex-direction: column;
    /* 子元素垂直排列 */
    justify-content: center;
    /* 垂直居中卡片内容 */
}

.google-sign-in {
    /* Adjust the Google sign-in button styles if needed */
    margin-top: 12px;
    width: 100%;
    font-weight: bold;
}

.sign-in-button,
.sign-up-button {
    /* Adjust the sign-in button styles if needed */
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 12px;
}



.info-section h1,
.info-section h2 {
    margin-top: 24px;
    /* 增加标题和列表项之间的间距 */
    white-space: nowrap;
    /* 防止标题换行 */
    color: white;
}

.info-section ul {
    list-style: none;
    /* 移除列表标记 */
    padding: 0;
    /* 移除默认填充 */
    margin-top: 48px
        /* 调整上下间距 */
}

.info-section li {
    margin-bottom: 24px;
    /* 每个列表项之间的间距 */
    font-size: 1.2rem;
    /* 列表项的字体大小 */
}

.or-text {
    font-size: 16px;
    color: #AAA;
}

::v-deep .el-dialog__headerbtn {
    border-radius: 50%;
    color: #999;
    background: #EEE;
    width: 32px;
    height: 32px;
    font-size: 24px;
    transform: translateX(48px);
}

/* 当屏幕宽度小于1080像素时，改为上下结构 */
@media (max-width: 1280px) {
    .info-section {
        display: none;
    }

    .login-section,
    .register-section {
        flex: 1;
        /* 使登录部分占据可用空间 */
        display: flex;
        /* 使其成为flex容器 */
        flex-direction: column;
        /* 子元素垂直排列 */
        height: min(640px, 60vh) !important;
        border-radius: 12px;
        padding: 12px 24px;
        background: #FFF;
        justify-content: center;
        height: 100%;
    }
}
</style>
