<template>
    <el-dialog :visible.sync="visible" title="Report Error">
        <div>
            <el-checkbox-group v-model="selectedErrorTypes">
                <el-checkbox :label="1" border>{{ errorLabels.dataError }}</el-checkbox>
                <el-checkbox :label="2" border>{{ errorLabels.dateError }}</el-checkbox>
                <el-checkbox :label="3" border>{{ errorLabels.iconError }}</el-checkbox>
                <el-checkbox :label="4" border>{{ errorLabels.confusing }}</el-checkbox>
            </el-checkbox-group>
            <el-input type="textarea" v-model="errorDescription" :placeholder="errorLabels.descriptionPlaceholder"
                style="margin-top: 24px;"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">{{ buttons.cancel }}</el-button>
            <el-button type="primary" @click="submitErrorReport">{{ buttons.submit }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            selectedErrorTypes: [],
            errorDescription: '',
        };
    },
    computed: {
        selectedLanguage() {
            return this.$store.getters.getSelectedLanguage;
        },
        errorLabels() {
            const isEnglish = this.selectedLanguage === 'English';
            return {
                dataError: isEnglish ? 'Report Data error' : '数据错误',
                iconError: isEnglish ? 'Report View error' : '视图错误',
                dateError: isEnglish ? 'Report Date error' : '日期错误',
                confusing: isEnglish ? 'Confusing' : '看不懂',
                descriptionPlaceholder: isEnglish ? 'Describe the issue...' : '请描述问题...'
            };
        },
        buttons() {
            const isEnglish = this.selectedLanguage === 'English';
            return {
                cancel: isEnglish ? 'Cancel' : '取消',
                submit: isEnglish ? 'Submit' : '提交',
            };
        }
    },
    methods: {
        submitErrorReport() {
            // 处理错误报告的逻辑
            console.log('Selected Error Types:', this.selectedErrorTypes);
            console.log('Description:', this.errorDescription);
            // 关闭对话框
            this.visible = false;
            // 这里可以发送请求到服务器
        },
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        }
    }
};
</script>

<style scoped>
::v-deep .el-checkbox {
    margin-right: 12px;
    margin-bottom: 12px;
}
</style>

