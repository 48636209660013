<template>
    <div class="company-logo-wrapper" :style="{ width: size + 'px', height: size + 'px' }">
      <el-image v-if="logoUrl" :src="logoUrl" class="company-logo" fit="cover">
        <div slot="error" class="company-letter">
          {{ companyLetter }}
        </div>
      </el-image>
      <div v-else class="company-letter">
        {{ companyLetter }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      company: {
        type: Object,
        required: true
      },
      size: {
        type: Number,
        default: 28
      }
    },
    computed: {
      logoUrl() {
        return this.company.profile && this.company.profile.logo;
      },
      companyLetter() {
        return this.company.name ? this.company.name.charAt(0).toUpperCase() : '';
      }
    }
  };
  </script>
  
  <style scoped>
  .company-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f0f0f0;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .company-logo {
    width: 100%;
    height: 100%;
  }
  
  .company-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #666;
  }
  </style>