

<script>
import CustomerSankey from './IncomeSankey.vue';
import Tools from '../../GlobalTools';
import EditableLabel from './EditableLabel.vue';
import SourcePanel from './SourceDataForm.vue';
import axios from 'axios';
export default {
    components: {
        SourcePanel,
        EditableLabel,
        CustomerSankey // Correct the component name to match the imported name
    },
    props: {
        report: {
            type: Object,
            default: () => { }
        },
        companyType: {
            type: String,
            default: 'LC'
        }
    },
    mounted() {
        this.getReportDetail();
    },
    data() {
        return {
            edited:false,
            symbol: '',
            activeName: '1',
            currentPage: 1,
            isFormValid: false,
            formCreaterDialog: {
                dialogVisible: false,
                currentStep: 0,
            },
            company: {},
            profile: {},

            loading: false,
            showSourcePanal: false, // 控制滑动窗口的显示
            source: {}, // 存储给source-panel的数据
            symbolIncomeDates: null,
            symbolIncomeSecDates: null,
            predefinedColors: ['gray', 'blue', 'orange'],
            popoverVisible: false, // Controls the visibility of the popover
            userHasEditedTitle: false, // 用于跟踪用户是否编辑过标题
            config: {
                title: '',
                subtitle: '',
                companyName: '',
                companyCode: '',
                reportType: '',
                endDate: '',
                currency: '',
                unit: '',
                color: '',
                industry: '',
                // logo: ''
            },
            form: {

            },
            Colors: {
                predefinedColors: ['red', 'green', 'blue', 'orange', 'gray'],
            },
            units: [], // 初始时单位数组为空
            imageSrc: '', // 这里放置您的图片路径
            sankeyData: {
                nodes: [],
                links: [],
                title: '',
                unit: '',
                unit_t: '',
                currency: '',
                date_ending: '',
                companyCode: '',
                coompanySymbol: ''
            },
            nodes: [],
            links: [],            
            cascaderOptions: [],
            cascaderData: [
                {
                    value: 'USD',
                    label: 'USD',
                    children: [
                        { value: 'B', label: 'B' },
                        { value: 'M', label: 'M' },
                        { value: 'K', label: 'K' },
                    ],
                },
                {
                    value: 'RMB',
                    label: 'RMB',
                    children: [
                        { value: '亿', label: '亿' },
                        { value: '百万', label: '百万' },
                        { value: '万', label: '万' },
                        { value: '千', label: '千' },
                        { value: '元', label: '元' },
                    ],
                },
                {
                    value: 'HKD',
                    label: 'HKD',
                    children: [
                        { value: 'B', label: 'B' },
                        { value: 'M', label: 'M' },
                        { value: 'K', label: 'K' },
                    ],
                },
                {
                    value: 'Other',
                    label: 'Other',
                    children: [
                        { value: 'B', label: 'B' },
                        { value: 'M', label: 'M' },
                        { value: 'K', label: 'K' },
                    ],
                },
            ],
            industryOptions: [
                { value: 'energy', label: 'Energy' },
                { value: 'basic_materials', label: 'Basic Materials' },
                { value: 'industrials', label: 'Industrials' },
                { value: 'consumer_goods', label: 'Consumer Goods' },
                { value: 'health_care', label: 'Health Care' },
                { value: 'consumer_services', label: 'Consumer Services' },
                { value: 'telecommunications', label: 'Telecommunications' },
                { value: 'utilities', label: 'Utilities' },
                { value: 'financials', label: 'Financials' },
                { value: 'technology', label: 'Technology' }
            ],
            monthsEndedConfig: [
                { value: '3months', label: '3 Months Ended' },
                { value: '6months', label: '6 Months Ended' },
                { value: '9months', label: '9 Months Ended' },
                { value: '12months', label: '12 Months Ended' },
                { value: '1months', label: '1 Months Ended' },
            ]
        };

    },
    computed: {
        leftColumnSpan() {
            return this.showSourcePanal ? 6 : 7;
        },
        middleColumnSpan() {
            return this.showSourcePanal ? 12 : 17;
        },
        imageContainerStyle() {
            let width;
            if (this.drawerVisible) {
                // 当滑动窗口显示时
                width = 'calc(100vw * 1/2 - 48px)'; // 根据实际布局调整这里的值
            } else {
                // 当滑动窗口隐藏时
                width = 'calc(100vw * 2/3 - 48px)';
            }

            return {
                width: width,
                height: `calc((${width} - 248px)* (2416 / 4296))`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                overflow: 'hidden',
                position: 'relative',
            };
        }
    },
    // 方法和逻辑可以根据需求添加
    methods: {
        toggleSourcePannel() {
            this.showSourcePanal = !this.showSourcePanal;
        },
        handleClosePannel() {
            // 处理关闭抽屉的逻辑
            this.showSourcePanal = false;
        },
        handlePageChange(newPage) {
            this.currentPage = newPage;
        },
        showSearchSymbolDialog() {
            // 发出一个自定义事件 'create-company'
            this.$emit('create-company');
        },
        getFormKeyV(id) {
            if (this.form[id]) {
                return this.form[id].v;
            }
            return 0;
        },
        getFormKeyAbsV(id) {
            if (this.form[id]) {
                return Math.abs(this.form[id].weight);
            }
            return 0;
        },
        getFormNodeColor(id, colorDefault) {
            if (this.form[id]) {
                return this.form[id].color;
            }
            return colorDefault;
        },
        getFormNodeSort(id, sortDefault) {
            if (this.form[id]) {
                return this.form[id].sort;
            }
            return sortDefault;
        },

        updateSankey() {
            this.sankeyData = this.generateSankeyData(); // Call this method when form data changes
            // Tools.logObj('updateSankey:', this.sankeyData);
        },
        checkReportType() {
            console.log(this.config.reportType); // 查看当前值
        },
        getFormateDate(endDate, reportType) {
            if (typeof endDate === 'string') {
                endDate = new Date(endDate);
            }
            if (!(endDate instanceof Date) || isNaN(endDate)) return '';

            // 直接使用 endDate 的年份
            const year = endDate.getFullYear();

            // 计算季度
            const quarter = Math.floor((endDate.getMonth() + 3) / 3);

            if (reportType === 'Q') {
                return `Q${quarter} ${year}`;
            } else if (reportType === 'Y') {
                return `${year}`;
            } else if (reportType === 'I') {
                return `${year} Interim`;
            } else {
                return '';
            }
        },

        // 用户输入时触发
        userTypedTitle(value) {
            // 直接更新 config.title 的值为用户输入的内容
            // this.userHasEditedTitle = true;
            this.config.title = value;
            this.$nextTick(() => {
                this.userHasEditedTitle = false;
            });
        },

        selectColor(color) {
            this.config.color = color;
            this.form.revenue.color = color;
            this.form.extraIncomeItems.forEach(item => {
                item.color = color;
            });
            this.popoverVisible = false; // Close the popover after a color is selected
            this.updateSankey();
        },

        lightenColor(hex, lightenAmount = 0, grayAmount = 60) {
            // 将十六进制颜色值转换为数字
            var num = parseInt(hex.slice(1), 16);

            // 分离红色、绿色和蓝色的值
            var r = (num >> 16) + lightenAmount;
            var g = ((num >> 8) & 0x00FF) + lightenAmount;
            var b = (num & 0x0000FF) + lightenAmount;

            // 增加灰度
            r += grayAmount;
            g += grayAmount;
            b += grayAmount;

            // 确保新值在0-255范围内
            r = Math.min(Math.max(0, r), 255);
            g = Math.min(Math.max(0, g), 255);
            b = Math.min(Math.max(0, b), 255);

            // 重新组合颜色值并返回
            var newHex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            return newHex;
        },
        weight_function(form_1, fun = '-', form_2) {
            var v1 = Tools.convertToNumber(form_1);
            var v2 = Tools.convertToNumber(form_2);
            if (fun === '+') {
                return v1 + v2;
            } else {
                return v1 - v2;
            }
        },
        parseNodeColor(nc) {
            switch (nc) {
                case 'green':
                    return '#269322';
                case 'red':
                    return '#BE0005';
                case 'gray':
                    return '#616161';
                case 'blue':
                    return '#1457B1';
                case 'orange':
                    return '#FA8C35';
                default:
                    return '#616161';
            }
        },

        formateDate(date_ending) {
            // Regular expression to check if the date is in 'YYYY-MM-DD' format
            const validDateFormat = /^\d{4}-\d{2}-\d{2}$/;

            // Check if the date is already in a valid format
            if (validDateFormat.test(date_ending)) {
                return date_ending;
            } else {
                // Extract the year, month, and day from the date string
                const year = date_ending.substring(0, 4);
                const month = date_ending.substring(4, 6);
                const day = date_ending.substring(6, 8);

                // Construct a date string in the 'YYYY-MM-DD' format
                const formattedDateString = `${year}-${month}-${day}`;
                return formattedDateString;
            }
        },

        triggerDownload() {
            this.$refs.customerSankeyRef.downloadSankeyImage();
        },

        cleanObject(obj) {
            if (!obj || typeof obj !== 'object') {
                return obj;
            }

            const cleanedObj = {};
            Object.keys(obj).forEach(key => {
                // 只保留简单数据类型的属性
                if (obj[key] === null || typeof obj[key] !== 'object') {
                    cleanedObj[key] = obj[key];
                }
            });
            return cleanedObj;
        },
        extractFormData() {
            let extractedData = {};

            Object.keys(this.form).forEach(key => {
                if (Array.isArray(this.form[key])) {
                    // 对数组的每个元素进行处理
                    extractedData[key] = this.form[key].map(item => this.extractSpecificFields(item));
                } else {
                    // 处理单个对象
                    extractedData[key] = this.extractSpecificFields(this.form[key]);
                }
            });

            return extractedData;
        },
        extractSpecificFields(obj) {
            // 只包含特定字段的新对象
            if (!obj) {
                return null;
            }
            return {
                id: obj.id || '',
                v: obj.v || '',
                vi: obj.vi || '',
                weight: obj.weight || '',
                color: obj.color || '',
                desc: obj.desc || '',
                yy: obj.yy || '',
                sort: obj.sort || 0
            };
        },
        sumNumbers(arr) {
            return arr.reduce((sum, value) => {
                return sum + (Number(value) || 0);
            }, 0);
        },
        setNodeValue(node, value) {
            node.v = value;
            node.vi = value + '';
            node.weight = Math.abs(value);
        },
        //type = 'ic'
        async getReportDetail() {
            console.log('getReportDetail:', this.report);
            this.config.companyCode = this.report.symbol;
            this.config.companyName = this.report.name;
            this.config.title = this.report.title;
            this.config.reportType = this.report.dateType;
            this.source = this.report;
            if (this.report.deadline) {
                this.loading = true;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const params = {
                    symbol: this.report.symbol,
                    deadline: this.report.deadline,
                    status: this.report.status,
                    type: this.report.type,
                    dateType: this.report.dateType,
                    months:this.report.months
                };
                axios.post(`${apiEndpoint}/getReportDetail`, params).then(response => {
                    this.loading = false;
                    if (response.data && response.data.result) {
                        var reportDetail = response.data.result;
                        console.log('getReportDetail:', reportDetail);
                        if (reportDetail.source === 'papermoney') {
                            this.companyCode = this.report.symbol;
                            this.companyName = this.report.name;
                            this.loadData(reportDetail.data.config, reportDetail.data.form);
                        } else {
                            var nodes = this.createNodes(reportDetail.data);
                            var config = {
                                companyCode: this.report.symbol,
                                companyName: this.report.name,
                                reportType: this.report.dateType ? this.report.dateType.toUpperCase() : '',
                                endDate: reportDetail.dateEnding,
                                unit: reportDetail.unit,
                                dateType: this.report.dateType,
                            };
                            config.currency = reportDetail.currency === '$' ? 'USD' :
                                reportDetail.currency === '¥' ? 'RMB' :
                                    reportDetail.currency === 'HK$' ? 'HKD' : 'Other';
                            this.loadData(config, nodes);
                        }

                    }
                }).catch(error => {
                    console.log('getReportDetail error:', error);
                    this.loading = false;
                });
            }
        },
        async validateForm() {
            if (this.$refs.config) {
                const valid = await this.$refs.config.validate();
                this.isFormValid = valid;
            }
        },
    },
    watch: {
        // Watch for changes in form to update Sankey data
        config: {
            handler() {
                this.updateSankey();
            },
            deep: true
        },
        form: {
            handler() {
                this.updateSankey();
            },
            deep: true
        },
        userCompanies(newVal, oldVal) {
            // 只有当实际数据发生变化时才更新搜索结果
            if (newVal.allReports !== oldVal.allReports) {
                this.initializeSearchResults();
            }
        },
        // 监听 companyName, reportType, endDate 的变化来生成标题
        'config.companyName'(newVal, oldVal) {
            // console.log('config.companyName:', newVal, oldVal);
            if (newVal !== oldVal) this.generateTitle();
        },
        'config.reportType'(newVal, oldVal) {
            // console.log('config.reportType:', newVal, oldVal);
            if (newVal !== oldVal) this.generateTitle();
        },
        'config.endDate'(newVal, oldVal) {
            // console.log('config.endDate:', newVal, oldVal);
            if (newVal !== oldVal) {
                this.generateTitle();
                this.generateSubTitle();
            }
        },
        'cascaderOptions'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.config.currency = newVal[0];
                this.config.unit = newVal[1];
                this.generateSubTitle();
            }
        }
    }
};
</script>
<style scoped>
::v-deep .el-row {
    padding: 0px;
    display: flex;
}

.form-container {
    /* Ensure that the form container has a maximum height */
    height: calc(100vh - 120px);
    overflow-y: auto;
    /* This will create a scrollbar if content overflows */
    box-shadow: 8px 0 15px -5px rgba(0, 0, 0, 0.1);
    padding: 24px 12px;
    margin-left: 12px;
}

::v-deep .el-collapse-item__header {
    font-size: 16px;
    font-weight: 400;
    padding-left: 12px;
}

::v-deep .el-collapse-item__content {
    padding: 12px;
}

::v-deep .el-form-item {
    display: flex;
    /* 启用 flex 布局 */
    flex-wrap: wrap;
    /* 允许换行 */
    align-items: center;
    /* 垂直居中 */
    border-bottom: 1px solid #ebebeb;
    /* 添加灰色线 */
    padding-top: 8px;
    padding-bottom: 8px;
    /* 在灰色线上方添加一些内边距 */
    margin-bottom: 0;
    /* 移除外边距，如果需要的话 */
    /* 去除底部外边距 */
}

::v-deep .el-form-item.group {
    border-bottom: none;
}

::v-deep .el-form-item__label {
    display: flex;
    align-items: flex-start;
    min-width: 80px;
    width: auto !important;
    margin-right: 8px;
    /* 设置标题和输入框之间的间距 */
    flex-shrink: 0;
    /* 防止标题被压缩 */
    margin-bottom: 0;
    /* 如果标签有默认的下外边距 */
}

::v-deep .el-form-item__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    /* 输入框填充剩余空间 */
    min-width: 120px;
    /* 设置输入框的最小宽度 */
    margin-bottom: 0;
    /* 如果内容有默认的下外边距 */
    margin-left: 0px !important;
}

/* 调整选择器和输入框的宽度 */
/* .el-select,
.el-input,
.el-date-picker {
    width: 100%;
} */

.unit_currency {
    max-width: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
}

.unit_currency .el-radio-group {
    margin-top: 16px;
}


/* .sankey-container {
    margin-top: 120px;
}  */

::v-deep .sankey-container .el-card__body {
    padding: 8px;
}

.image-container {
    /* height: calc(100vh - 120px); */
    width: calc(100vw * 2/3 - 48px);
    height: calc((100vw * 2/3 - 248px)* (2416 / 4296));
    /* 窗口高度减去标题高度 */
    /* 窗口的最大高度 */
    display: flex;
    /* 使用 Flexbox 布局 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    text-align: center;
    overflow: hidden;
    /* 允许滚动 */
    position: relative;
    /* background-color: #F5F5F5; */
}

::v-deep .el-color-picker {
    height: auto;
    margin-right: 12px;
}

::v-deep .el-color-picker__trigger {
    width: 20px;
    /* 或者您想要的大小 */
    height: 20px;
    border: none !important;
    /* 去除边框 */
    padding: 0 !important;
    /* 去除内边距 */
    box-shadow: none !important;
    /* 去除阴影 */
    background: none !important;
    /* 去除可能存在的背景 */
}

::v-deep .el-color-picker__trigger .el-color-picker__color {
    border: none !important;
    /* 去除颜色展示区域的边框 */
}

::v-deep .el-color-picker__trigger .el-color-picker__color-inner {
    width: 100% !important;
    height: 100% !important;
    /* 使内部颜色展示为圆形 */
}

::v-deep .el-icon-arrow-down:before {
    content: none !important;
}

.yy {
    margin-left: 12px;
    width: 100px;
    /* yy 输入框宽度固定为 40px */
}

.yy>input {
    padding: 0px 8px;
}

::v-deep .el-input-group__append {
    padding: 0px 4px;
}

::v-deep .el-button.dot {
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 2px;
    text-align: center;
}


.custom-confirm {
    font-size: 14px;
    color: #333;
}

.custom-confirm ul {
    list-style: none;
    padding: 0;
}

.custom-confirm li {
    margin-bottom: 10px;
}

.custom-confirm-box .el-message-box__content {
    padding: 20px 30px;
}

::v-deep .el-steps--simple {
    padding: 12px;
    border-radius: 0px;
}

::v-deep .el-step__title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.form-box {
    border-radius: 8px;
    background-color: #F5F5F5;
    padding: 24px;
}

::v-deep .create-step .el-step__title {
    font-size: 16px !important;
    line-height: 2.0em;
}

::v-deep .create-step .el-step__icon.is-text {
    background: #409EFF;
    border: white;
    color: white;
}

::v-deep .el-pager li {
    font-size: 16px;
    color: #666;
    font-weight: 400;
}

::v-deep .el-popconfirm__main {
    font-size: 14px;
    margin: 12px 24px;
}

.button-new {
    margin: 48px 24px;
    width: 720px;
    height: 60px;
    font-size: 20px;
    font-weight: 400
}

.steps-container-vertical {
    display: flex;
    width: 100%;
    margin-top: 24px;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
}

.steps-container {
    display: flex;
    width: calc(100vw * 2/3 - 48px);
    margin-top: 24px;
    /* align-items: center; */
    justify-content: flex-end;
    text-align: center;
    padding: 0px 24px;
}

.steps-container .view {
    display: flex;
    width: 80%;
    justify-content: center;
    text-align: center;

}

.step {
    display: inline-block;
    margin: 0 12px;
    /* 调整步骤之间的距离 */
}

.step-marker {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    /* 圆形 */
    /* 蓝色背景 */
    color: #333;
    /* 白色文字 */
    font-size: 16px;
    margin-bottom: 8px;
    border: 1px solid #AAA;
    /* 与标题的距离 */
}

.step-marker.finish {
    background-color: #FC7A6E;
    color: white;
    border: 1px solid #FC7A6E;
}

.step:hover {
    scale: 1.2;
}

.step-title {
    display: block;
    /* 确保标题独占一行 */
    color: #5e6d82;
    /* 标题的颜色 */
    font-size: 16px;
    white-space: nowrap;
    margin: 0px -24px;
}

.step-title :hover {
    color: #409EFF;
    font-weight: 500;
}

.el-divider {
    margin: 1em 0px;
    /* 分割线与步骤标记的间距 */
}

.form-step-title {
    font-size: 24px;
    font-weight: 500;
    margin: 12px 12px;
}

.source-button {
    margin: 12px 12px 24px 12px;
}

::v-deep .el-drawer__header {
    margin-bottom: 0px !important;
}

::v-deep .el-drawer__wrapper {
    z-index: 1 !important;
}

</style>