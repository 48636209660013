<template>
    <div>
        <HeaderComponent ref="headerComponent" />
        <div class="reportview">
            <div class="left" v-if="isSidebarVisible">
                <div style="padding: 12px;height: 100%;display: flex;flex-direction: column;">
                    <div v-if="isSidebarVisible" @click="toggleSidebar" style="display: flex;justify-content: end;">
                        <i class="btn-close el-icon-s-fold"></i>
                    </div>
                    <div style="height:1px;margin:16px 4px;background-color: #999;"></div>
                    <search-list-container @item-selected="onSearchItemSelected" style="width: 100%;"
                        class="search-input"></search-list-container>
                    <div style="flex: 1;padding:24px 12px 48px 12px;" class="scrollable-content">
                        <div style="font-size: 16px;color: #EEE;margin-bottom: 4px;font-weight: bold;">Recently Viewed
                        </div>
                        <div style="min-height: 120px;" class="left-viewed">
                            <div v-for="item in recentlyViewedCompanies" :key="item.symbol"
                                @click="reloadReport(item.symbol, item.companyName)">
                                {{ item.companyName }} ({{ item.symbol }})
                            </div>
                        </div>
                        <div style="height:1px;margin:16px 4px;background-color: #999;"></div>
                        <div style="font-size: 16px;color: #EEE;margin-bottom: 4px;font-weight: bold;">Related
                            Industries
                        </div>
                        <div style="height: 120px;" class="left-viewed">
                            <div v-for="company in recommendedCompanies" :key="company.symbol"
                                @click="reloadReport(company.symbol, company.name)">
                                {{ company.name }}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="sidebar-toggle" @click="toggleSidebar" :class="{ 'is-collapsed': !isSidebarVisible }">
                <div v-if="!isSidebarVisible" class="toggle-icon" :class="{ 'collapsed': !isSidebarVisible }">
                    <i class="el-icon-time" style="font-size: 32px;color: white;"></i>
                </div>
            </div>
            <div class="chat-main" v-loading="isLoading">
                <div style="height: 100%;">
                    <el-row style="height: 100%;">
                        <el-col :span="isCompareMode ? 18 : 16" style="height: calc(100% - 12px);">
                            <div class="reportView chat"
                                style="height: 100%; display: flex; flex-direction: column;padding: 0px 12px;">
                                <div
                                    style="display: flex;align-items: center;margin-left: 12px;margin-top: 24px;justify-content: center;">
                                    <img v-if="company?.profile?.logo && isLogoVisible"
                                        :class="{ 'hidden': !isLogoVisible }"
                                        style="border-radius: 99px;width: 40px;height: 40px;"
                                        :src="company?.profile?.logo" alt="Logo" class="company-logo"
                                        @error="hideLogo" />
                                    <span style="font-size: 24px;margin:0px 24px;">
                                        {{ companyName }}</span>
                                    <el-divider direction="vertical"></el-divider>
                                    <div style="margin-left: 12px;">
                                        <!-- 在对比模式时显示“Exit Compare” -->
                                        <el-button v-if="isCompareMode" icon="el-icon-close" round
                                            @click="exitCompareMode" type="info" style="background-color: #131722;">Exit
                                            Compare</el-button>
                                        <!-- 不在对比模式时显示“Compare” -->
                                        <el-button v-else icon="el-icon-plus" round @click="handleCompare"
                                            class="gradient-button">Compare</el-button>
                                    </div>


                                </div>
                                <div
                                    style="display: flex;align-items: center;justify-content: space-between;margin-top: 24px;">
                                    <div class="tab-statment">
                                        <div class="menu" :class="{ 'is-selected': currentReportType === 'all' }"
                                            @click="filterReport('all')">All Statement</div>
                                        <div class="menu" :class="{ 'is-selected': currentReportType === 'ic' }"
                                            @click="filterReport('ic')">Income Statement</div>
                                        <div class="menu" :class="{ 'is-selected': currentReportType === 'cs' }"
                                            @click="filterReport('cs')">CashFlow Statement</div>
                                        <div class="menu" :class="{ 'is-selected': currentReportType === 'bs' }"
                                            @click="filterReport('bs')">Balance Statement</div>
                                    </div>
                                    <div>
                                        <el-button size="small" :type="currentFilterType === 'all' ? 'primary' : ''"
                                            @click="filterReportByType('all')">All</el-button>
                                        <el-button size="small" :type="currentFilterType === 'y' ? 'primary' : ''"
                                            @click="filterReportByType('y')">Annual</el-button>
                                        <el-button size="small" :type="currentFilterType === 'q' ? 'primary' : ''"
                                            @click="filterReportByType('q')">Quarterly</el-button>
                                    </div>

                                </div>
                                <div
                                    :style="{ display: 'flex', flex: 1, height: 'calc(100% - 132px)', paddingBottom: '24px', flexDirection: isCompareMode ? 'row' : 'column' }">
                                    <ReporterList class="scrollable-content" ref="refreporttree"
                                        @sendPrompt="sendPrompt" :list-id="0" :is-compare-mode="isCompareMode"
                                        :style="{ flex: 1, overflowY: 'auto', marginTop: '24px', marginRight: isCompareMode ? '24px' : '0' }">
                                    </ReporterList>
                                    <!-- 当处于对比模式时，再添加一个ReporterList -->
                                    <div v-if="isCompareMode && isSearchBoxVisible" class="pk-search-container"
                                        :style="{ flex: 1, overflowY: 'auto', marginTop: '24px' }">
                                        <search-list-container @item-selected="onComopareSearchItemSelected"
                                            style="width: 100%;" class="search-input"></search-list-container>
                                    </div>
                                    <ReporterList class="scrollable-content" v-else-if="isCompareMode" :list-id="1"
                                        ref="refreporttreeCompare" @sendPrompt="sendPrompt"
                                        :is-compare-mode="isCompareMode"
                                        :style="{ flex: 1, overflowY: 'auto', marginTop: '24px' }">
                                    </ReporterList>
                                </div>

                            </div>
                        </el-col>
                        <el-col :span="isCompareMode ? 6 : 8" style="height: calc(100% - 12px)">
                            <ChatReporte ref="chat" :is-compare-mode="isCompareMode" style="height: 100%;"
                                @require-login="openLoginForm"></ChatReporte>
                        </el-col>
                    </el-row>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import axios from 'axios';
import Tools from '../GlobalTools.js';
import SearchListContainer from './animate/SearchListContainer.vue';
import ReporterList from './reporterchat/ReporterList.vue';
import ChatReporte from './reporterchat/ChatReporte.vue';
export default {
    name: 'ReporterViewer',
    components: {
        HeaderComponent,
        SearchListContainer,
        ReporterList,
        ChatReporte
    },
    created() {
        let symbol, companyName;

        if (this.$route.params.symbol && this.$route.params.name) {
            // 从 params 中获取
            symbol = this.$route.params.symbol;
            companyName = this.$route.params.name;
        } else if (this.$route.query.symbol && this.$route.query.name) {
            // 从 query 中获取
            symbol = this.$route.query.symbol;
            companyName = this.$route.query.name;
        }

        if (symbol && companyName) {
            this.reloadReport(symbol, companyName);
        } else {
            console.error('缺少必要的参数');
            // 处理参数缺失的情况，例如返回上一页或显示错误信息
            symbol = 'AAPL';
            companyName = 'Apple Inc';
            this.reloadReport(symbol, companyName);
        }
    },

    mounted() {
        this.$nextTick(() => {
            const textarea = this.$el.querySelector('.el-textarea__inner');
            textarea.style.height = '1em';
        });
    },
    data() {
        return {
            isLoading: false,
            isLogoVisible: true, // 默认Logo是可见的
            isCompareMode: false,
            isSearchBoxVisible: false, // 新增状态控制搜索框显示
            isReportEmpty: false,
            recommendedCompanies: [], // 用于存储推荐的公司列表
            isChatReplaying: false,
            isSidebarVisible: true,
            company: null,
            searchText: '',
            symbolSearch: {
                searching: false,
                searchResults: [],
                timeout: null,
                cancelToken: null,
            },
            show: true,
            companyCode: '',
            companyName: '',
            currentReportType: 'all', // 默认选中所有报表
            currentFilterType: 'all',
        };
    },
    methods: {
        hideLogo() {
            console.log('on image error hideLogo');
            this.isLogoVisible = false; // 当图片加载失败时隐藏Logo
        },
        toggleSidebar() {
            console.log('toggleSidebar');
            this.isSidebarVisible = !this.isSidebarVisible;
            this.$refs.refreporttree.updateView();
        },
        exitCompareMode() {
            this.isCompareMode = false;
            this.isSearchBoxVisible = false;
            this.filterReport('all');
            this.filterReportByType('all');
            this.$refs.refreporttree.updateView();
            if (this.$refs.refreporttreeCompare) {
                this.$refs.refreporttreeCompare.setViewEmpty();
            }
        },
        handleCompare() {
            // this.$message('Comming Soon!');
            this.isCompareMode = true;
            this.isSidebarVisible = false;
            this.isSearchBoxVisible = true;
            this.filterReport('all');
            this.filterReportByType('all');
            this.$refs.refreporttree.updateView();
            if (this.$refs.refreporttreeCompare) {
                this.$refs.refreporttreeCompare.setViewEmpty();
            }
        },
        // 修改或添加方法来处理搜索项的选中
        onComopareSearchItemSelected(searchItem) {
            if (searchItem && searchItem.symbol) {
                this.isSearchBoxVisible = false;
                this.$nextTick(() => {
                    this.fetchPKCompanyReport(searchItem.symbol, 'ALL'); // 这里假设是获取所有类型的报告
                });
            }
        },
        onSearchItemSelected(searchItem) {
            this.handleSelect(searchItem);
        },
        handleSelect(item) {
            if (item && !Tools.isEmpty(item.symbol)) {
                // console.log('handleSelect:', item);
                this.reloadReport(item.symbol, item.name);
            }
        },

        removeCompare() {
            this.isCompareMode = false;
            if (this.$refs.refreporttreeCompare) {
                this.$refs.refreporttreeCompare.setViewEmpty();
            }
        },

        async fetchCompanyReport(symbol, reportType) {
            // 这里是调用服务的逻辑
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            if (this.$refs.refreporttree) {
                this.$refs.refreporttree.setViewEmpty();
            }
            this.removeCompare();
            this.isLoading = true;
            return await axios.post(`${apiEndpoint}/getAllReportsDetail`, { symbol, reportType })
                .then(response => {
                    // 处理响应数据
                    this.company = response?.data?.company;
                    this.resetChat(symbol, '');
                    this.$refs.refreporttree.updateRepoterDetail(symbol, this.companyName, response.data);
                })
                .catch(error => {
                    // 错误处理
                    console.error('Error in retrieving report data.', error);

                }).finally(() => {
                    this.isLoading = false;
                });
        },

        //获取pk公司的数据
        async fetchPKCompanyReport(pkSymbol, reportType) {
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            this.isLoading = true;
            return await axios.post(`${apiEndpoint}/getAllReportsDetail`, { symbol: pkSymbol, reportType })
                .then(response => {
                    // 处理响应数据
                    const company = response?.data?.company;
                    this.resetChat(this.companyCode, pkSymbol);
                    this.$refs.refreporttreeCompare.updateRepoterDetail(pkSymbol, company?.name, response.data);
                })
                .catch(error => {
                    // 错误处理
                    console.error('Error in retrieving report data.', error);

                }).finally(() => {
                    this.isLoading = false;
                });
        },

        async fetchCompanyRecommend(symbol) {
            // 这里是调用服务的逻辑
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            try {
                const response = await axios.post(`${apiEndpoint}/getCompanyRecommend`, { symbol });
                this.recommendedCompanies = response.data; // 假设返回的数据是公司数组
                // console.log('fetchCompanyRecommend response:', response.data);
            } catch (error) {
                console.log('Error fetching company recommendations:', error);
                // 可以添加错误处理逻辑
            }
        },
        reloadReport(symbol, companyName) {
            this.companyName = companyName;
            this.companyCode = symbol;
            if (!Tools.isEmpty(symbol)) {
                this.fetchCompanyReport(symbol, ''); // 'desiredReportType' 替换为所需报告类型
                this.fetchCompanyRecommend(symbol);
            } else {
                this.fetchCompanyReport('AAPL', ''); // 'desiredReportType' 替换为所需报告类型
                this.companyName = 'Apple Inc';
                this.companyCode = 'AAPL';
            }
            if (symbol && companyName) {
                this.$store.dispatch('updateRecentlyViewed', { companyName, symbol });
            }
        },
        resetChat(symbol, pkSymbol) {
            console.log('resetChat:', symbol, pkSymbol);
            if (this.$refs.chat) {
                this.$refs.chat.resetChat(symbol, pkSymbol);
            }
        },

        goToCreateReport() {
            // 检查用户是否已登录
            if (this.$store.getters.isAuthenticated) {
                // 如果已登录，跳转到 /create 页面
                this.$router.push('/create');
            } else {
                // 如果未登录，打开登录对话框
                this.openLoginForm;
            }
        },
        openLoginForm() {
            this.$refs.headerComponent.openLoginForm();
        },
        handleNodeClick(data) {
            console.log(data);
        },


        getDefaultCheckedKeys(data) {
            const keys = [];
            function traverse(node) {
                if (node.checked) {
                    keys.push(node.id);
                }
                if (node.children && node.children.length > 0) {
                    node.children.forEach(child => traverse(child));
                }
            }
            data.forEach(node => traverse(node));
            return keys;
        },
        // 通用的更新视图逻辑
        updateView(refName) {
            this.$nextTick(() => {
                const treeWrapper = this.$refs[refName]?.$el;
                if (treeWrapper && this.filteredTreeData && this.filteredTreeData.length > 0) {
                    const firstNode = treeWrapper.querySelector('.el-tree-node');
                    if (firstNode) {
                        firstNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }
            });
        },

        // 重构后的filterReport函数
        filterReport(type) {
            this.currentReportType = type; // 更新当前选中的报表类型
            this.$refs.refreporttree.filterReport(type);
            this.updateView('refreporttree');
            if (this.$refs.refreporttreeCompare) {
                this.$refs.refreporttreeCompare.filterReport(type);
                this.updateView('refreporttreeCompare');
            }
        },

        // 重构后的filterReportByType函数
        filterReportByType(aqType) {
            this.currentFilterType = aqType; // 更新当前的筛选类型
            this.$refs.refreporttree.filterReportByType(aqType);
            this.updateView('refreporttree');
            if (this.$refs.refreporttreeCompare) {
                this.$refs.refreporttreeCompare.filterReportByType(aqType);
                this.updateView('refreporttreeCompare');
            }
        },
        sendPrompt(prompt, data) {
            this.$refs.chat.sendPrompt(prompt, data);
        },
    },

    computed: {

        reportData() {
            return this.$route.query.symbol
        },
        recentlyViewedCompanies() {
            // 获取并返回最近浏览的公司信息
            return this.$store.getters.getRecentlyViewed;
        },
        selectedLanguage() {
            return this.$store.getters.getSelectedLanguage;
        },
    }

};
</script>

<style scoped>
.el-scrollbar::-webkit-scrollbar {
    width: 0;
    background: transparent;
    /* 设置滚动条背景为透明 */
}

.el-scrollbar {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 11 */
}

.el-scrollbar::scrollbar {
    display: none;
    /* 对于支持的未来浏览器版本 */
}

.hidden {
    display: none;
}

.reportview {
    display: flex;
    height: calc(100vh - 92px);
}

.reportview .left {
    width: 320px;
    height: 100%;
    background: #252525;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    float: left;
}


.tab-statment {
    display: flex;
    align-items: center;
    padding: 0px 12px
}

.tab-statment div.menu {
    margin-right: 12px;
    border-radius: 99px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #f0f3fa;
    font-weight: 400;
    font-size: 14px;
}

.tab-statment div.menu:hover {
    background-color: #131722;
    color: #FFF;
}

.tab-statment div.menu.is-selected {
    background-color: #131722;
    color: #FFF;
}


.scrollable-content {
    height: 100%;
    overflow-y: scroll;
    /* 总是显示滚动条 */
    /* 确保内容不会被滚动条遮挡 */
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE和Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.scrollable-content:hover {
    overflow-y: scroll;
}

.btn-close {
    font-size: 24px;
    color: white;
    font-weight: 300;
    cursor: pointer;
}

.btn-close:hover {
    scale: 1.1;
}

.sidebar-toggle {
    width: 60px;
    cursor: pointer;
    position: relative;
    /* 设置相对定位，使得子元素可以绝对定位于此元素内 */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    /* 按钮的背景颜色 */
}

/* 竖线的样式 */
.toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -120px;
    width: 48px;
    height: 48px;
    /* 可以根据需要调整高度 */
    background-color: #333;
    position: absolute;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: all 0.3s;
    /* 动画效果 */
}

/* 当侧边栏被收起时改变竖线的样式 */
.sidebar-toggle.is-collapsed .toggle-icon {
    background-color: #333;
    /* 改变颜色 */
}

/* 鼠标悬停时竖线的样式 */
.sidebar-toggle:hover .toggle-icon {
    background-color: #1457B1;
}

.report-title {
    color: #333;
}


.split-container {
    height: calc(100vh - 120px);
    overflow: hidden;
    background-color: #FFF;
    border-radius: 12px;
    margin-right: 12px;
    padding-bottom: 12px;
    position: relative;
    /* 为伪元素定位 */
}

/* 创建一个伪元素来作为滚动条背景 */
.split-container::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    /* 滚动条的宽度 */
    height: 100%;
    background: transparent;
    /* 伪滚动条的背景颜色 */
    pointer-events: none;
    /* 确保不会干扰滚动 */
}


.reportview ::v-deep .splitter-pane-resizer:hover {
    width: 8px;
    background-color: #F5F5F5;
    /* 鼠标悬停时改变颜色 */
}

::v-deep .left-viewed div {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #EEE;
    cursor: pointer;
    padding: 4px 12px;
    white-space: nowrap;
    /* 禁止文本换行 */
    overflow: hidden;
    /* 隐藏超出部分的内容 */
}

::v-deep .left-viewed div:hover {
    background: #999;
    border-radius: 4px;
}

::v-deep .el-empty p {
    font-size: 16px !important;
}

.loading-icon {
    width: 24px;
    /* 正方形的尺寸 */
    height: 24px;
    color: white;
    /* 正方形的背景颜色 */
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #666;
    background-color: #666;

}

.loading-icon:hover {
    background-color: #EEE;
    color: #666;
}

.loading-icon span {
    font-size: 14px;
}


::v-deep .search-input input {
    font-size: 14px;
    color: #EEE;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.15);
}

::v-deep .search-input .el-input__inner {
    border-radius: 12px !important;
}

::v-deep .search-input .el-autocomplete-suggestion {
    z-index: 200;
    /* 一个足够高的值 */
}

::v-deep .pk-search-container .search-input input {
    font-size: 14px;
    color: #333;
    background: #F5F5F5;
    border: 1px solid #EEE;
}

::v-deep .pk-search-container .search-input .el-input__inner {
    border-radius: 4px !important;
    height: 56px;
}

.gradient-button {
    background-image: linear-gradient(to right, #42b3f4, #a776c8);
    border: none;
    font-size: 16px;
    color: white;
}

.gradient-button:hover {
    transform: scale(1.1);
}
</style>
