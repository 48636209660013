<template>
    <el-container>
        <el-aside min-width="240px" style="border-right:2px solid #EEE;padding-top: 24px;">
            <div style="display: flex;flex-direction: column;width: 80%;margin-left: 12px;margin-top: 24px;">
                <el-button class="btn-left" @click="showRecent" :autofocus=true>All Companies ({{ totalRecent
                }})</el-button>
                <el-button class="btn-left" @click="showPublic">Public Companies({{ totalPublic }})</el-button>
                <el-button class="btn-left" @click="showPrivate">Private Companies({{ totalPrivate }})</el-button>
                <el-button class="btn-left" @click="showWaiting">In Review ({{ totalWaiting }})</el-button>
            </div>
        </el-aside>
        <el-container class="mycompany-form">
            <el-main>
                <div style="padding-top: 24px;">
                    <Span class="title">Create New</Span>
                    <div style="display: flex;flex-direction: row;">
                        <el-card shadow="always" class="company-type" body-style="padding:0px"
                            :class="{ 'disabled': !vip }">
                            <div class="card" @click="vip && CreateNewCompany('LC')" style="color: #FA8C35;">
                                <i class="el-icon-data-analysis icon"></i>
                                <span style="font-size: 24px;">Public Company</span>
                                <span style="font-size: 16px;margin-top: 12px;">Visible to All Users</span>
                                <span style="font-size: 16px;margin-top: 12px;color: #aaa;">Joining the Team.</span>
                            </div>
                        </el-card>

                        <el-card shadow="always" class="company-type" body-style="padding:0px">
                            <div class="card" @click="CreateNewCompany('NLC')" style="color: #6A7AAD;">
                                <i class="el-icon-data-board icon"></i>
                                <span style="font-size: 24px;">Private Company</span>
                                <span style="font-size: 16px;margin-top: 12px;">Visible Only to Creator</span>
                                <span style="font-size: 16px;margin-top: 12px;color: #aaa;">Create Private
                                    Reports</span>
                            </div>
                        </el-card>
                    </div>
                </div>
                <div style="display: flex;flex-direction: row;align-items: center;padding-bottom: 12px;">
                    <Span class="title">My Companies</Span>
                </div>
                <div style="flex: 1;padding:0px 24px;">
                    <el-table :data="userCompanies" @row-click="handleCompany">
                        <el-table-column prop="name" label="Company Symbol" sortable>
                            <template slot-scope="scope">
                                <!-- 如果来源是 papermoney.ai，则显示星星图标 -->
                                <div style="display: flex;align-items: center;">
                                    <img class="icon-image" :src="getCheckedIcon()">
                                    {{ scope.row.symbol }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="Company Name" sortable></el-table-column>
                        <el-table-column prop="last_modified" label="Last Modified" sortable></el-table-column>
                        <el-table-column label="Public or Private" sortable :sort-method="publicValueForSorting">
                            <template slot-scope="scope">
                                <div :style="{ color: scope.row.public ? '#269322' : '#CC3333' }">
                                    {{ scope.row.public ? 'Public Report' : 'Private Report' }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-main>
        </el-container>
        <el-dialog class="dialog-newcompany" :visible.sync="showDialogPublic" title="Create Public Company" center
            :close-on-click-modal="false">
            <span class="title">Search Public Company Ticker or Name First</span>
            <search-list-container :showAddNew="true" @add-new-company="handleAddNewPublicCompany"
                @update:searchText="updatePublicSearchText" @update:searchResults="updatePublicSearchResults"
                @item-selected="handleSelectPublic" style="width: 100%;" class="search-input"></search-list-container>
            <!-- <div style="display: flex;align-items: center;justify-content: center;margin-top: 24px;">
                <el-button type="primary" :disabled="!canCreatePublicCompany" @click="handleAddNewPublicCompany">{{
                    publicCompanyInput ? `Create New Private
                    Company
                    For ${publicCompanyInput}` : `Input Company Ticker or Name First` }}</el-button>
            </div> -->
        </el-dialog>
        <el-dialog class="dialog-newcompany" :visible.sync="showDialogPrivate" title="Create Private Company" center
            :close-on-click-modal="false">
            <span class="title">Input Private Company Name</span>
            <el-autocomplete class="inline-input" v-model="privateCompanyInput" :fetch-suggestions="querySearchPrivate"
                placeholder="Input Company Name" @select="handleSelectPrivate">
                <template slot-scope="{ item }">
                    <div v-if="item.isAddNew" class="add-new-item">
                        <i class="el-icon-plus"></i> <!-- 你可以选择一个合适的图标 -->
                        <span>{{ item.value }}</span>
                    </div>
                    <div v-else class="autocomplete-item">
                        <!-- 你的普通条目的展示方式 -->
                        <span>{{ item.value }}</span>
                    </div>
                </template>
            </el-autocomplete>
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 24px;">
                <el-button type="primary" :disabled="!canCreateCompany" @click="handleAddNewPrivateCompany">{{
                    privateCompanyInput ? `Create New Private Company
                    For ${privateCompanyInput}` : `Input Company Name First` }}</el-button>
            </div>
        </el-dialog>

    </el-container>
</template>

<script>
import axios from 'axios';
import SearchListContainer from '../animate/SearchListContainer.vue';

export default {
    name: 'MyCompanyForm',
    components: {
        SearchListContainer
    },
    mounted() {
        this.getUserCompanies();
    },
    data() {
        return {
            searching: false,
            publicCompanyInput: '', // 用于绑定到el-autocomplete的输入
            searchPublicCompanyResults: [],
            privateCompanyInput: '', // 用于绑定到el-autocomplete的输入
            showDialogPublic: false,
            showDialogPrivate: false,
            vip: false,
            companyType: '',
            allCompanies: [],
            userCompanies: [],
            privateCompanies: [],
            totalRecent: 0,   // 总数量
            totalPublic: 0,   // 公开公司数量
            totalPrivate: 0,  // 私有公司数量
            totalWaiting: 0   // 等待中的数量
        }
    },
    computed: {
        canCreateCompany() {
            return this.privateCompanyInput.length > 0
        },
        canCreatePublicCompany() {
            return this.publicCompanyInput.length > 0 && this.searchPublicCompanyResults.length === 0;
        }
    },
    methods: {
        handleCompany(row, column, event) {
            console.log(row, column, event);
            var data = { symbol: row.symbol, name: row.name, public: row.public };
            this.$emit('showreports-by-symbol', data);
        },
        getCheckedIcon() {
            return require('@/assets/serch-icon.png');
        },
        getUserCompanies() {
            this.searching = true;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.post(`${apiEndpoint}/getCompaniesByUser`, {/* Your data here */ })
                .then(response => {
                    this.allCompanies = response.data?.companies || [];
                    this.userCompanies = [...this.allCompanies];
                    this.vip = response.data?.vip || false;
                    // 更新不同类型的数量
                    this.totalRecent = this.allCompanies.length;
                    this.totalPublic = this.allCompanies.filter(company => company.public === true).length;
                    this.totalPrivate = this.allCompanies.filter(company => company.public === false).length;
                    this.privateCompanies = this.allCompanies.filter(company => company.public === false);
                    this.totalWaiting = 0; // 'Waiting' 类型的数量，根据您的逻辑决定如何计算

                    this.searching = false;
                })
                .catch(error => {
                    // 错误处理
                    console.log('error', error);
                    this.searching = false;
                });
        },
        CreateNewCompany(type) {
            this.companyType = type;
            this.showDialogPublic = type === 'LC';
            this.showDialogPrivate = type === 'NLC';

            // var showCompanies = this.allCompanies.filter(company => (type === 'LC' ? company.public === true : company.public === false));
            // var data = { type: type, compaines: showCompanies };
            // this.$emit('create-new-company', data);
        },
        showRecent() {
            this.userCompanies = [...this.allCompanies]; // 显示所有公司
        },
        showPublic() {
            this.userCompanies = this.allCompanies.filter(company => company.public === true);
        },
        showPrivate() {
            this.userCompanies = this.allCompanies.filter(company => company.public === false);
        },
        showWaiting() {
            this.userCompanies = []; // 显示空数组
        },
        updatePublicSearchText(value) {
            this.publicCompanyInput = value;
        },
        updatePublicSearchResults(results) {
            console.log('updatePublicSearchResults', results);
            this.searchPublicCompanyResults = results;
        },
        handleAddNewPublicCompany() {
            console.log('handleAddNewPublicCompany', this.publicCompanyInput);
            this.showDialogPublic = false;
            var symbol = this.publicCompanyInput;
            var data = { symbol: symbol, name: symbol, public: true, newCompany: true };
            this.$emit('showreports-by-symbol', data);
        },
        publicValueForSorting(company) {
            return company.public ? 1 : 0;
        },
        handleSelectPublic(item) {
            this.showDialogPublic = false;
            var data = { symbol: item.symbol, name: item.name, public: this.companyType === 'LC', newCompany: false };
            console.log('handleSelectPublic', data,this.companyType);
            this.$emit('showreports-by-symbol', data);
        },
        getNewPrivateSymbol(code) {
            const email = this.$store.getters.userEmail;
            let hash = 0;
            for (let i = 0; i < email.length; i++) {
                hash = (hash << 5) - hash + email.charCodeAt(i);
                hash = hash & hash; // Convert to 32bit integer
            }
            hash = Math.abs(hash); // Ensure it's a positive number
            const shortHash = hash.toString(36); // Convert to base-36 for a shorter string
            // console.log('shortHash', shortHash);
            return `${code}.${shortHash}`;
        },
        handleAddNewPrivateCompany() {
            console.log('handleAddNewPrivateCompany', this.privateCompanyInput);
            this.showDialogPrivate = false;
            var data = { symbol: this.getNewPrivateSymbol(this.privateCompanyInput), name: this.privateCompanyInput, public: false, newCompany: true };
            this.$emit('showreports-by-symbol', data);
        },
        handleSelectPrivate(item) {
            var data = {};
            this.showDialogPrivate = false;
            if (item.isAddNew) {
                // 用户选择了新增公司的选项
                // 从 privateCompanyInput 中提取原始输入的公司名称
                const prefix = `Create New Private Company For "`;
                const originalInput = this.privateCompanyInput.slice(prefix.length, -1); // 去除前缀和最后的引号
                this.privateCompanyInput = originalInput;
                console.log('Add new private company:', this.privateCompanyInput);
                data = { symbol: this.getNewPrivateSymbol(this.privateCompanyInput), name: this.privateCompanyInput, public: false, newCompany: true };
                // 这里可以添加处理新增非上市公司的逻辑
            } else {
                // 这里可以添加处理选中非上市公司的逻辑
                var report = this.privateCompanies.find(company => company.name === item.value);
                data = { symbol: report.symbol, name: report.name, public: false, newCompany: false };
            }
            this.$emit('showreports-by-symbol', data);
        },
        querySearchPrivate(queryString, cb) {
            let results = [];
            if (queryString) {
                results = this.privateCompanies.filter(company => {
                    return company.name.toLowerCase().includes(queryString.toLowerCase());
                }).map(company => {
                    return { value: company.name }; // 确保每个对象都有一个 'value' 属性
                });
            } else {
                results = this.privateCompanies.map(company => {
                    return { value: company.name };
                });
            }
            if (queryString.length > 0 && !results.length) {
                // 在第一行增加“新增**公司”的条目
                const addNewItem = {
                    value: `Create New Private Company For "${queryString}"`,
                    isAddNew: true
                };
                results.unshift(addNewItem);
            }
            cb(results);
        }
    }
}
</script>

<style scoped>
.btn-left {
    margin: 12px 0px;
    display: flex;
    font-size: 16px;
    border: none;
}

.mycompany-form {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
}

.icon-image {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin: 24px 48px 12px 48px;
}

.box-card {
    margin: 24px 0px;
    font-size: 16px;
}


.company-type {
    margin: 24px;
    border-radius: 24px;
    transition: transform 0.3s ease;
    /* 添加平滑的过渡效果 */
}

.company-type:not(.disabled):hover {
    transform: scale(1.1);
    /* 确保整个卡片放大，包括边框和阴影 */
}


.company-type.disabled {
    opacity: 0.5;
    pointer-events: none;
    /* 禁用鼠标事件 */
}

.search-input{
    margin-bottom: 24px;
}

.company-type .card {
    width: 240px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px;
}

.dialog-newcompany .title {
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin: 24px 0px;
}

::v-deep .dialog-newcompany .el-dialog__body {
    padding: 24px;
    display: flex;
    flex-direction: column;
}


.company-type .card .icon {
    font-size: 40px;
    margin-bottom: 12px;
}

.add-new-item {
    /* 为新增按钮添加的样式 */
    color: #3489FF;
    /* 示例颜色 */
    display: flex;
    align-items: center;
    font-weight: bold;
}

.autocomplete-item {
    /* 你的普通条目的样式 */
    display: flex;
    align-items: center;
}

/* 调整图标样式 */
.el-icon-plus {
    margin-right: 8px;
}
</style>