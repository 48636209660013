<template>
    <div>
        <HeaderComponent></HeaderComponent>
        <div class="clistview">
            <div class="left" v-if="isSidebarVisible">
                <div style="margin-top: 24px; padding: 12px;" class="scrollable-content">
                    <div style="color: #AAA;text-align: center;">Company Industries</div>
                    <el-divider></el-divider>
                    <ul class="industry-list">
                        <li v-for="industry in industries" :key="industry.name" @click="selectIndustry(industry.name)"
                            :class="{ 'active': selectedIndustry === industry.name }">
                            <i :class="industry.icon"></i>
                            {{ industry.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="sidebar-toggle" @click="toggleSidebar" :class="{ 'is-collapsed': !isSidebarVisible }">
                <div class="toggle-icon" :class="{ 'collapsed': !isSidebarVisible }"></div>
            </div>
            <div class="list-main">
                <div style="display: flex;align-items: center;padding-top: 12px;">
                    <div style="margin-top: 4px;"><search-button-list-container @toggle-expand="toggleSearchExpand"
                            @item-selected="onSearchItemSelected"></search-button-list-container></div>
                    <div class="tab-container" v-if="!isSearchExpanded">
                        <div @click="selectMarket('ALL')" :class="{ active: selectedMarket === 'ALL' }"><img
                                :src="require('@/assets/logo.png')" style="width: 20px;height: 20px;">All Listed</div>
                        <div @click="selectMarket('US')" :class="{ active: selectedMarket === 'US' }"><img
                                :src="require('@/assets/location/NQ.png')" style="width: 24px;height: 24px;">US Listed
                        </div>
                        <div @click="selectMarket('HK')" :class="{ active: selectedMarket === 'HK' }"><img
                                :src="require('@/assets/location/HK.png')" style="width: 20px;height: 20px;">HK Listed
                        </div>
                        <div @click="selectMarket('MLCN')" :class="{ active: selectedMarket === 'MLCN' }"><img
                                :src="require('@/assets/location/CN.png')" style="width: 24px;height: 24px;">MainLand
                            China Listed
                        </div>
                    </div>
                </div>
                <div style="margin:12px 12px 8px 12px;height: 1px;background-color: #EEE;"></div>
                <div class="company-list scrollable-content" style="height: calc(100% - 64px);" v-loading="isLoading">
                    <el-table :data="companyList" style="width: 100%" height="100%"
                        :header-cell-style="{ fontSize: '14px', fontWeight: '500' }" @row-click="openReportViewer">
                        <el-table-column label="Ticker" width="160">
                            <template slot-scope="scope">
                                <a :href="getReportViewerLink(scope.row)" target="_blank" class="no-underline">
                                    <div style="display: flex; align-items: center;">
                                        <div class="company-logo-container"
                                            v-if="scope.row.profile && scope.row.profile.logo">
                                            <img :src="scope.row.profile.logo" alt="Logo" class="company-logo" />
                                        </div>
                                        <div class="company-initials" v-else>
                                            {{ scope.row.symbol ? getFirstLetter(scope.row.name) : '' }}
                                        </div>
                                        {{ scope.row.symbol }}
                                    </div>
                                </a>
                            </template>
                        </el-table-column>
                        <el-table-column width="320" prop="name" label="Company Name"></el-table-column>
                        <el-table-column width="120" prop="profile.market" label="Market"
                            :formatter="formatMarket"></el-table-column>
                        <el-table-column width="200" prop="industry" label="Industry"></el-table-column>
                        <el-table-column width="200" prop="profile.companyTags" label="Company Tags"></el-table-column>
                        <el-table-column width="200" prop="last_modified" label="Last Modified Date"
                            :formatter="formatDateToDay" sortable :sort-method="sortByDate"></el-table-column>

                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import Global from '../GlobalValues.js';
import axios from 'axios';
import SearchButtonListContainer from './animate/SearchButtonListContainer.vue';
export default {
    name: 'CompanyListForm',
    components: {
        HeaderComponent,
        SearchButtonListContainer,
    },
    created() {
        // 检查外部参数并发起初始请求
        this.selectedMarket = this.$route.query.market || 'ALL';
        this.selectedIndustry = this.$route.query.tag || 'ALL';
        this.orderBy = this.$route.query.orderBy || '';
        console.log('Initial market:', this.selectedMarket, this.selectedIndustry);
        this.getCompanyList(this.selectedMarket, this.selectedIndustry);
    },
    mounted() {
        // 添加滚动监听
        // window.addEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            isSidebarVisible: true,
            isSearchExpanded: false,
            isLoading: false,
            selectedMarket: 'US', // 默认选中的市场
            selectedIndustry: null, // 添加选中行业的变量
            orderBy: '', // 默认按照最后修改日期排序
            industries: [{
                name: 'ALL',
                icon: 'el-icon-menu',
                gradientStart: '#FFD700',
                gradientEnd: '#FF8C00',
                description: 'Covers all the industries'
            }, ...Global.companyIndustries], // 行业标签数据
            currentPage: 1,
            pageSize: 10, // 假设每页显示10条数据
            totalPages: 0, // 总页数
            companyList: [], // 存储公司数据】
        };
    },
    methods: {
        openReportViewer(company) {
            console.log('Open report viewer:', company);
            const routeData = this.$router.resolve({
                name: 'ReportViewer',
                query: {
                    name: company.name,
                    symbol: company.symbol
                }
            });
            window.open(routeData.href, '_blank');
        },
        getReportViewerLink(company) {
            const routeData = this.$router.resolve({
                name: 'ReportViewer',
                query: {
                    name: company.name,
                    symbol: company.symbol
                }
            });
            return routeData.href;
        },
        toggleSidebar() {
            this.isSidebarVisible = !this.isSidebarVisible;
        },
        toggleSearchExpand(expended) {
            this.isSearchExpanded = expended;
        },
        onSearchItemSelected(item) {
            this.openReportViewer({ symbol: item.symbol, name: item.name });
        },
        selectMarket(market) {
            this.selectedMarket = market;
            this.getCompanyList(this.selectedMarket, this.selectedIndustry);
        },
        selectIndustry(industry) {
            this.selectedIndustry = industry;
            // 在这里可以添加加载不同行业公司数据的逻辑
            this.getCompanyList(this.selectedMarket, this.selectedIndustry);
        },
        getFirstLetter(string) {
            return string ? string.charAt(0) : '';
        },
        getCompanyList(market = '', industry = '', page = 1) {
            this.isLoading = true;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            this.companyList = [];
            var industryId = Global.getIndustryIdByName(industry);
            industryId = industryId ? industryId : industry;
            axios.post(`${apiEndpoint}/getCompanyListByMarketIndustry`, { market, industry: industryId, page, limit: this.pageSize })
                .then(response => {
                    // 假设服务器返回数据格式为：{ data: [], total: 100 }
                    this.companyList = response.data.companies.map(company => {
                        if (!company.last_modified || isNaN(new Date(company.last_modified).getTime())) {
                            company.last_modified = '2023-09-30';  // 设置默认值
                        }
                        return company;
                    });
                    // 如果this.orderBy有效且等于'modified'，则按照last_modified排序
                    if (this.orderBy && this.orderBy === 'last_modified') {
                        this.companyList.sort((a, b) => {
                            // 将日期字符串转换为日期对象，以便进行比较
                            let dateA = new Date(a.last_modified).getTime();
                            let dateB = new Date(b.last_modified).getTime();

                            return dateB - dateA; // 升序排序
                            // 如果你想要降序排序，可以使用 return dateB - dateA;
                        });
                    }
                    this.totalPages = Math.ceil(response.data.total / this.pageSize); // 计算总页数
                    this.currentPage = page;
                    this.isLoading = false;
                    console.log('Company list:', this.companyList);
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.isLoading = false;
                });
        },
        handleScroll() {
            // 检查用户是否接近底部
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) {
                // 只有在不是最后一页且当前没有加载数据时才加载下一页
                if (this.currentPage < this.totalPages && !this.isLoading) {
                    this.getCompamiList(this.selectedMarket, this.selectedIndustry, this.currentPage + 1);
                }
            }
        },
        formatPercentage(row, column, cellValue) {
            // 将小数转换为百分比，并保留两位小数
            const result = (cellValue * 100).toFixed(2) + '%';
            return result;
        },
        formatMarket(row, column, cellValue) {
            switch (cellValue) {
                case 'US':
                    return 'United States';
                case 'HK':
                    return 'Hong Kong';
                case 'MLCN':
                    return 'Mainland China';
                default:
                    return cellValue;
            }
        },
        formatDateToDay(row, column, dateString) {
            if (dateString) {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            } else {
                return '';
            }
        },
        sortByDate(a, b) {
            const dateA = a.last_modified ? new Date(a.last_modified) : null;
            const dateB = b.last_modified ? new Date(b.last_modified) : null;

            if (dateA === null && dateB === null) {
                return 0;
            } else if (dateA === null) {
                return 1;  // 将空值排在后面
            } else if (dateB === null) {
                return -1; // 将空值排在后面
            } else {
                return dateA - dateB;
            }
        }
    },
    beforeDestroy() {
        // 移除滚动监听
        // window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>


<style scoped>
.no-underline {
    text-decoration: none;
    /* 移除下划线 */
    color: #333;
}

.clistview {
    display: flex;
    height: 100%;
}

.clistview .left {
    width: 320px;
    background: #252525;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    float: left;
    height: calc(100vh - 80px);
}


.sidebar-toggle {
    width: 20px;
    cursor: pointer;
    position: relative;
    /* 设置相对定位，使得子元素可以绝对定位于此元素内 */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    /* 按钮的背景颜色 */
}

/* 竖线的样式 */
.toggle-icon {
    margin-top: -120px;
    width: 8px;
    height: 48px;
    /* 可以根据需要调整高度 */
    background-color: #333;
    position: absolute;
    border-radius: 4px;
    transition: all 0.3s;
    /* 动画效果 */
}

/* 当侧边栏被收起时改变竖线的样式 */
.sidebar-toggle.is-collapsed .toggle-icon {
    background-color: #333;
    /* 改变颜色 */
}

/* 鼠标悬停时竖线的样式 */
.sidebar-toggle:hover .toggle-icon {
    width: 12px;
    border-radius: 6px;
    background-color: #1457B1;
    /* 改变颜色 */
}


.industry-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    /* 如果内容超出则显示滚动条 */
    height: 100%;
    /* 确保列表占满整个左侧栏高度 */
}

.industry-list li {
    cursor: pointer;
    padding: 12px 16px;
    /* 增加填充以便于点击和阅读 */
    border-radius: 4px;
    /* 增加上下外边距 */
    display: flex;
    align-items: center;
    color: #fff;
    /* 设置文字颜色为白色以增强对比度 */
    transition: background-color 0.3s;
    font-size: 14px;
}

.industry-list li:hover {
    background-color: #555;
    /* 悬停时背景颜色变化 */
}

.industry-list li.active {
    background-color: #007bff;
    /* 激活状态的背景颜色 */
    color: #fff;
    /* 激活状态的文字颜色 */
    font-weight: bold;
    /* 激活状态的文字加粗 */
}

.industry-list li i {
    margin-right: 12px;
    /* 增加图标和文字之间的间距 */
    min-width: 24px;
    /* 确保所有图标宽度一致 */
    text-align: center;
    /* 图标居中对齐 */
    font-size: 1.2em;
    /* 统一图标大小 */
}

/* 隐藏滚动条但仍然可以滚动 */
.scrollable-content::-webkit-scrollbar {
    display: none;
}

.scrollable-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* 你可以根据实际需要调整样式 */

.clistview .list-main {
    display: flex;
    flex-direction: column;
    /* 垂直排列 */
    /* height: 100%; */
    margin-left: 12px;
    width: calc(100% - 12px);
}

.tab-container {
    margin-left: 12px;
    display: flex;
}

.tab-container div {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    margin-right: 8px;
    height: 32px;
    /* 更大的内边距 */
    cursor: pointer;
    transition: all 0.3s ease;
    /* 过渡效果使点击和悬停看起来更平滑 */
    color: #666;
    font-size: 16px;
}

.tab-container div:hover {
    background-color: #F5F5F5;
    /* 悬停时的背景色 */
    border-radius: 99px;
    color: #666;
    font-size: 18px;
}

.tab-container .active {
    background-color: #F1F1F1;
    border-radius: 99px;
    font-weight: 500;
    color: #333;
    font-size: 18px;
}

.tab-container div img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.company-list {
    overflow-y: auto;
    /* 添加垂直滚动条 */
    height: 100%;
    /* 确保占满其容器高度 */
}

.company-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.company-logo {
    width: 30px;
    /* 可以根据需要调整大小 */
    height: 32px;
    /* 可以根据需要调整大小 */
    border-radius: 50%;
    margin-right: 12px;
}

.company-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f0f0f0;
    /* 可以根据需要调整颜色 */
    color: #333;
    /* 可以根据需要调整文字颜色 */
    font-size: 16px;
    /* 可以根据需要调整文字大小 */
    margin-right: 12px;
}
</style>
