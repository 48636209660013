<template>
  <div>

    <div :id="`sankeyDiagram-${containerId}`"></div>

  </div>
</template>
  
<script>
import * as d3 from 'd3'
import { sankey, sankeyLinkHorizontal, sankeyCenter } from 'd3-sankey';
import Tools from '../../GlobalTools'
export default {
  // ... other options ...
  name: 'CustomerSankey',
  props: {
    sankeyData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      containerId: 'income',
      picWidth: 4296,
      picHeight: 2416,
      logoSrc: require('@/assets/logo.png')
    }
  },

  methods: {

    drawSankey(data) {
      // console.log('Drawing sankey diagram,', this.sankeyData);
      this.drawChart(data);
    },
    updateSankeyData() {
      // Update your sankeyData based on the Vue component's form data
      this.drawSankey(this.sankeyData);
    },

    wrapText(text, node) {
      const maxTextWidth = 640; // 设置最大文本宽度
      const maxLines = 2; // 最多显示行数
      let words = text.split(/\s+/);
      let lines = [];
      let lineHeight = 48; // 行高
      let tempText = node.append('text')
        .attr('visibility', 'hidden')
        .attr('font-size', `${lineHeight}px`);

      let currentLine = '';
      let testWidth;

      words.forEach((word) => {
        let testLine = currentLine + word + " ";
        tempText.text(testLine);
        testWidth = tempText.node().getComputedTextLength();
        if (testWidth > maxTextWidth && currentLine !== '') {
          lines.push(currentLine.trim());
          currentLine = word + " ";
        } else {
          currentLine = testLine;
        }
        // 为了避免一个单词超过最大宽度导致的无限循环
        if (currentLine.trim() && tempText.text(currentLine).node().getComputedTextLength() > maxTextWidth) {
          currentLine = currentLine.trim().slice(0, -word.length) + '...';
        }
      });

      if (lines.length < maxLines) {
        lines.push(currentLine.trim());
      }

      if (lines.length === maxLines && tempText.text(lines[lines.length - 1]).node().getComputedTextLength() > maxTextWidth) {
        let lastLine = lines[maxLines - 1];
        tempText.text(lastLine + '...');
        while (tempText.node().getComputedTextLength() > maxTextWidth && lastLine.length > 1) {
          lastLine = lastLine.slice(0, -4) + '...'; // 减去字符再加上省略号
          tempText.text(lastLine);
        }
        lines[maxLines - 1] = lastLine;
      }

      tempText.remove();
      return lines.map(line => ({ text: line, height: lineHeight, width: testWidth }));
    },

    drawChart(data) {
      // 获取容器元素
      let parentId = data.containerId ? data.containerId : '';
      if (parentId) {
        this.containerId = 'income' + parentId;
        this.$nextTick(() => {
          // console.log('Income drawChart', this.containerId);
          this.drawIncomeChart(data);
        });
      } else {
        this.drawIncomeChart(data);
      }

    },

    drawIncomeChart(data) {
      // console.log('drawChart title:', data.title);
      var unit = data.unit;
      var currency = data.currency;
      var unit_t = data.unit_t;
      // Append a background rect to the SVG


      const titleHeight = 120; // Height of the title area
      const titleMargin = 240; // Margin around the title and icon


      var nodeWidth = 80;
      var nodePadding = 260;

      var chartName = data.chatName ? data.chatName : data.symbol;
      var svgParent = `#sankeyDiagram-${this.containerId}`;
      const svgId = `svg-${chartName}`;
      d3.select(`${svgParent} svg`).remove();
      const svg = d3.select(`${svgParent}`).append('svg')
        .attr('id', svgId)
        .attr('width', this.picWidth)
        .attr('height', this.picHeight);

      // Append a background rect to the SVG
      svg.append('rect')
        .attr('width', this.picWidth)
        .attr('height', this.picHeight)
        .attr('fill', '#F5F5F5');

      // Add an icon to the title group



      // 创建包含标题的组
      const titleGroup = svg.append('g')
        .attr('transform', `translate(${this.picWidth / 2}, ${titleMargin})`);

      // let titleWidth = 0;
      if (!Tools.isEmpty(data.title)) {
        // 先添加标题文本
        titleGroup.append('text')
          .attr('x', 0)
          .attr('y', titleHeight * 1 / 3)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .attr('font-size', '120px')
          .attr('fill', '#444444')
          .attr('font-weight', 'bold')
          .attr('font-family', 'Times New Roman')
          .text(data.title);

      }

      if (data.subtitle) {
        // 添加副标题
        titleGroup.append('text')
          .attr('x', 0) // 水平居中
          .attr('y', titleHeight + 32) // 在主标题下方
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'middle') // 文本中心对齐
          .attr('font-size', '48px') // 副标题字体大小，可以根据需要调整
          .attr('fill', '#aaa') // 副标题字体颜色，可以根据需要调整
          .text(data.subtitle); // 动态副标题
      }

      //底部添加官方网址
      // 首先，确定 text 的大小（根据字体大小和内容大致估计）
      const textSizeEstimate = 680; // 这个值可能需要调整

      // 计算 text 和 logo 的位置
      const xPosition = this.picWidth / 2;
      const logoWidth = 100; // 假设 logo 宽度为100，根据实际情况调整
      const totalWidth = textSizeEstimate + logoWidth + 80; // text 宽度 + logo 宽度 + 80px 间距

      // 计算 logo 的位置
      const logoX = xPosition - totalWidth / 2 - 88;
      const logoY = this.picHeight - logoWidth - 40; // 假设 logo 高度和宽度相同

      // 添加 logo
      svg.append('image')
        .attr('x', logoX)
        .attr('y', logoY)
        .attr('width', logoWidth)
        .attr('height', logoWidth)
        .attr('href', this.logoSrc); // 使用从 data 函数中获取的路径

      // 添加 text（和你的代码一样）
      svg.append('text')
        .attr('x', xPosition)
        .attr('y', this.picHeight - 60)
        .attr('text-anchor', 'middle')
        .attr('font-size', '80px')
        .attr('font-family', 'sans-serif')
        .attr('font-weight', 'bold')
        .attr('fill', '#133D64')
        .text('www.papermoney.ai');



      if (!Tools.isArrayEmpty(data.nodes) && !Tools.isArrayEmpty(data.links)) {
        // 桑基图数据
        const graph = {
          nodes: data.nodes,
          links: data.links
        };

        try {
          let vm = this; // 保存 Vue 实例的 this

          // 创建桑基图布局
          const margin = { top: 180, right: 360, bottom: 180, left: 360 };
          const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top + titleMargin + titleHeight * 1.5})`);

          const chartWidth = this.picWidth - margin.left - margin.right;
          const chartHeight = this.picHeight - titleMargin - titleHeight * 1.5 - margin.top - margin.bottom;

          const sankeyGenerator = sankey()
            .nodeWidth(nodeWidth)
            .nodePadding(nodePadding)
            .nodeAlign(sankeyCenter)
            .size([chartWidth, chartHeight])
            .nodeId(d => d.id)
            .linkSort(function (a, b) {
              return b.sort - a.sort;
            })
            .nodeSort(function (a, b) {
              return b.sort - a.sort;
            });

          sankeyGenerator(graph);



          // 遍历所有节点来计算每个层级的节点数量
          const layerNodeCounts = {};

          graph.nodes.forEach(node => {
            layerNodeCounts[node.depth] = (layerNodeCounts[node.depth] || 0) + 1;
          });

          // 基于最大层级节点数量来调整nodePadding
          const maxNodesInALayer = Math.max(...Object.values(layerNodeCounts));

          // 如果节点数量较少，使用较大的padding
          nodePadding = Math.max(160, Math.min(240, 240 * 5 / maxNodesInALayer));
          // console.log('maxNodesInALayer，nodePadding:', maxNodesInALayer, nodePadding);

          sankeyGenerator.nodePadding(nodePadding);
          sankeyGenerator(graph);

          chart
            .append('g')
            .selectAll()
            .data(graph.nodes)
            .join('g')
            .attr('class', 'node')
            .attr('indexName', (d) => d.name)
            .append('rect')
            .attr('fill', (d) => d.color)
            .attr('x', (d) => d.x0)
            .attr('y', (d) => d.y0)
            .attr('height', (d) => d.y1 - d.y0)
            .attr('width', (d) => d.x1 - d.x0)
            .append('title')
            .text((d) => `${d.name}`)

          chart
            .append('g')
            .attr('fill', 'none')
            .selectAll()
            .data(graph.links)
            .join('path')
            .attr('class', 'link')
            .attr('d', sankeyLinkHorizontal())
            .attr('indexName', (d) => d.source.name + '-' + d.target.name)
            .attr('stroke', (d) => d.color)
            .attr('stroke-width', (d) => (d.width > 0 ? Math.max(1, d.width) : 0)) // 确保最小宽度是2px
            .attr('stroke-opacity', '1.0')
            .append('title')

          // 绘制标签
          const nodeGroups = chart
            .append('g')
            .selectAll('g.node')
            .data(graph.nodes)
            .enter()
            .append('g')
            .attr('class', 'node');

          nodeGroups
            .append('rect')
            .attr('fill', (d) => d.color)
            .attr('x', (d) => d.x0)
            .attr('y', (d) => d.y0)
            .attr('height', (d) => d.y1 - d.y0)
            .attr('width', (d) => d.x1 - d.x0);

          // Append the title for each node
          // 绘制标题
          nodeGroups.each((d, i, nodes) => {
            let node = d3.select(nodes[i]);
            let lineHeight = 48;
            let lines = this.wrapText(d.desc, node); // 使用改进的 wrapText 方法
            let textBlockHeight = lines.length * lineHeight; // 计算整个文本块的高度
            // 确定文本块起始y位置，这样最后一行将与底部对齐
            let startYPosition = this.determineYPosition(d, nodePadding) - textBlockHeight + lineHeight + 24;

            let text = node.append('text')
              .attr('class', 'chart-node-title')
              .attr('x', (d) => this.determineXPosition(d))
              .attr('y', startYPosition)
              .attr('fill', (d) => d.color)
              .attr('text-anchor', (d) => this.determineTextAnchor(d))
              .attr('alignment-baseline', 'hanging') // 顶部对齐
              .attr('font-family', 'sans-serif')
              .attr('font-size', '48px');

            // 为每行文本添加一个 tspan 元素
            lines.forEach(function (line, index) {
              text.append('tspan')
                .attr('x', text.attr('x')) // 每行的x位置与文本块的x位置相同
                .attr('dy', `${index * lineHeight}px`) // 设置dy为行高的倍数，以向下堆叠
                .text(line.text);
            });
          });



          // Append text element for the amount and margin combined
          nodeGroups
            .append('text')
            .attr('class', 'chart-node-desc')
            .attr('x', (d) => this.determineXPosition(d))
            .attr('y', (d) => this.determineYPosition(d, nodePadding) + 76) // Positioned at a certain distance below the title
            .attr('text-anchor', (d) => this.determineTextAnchor(d))
            .attr('alignment-baseline', 'middle')
            // The dy attribute here will vertically align the text
            .attr('dy', '0.35em')
            .attr('font-family', 'sans-serif')
            .each(function (d) {
              // Append amount
              d3.select(this)
                .append('tspan')
                .text((d) => vm.formatAmount(d.v, unit, currency, unit_t))
                .attr('font-size', '48px') // Size for the amount
                .attr('font-weight', 'bold') // Make the amount bold
                .attr('fill', (d) => d.color); // Color for the amount

              // Append margin if it exists
              if (d.margin) {
                d3.select(this)
                  .append('tspan')
                  .text((d) => `(${d.margin})`)
                  .attr('dx', '8')
                  .attr('dy', '-8')
                  .attr('font-size', '40px') // Smaller size for the margin
                  .attr('fill', '#666') // Different color for the margin
                  .attr('font-weight', 'normal'); // Margin is not bold
              }
            });

          // 绘制YY
          nodeGroups
            .filter((d) => (d.yy))
            .append('text')
            .attr('class', 'chart-node-margin')
            .attr('x', (d) => this.determineXPosition(d))
            .attr('y', (d) => this.determineYPosition(d, nodePadding) + 112) // 在value下方一定距离
            .attr('text-anchor', (d) => this.determineTextAnchor(d))
            .attr('alignment-baseline', 'middle')
            .attr('font-size', '40px')
            .attr('fill', '#666')
            .text((d) => this.formatYY(d.yy))


        } catch (err) {
          console.log(err);
        }
      }

      // 获取类名为 'image-container' 的所有元素
      const containers = document.getElementsByClassName('image-container');

      // 检查是否有获取到元素，并获取第一个元素的尺寸
      let containerWidth, containerHeight;
      if (containers.length > 0) {
        containerWidth = containers[0].clientWidth - 16;
        containerHeight = containers[0].clientHeight;
      } else {
        console.log('未找到具有 image-container 类的元素');
        // 可以选择在这里设置一个默认的宽度和高度，或者执行其他操作
      }

      // 计算缩放比例
      const scaleX = containerWidth / this.picWidth;
      const scaleY = containerHeight / this.picHeight;
      const scale = Math.min(scaleX, scaleY); // 选择较小的比例以保持比例

      // console.log('svg transform', scale, containerHeight, containerWidth);

      // 应用缩放和转换
      svg.attr('transform', 'scale(' + scale + ')');
    },

    // Helper function to determine x position based on leaf property
    determineXPosition(d) {
      if (d.leaf === 'l') {
        return d.x0 - 80;
      } else if (d.leaf === 'r') {
        return d.x1 + 20;
      } else {
        return (d.x0 + d.x1) / 2; // 默认居中
      }
    },

    // Helper function to determine y position based on leaf property
    determineYPosition(d) {
      // 垂直居中位置
      // 如果leaf为'l'或'r'，标签放在节点垂直中心
      if (d.leaf === 'l' || d.leaf === 'r') {
        return (d.y0 + d.y1) / 2;
      }
      // 否则，标签放在节点上方
      else {
        var top = 92;
        if (d.yy)
          top = top + 40;
        return d.y0 - top;
      }
    },

    // Helper function to determine text anchor based on leaf property
    determineTextAnchor(d) {
      if (d.leaf === 'l') {
        return 'end';
      } else if (d.leaf === 'r') {
        return 'start';
      } else {
        return 'middle'; // 默认居中
      }
    },

    formatAmount(amount, unit = 'D', currency = 'USD') {
      const isNegative = amount < 0;
      const absoluteAmount = Math.abs(amount);
      let formattedAmount;
      let c;
      switch (currency) {
        case 'RMB':
          c = '¥';
          break;
        case 'HKD':
          c = 'HK$';
          break;
        case 'USD':
          c = '$';
          break;
        case 'other':
          c = '';
          break;
        default:
          c = currency; // 或者您可以选择一个默认的货币符号
          break;
      }
      const unitMappings = {
        'K': 1e3,
        'M': 1e6,
        'B': 1e9,
        'T': 1e12,
        '元': 1,
        '千': 1e3,
        '万': 1e4,
        '百万': 1e6,
        '亿': 1e8
      };

      const multiplier = unitMappings[unit] || 1;
      const amountInBaseUnit = absoluteAmount * multiplier;

      function formatCurrency(value, suffix) {
        // return `${c}${parseFloat(value.toFixed(1)).toString()}${suffix}`;
        let formattedValue = value.toFixed(1);
        // 如果小数部分为0，则忽略小数点
        if (formattedValue.endsWith('.0')) {
          formattedValue = formattedValue.slice(0, -2);
        }
        return `${c}${formattedValue}${suffix}`;
      }

      if (currency === 'RMB') {
        if (amountInBaseUnit >= 1e8) {
          formattedAmount = formatCurrency(amountInBaseUnit / 1e8, '亿');
        } else if (amountInBaseUnit >= 1e4) {
          formattedAmount = formatCurrency(amountInBaseUnit / 1e4, '万');
        } else {
          formattedAmount = formatCurrency(amountInBaseUnit, '元');
        }
      } else {
        if (amountInBaseUnit >= 1e9) {
          formattedAmount = formatCurrency(amountInBaseUnit / 1e9, 'B');
        } else if (amountInBaseUnit >= 1e6) {
          formattedAmount = formatCurrency(amountInBaseUnit / 1e6, 'M');
        } else if (amountInBaseUnit >= 1e3) {
          formattedAmount = formatCurrency(amountInBaseUnit / 1e3, 'K');
        } else {
          formattedAmount = formatCurrency(amountInBaseUnit, '');
        }
      }
      // return formattedAmount;
      return isNegative ? `(${formattedAmount})` : formattedAmount;
    },

    formatMargin(value) {
      // 如果值已经是百分比形式，直接返回
      if (typeof value === 'string' && value.includes('%')) {
        var unit = '';
        if (value.endsWith('%')) {
          unit = ' margin';

        } if (value.startsWith('-')) {
          // 移除负号并在百分比后添加 'margin'
          return value.slice(1) + unit;
        } else {
          // 直接在百分比后添加 'margin'
          return value + unit;
        }

      }
      // 如果值是数字，转换成百分比
      return (value * 100).toFixed(2) + '% margin';
    },

    formatYY(value) {
      // 如果value是字符串且已包含'pp'或'%'，则直接返回value
      if (typeof value === 'string') {
        if (value.includes('pp') || value.includes('%')) {
          if (!value.endsWith('Y/Y')) {
            return value + ' Y/Y';
          } else {
            return value;
          }
        } else {
          return value + '% Y/Y'
        }
      } else if (typeof value === 'number') {
        return value + '% Y/Y'
      } else {
        return '';
      }
    },

    downloadSankeyImage() {
      // 找到SVG元素并克隆一份，以便在不影响原始SVG的情况下进行修改
      var svgParent = `sankeyDiagram-${this.containerId}`;

      const svgElement = document.getElementById(svgParent).getElementsByTagName('svg')[0];
      const clonedSvgElement = svgElement.cloneNode(true);

      // 移除克隆SVG的任何缩放变换
      clonedSvgElement.removeAttribute('transform');

      // 序列化克隆的SVG数据
      const svgData = new XMLSerializer().serializeToString(clonedSvgElement);

      // 创建画布并设置其尺寸为SVG的原始尺寸
      const canvas = document.createElement('canvas');
      canvas.width = this.picWidth;
      canvas.height = this.picHeight;
      const ctx = canvas.getContext('2d');

      // 创建一个新的Image对象并设置其源为序列化的SVG数据
      const img = new Image();
      img.onload = () => {
        // 在画布上绘制图像
        ctx.drawImage(img, 0, 0, this.picWidth, this.picHeight);

        // 转换画布到PNG
        const pngFile = canvas.toDataURL('image/png');

        // 创建一个下载链接并触发下载
        const downloadLink = document.createElement('a');
        var fileName = this.convertTitleToFilename(this.sankeyData.title);
        downloadLink.download = fileName;
        downloadLink.href = `${pngFile}`;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // 显示下载成功的消息
        this.$message('The image is saved in the Downloads folder.');
      };

      // 设置Image对象的源为SVG数据
      img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
    },

    convertTitleToFilename(title, ftype = '.png') {
      // 替换空格为下划线
      let filename = title.replace(/\s/g, "_");

      // 替换其他可能导致问题的特殊字符
      filename = filename.replace(/:/g, "-");
      filename = filename.replace(/\//g, "-");

      // 可以选择转换为小写
      filename = filename.toLowerCase() + ftype;

      return filename;
    },
    emitSaveReportPublic() {
      // 用户选择后关闭弹窗并触发事件
      this.$emit('save-report', true);
    },
    emitSaveReportPrivate() {
      // 用户选择后关闭弹窗并触发事件
      this.$emit('save-report', false);
    },
    async uploadImgToOSS() {
      try {
        var svgParent = `sankeyDiagram-${this.containerId}`;
        const svgElement = document.getElementById(svgParent).getElementsByTagName('svg')[0];
        const clonedSvgElement = svgElement.cloneNode(true);
        clonedSvgElement.removeAttribute('transform');
        const svgData = new XMLSerializer().serializeToString(clonedSvgElement);

        const canvas = document.createElement('canvas');
        canvas.width = this.picWidth;
        canvas.height = this.picHeight;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));

        // 将图像绘制到画布上
        await new Promise((resolve) => {
          img.onload = () => {
            ctx.drawImage(img, 0, 0, this.picWidth, this.picHeight);
            resolve();
          };
        });

        // 将画布内容转换为图像数据
        const imageData = canvas.toDataURL('image/jpeg', 0.7);

        // 调用上传到OSS的方法
        const uploadedUrl = await this.uploadToOSS(imageData);
        console.log('uploadImgToOSS:', uploadedUrl);
        return uploadedUrl;
      } catch (error) {
        console.error('上传图片到OSS时发生错误:', error);
        return '';
      }
    },


    async uploadToOSS(imageData) {
      try {
        const blobData = this.dataURLToBlob(imageData);
        const formData = new FormData();
        formData.append('file', blobData);
        formData.append('fileName', this.convertTitleToFilename(this.sankeyData.title));
        formData.append('companyCode', this.sankeyData.companyCode);
        const url = process.env.VUE_APP_UPLOAD_WORKER_URL;
        // 调用 Cloudflare Worker
        const response = await fetch(url, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          console.log('uploadToOSS:', response);
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const result = await response.json();
        if (!result.success) {
          throw new Error(result.error);
        }

        return result.url;
      } catch (error) {
        console.error('上传失败:', error);
        this.$message.error('上传失败: ' + error.message);
        throw error;
      }
    },

    // 将 DataURL 转换为 Blob
    dataURLToBlob(dataurl) {
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    , generateImage() {
      return new Promise((resolve, reject) => {
        const svgElement = this.$el.getElementsByTagName('svg')[0];
        if (!svgElement) {
          reject('SVG element not found');
          return;
        }

        const clonedSvgElement = svgElement.cloneNode(true);
        clonedSvgElement.removeAttribute('transform');

        const svgData = new XMLSerializer().serializeToString(clonedSvgElement);
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = this.picWidth;
          canvas.height = this.picHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, this.picWidth, this.picHeight);
          const pngFile = canvas.toDataURL('image/png');
          resolve(pngFile);
        };
        img.onerror = (e) => {
          reject(e);
        };
        img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);
      });
    }
  },
  mounted() {
    // this.drawSankey();
  },
  watch: {
    // Watch for changes in sankeyData to update the sankey diagram
    sankeyData: {
      handler(newData) {
        // console.log('Sankey data changed, updating from parent Sankey data:', newData);
        this.updateSankeyData(newData);
      },
      deep: true
    }
  },
}
</script>
  
<style></style>