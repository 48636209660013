import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        authToken: null,
        email: null,
        selectedReport: null,
        recentlyViewed: JSON.parse(localStorage.getItem('recentlyViewed')) || [], // 使用localStorage初始化状态
        selectedLanguage: localStorage.getItem('selectedLanguage') || 'English', // 默认为 'English'

    },
    mutations: {
        SET_AUTH(state, payload) {
            state.authToken = payload.token;
            state.email = payload.email;
            localStorage.setItem('authToken', payload.token);
            localStorage.setItem('email', payload.email);
        },
        CLEAR_AUTH(state) {
            console.log('clear auth');
            state.authToken = null;
            state.email = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('email');
        },
        setSelectedReport(state, report) {
            console.log('set selected report:', report);
            state.selectedReport = report;
        },
        UPDATE_RECENTLY_VIEWED(state, company) {
            const existingIndex = state.recentlyViewed.findIndex(item => item.symbol === company.symbol);

            // 如果公司不存在于列表中，则添加它
            if (existingIndex === -1) {
                state.recentlyViewed.unshift(company);

                // 确保数组不超过最大长度，如10
                if (state.recentlyViewed.length > 10) {
                    state.recentlyViewed.pop();
                }
            }
        },
        LOAD_RECENTLY_VIEWED(state, companies) {
            // 从localStorage加载数据时使用
            state.recentlyViewed = companies;
        },
        SET_LANGUAGE(state, language) {
            state.selectedLanguage = language;
            localStorage.setItem('selectedLanguage', language);
        }
    },
    actions: {
        login({ commit }, payload) {
            commit('SET_AUTH', payload);
        },
        logout({ commit }) {
            commit('CLEAR_AUTH');
        },
        updateRecentlyViewed({ commit }, company) {
            commit('UPDATE_RECENTLY_VIEWED', company);
            // 更新localStorage
            localStorage.setItem('recentlyViewed', JSON.stringify(this.state.recentlyViewed));
        },
        loadRecentlyViewed({ commit }) {
            // 从localStorage加载最近浏览的公司信息
            const data = localStorage.getItem('recentlyViewed');
            if (data) {
                commit('LOAD_RECENTLY_VIEWED', JSON.parse(data));
            }
        },
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language);
        }
    },
    getters: {
        isAuthenticated: state => !!state.authToken,
        userEmail: state => state.email,
        getRecentlyViewed: state => state.recentlyViewed,
        getSelectedLanguage: state => state.selectedLanguage,
    }
});
