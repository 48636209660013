<template>
    <svg :width="width" :height="height" viewBox="0 0 60.000000 70.000000" preserveAspectRatio="xMidYMid meet"
        color-interpolation-filters="sRGB" style="margin: auto;">
        <g>
            <rect fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0" width="60" height="69.28006466139368"
                class="image-rect"></rect>
            <svg x="0" y="0" width="60" height="69.28006466139368" class="image-svg-svg primary" style="overflow: visible;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.41999816894531 105.55999755859375">
                    <g fill-rule="evenodd">
                        <path fill="#1a6cb5" opacity=".8" d="M91.42 79.17l-45.71 26.39L0 84.08l91.42-4.91z"></path>
                        <path fill="#6ec3ee" opacity=".8" d="M45.71 105.56L0 79.17V36.21l45.71 69.35z"></path>
                        <path fill="#f37832" opacity=".8" d="M0 26.39L45.71 0l37.21 21.48L0 26.39z"></path>
                        <path fill="#ee483c" opacity=".8" d="M45.71 0l45.71 26.39v42.97L45.71 0z"></path>
                        <path fill="#fdbc3e" opacity=".8" d="M0 79.17V26.39L37.21 4.91 0 79.17z"></path>
                        <path fill="#c91588" opacity=".8" d="M91.42 26.39v52.78l-37.21 21.48 37.21-74.26z"></path>
                    </g>
                </svg>
            </svg>
        </g>
    </svg>
</template>
  
<script>
export default {
    name: 'MainLogo',
    props: {
        width: {
            type: String,
            default: '32px'
        },
        height: {
            type: String,
            default: '32px'
        }
    }
};
</script>

<style scoped>
/* 添加到你的全局样式中 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

</style>

  