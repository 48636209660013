<template>
    <div>
        <el-row :gutter="10" style="margin-top: 12px;">
            <el-col :span="6" v-for="(item, index) in displayItems" :key="index">
                <router-link class="recommend no-underline"
                    :to="{ path: '/ReportViewer', query: { name: item?.company?.name, symbol: item?.company?.symbol } }">
                    <el-image class="img-grid" :src="getScaleImgLink(item.lastreport.reviewLink)" fit="contain"
                        :alt="`Cover of ${item?.lastreport?.data?.title} report`"
                        style="width: 100%; aspect-ratio: 16/9;">
                        <div slot="error"
                            style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                            <i class="el-icon-coffee-cup"></i>
                        </div>
                    </el-image>
                    <div class="desc-grid">
                        <!-- <img :src="require('@/assets/serch-icon.png')" class="icon-image" /> -->
                        <company-logo :company="item.company" />
                        <span>{{ item?.lastreport?.data?.title }}</span>
                    </div>
                    <div class="desc-lastmodify" v-if="formatDateTime(item.company.last_modified)">
                        <i class="el-icon-upload" style="margin-right: 8px;"></i>
                        <span>{{ formatDateTime(item.company.last_modified) }}</span>
                    </div>
                </router-link>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
import CompanyLogo from './CompanyLogo.vue';
export default {
    components: {
        CompanyLogo
    },
    data() {
        return {
            recommendations: []
        }
    },
    mounted() {
        this.getHomeRecommend();
    },
    computed: {
        displayItems() {
            // 限制显示项为四个
            return this.recommendations.slice(0, 4);
        },
    },
    methods: {
        getHomeRecommend() {
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.post(`${apiEndpoint}/getHomeReportRecent`, {/* Your data here */ })
                .then(response => {
                    this.recommendations = response.data?.recentReports; // 存储响应数据
                    console.log('recentReports:', this.recommendations);
                }
                )
                .catch(error => {
                    console.error('Error:', error);
                });

        },
        openReportViewer(company) {
            this.$emit('open-report-detail', company);
        },
        formatDateTime(time) {
            if (time) {
                return new Date(time).toLocaleString();
            }
            return '';
        },
        getScaleImgLink(link, width = 400) {
            if (link) {
                return `${link}?x-oss-process=image/resize,w_${width}`;
            }
            return '';
        }
    }
}
</script>

<style scoped>
.no-underline {
    text-decoration: none;
    /* 移除下划线 */
}

.recommend div:hover {
    scale: 1.02;
}

.recommend .img-grid {
    display: block;
    border-radius: 8px;
    cursor: pointer;
}



.recommend .desc-grid {
    margin-top: 8px;
    padding: 4px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

}

.recommend .desc-grid span {
    font-size: 16px;
    font-weight: 500;
    color: #777;
    cursor: pointer;
    flex: 1;
    margin-left: 12px;

}

.recommend .desc-lastmodify {
    font-size: 14px;
    font-weight: 300;
    color: #999;
    margin-left: 20px;
    cursor: pointer;
}

.icon-image {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}
</style>