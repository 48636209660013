<template>
    <div>
        <HeaderComponent ref="headerComponent" />
        <el-container>
            <el-main>
                <el-dialog title="SEARCH COMPANY SYMBOL" :visible.sync="showSearchDialog" center
                    :close-on-click-modal="false">
                    <div v-if="dialogType === 'showReport'" class="search-dialog" v-loading="searching"
                        style="margin-top: 24px;">
                        <div>
                            <el-tabs v-model="reportType" type="border-card" @tab-click="handleTabClick">
                                <el-tab-pane v-for="type in reportTypes" type="card" :key="type" :label="getTabLabel(type)"
                                    :name="type">
                                    <el-table :data="filteredAndSortedReports" @row-click="handleSearchReportRowClick"
                                        height="400">
                                        <el-table-column prop="status" label="Deadline" width="150" sortable>
                                            <template slot-scope="scope">
                                                <div style="display: flex;align-items: center;">
                                                    <img class="icon-image" v-if="scope.row.status === 'checked'"
                                                        :src="getCheckedIcon()">
                                                    <i v-else class="el-icon-tickets"></i>
                                                    {{ scope.row.deadline }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="months" label="Months" sortable
                                            width="150"></el-table-column>
                                        <el-table-column label="Type" sortable width="180">
                                            <template slot-scope="scope">
                                                {{ getTypeDesc(scope.row.type) }}
                                            </template></el-table-column>
                                        <el-table-column prop="title" label="Title" sortable></el-table-column>
                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>
                            <div style="display: flex; justify-content: center; margin-top: 12px;margin-bottom:24px">
                                <el-button icon="el-icon-plus" type="success" @click="createNewReport"
                                    style="min-width:240px">
                                    {{ selectedCompany ? `Create New Report For ${selectedCompany}`
                                        : 'Search and Create Report' }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="dialogType === 'showType'">
                        <el-card class="box-card" shadow="hover">
                            <div @click="createNewReportByType('ic')"
                                style="display:flex;align-items: center;cursor:pointer"><img class="icon-image"
                                    :src="getIncomeIcon()">Create IncomeStatement Visual View</div>
                        </el-card>
                        <el-card class="box-card" shadow="hover">
                            <div @click="createNewReportByType('cs')"
                                style="display:flex;align-items: center;cursor:pointer"><img class="icon-image"
                                    :src="getCashflowIcon()">Create CaseFLowStatment Visual View</div>
                        </el-card>
                        <el-card class="box-card" shadow="hovser">
                            <div @click="createNewReportByType('bs')"
                                style="display:flex;align-items: center;cursor:pointer"><img class="icon-image"
                                    :src="getBalanceIcon()">Create BalanceSheet Visual View</div>
                        </el-card>
                    </div>
                </el-dialog>
                <div>
                    <component :is="currentFormComponent" :report="selectedReport" :companyType="companyType"
                        @create-company="resetForm" @showreports-by-symbol="showReportsBySymbol"
                        @unauthorized-access="handleUnauthorizedAccess">
                    </component>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from 'axios';
import HeaderComponent from './HeaderComponent.vue';
import IncomeForm from './creater/IncomeForm.vue';
import CaseFlowForm from './creater/CaseFlowForm.vue';
import BalanceForm from './creater/BalanceForm.vue';
import MyCompanyForm from './creater/MyCompanyForm.vue';
// import Tools from '../GlobalTools';
export default {
    name: 'UserCreationPage',
    components: {
        HeaderComponent,
        MyCompanyForm,
        IncomeForm,
        CaseFlowForm,
        BalanceForm,
    },
    data() {
        return {
            showSearchDialog: false, // 控制弹窗的显示
            searching: false,
            searchQuery: '', // 搜索查询字符串
            userCompanies: null,
            dialogType: 'showReport',
            searchedCompanines: [],
            searchedReports: [],
            reportType: 'all', // 默认显示全部
            reportTypes: ['all', 'ic', 'cs', 'bs'], // 定义报告类型
            selectedSymbol: null,
            selectedCompany: null,
            selectedReport: null,
            incomeData: {},
            balanceData: {},
            cashData: {},
            creater: 'empty',
            companyType: 'LC',
        };
    },
    computed: {
        // 计算属性，用于过滤和排序报告
        filteredAndSortedReports() {
            let reports = this.searchedReports;

            if (this.reportType !== 'all') {
                reports = reports.filter(report => report.type === this.reportType);
            }

            // 按日期排序，最新的在最前面
            return reports.sort((a, b) => b.deadline.localeCompare(a.deadline));
        },
        deadlineLabel() {
            return this.deadline + ' ' + this.months;
        },
        currentFormComponent() {
            // console.log('currentFormComponent', this.creater);
            switch (this.creater) {
                case 'empty':
                    return MyCompanyForm;
                case 'ic':
                    return IncomeForm;
                case 'cs':
                    return CaseFlowForm;
                case 'bs':
                    return BalanceForm;
                default:
                    return MyCompanyForm;
            }
        }
    },
    methods: {
        handleUnauthorizedAccess() {
            this.$refs.headerComponent.logout();
            this.$refs.headerComponent.openLoginForm();
        },
        showReportsBySymbol(data) {
            this.searchQuery = data.name;
            this.companyType = data.public === false ? 'NLC' : 'LC';
            this.showSearchDialog = true;
            this.selectedSymbol = data.symbol;
            this.selectedCompany = data.name;
            if(data.newCompany){
                this.dialogType = 'showType';
                this.createNewReport();
            }else{
                this.dialogType = 'showReport';
                this.searchReportBySymbol(data.symbol, data.name);
            }
        },
        resetForm() {
            this.creater = 'empty';
            this.dialogType = 'showReport';
        },
        //搜索symbol的dailog
        searchCompanySymbol() {
            this.searching = true;
            this.dialogType = 'showCompany';
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.get(`${apiEndpoint}/findCompanySymbol?keywords=${this.searchQuery}`)
                .then(response => {
                    this.searching = false;
                    this.searchedCompanines = response.data.companies;
                    // console.log('searchedCompanines', this.searchedCompanines);
                })
                .catch(error => {
                    this.searching = false;
                    console.error("Error during search!", error);
                });
        },
        async searchReportBySymbol(symbol, name) {

            this.searching = true;
            this.dialogType = 'showReport';
            // 设置默认的报告类型
            const reportType = 'ic';
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            return axios.post(`${apiEndpoint}/getReportsBySymbol`, { symbol, reportType })
                .then(response => {
                    this.searching = false;
                    // console.log('searchReportBySymbol', response.data);
                    var checked = response.data.checkedData;
                    var unchecked = response.data.uncheckedData;
                    this.searchedReports = this.mergeAndSortData(checked, unchecked, symbol, name);
                    // console.log('searchedReports', this.searchedReports);

                })
                .catch(error => {
                    this.searching = false;
                    console.error("Error during search!", error);
                });

        },
        mergeAndSortData(checkedData, uncheckedData, symbol, name) {
            // 转换数据为统一的数组格式
            // Tools.logObj('checkedData', checkedData);
            // Tools.logObj('uncheckedData', uncheckedData);
            const convertDataToArray = (data, status) => {
                const dataArray = [];
                if (data) {
                    Object.keys(data).forEach(type => {
                        Object.keys(data[type]).forEach(dateType => {
                            data[type][dateType].forEach(details => {
                                dataArray.push({
                                    symbol,
                                    name,
                                    type,
                                    dateType,
                                    deadline: details.deadline,
                                    title: details.title ? details.title : name + ' ' + this.getFormateDate(details.deadline, dateType) + ' ' + this.getTabLabel(type),
                                    months: details.months,
                                    status: status || details.status
                                });
                            });
                        });
                    });
                }
                return dataArray;
            };


            // 创建标识符映射
            const typePriority = { 'ic': 1, 'cs': 2, 'bs': 3 };
            const dateTypePriority = { 'q': 1, 'y': 2, 'i': 3 };

            // 合并并去重数据
            const checkedArray = convertDataToArray(checkedData, 'checked');
            const uncheckedArray = convertDataToArray(uncheckedData, 'unchecked');
            const combinedArray = [...checkedArray, ...uncheckedArray];
            const uniqueData = {};

            combinedArray.forEach(item => {
                const identifier = `${item.type}-${item.dateType}-${item.deadline}`;
                if (!uniqueData[identifier] || item.status === 'checked') {
                    uniqueData[identifier] = item;
                }
            });

            // 转换为数组并排序
            const sortedData = Object.values(uniqueData).sort((a, b) => {
                // 按 type 排序
                if (typePriority[a.type] !== typePriority[b.type]) {
                    return typePriority[a.type] - typePriority[b.type];
                }
                // 若 type 相同，按 dateType 排序
                if (dateTypePriority[a.dateType] !== dateTypePriority[b.dateType]) {
                    return dateTypePriority[a.dateType] - dateTypePriority[b.dateType];
                }
                // 若 type 和 dateType 都相同，按 deadline 排序
                return a.deadline.localeCompare(b.deadline);
            });

            return sortedData;
        },
        getCheckedIcon() {
            return require('@/assets/serch-icon.png');
        },
        getIncomeIcon() {
            return require('@/assets/income-icon.png');
        },
        getBalanceIcon() {
            return require('@/assets/balance-icon.png');
        },
        getCashflowIcon() {
            return require('@/assets/cashflow-icon.png');
        },
        createNewReport() {
            // this.showSearchDialog = false;
            this.dialogType = 'showType';
            this.symbol = this.selectedSymbol;
            this.companyName = this.selectedCompany;
        },
        handleTabClick(tab) {
            this.reportType = tab.$props.name; // 从 tab 的 $props 获取 name 属性
        },
        getFormateDate(endDate, reportType) {
            // console.log('endDate', endDate, reportType);

            // 检查 endDate 是否为符合格式的字符串，并转换为 Date 对象
            if (typeof endDate === 'string') {
                // 假设日期格式为 'YYYYMMDD'
                if (endDate.match(/^\d{4}\d{2}\d{2}$/)) {
                    const year = endDate.substring(0, 4);
                    const month = endDate.substring(4, 6);
                    const day = endDate.substring(6, 8);
                    endDate = new Date(`${year}-${month}-${day}`);
                } else {
                    endDate = new Date(endDate);
                }
            }

            if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
                console.error('Invalid date');
                return '';
            }

            const year = endDate.getFullYear();
            const quarter = Math.floor((endDate.getMonth() + 3) / 3);

            if (reportType.toUpperCase() === 'Q') {
                return `Q${quarter} ${year}`;
            } else if (reportType.toUpperCase() === 'Y') {
                return `${year}`;
            } else if (reportType.toUpperCase() === 'I') {
                return `${year} Interim`;
            } else {
                return '';
            }
        },
        getTabLabel(type) {
            const typeMap = {
                'all': 'All',
                'ic': 'IncomeStatement',
                'cs': 'CashFlowStatement',
                'bs': 'BalanceSheet',
                // 在这里添加更多映射，如果需要
            };
            return typeMap[type] || type; // 如果映射不存在，则返回原始类型
        },
        handleSearchReportRowClick(row) {
            // console.log('handleSearchReportRowClick', row);
            this.selectedReport = row;
            this.creater = row.type;
            this.showSearchDialog = false;
        },
        createNewReportByType(type) {
            this.selectedReport = { symbol: this.symbol, name: this.companyName, type: type }
            this.creater = type;
            this.showSearchDialog = false;
        },
        getTypeDesc(type) {
            switch (type) {
                case 'ic':
                    return 'IncomeStatement';
                case 'cs':
                    return 'CashFlowStatement';
                case 'bs':
                    return 'BalanceSheet';
                default:
                    return '';
            }
        }
    }
};
</script>
  
<style scoped>
.el-container {
    overflow: hidden;
}

.el-main {
    padding: 0px;
}

.el-header {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
}

.search-dialog {
    height: 504px;
    padding-bottom: 24px;
}

.search-dialog .el-table {
    height: 400px;
    overflow-y: auto;
}

::v-deep .search-dialog .el-table__empty-block {
    margin-top: 48px;
    align-items: baseline;
}

::v-deep .search-dialog .el-table__empty-text {
    width: 80%;
}

.text.item {
    height: 200px;
}


.icon-image {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.el-icon-tickets {
    margin-right: 12px;
}

::v-deep .el-tabs__content {
    padding: 0px 12px 12px 12px !important;
}


.box-card {
    margin: 24px 0px;
    font-size: 16px;
}

.box-card:hover {
    font-weight: bold;
    font-size: 18px;
}
</style>
  