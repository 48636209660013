<template>
    <div>
        <el-row :gutter="10" style="margin-top: 12px;">
            <el-col :span="6">
                <div class="recommend" v-if="recommendations.length > 1">
                    <router-link style="padding: 8px 12px;"
                        :to="{ path: '/ReportViewer', query: { name: recommendations[1]?.company?.name, symbol: recommendations[1]?.company?.symbol } }">
                        <el-image class="img-grid" :src="getScaleImgLink(recommendations[1]?.lastreport?.reviewLink)"
                            fit="contain" :alt="`Cover of ${recommendations[1]?.lastreport?.data?.title} report`"
                            style="width: 100%; aspect-ratio: 16/9;">
                            <div slot="error"
                                style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                                <i class="el-icon-coffee-cup"></i>
                            </div>
                        </el-image>
                        <div class="desc-grid">
                            <img :src="require('@/assets/serch-icon.png')" class="icon-image" />
                            <span>{{
            recommendations[1]?.lastreport?.data?.title }}</span>
                        </div>
                        <div class="desc-lastmodify" v-if="formatDateTime(recommendations[1].company.last_modified)">
                            <i class="el-icon-upload" style="margin-right: 8px;"></i>
                            <span>{{ formatDateTime(recommendations[1].company.last_modified) }}</span>
                        </div>
                    </router-link>
                    <div style="margin: 4px 12px;background: #EEE; height: 1px;"></div>
                    <router-link style="padding: 8px 12px;"
                        :to="{ path: '/ReportViewer', query: { name: recommendations[2]?.company?.name, symbol: recommendations[2]?.company?.symbol } }">
                        <el-image class="img-grid" :src="getScaleImgLink(recommendations[2].lastreport.reviewLink)"
                            fit="contain" :alt="`Cover of ${recommendations[2]?.lastreport?.data?.title} report`"
                            style="width: 100%; aspect-ratio: 16/9;">
                            <div slot="error"
                                style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                                <i class="el-icon-coffee-cup"></i>
                            </div>
                        </el-image>
                        <div class="desc-grid">
                            <img :src="require('@/assets/serch-icon.png')" class="icon-image" />
                            <span>{{
            recommendations[2]?.lastreport?.data?.title }}</span>
                        </div>
                        <div class="desc-lastmodify" v-if="formatDateTime(recommendations[2].company.last_modified)">
                            <i class="el-icon-upload" style="margin-right: 8px;"></i>
                            <span>{{ formatDateTime(recommendations[2].company.last_modified) }}</span>
                        </div>
                    </router-link>
                </div>
            </el-col>
            <el-col :span="12">
                <router-link class="recommend" v-if="recommendations[0]" style="height: 100%;padding: 8px 0px;"
                    :to="{ path: '/ReportViewer', query: { name: recommendations[0]?.company?.name, symbol: recommendations[0]?.company?.symbol } }">
                    <el-image class="img-grid"
                        style="display: block;border-radius: 12px;width: 100%; aspect-ratio: 16/9;"
                        :src="getScaleImgLink(recommendations[0].lastreport.reviewLink, 860)" fit="contain"
                        :alt="`Cover of ${recommendations[0]?.lastreport?.data?.title} report`">
                        <div slot="error"
                            style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                            <i class="el-icon-coffee-cup"></i>
                        </div>
                    </el-image>
                    <div style="padding:4px 12px;display: flex;align-items: center;">
                        <img :src="require('@/assets/serch-icon.png')" class="icon-image" />
                        <span style="font-size: 20px;font-weight:bold;color:#444">{{
            recommendations[0]?.lastreport?.data?.title }}</span>
                    </div>
                    <div style="padding: 4px 12px;">
                        <span style="font-weight: 300;color: #777;font-size: 16px;line-height: 1.5em;">{{
            recommendations[0].sign }}</span>
                    </div>
                    <div v-if="formatDateTime(recommendations[0].company.last_modified)"
                        style="font-size: 16px;font-weight: 300;color: #999;margin-left: 12px;">
                        <i class="el-icon-upload" style="margin-right: 8px;"></i>
                        <span>{{ formatDateTime(recommendations[0].company.last_modified) }}</span>
                    </div>
                </router-link>
            </el-col>
            <el-col :span="6">
                <div class="recommend" v-if="recommendations.length > 3">
                    <router-link style="padding: 8px 12px;"
                        :to="{ path: '/ReportViewer', query: { name: recommendations[3]?.company?.name, symbol: recommendations[3]?.company?.symbol } }">
                        <el-image class="img-grid" :src="getScaleImgLink(recommendations[3].lastreport.reviewLink)"
                            fit="contain" :alt="`Cover of ${recommendations[3]?.lastreport?.data?.title} report`"
                            style="width: 100%; aspect-ratio: 16/9;">
                            <div slot="error"
                                style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                                <i class="el-icon-coffee-cup"></i>
                            </div>
                        </el-image>
                        <div class="desc-grid">
                            <img :src="require('@/assets/serch-icon.png')" class="icon-image" />
                            <span>{{
            recommendations[3]?.lastreport?.data?.title }}</span>
                        </div>
                        <div class="desc-lastmodify" v-if="formatDateTime(recommendations[3].company.last_modified)">
                            <i class="el-icon-upload" style="margin-right: 8px;"></i>
                            <span>{{ formatDateTime(recommendations[3].company.last_modified) }}</span>
                        </div>
                    </router-link>
                    <div style="margin: 4px 12px;background: #EEE; height: 1px;"></div>
                    <router-link style="padding: 8px 12px;"
                        :to="{ path: '/ReportViewer', query: { name: recommendations[4]?.company?.name, symbol: recommendations[4]?.company?.symbol } }">
                        <el-image class="img-grid" :src="getScaleImgLink(recommendations[4].lastreport.reviewLink)"
                            fit="contain" :alt="`Cover of ${recommendations[4]?.lastreport?.data?.title} report`"
                            style="width: 100%; aspect-ratio: 16/9;">
                            <div slot="error"
                                style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;font-size: 24px;color: #AAA;">
                                <i class="el-icon-coffee-cup"></i>
                            </div>
                        </el-image>
                        <div class="desc-grid">
                            <img :src="require('@/assets/serch-icon.png')" class="icon-image" />
                            <span>{{
            recommendations[4]?.lastreport?.data?.title }}</span>
                        </div>
                        <div class="desc-lastmodify" v-if="formatDateTime(recommendations[4].company.last_modified)">
                            <i class="el-icon-upload" style="margin-right: 8px;"></i>
                            <span>{{ formatDateTime(recommendations[4].company.last_modified) }}</span>
                        </div>
                    </router-link>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            recommendations: []
        }
    },
    mounted() {
        this.getHomeRecommend();
    },
    methods: {
        getHomeRecommend() {
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.post(`${apiEndpoint}/getHomeReportRecommend`, {/* Your data here */ })
                .then(response => {
                    this.recommendations = response.data?.recommendedCompanies; // 存储响应数据
                    console.log('recommendations:', this.recommendations);
                }
                )
                .catch(error => {
                    console.error('Error:', error);
                });

        },
        openReportViewer(company) {
            this.$emit('open-report-detail', company);
        },
        formatDateTime(time) {
            if (time) {
                return new Date(time).toLocaleString();
            }
            return '';
        },
        getScaleImgLink(link, width = 400) {
            if (link) {
                return `${link}?x-oss-process=image/resize,w_${width}`;
            }
            return '';
        }
    }
}
</script>

<style scoped>
.recommend div:hover {
    scale: 1.02;
}

a {
    text-decoration: none;
    /* 移除下划线 */
}

.recommend .img-grid {
    display: block;
    border-radius: 8px;
    cursor: pointer;
}



.recommend .desc-grid {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.recommend .desc-grid span {
    font-size: 16px;
    font-weight: 500;
    color: #777;
    cursor: pointer;

}

.recommend .desc-lastmodify {
    font-size: 14px;
    font-weight: 300;
    color: #999;
    margin-left: 12px;
    cursor: pointer;
}

.icon-image {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}
</style>