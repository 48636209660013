<template>
  <div id="app">
    <keep-alive include="ListContainer" exclude="UserCreationPage">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import './assets/main.css';
import Vue from 'vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(ElementUI, { locale })

export default {
  name: 'App',
  created() {
    this.checkAuthState();
  },
  methods: {
    checkAuthState() {
      const authToken = localStorage.getItem('authToken');
      const email = localStorage.getItem('email');
      console.log('authToken', authToken);
      if (authToken) {
        // 将用户的登录状态设置为true
        // 您也可以在这里进行进一步的验证，例如通过API验证token是否有效
        this.$store.commit('SET_AUTH', { token: authToken, email: email });

        // 如果使用Vue Router，您可能还需要根据认证状态更改路由
        // this.$router.push({ name: 'Dashboard' });
      } else {
        // 如果没有token或者token无效，可以重定向到登录页
        // this.$router.push({ name: 'Login' });
      }
    }
  }
}



</script>

<style>
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
input,
textarea,
img {
  cursor: default;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  border: 0;
  font-family: 'SimHei', -apple-system, BlinkMacSystemFont, "PingFang SC", "Noto Sans", "Noto Sans CJK SC", "Microsoft YaHei UI", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.scroll-hide {
  overflow: auto;
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3 {
  font-size: 24px;
  /* 默认值，后面会覆盖 */
  color: #333;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

.chat-wrapper ol li,
.chat-wrapper ul li {
  line-height: 2.0em;
}


.chat-wrapper span,
.chat-wrapper p,
.chat-wrapper div {
  line-height: 2.0em;
}

.chat-wrapper ul,
.chat-wrapper .markdown-content ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: calc(var(--layout-pix__default)*5);
}

.chat-wrapper ol,
.chat-wrapper .markdown-content ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: calc(var(--layout-pix__default)*6);
  margin-left: 0px;
}

.chat-wrapper li::marker,
.chat-wrapper .markdown-content li::marker {
  color: #C23535;
}

.chat-wrapper table,
.chat-wrapper .markdown-content table,
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.chat-wrapper table tr,
.chat-wrapper .markdown-content table tr,
tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}

.chat-wrapper th,
.chat-wrapper td {
  border: 1px solid #ddd;
  padding: 8px;
}

.chat-wrapper th {
  background-color: #f2f2f2;
}

.chat-wrapper tr:nth-child(even) {
  background-color: #f9f9f9;
}

.chat-wrapper tr:not(:last-child) td {
  border-bottom: 1px solid #ddd;
}

.chat-wrapper td:last-child {
  border-right: 1px solid #ddd;
}

.chat-wrapper hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 8px;
  margin-bottom: 8px;
}

.el-drawer__wrapper {
  z-index: 1 !important;
}
</style>
