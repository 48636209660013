<template>
    <div v-loading="loading" class="soruce-pannel">

        <div class="chat-main">
            <div style="flex: 1;padding-bottom: 120px;" class="scrollable-content" ref="chatContainer">
                <el-row v-if="tableData !== null" type="flex" justify="space-between" align="middle"
                    style="border-top: 1px solid #EEE;">
                    <el-col style="width: calc(100% - 180px);border-right: 1px solid #EEE;">
                        <p class="header-tr">{{ title }}</p>
                    </el-col>
                    <el-col style="width: 180px;" class="text-right">
                        <p class="header-tr"><strong>{{ months }}</strong></p>
                        <p class="header-tr" style="border-top: 1px solid #EEE;">{{ deadline }}</p>
                    </el-col>
                </el-row>
                <el-table v-if="tableData !== null" :data="tableData" style="width: 100%" border stripe
                    :show-header="false">
                    <el-table-column prop="key" label="A" width="calc(100% - 180px)">
                        <!-- 自定义单元格模板 -->
                        <template slot-scope="scope">
                            <div class="cell-key">{{ scope.row.key }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="value" label="B" width="180px">
                        <!-- 自定义单元格模板 -->
                        <template slot-scope="scope">
                            <div class="cell-value">{{ scope.row.value }}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-for="chat in chats" :key="chat.id" style="margin-top: 12px;">
                    <!-- 使用 v-if 和 v-else-if 根据消息类型决定渲染哪个组件 -->

                    <dialogue-message v-if="chat.type === 'dialogue'" :message="chat.message">
                    </dialogue-message>
                    <reply-message v-else-if="chat.type === 'reply'" :message="chat.message" :isLoading="chat.loading"
                        :replayId="chat.id" :error="chat.error" @retry="handleRetry(chat.id)">
                    </reply-message>
                </div>
            </div>
        </div>

        <div class="chat-input-main">
            <el-input type="textarea" id="id-source-pannel-input" placeholder="ask question " v-model="input"
                @keydown.enter.native="submitChatEnter($event)" @compositionstart="handleCompositionStart"
                @compositionend="handleCompositionEnd" :autosize="{ minRows: 1, maxRows: 4 }">
            </el-input>
            <div @click="isChatReplaying ? abortRequest() : submitChat()">
                <div v-if="isChatReplaying" class="loading-icon">
                    <span>■</span>
                </div>
                <i v-else :class="'el-icon-s-promotion'" :style="inputIconStyle"></i>
            </div>
            <!-- <div @click="submitChat"><i class="el-icon-s-promotion" :style="inputIconStyle"></i></div> -->
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import DialogueMessage from '../reporterchat/DialogueMessage.vue';
import ReplyMessage from '../reporterchat/ReplyMessage.vue';
export default {
    components: {
        DialogueMessage,
        ReplyMessage
    },
    props: {
        source: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        // 计算属性来动态改变图标颜色
        inputIconStyle() {
            return {
                fontSize: '24px',
                color: this.input ? '#1457B1' : '#616161' // 如果输入框不为空，则图标颜色为蓝色，否则为黑色
            };
        },
    },
    mounted() {
        console.log("SourceDataForm mounted:", this.source);
        this.loadSourceData();
    },
    data() {
        return {
            loading: true,
            reportData: null,
            tableData: [],
            title: null,
            deadline: null,
            months: null,
            input: '',
            composing: false,//修正输入法问题的
            chats: [],
            socket: null, // WebSocket 连接，中断使用
            isChatReplaying: false,
        };
    },
    methods: {
        loadSourceData() {
            console.log('loadSourceData:', this.source);
            if (this.source && this.source.deadline) {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const params = {
                    symbol: this.source.symbol,
                    deadline: this.source.deadline,
                    status: this.source.status,
                    type: this.source.type,
                    dateType: this.source.dateType,
                    months:this.source.months
                };
                this.loading = true;
                axios.post(`${apiEndpoint}/getReportSourceData`, params).then(response => {
                    this.loading = false;
                    if (response.data && response.data.result) {
                        var reportDetail = response.data.result;
                        this.reportData = reportDetail;
                        console.log('getReportSourceData:', reportDetail);
                        this.title = reportDetail.title;
                        this.deadline = reportDetail.deadline;
                        this.months = reportDetail.months;
                        this.tableData = Object.entries(reportDetail.form).map(([key, value]) => {
                            return { key, value }; // 创建一个包含 key 和 value 的对象
                        });

                    }
                }).catch(error => {
                    console.log('getReportDetail error:', error);
                    this.loading = false;
                });
            }
        },
        // 当用户按下回车键时触发
        handleCompositionStart() {
            this.composing = true;
        },
        handleCompositionEnd() {
            this.composing = false;
        },
        submitChatEnter(event) {
            if (event.shiftKey) {
                // 允许换行
                return;
            }
            if (event.key === 'Enter' && !this.composing) {
                event.preventDefault();
                this.submitChat(); // 调用发送消息的方法
            }
        },
        // 当用户点击发送按钮时触发
        submitChat() {
            if (this.input.trim()) {
                const newMessage = {
                    message: this.input,
                    id: 'u-' + Date.now(),
                    fromUser: true,
                    type: 'dialogue'
                };
                this.chats.push(newMessage);
                // 确保DOM更新后再滚动到底部
                this.$nextTick(() => {
                    const chatContainer = this.$refs.chatContainer; // 假设你的聊天容器有一个ref="chatContainer"
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                });
                this.sendToChatAPI();
            }
        },
        updateChatMessage(id, newMessage, loading, error, updateMessageContent = true) {
            // console.log('updateChatMessage:', id, newMessage, loading, error, updateMessageContent);
            const chatIndex = this.chats.findIndex(c => c.id === id);
            if (chatIndex !== -1) {
                this.$set(this.chats, chatIndex, {
                    ...this.chats[chatIndex],
                    message: updateMessageContent ? newMessage : this.chats[chatIndex].message,
                    loading: loading,
                    error: error
                });
            }
            this.isChatReplaying = loading;
            if (!this.isChatReplaying) {
                this.closeConnection();
            }
        },
        async sendToChatAPI(id = null) {

            this.isChatReplaying = true;
            let replayId = id;
            if (replayId) {
                this.updateChatMessage(replayId, '', true, null);
            } else {
                replayId = 'r-' + Date.now();
                this.chats.push({
                    type: 'reply',
                    message: '',
                    loading: true,
                    id: replayId,
                    fromAI: true
                });
            }

            var messages = this.chats
                .filter(chat => chat.message.trim() !== '')
                .map(chat => ({
                    role: chat.fromUser ? "user" : "model",
                    content: chat.message
                }));

            if (this.reportData) {
                // 插入新的消息对象
                const reportDetailMessages = [
                    {
                        role: "user",
                        content: `You are a financial expert. Your job is to help users understand company financial reports and provide insights. Your users have no financial background, and they rely on your expertise to interpret financial data and offer insights. The current financial report data to analyze is: ${JSON.stringify(this.reportData)}`
                    },
                    {
                        role: "model",
                        content: `Of course! Please provide the financial report data that you wish to analyze. You can either paste the report data directly here or describe specific financial metrics or issues that you would like to analyze. I will then proceed to provide a detailed analysis and explanation based on this data.`
                    }
                ];

                // 将新消息和历史消息合并
                messages = [...reportDetailMessages, ...messages];
            }


            const dataToSend = {
                token: localStorage.getItem('authToken'),
                free: true,
                messages: messages,
            };

            // 建立 WebSocket 连接
            // console.log('WebSocket apiEndpoint:', apiEndpoint);
            this.closeConnection(); // 关闭之前的连接
            const apiEndpoint = process.env.VUE_APP_WS_API_ENDPOINT;
            this.socket = new WebSocket(apiEndpoint);
            // this.socket = new WebSocket('ws://localhost:3002');
            this.input = ''; // 清空输入框

            this.socket.onopen = () => {
                // 发送数据到服务端
                // console.log('WebSocket opened:', dataToSend);
                this.socket.send(JSON.stringify(dataToSend));

            };

            this.socket.onmessage = (event) => {
                // console.log('WebSocket message:', event.data);
                try {
                    // 尝试将 JSON 字符串解析为对象
                    var response = JSON.parse(event.data);

                    // 检查解析后的对象是否包含 'endOfStream' 键
                    if (response.endOfStream) {
                        console.log('WebSocket stream ended.');
                        // 这里可以执行一些清理操作或通知用户流已结束
                        this.updateChatMessage(replayId, '', false, null, false);
                    } else if (response.error) {
                        // 如果消息中包含 'error'，则打印错误信息
                        console.error('WebSocket Error:', response.error);
                        this.updateChatMessage(replayId, '', false, response.error, false);
                    } else if (response.message) {
                        // 如果消息中包含 'message'，则更新聊天消息
                        this.updateChatMessage(replayId, response.message, true, null, true);
                    } else {
                        // 如果收到了未知类型的消息，可以选择记录或忽略
                        // console.log('Received unknown message type:', response);
                        this.updateChatMessage(replayId, '', false, 'Received unknown message type.', false);
                    }
                } catch (e) {
                    // 如果解析 JSON 失败，打印错误并更新聊天消息
                    // console.error('Error parsing WebSocket message:', e);
                    this.updateChatMessage(replayId, `Error parsing message: ${event.data}`, false, null, false);
                }
            };

            this.socket.onerror = (error) => {
                // 处理 WebSocket 错误
                console.error('WebSocket error:', error);
                this.updateChatMessage(replayId, '', false, `error: ${error.message}`, false);
            };

            this.socket.onclose = (event) => {
                // 处理 WebSocket 关闭
                console.log('WebSocket closed:', event);
                if (this.socket) {
                    if (!event.wasClean) {
                        this.updateChatMessage(replayId, '', false, 'connection closed unexpectedly.', false);
                    } else {
                        this.updateChatMessage(replayId, '', false, null, false);
                        this.closeConnection();
                    }
                }

            };
        },
        // 手动关闭 WebSocket 连接的方法
        closeConnection() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
        },
    }
}
</script>

<style scoped>
.soruce-pannel {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}


.soruce-pannel .chat-main {
    padding: 8px 12px 24px 12px;
    flex: 1;
    width: calc(100% - 24px);
    height: calc(100% - 120px);
    overflow-y: auto;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #666;
}

.chat-input-main {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 12px 8px 0px;
    border-radius: 12px;
    border: 1px solid #999;
    margin: 12px 12px 12px 12px
}

.header-tr {
    padding: 8px;
}


.loading-icon {
    width: 24px;
    /* 正方形的尺寸 */
    height: 24px;
    color: white;
    /* 正方形的背景颜色 */
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #666;
    background-color: #666;

}

.loading-icon:hover {
    background-color: #EEE;
    color: #666;
}

.loading-icon span {
    font-size: 14px;
}


/* 使用 ::v-deep 来影响子组件内部的元素 */
::v-deep .el-textarea__inner {
    min-height: 80px;
    line-height: 1.5em;
    font-size: 16px;
    resize: none;
    border: none;
    /* 设置边框颜色 */
}

/* 如果您希望移除Element UI默认的边框阴影效果 */
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
    box-shadow: none;
    max-height: 8em;
}

::v-deep .search-input input {
    font-size: 14px;
    color: #EEE;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.15);
}

::v-deep .search-input .el-input__inner {
    border-radius: 12px !important;
}

::v-deep .search-input .el-autocomplete-suggestion {
    z-index: 2000;
    /* 一个足够高的值 */
}


.scrollable-content {
    height: 100%;
    overflow-y: scroll;
    /* 总是显示滚动条 */
    padding: 12px;
    /* 确保内容不会被滚动条遮挡 */
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE和Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.scrollable-content:hover {
    overflow-y: scroll;
}
</style>