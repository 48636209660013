<template>
    <div class="reply-message">
        <div style="display:inline-block; line-height:24px; vertical-align:middle;">
            <MainLogo width="24px" height="24px" style="vertical-align:middle;" />
            <span style="margin-left:8px;font-weight:bold;height:24px; line-height:24px; vertical-align:middle;">Financial
                Report Expert</span>
        </div>

        <div class="message-content">
            <div v-html="renderedMessage">
            </div>
        </div>
        <div style="margin-left: 12px;">
            <el-button round size="small" @click="handleProfile" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnProfile }}</el-button>
            <el-button round size="small" @click="handleAnalysis" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnAnalysis }}</el-button>
            <el-button round size="small" @click="handleGraspIncome" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewIncome }}</el-button>
            <el-button round size="small" @click="handleGraspBalance" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewBalance }}</el-button>
            <el-button round size="small" @click="handleGraspCashFlow" icon="el-icon-chat-line-round" class="quickmenu">{{
                btnViewCashFlow }}</el-button>
        </div>

    </div>
</template>

<script>
import MainLogo from '../animate/MainLogo.vue';

export default {
    components: {
        MainLogo,
    },
    props: {
        language: String,
    },
    data() {
        return {
            errorMessage: '' // 存储错误信息
        };
    },
    methods: {
        handleProfile() {
            // 根据所选语言发出公司概况的提示
            const prompt = this.language === 'English' ? 'Company Overview' : '介绍一下这家公司';
            this.$emit('sendPrompt', prompt);
            console.log('Profile prompt sent.');
        },
        handleAnalysis() {
            // 根据所选语言发出财务分析的提示
            const prompt = this.language === 'English' ? 
            `I have provided you with the latest quarterly financial report for this company.including:<profit_and_loss_statement><balance_sheet><cash_flow_statement>
Please carefully review the above financial data. In a <scratchpad>, write out your initial observations, noting any key takeaways, positive or negative trends, or areas that warrant further analysis. 
Then, perform a comprehensive analysis of the company's recent financial performance and position by addressing each of the following in detail:
1. Profitability - Analyze revenue, expenses, and profit margins. Discuss trends and YoY growth rates. Highlight any unusual or one-time items impacting profits.
2. Liquidity - Assess the company's ability to meet short-term obligations by examining current ratio, quick ratio and working capital. Note any changes from prior period.
3. Leverage - Evaluate debt levels, debt-to-equity ratio, and interest coverage. Discuss the company's capital structure and ability to service debt.
4. Efficiency - Calculate key efficiency ratios like inventory turnover, receivables turnover and asset turnover. Discuss how efficiently the company utilizes its resources. 
5. Cash Flows - Analyze operating, investing and financing cash flows. Discuss the main drivers of each. Evaluate the company's ability to generate positive free cash flow.
6. Key Financial Strengths and Weaknesses - Summarize the main financial strengths and weaknesses of the company based on your analysis. Discuss implications and potential concerns for the business going forward.
7. Overall Financial Health - Provide an overall assessment of the company's current financial health based on your comprehensive analysis. Discuss if the financials indicate an improving, stable or deteriorating position.
\nPlease present your complete analysis inside <analysis> tags. The analysis should be clear, well-structured, and easy for a business audience to follow. Explain your points concisely but with sufficient detail. Use specific figures and ratios from the financial statements to support your analysis.` : 
            `我为你提供了这家公司最新的季度财务报表，包括：<损益表><资产负债表><现金流量表>
请仔细检查上述财务数据。在<草稿本>中写出您的初步观察，注意任何关键的收获、积极或消极的趋势，或者需要进一步分析的领域。
然后，通过详细解答以下每一点，对公司近期的财务表现和状况进行全面分析：
1. 盈利能力 - 分析收入、费用和利润率。讨论趋势和年度增长率。强调影响利润的任何不寻常或一次性项目。
2. 流动性 - 通过检查流动比率、速动比率和营运资本，评估公司满足短期义务的能力。注意与前期的任何变化。
3. 杠杆作用 - 评估债务水平、负债与权益比率和利息保障倍数。讨论公司的资本结构和偿债能力。
4. 效率 - 计算关键的效率比率，如存货周转率、应收账款周转率和资产周转率。讨论公司如何有效利用其资源。
5. 现金流 - 分析经营、投资和融资现金流。讨论每个的主要驱动因素。评估公司产生积极自由现金流的能力。
6. 关键财务优势和劣势 - 根据您的分析，总结公司的主要财务优势和劣势。讨论对商业前景的含义和潜在关切。
7. 总体财务健康 - 根据您的全面分析，提供公司当前财务健康状况的总体评估。讨论财务状况是改善、稳定还是恶化。
\n请在<分析>标签内呈现您的完整分析。分析应清晰、结构良好，易于商业听众理解。简洁但详尽地解释您的观点。使用财务报表中的具体数字和比率支持您的分析。`;
            this.$emit('sendPrompt', prompt);
            console.log('Analysis prompt sent.');
        },
        handleGraspIncome() {
            // 根据所选语言发出利润表了解的提示
            const prompt = this.language === 'English' ? `Please analyze the income statements provided for the past 4 quarters and 2 years
\nCarefully examine the key line items and metrics in the income statements, including:
- Revenue
- Cost of goods sold and gross profit
- Operating expenses
- Operating income
- Interest expense
- Taxes
- Net income
- Any other notable line items
\nIdentify and discuss any significant trends, changes, or developments you observe over the 4 quarter and 2 year time periods. For example, discuss if revenue is growing or shrinking, if margins are expanding or contracting, if certain expenses are increasing disproportionately, etc. 
Summarize your overall analysis and key takeaways in an <analysis_summary> section. What is your high-level assessment of the company's financial performance and health based on the income statement data?
\nFinally, in an <additional_questions> section, share any open questions you still have or areas you would want to investigate further to better understand the drivers behind the company's financial results and future trajectory. What additional context or data points would help paint a clearer picture?
\nProvide your full analysis in a detailed yet easy-to-follow format. Use clear headings and sections as appropriate.` : 
                `请分析所提供的过去4个季度和2年的损益表：
\n仔细检查损益表中的关键项目和指标，包括：
- 营业收入
- 销售成本和毛利润
- 营业费用
- 营业收入
- 利息支出
- 税收
- 净利润
- 其他值得注意的项目
\n识别并讨论您在4个季度和2年时间内观察到的任何重要趋势、变化或发展。例如，讨论营业收入是增长还是缩减，利润率是扩大还是收缩，某些费用是否不成比例增加等。在<分析总结>部分，总结您的整体分析和关键收获。基于损益表数据，您对公司的财务表现和健康状况的高层次评估是什么？最后，在<额外问题>部分，分享您仍有的任何未解决的问题或您希望进一步调查的领域，以便更好地理解公司财务结果和未来轨迹的驱动因素。哪些额外的背景或数据点将有助于更清晰地描绘情况？请以详尽而易于遵循的格式提供您的完整分析。适当使用清晰的标题和部分。`;
            const data = {
                reportType:"ic"
            }
            this.$emit('sendPrompt', prompt,data);
            console.log('Income Statement prompt sent.');
        },
        handleGraspBalance() {
            // 根据所选语言发出资产负债表了解的提示
            const prompt = this.language === 'English' ? `Please analyze the balance sheets for the past 4 quarters and 2 years:
Please carefully analyze these financial statements, looking at trends in assets, liabilities, and equity over the 4 quarters and 2 years. Pay particular attention to changes in key metrics like cash, debt, inventory, etc. 
First, in a <analysis> section, provide a detailed analysis of what you observe in the balance sheets. Discuss the most notable changes, flag any potential concerns, and highlight strengths or positive trends. Try to uncover insights that would be valuable to the company's management or investors.
Then, in a <summary> section, concisely summarize your overall assessment of the company's financial health and trajectory based on the balance sheet data. Indicate whether the company's financial position appears to be improving, deteriorating or remaining stable over the periods shown.` : 
    `分析过去4个季度和2年的资产负债表：
请仔细分析这些财务报表，观察4个季度和2年中资产、负债和权益的趋势。特别注意现金、债务、库存等关键指标的变化。
首先，在<分析>部分，提供您在资产负债表中观察到的详细分析。讨论最显著的变化，标记任何潜在的问题，并突出优势或积极趋势。尝试发现对公司管理层或投资者有价值的洞察。
然后，在<总结>部分，简洁地总结您基于资产负债表数据对公司财务健康状况和轨迹的整体评估。指出公司的财务状况在所示时期内是在改善、恶化还是保持稳定。`;
            const data = {
                reportType:"bs"
            }
            this.$emit('sendPrompt', prompt,data);
            console.log('Balance Sheet prompt sent.');
        },
        handleGraspCashFlow() {
            // 根据所选语言发出现金流量表了解的提示
            const prompt = this.language === 'English' ? `Please analyze the cash flow statements from the past 4 quarters and 2 years.
Your task is to analyze the cash flow statements and share your insights. Here are the steps to follow:
1. Carefully review the quarterly and annual cash flow statements. Pay close attention to the key cash flow categories like cash from operations, investing, and financing. 
2. Identify trends and material changes across the quarters and years. Consider things like:
- Is operating cash flow growing or shrinking? 
- Is the company investing heavily or pulling back?
- Is the company raising a lot of capital or returning money to shareholders?
- How do the quarterly trends compare to the annual trends?
3. Write up your analysis in an <analysis> section. Discuss the key trends you observed and share any insights into what the cash flows tell you about the company's business and financial health. Aim for 4-5 paragraphs.
4. Summarize the overall picture of the company's cash flow health and trajectory in a <summary> section. In 1-2 paragraphs, succinctly explain whether the cash flow statements indicate the company is in a strong or weak cash position, and if that position is improving or deteriorating. Justify your conclusion.
Remember, focus on the material items and trends in the statements. Avoid getting caught up in the minute details. The goal is to analyze what the cash flows reveal about the company's financial position and future prospects.
Please begin your analysis now. Write your full response, with the <analysis> and <summary> sections.` : 
    `请分析一家公司过去4个季度和2年的现金流量表。
您的任务是分析现金流量表并分享您的见解。请按照以下步骤进行：
1. 仔细审查季度和年度现金流量表。密切关注关键的现金流类别，如经营活动产生的现金、投资活动产生的现金和筹资活动产生的现金。
2. 识别各季度和年度的趋势和重大变化。考虑如下事项：
- 经营现金流是在增长还是在减少？
- 公司是在大举投资还是在收缩？
- 公司是在筹集大量资本还是在向股东返还资金？
- 季度趋势与年度趋势相比如何？
3. 在<分析>部分撰写您的分析。讨论您观察到的关键趋势，并分享现金流向您透露的关于公司业务和财务健康状况的任何见解。目标是4-5段。
4. 在<总结>部分总结公司现金流健康状况和轨迹的整体情况。在1-2段中简洁地解释现金流量表是否表明公司处于强势或弱势现金位置，以及该位置是在改善还是在恶化。请为您的结论提供理由。
\n请记住，重点关注报表中的重大项目和趋势。避免陷入细枝末节的细节。分析的目标是了解现金流量透露的公司财务状况和未来前景。
\n现在请开始您的分析。撰写您的完整回答，包括<分析>和<总结>两个部分。`;
            const data = {
                reportType:"cs"
            }
            this.$emit('sendPrompt', prompt,data);
            console.log('Cash Flow Statement prompt sent.');
        },
    },
    computed: {
        renderedMessage() {
            return this.language === 'English' ?
                `Greetings! As your dedicated financial report analyst, I am here to help you gain a comprehensive understanding of the company's financial reports. On the left side, we've visually represented key financial data to enhance your comprehension. Moving forward, I am ready to offer further assistance.` :
                `您好，作为您的财报分析师，我将协助您全面了解公司的财务状况。在左侧的列表中以”图“的方式展示了财报，以更直观的方式辅助您的理解。接下来，我会进一步提供帮助：`;
        },
        btnProfile() {
            return this.language === 'English' ? 'Company Overview' : '介绍一下这家公司';
        },
        btnAnalysis() {
            return this.language === 'English' ? 'Comprehensive Financial Analysis' : '给出最新财报综合分析';
        },
        btnViewIncome() {
            return this.language === 'English' ? 'Deep Dive Income Statement' : '深度解读利润表';
        },
        btnViewBalance() {
            return this.language === 'English' ? 'Deep Dive Balance Sheet' : '深度解读资产负债表';
        },
        btnViewCashFlow() {
            return this.language === 'English' ? 'Deep Dive Cash Flow Statement' : '深度解读现金流量表';
        },
    },
};
</script>

<style scoped>
.reply-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.thinking {
    margin-left: 24px;
}

.reply-message .message-content {
    margin-left: 32px;
}

.quickmenu {
    background: #FFF;
    margin-left: 12px;
    cursor: pointer;
    height: 32px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 99px;
    margin-top: 8px;
}

::v-deep .quickmenu span {
    cursor: pointer;
    padding: 0px;
    line-height: 1.0em;
}

</style>
