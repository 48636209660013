<template>
    <div class="chat chat-wrapper">
        <div class="report-view-title">
            <span style="padding-left: 28px;">Chat</span>
            <el-dropdown @command="handleLanguageChange">
                <span style="background: #FAFAFA;border-radius: 4px;padding: 8px 12px;">
                    {{ selectedLanguage }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="English">English</el-dropdown-item>
                    <el-dropdown-item command="中文">中文</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div style="flex: 1;padding-bottom: 120px;" class="scrollable-content chat-list" ref="chatContainer">

            <div style="margin-top: 12px;">
                <character-message v-if="!isCompareMode" :language="selectedLanguage" @sendPrompt="sendPrompt">
                </character-message>
                <character-message-PK v-else :language="selectedLanguage" @sendPrompt="sendPrompt">
                </character-message-PK>
            </div>
            <div style="margin-top: 12px;" v-for="chat in chats" :key="chat.id">
                <!-- 使用 v-if 和 v-else-if 根据消息类型决定渲染哪个组件 -->

                <dialogue-message v-if="chat.type === 'dialogue'" :message="chat.message">
                </dialogue-message>
                <reply-message v-else-if="chat.type === 'reply'" :message="chat.message" :isLoading="chat.loading"
                    :replayId="chat.id" :error="chat.error" @retry="handleRetry(chat.id)">
                </reply-message>
            </div>
        </div>
        <div class="chat-input">
            <el-input type="textarea" id="id-reportview-input" placeholder="ask question " v-model="input"
                @keydown.enter.native="submitChatEnter($event)" @compositionstart="handleCompositionStart"
                @compositionend="handleCompositionEnd" :autosize="{ minRows: 1, maxRows: 8 }">
            </el-input>
            <div @click="isChatReplaying ? abortRequest() : submitChat()">
                <div v-if="isChatReplaying" class="loading-icon">
                    <span>■</span>
                </div>
                <i v-else :class="'el-icon-s-promotion'" :style="inputIconStyle"></i>
            </div>
            <!-- <div @click="submitChat"><i class="el-icon-s-promotion" :style="inputIconStyle"></i></div> -->
        </div>
    </div>
</template>
<script>
import CharacterMessage from './CharacterMessage.vue';
import CharacterMessagePK from './CharacterMessagePK.vue';
import DialogueMessage from './DialogueMessage.vue';
import ReplyMessage from './ReplyMessage.vue';
export default {
    name: "ChatReporte",
    props: {
        isCompareMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CharacterMessage,
        DialogueMessage,
        ReplyMessage,
        CharacterMessagePK
    },

    computed: {
        selectedLanguage() {
            return this.$store.getters.getSelectedLanguage;
        },
        // 计算属性来动态改变图标颜色
        inputIconStyle() {
            return {
                fontSize: '24px',
                color: this.input ? '#1457B1' : '#616161' // 如果输入框不为空，则图标颜色为蓝色，否则为黑色
            };
        },
    },
    data() {
        return {
            companyCode: '',
            pkCompanyCode: '', //pk的两家公司
            input: '', // 输入框内容
            chats: [], // 聊天记录
            isChatReplaying: false, // 是否正在发送消息
            composing: false,//修正输入法问题的
            abortController: null,  //chatgpt用到的中断信号
            socket: null, // WebSocket 连接，中断使用
        };
    },

    methods: {
        handleLanguageChange(language) {
            if (this.selectedLanguage !== language) {
                this.$store.dispatch('setLanguage', language);
            }
        },
        // 当用户按下回车键时触发
        handleCompositionStart() {
            this.composing = true;
        },
        handleCompositionEnd() {
            this.composing = false;
        },
        submitChatEnter(event) {
            if (event.shiftKey) {
                // 允许换行
                return;
            }
            if (event.key === 'Enter' && !this.composing) {
                event.preventDefault();
                this.submitChat(); // 调用发送消息的方法
            }
        },
        // 当用户点击发送按钮时触发
        submitChat() {
            if (this.input.trim()) {
                // 将用户的输入添加到聊天数组
                const newMessage = {
                    message: this.input,
                    id: 'u-' + Date.now(),
                    fromUser: true,
                    type: 'dialogue'
                };
                this.chats.push(newMessage);
                // console.log('submitChat:', mId, this.chats);
                // 确保DOM更新后再滚动到底部
                this.$nextTick(() => {
                    const chatContainer = this.$refs.chatContainer; // 假设你的聊天容器有一个ref="chatContainer"
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                });
                // 调用发送到服务端的方法
                this.sendToChatAPI({});
                this.input = ''; // 清空输入框
            }
        },
        resetChat(symbol, symbolPk = '') {
            this.companyCode = symbol;
            this.pkCompanyCode = symbolPk;
            this.chats = [];
            this.treeData = [];
            this.filteredTreeData = [];
        },
        updateChatMessage(id, newMessage, loading, error, updateMessageContent = true) {
            // console.log('updateChatMessage:', id, newMessage, loading, error, updateMessageContent);
            const chatIndex = this.chats.findIndex(c => c.id === id);
            if (chatIndex !== -1) {
                this.$set(this.chats, chatIndex, {
                    ...this.chats[chatIndex],
                    message: updateMessageContent ? newMessage : this.chats[chatIndex].message,
                    loading: loading,
                    error: error
                });
            }
            this.isChatReplaying = loading;
            if (!this.isChatReplaying) {
                this.closeConnection();
            }
        },
        handleRetry(id) {
            const chat = this.chats.find(c => c.id === id);
            if (chat && chat.report_id) {
                this.sendToChatAPI({ id, report_id: chat.report_id, symbol: chat.symbol });
            } else {
                this.sendToChatAPI({ id });
            }
        },

        abortRequest() {
            if (this.abortController) {
                this.abortController.abort();
            }
            if (this.socket) {
                console.log('Closing WebSocket connection');
                this.socket.close(); // 关闭 WebSocket 连接
                // this.socket = null;
            }
        },

        // 手动关闭 WebSocket 连接的方法
        closeConnection() {
            if (this.socket) {
                this.manualClose = true; // 标记为手动关闭
                this.socket.close();
                this.socket = null;
            }
        },
        sendPrompt(prompt, data) {
            console.log('sendPrompt:', prompt, data);
            if (!prompt) {
                return;
            }
            const newMessage = {
                message: prompt,
                id: 'u-' + Date.now(),
                fromUser: true,
                type: 'dialogue',
                report_id: data ? data.id : '',
                symbol: data ? data.symbol : '',
                reportType: data ? data.reportType : '',
            };
            this.chats.push(newMessage);
            // console.log('submitChat:', mId, this.chats);
            // 确保DOM更新后再滚动到底部
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatContainer; // 假设你的聊天容器有一个ref="chatContainer"
                chatContainer.scrollTop = chatContainer.scrollHeight;
            });
            // 调用发送到服务端的方法
            let report_id = '';
            let symbol = '';
            let report_type = '';
            if (data) {
                //说明是unched的数据，模拟一个id
                if (!data.id) {
                    if (data.typeShort) {
                        report_id = 'uncheced^' + data.typeShort + '^' + data.dataType + '^' + data.deadline + '^' + data.months;
                    } else {
                        report_id = '';
                    }
                } else {
                    report_id = data.id;
                }
                symbol = data.symbol;
                report_type = data.reportType;
            }
            console.log('sendPrompt:', prompt, report_id, symbol, report_type);
            this.sendToChatAPI({ report_id: report_id, symbol: symbol, report_type: report_type });
        },
        // 在 Vue 组件的 methods 中添加
        async sendToChatAPI({ id, report_id, symbol,report_type }) {

            if (!this.$store.getters.isAuthenticated) {
                this.$emit('require-login');
                return;
            }
            this.isChatReplaying = true;
            let replayId = id;
            if (replayId) {
                //获取上一次的replayId
                this.updateChatMessage(replayId, '', true, null);
            } else {
                replayId = 'r-' + Date.now();
                this.chats.push({
                    type: 'reply',
                    message: '',
                    loading: true,
                    id: replayId,
                    report_id: report_id,
                    fromAI: true,
                    symbol: symbol,
                });
            }

            const dataToSend = {
                report_id: report_id,
                symbol: symbol ? symbol : this.companyCode,
                symbolPk: symbol ? '' : (this.pkCompanyCode ? this.pkCompanyCode : ''),
                type: report_type,
                token: localStorage.getItem('authToken'),
                language: this.selectedLanguage,
                messages: this.chats
                    .filter(chat => chat.message.trim() !== '')
                    .map(chat => ({
                        role: chat.fromUser ? "user" : "model",
                        content: chat.message
                    }))
            };

            // 建立 WebSocket 连接
            // console.log('WebSocket apiEndpoint:', apiEndpoint);
            this.closeConnection(); // 关闭之前的连接
            const apiEndpoint = process.env.VUE_APP_WS_API_ENDPOINT;
            this.socket = new WebSocket(apiEndpoint);
            // this.socket = new WebSocket('ws://localhost:3002');

            this.socket.onopen = () => {
                // 发送数据到服务端
                // console.log('WebSocket opened:', dataToSend);
                this.socket.send(JSON.stringify(dataToSend));

            };

            this.socket.onmessage = (event) => {
                // console.log('WebSocket message:', event.data);
                try {
                    // 尝试将 JSON 字符串解析为对象
                    var response = JSON.parse(event.data);

                    // 检查解析后的对象是否包含 'endOfStream' 键
                    if (response.endOfStream) {
                        console.log('WebSocket stream ended.');
                        // 这里可以执行一些清理操作或通知用户流已结束
                        this.updateChatMessage(replayId, '', false, null, false);
                    } else if (response.error) {
                        // 如果消息中包含 'error'，则打印错误信息
                        console.error('WebSocket Error:', response.error);
                        this.updateChatMessage(replayId, '', false, response.error, false);
                    } else if (response.message) {
                        // 如果消息中包含 'message'，则更新聊天消息
                        this.updateChatMessage(replayId, response.message, true, null, true);
                    } else {
                        // 如果收到了未知类型的消息，可以选择记录或忽略
                        // console.log('Received unknown message type:', response);
                        this.updateChatMessage(replayId, '', false, 'Received unknown message type.', false);
                    }
                } catch (e) {
                    // 如果解析 JSON 失败，打印错误并更新聊天消息
                    // console.error('Error parsing WebSocket message:', e);
                    this.updateChatMessage(replayId, `Error parsing message: ${event.data}`, false, null, false);
                }
            };

            this.socket.onerror = (error) => {
                // 处理 WebSocket 错误
                console.error('WebSocket error:', error);
                this.updateChatMessage(replayId, '', false, `error: ${error.message}`, false);
            };

            this.socket.onclose = (event) => {
                // 处理 WebSocket 关闭
                console.log('WebSocket closed:', event);
                if (this.socket) {
                    if (!event.wasClean) {
                        this.updateChatMessage(replayId, '', false, 'connection closed unexpectedly.', false);
                    } else {
                        this.updateChatMessage(replayId, '', false, null, false);
                        this.closeConnection();
                    }
                }

            };
        },
    }

}
</script>

<style scoped>
.scrollable-content {
    height: 100%;
    overflow-y: scroll;
    /* 总是显示滚动条 */
    padding: 12px;
    /* 确保内容不会被滚动条遮挡 */
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE和Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.scrollable-content:hover {
    overflow-y: scroll;
}


.chat {
    height: 100%;
    flex-grow: 1;
    /* 填充所有可用空间 */
    overflow-y: auto;
    /* 内容超出时可滚动 */
    display: flex;
    flex-direction: column;
    /* 垂直排列 */
}


.chat-main {
    display: flex;
    flex-direction: column;
    /* 垂直排列 */
    height: 100%;
    margin-left: 12px;
    width: calc(100% - 12px);
}

.chat-wrapper {
    flex: 1;
    border-radius: 12px;
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
    background-image: linear-gradient(to bottom right, rgba(22, 153, 228, 0.15), rgba(167, 0, 248, 0.15));
}

::v-deep.chat-wrapper table {
    background-color: #FFF !important;
}

.chat-list {
    padding: 0px 12px;
}

.chat-input {
    align-items: center;
    display: flex;
    padding: 8px 12px 8px 0px;
    border-radius: 8px;
    border: 1px solid #D7D9EE;
    margin: 12px;
    background: #FFF;
}



/* 如果您希望移除Element UI默认的边框阴影效果 */
::v-deep .chat-input .el-input__inner,
::v-deep .chat-input .el-textarea__inner {
    box-shadow: none;
    max-height: 8em;
}

::v-deep .chat-input .el-textarea .el-textarea__inner {
    width: 100%;
    font-size: 16px;
    min-height: 1em;
    /* 设置为期望的最小高度 */
    height: auto;
    /* 允许自动调整高度 */
    resize: none;
    /* 防止用户调整大小 */
    border: none;
}

.report-view-title {
    height: 48px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DCDFE6;
    font-size: 20px;
    justify-content: space-between;
}
</style>