<template>
    <header class="site-header">
        <div
            style="display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid #EEE; padding:12px 60px;">
            <div class="title-container">
                <div style="display: flex;align-items: center;justify-content: left;">
                    <div @click="goToHomePage" style="cursor: pointer;">
                        <svg data-v-0dd9719b="" version="1.0" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px"
                            viewBox="0 0 60.000000 70.000000" preserveAspectRatio="xMidYMid meet"
                            color-interpolation-filters="sRGB" style="margin: auto;">
                            <g data-v-5c833af0="">
                                <rect data-v-5c833af0="" fill="#333" fill-opacity="0" stroke-width="2" x="0" y="0"
                                    width="60" height="69.28006466139368" class="image-rect"></rect>
                                <svg data-v-5c833af0="" x="0" y="0" width="60" height="69.28006466139368"
                                    filtersec="colorsb9187000062" class="image-svg-svg primary"
                                    style="overflow: visible;">
                                    <svg data-v-5c833af0="" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 91.41999816894531 105.55999755859375">
                                        <g data-v-5c833af0="" fill-rule="evenodd">
                                            <path data-v-5c833af0="" fill="#1a6cb5" opacity=".8"
                                                d="M91.42 79.17l-45.71 26.39L8.5 84.08l82.92-4.91z"></path>
                                            <path data-v-5c833af0="" fill="#6ec3ee" opacity=".8"
                                                d="M45.71 105.56L0 79.17V36.21l45.71 69.35z"></path>
                                            <path data-v-5c833af0="" fill="#f37832" opacity=".8"
                                                d="M0 26.39L45.71 0l37.21 21.48L0 26.39z"></path>
                                            <path data-v-5c833af0="" fill="#ee483c" opacity=".8"
                                                d="M45.71 0l45.71 26.39v42.97L45.71 0z"></path>
                                            <path data-v-5c833af0="" fill="#fdbc3e" opacity=".8"
                                                d="M0 79.17V26.39L37.21 4.91 0 79.17z"></path>
                                            <path data-v-5c833af0="" fill="#c91588" opacity=".8"
                                                d="M91.42 26.39v52.78l-37.21 21.48 37.21-74.26z"></path>
                                        </g>
                                    </svg>
                                </svg>
                            </g>
                        </svg>
                    </div>
                    <span @click="goToHomePage" class="title" style="margin-left: 8px; cursor: pointer;">AI Financial
                        Report
                        Expert</span>
                </div>
                <div v-if="showNavBar"
                    style="display: flex; justify-content: center;align-items:center;margin-left: 12px;">
                    <el-divider direction="vertical"></el-divider>
                    <NavBar />
                </div>
            </div>

            <div style="display: flex;">
                <el-button type="primary" class="user-create-button" round icon="el-icon-upload" @click="createNew"
                    style="margin-right: 12px;">Create My Reports</el-button>

                <div v-if="!isAuthenticated" style="display: flex;flex-direction: row;align-items: center;">
                    <el-button round size="mini" @click="openRegisterFrom" icon="el-icon-user-solid">Sign up
                        for free</el-button>
                    <el-button round size="mini" icon="el-icon-user" class="custom-gradient-button"
                        @click="openLoginForm">Sign
                        In</el-button>
                </div>
                <!-- 已登录状态下显示 -->
                <div v-else style="display: flex;flex-direction: row;align-items: center;">
                    <!-- <el-button icon="el-icon-magic-stick" round size="mini">Share</el-button> -->
                    <el-dropdown>

                        <el-button icon="el-icon-user-solid" round size="medium" class="user-email-button"
                            style="margin-left:12px">
                            {{ userEmailPrefix }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="goToCreatePage"><i class="el-icon-pie-chart"
                                    style="margin-right: 8px;"></i>Report Creater</el-dropdown-item>
                            <el-dropdown-item @click.native="logout"><i class="el-icon-switch-button"
                                    style="margin-right: 8px;"></i>Log Out</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

        </div>
        <login-form ref="loginFormComponent"></login-form>
    </header>
</template>

<script>
import LoginForm from './LoginForm.vue';
import { mapGetters } from 'vuex';
import NavBar from './NavBar.vue';

export default {
    name: 'HeaderComponent',
    data() {
        return {
        }
    },
    components: {
        'login-form': LoginForm,
        'NavBar': NavBar
    },
    methods: {
        goToHomePage() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        },
        goToCreatePage() {
            this.$router.push('/create');
        },
        openLoginForm() {
            this.$refs.loginFormComponent.openDialog();
        },
        openRegisterFrom() {
            this.$refs.loginFormComponent.openRegisterForm();
        },
        openWebPageGpts() {
            const url = 'https://chat.openai.com/g/g-6QU0emKR6-financial-report-expert';
            window.open(url, '_blank');
        },
        createNew() {
            this.$router.push('/create');
        },
        // 登出方法
        logout() {
            this.$store.dispatch('logout');
            this.$router.push('/').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'userEmail']), // 使用 Vuex 的 mapGetters 辅助函数
        userEmailPrefix() {
            const email = this.$store.getters.userEmail;
            return email.split('@')[0];
        },
        showNavBar() {
            return window.innerWidth >= 768;
        }
    }
}
</script>

<style scoped>
.title-container {
    display: flex;
    text-align: center;
    align-items: center;
}

.title {
    /* font-weight: bold; */
    /* font-size: 32px; */
    font-family: serif;
    color: #333;
    justify-content: center;
}

.gpts {
    width: 80px;
    font-family: Helvetica Neue;
    color: #333;
    cursor: pointer;
}

.navigate-button {
    margin-top: 20px;
    /* Adjust as needed */
    /* Add additional styling as desired */
}

.custom-gradient-button {
    background: #22841B;
    border: none;
    /* 移除边框 */
    color: white;
    /* 设置文本颜色为白色 */
}



/* 针对鼠标悬停状态的样式 */
.custom-gradient-button:hover {
    background: linear-gradient(to right, #70B36D, #22841B);
    /* 稍微调暗渐变色 */
}

.user-create-button {
    border: none;
    height: 34px;
    display: flex;
    align-items: center;
}

.user-email-button {
    background: #FF9966;
    border: none;
    color: white;
}

.user-email-button:hover {
    background: #FCC058;
}
</style>
