<template>
    <nav>
        <ul style="display: flex;align-items: center;">
            <li :class="{ active: activeIndex === 1 }">
                <router-link to="/">
                    Home
                </router-link>
            </li>
            <el-divider direction="vertical"></el-divider>
            <li :class="{ active: activeIndex === 2 }">
                <el-dropdown @command="handleMarketCommand" placement="bottom-start" slot="dropdown">
                    <span class="el-dropdown-link">
                        Market <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                        <el-dropdown-item command="US" class="custom-dropdown-item">United States
                            Listed</el-dropdown-item>
                        <el-dropdown-item command="HK" class="custom-dropdown-item">Hong Kong Listed</el-dropdown-item>
                        <el-dropdown-item command="MLCN" class="custom-dropdown-item">Mainland China
                            Listed</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </li>
            <el-divider direction="vertical"></el-divider>
            <li :class="{ active: activeIndex === 3 }">
                <router-link :to="{ path: '/list', query: { orderBy: 'popularity' } }">
                    Popular Company
                </router-link>
            </li>
            <el-divider direction="vertical"></el-divider>
            <li :class="{ active: activeIndex === 5 }">
                <router-link to="/about">
                    About
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            activeIndex: 1,
            showMarketSubmenu: false
        };
    },
    watch: {
        $route(to) {
            this.updateActiveIndex(to);
        }
    },
    mounted() {
        this.updateActiveIndex(this.$route);
    },
    methods: {
        updateActiveIndex(route) {
            switch (route.path) {
                case '/':
                    this.activeIndex = '1';
                    break;
                case '/list':
                    if (route.query.market === 'US') {
                        this.activeIndex = '2-1';
                    } else if (route.query.market === 'HK') {
                        this.activeIndex = '2-2';
                    } else if (route.query.market === 'MLCN') {
                        this.activeIndex = '2-3';
                    } else if (route.query.orderBy === 'popularity') {
                        this.activeIndex = '3';
                    }
                    break;
                case '/create':
                    this.activeIndex = '4';
                    break;
                case '/about':
                    this.activeIndex = '5';
                    break;
                default:
                    this.activeIndex = '1';
            }
        },
        toggleMarketSubmenu() {
            this.showMarketSubmenu = !this.showMarketSubmenu;
        },
        handleMarketCommand(command) {
            this.$router.push({ path: '/list', query: { market: command } });
        }
    }
}
</script>

<style scoped>
nav {
    display: flex;
    justify-content: center;
}

ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 10px;
}

a {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    color: #666;
}

.active {
    font-weight: bold;
}

.el-dropdown-link {
    font-size: 14px;
    color: #666;
}

.custom-dropdown-menu {
    display: flex;
    flex-direction: column;
}

.el-dropdown-menu__item {
    text-align: left;
}
</style>
