<template>
    <div>
        <el-row :gutter="48" v-loading="loading">

            <el-col :span="7" class="form-container" style="z-index:2">

                <!-- 页面1的内容 -->
                <div v-if="currentPage === 1">
                    <div class="form-step-title">Step1 Company Profile & Report Type</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <!-- <div><i class="el-icon-s-operation" style="margin-right: 12px;"></i>1、Update Company Overview</div> -->
                    <el-card>
                        <el-form :model="config" ref="config" label-width="120px" labelPosition="'right" :rules="rules">
                            <el-form-item label="Company Symbol" prop="companyCode" required>
                                <el-input v-model="config.companyCode" placeholder="Company Symbol" readonly
                                    suffix-icon="el-icon-search"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Name" prop="companyName" required>
                                <el-input v-model="config.companyName" placeholder="Company Name"
                                    :disabled="!config.companyCode"></el-input>
                            </el-form-item>
                            <el-form-item label="Ending Date" prop="endDate" required>
                                <el-date-picker v-model="config.endDate" type="date"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="Ended Months" prop="months" required>
                                <el-select v-model="config.months" placeholder="Select Months Ended">
                                    <el-option v-for="item in monthsEndedConfig" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Currency and Unit">
                                <el-cascader v-model="cascaderOptions" :options="cascaderData"
                                    :props="{ expandTrigger: 'hover' }">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="Title" prop="title" required>
                                <el-input :value="config.title" @input="userTypedTitle"></el-input>
                            </el-form-item>
                            <el-form-item label="Subtitle">
                                <el-input v-model="config.subtitle" clearable></el-input>
                            </el-form-item>

                        </el-form>
                    </el-card>
                </div>

                <div v-if="currentPage === 2">
                    <div class="form-step-title">Step2 Operating activities</div>
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button icon="el-icon-chat-line-round" round class="source-button"
                            @click="toggleSourcePannel">View Source Data</el-button>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.cashBegingBalance"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.caseEndingBalance"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.netCashFlow"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <editable-label :node="form.operatingActivities"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraOAItems"
                                :key="`extra-oa-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeOAItem(index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>

                            <el-form-item>
                                <el-button type="text" @click="addOAItem">+ Add Operating Activity</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
                <div v-if="currentPage === 3">
                    <div>
                        <div class="form-step-title">Step3 Investing activities</div>
                        <div style="display: flex;justify-content: flex-end;">
                            <el-button icon="el-icon-chat-line-round" round class="source-button"
                                @click="toggleSourcePannel">View Source Data</el-button>
                        </div>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.investingActivities"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraIAItems"
                                :key="`extra-ia-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeIAItem(index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addIAItem">+ Add Investing Activity</el-button>
                            </el-form-item>
                        </el-form>

                    </el-card>
                </div>
                <div v-if="currentPage === 4">
                    <div>
                        <div class="form-step-title">Financing activities</div>
                        <div style="display: flex;justify-content: flex-end;">
                            <el-button icon="el-icon-chat-line-round" round class="source-button"
                                @click="toggleSourcePannel">View Source Data</el-button>
                        </div>
                    </div>
                    <el-card>
                        <el-form>
                            <el-form-item>
                                <editable-label :node="form.financingActivities"
                                    @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item class="group" v-for="(item, index) in form.extraFAItems"
                                :key="`extra-fa-${index}`" style="padding-left: 4%;">
                                <el-button type="text" icon="el-icon-delete" @click="removeFAItem(index)"
                                    style="margin-right: 12px;"></el-button>
                                <editable-label :node="item" @update:node="handleNodeChange"></editable-label>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" @click="addFAItem">+ Add Financing Activity</el-button>
                            </el-form-item>
                        </el-form>

                    </el-card>
                </div>
                <!-- 分页器 -->
                <el-pagination layout="prev, pager, next" :total="4" :current-page.sync="currentPage"
                    @current-change="handlePageChange" :page-size="1" background
                    style="display: flex;justify-content: right;margin-top: 24px;">
                </el-pagination>


            </el-col>
            <el-col :span="17" style="z-index=0">
                <div style="display: flex;height:100%;flex-direction: column;align-items: center;">
                    <div
                        style="width: 100%;margin:24px;padding:24px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <div v-show="!edited" style="font-size:24px;color:#333">In Just <b
                                style="color:#FC7A6E;font-size:28px">4</b> Steps
                            To
                            Master the Art of Cash Flow Management for Financial Success</div>
                        <div v-show="!edited" style="font-size:14px;color:#666;margin-top:12px">We pursue the accuracy
                            of
                            data, so please
                            prepare financial statement data that has been checked.</div>
                        <div class="steps-container">
                            <div class="view">
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(1)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(1) }">1</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(1) }">Report Profiles
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(2)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(2) }">2</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(2) }">Operating
                                        Activities
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(3)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(3) }">3</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(3) }">Investing
                                        Activities
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <div class="step" @click="handlePageChange(4)">
                                    <div class="step-marker" :class="{ 'finish': isStepFinished(4) }">4</div>
                                    <div class="step-title" :class="{ 'finish': isStepFinished(4) }">Financing
                                        Activities
                                    </div>
                                </div>
                                <el-divider></el-divider>

                            </div>
                            <div style="display:flex;justify-content: flex-end;margin-left:24px">
                                <el-popconfirm confirm-button-text="New" cancel-button-text="Cancel" icon="el-icon-info"
                                    icon-color="red" title="Create new report?" @confirm="showSearchSymbolDialog">
                                    <el-button slot="reference" icon="el-icon-search" round
                                        style="margin-right: 12px; height:40px">New</el-button>
                                </el-popconfirm>

                                <!-- <el-popconfirm confirm-button-text="Save as  Publish" cancel-button-text="Save as Private"
                                    icon="el-icon-upload" icon-color="green" title="How would you like to save this report?"
                                    @confirm="saveReportData(true)" @cancel="saveReportData(false)"
                                    cancel-button-type="primary" confirm-button-type="success">
                                    <el-button round slot="reference" type="success" style="margin-right: 12px;"
                                        icon="el-icon-upload">
                                        Save
                                    </el-button>
                                </el-popconfirm> -->
                                <el-button round slot="reference" @click="saveReportData" type="success"
                                    style="margin-right: 12px;height:40px" icon="el-icon-upload">
                                    Save
                                </el-button>
                                <el-button round style="height:40px" @click="triggerDownload" type="primary"
                                    icon="el-icon-download">Download
                                </el-button>
                            </div>
                        </div>

                    </div>
                    <el-card class="sankey-container">

                        <div class="image-container" style="flex:1">
                            <CashFlowSankey ref="customerSankeyRef" :sankey-data="sankeyData" />
                        </div>
                    </el-card>
                </div>

            </el-col>

        </el-row>
        <el-drawer title="Financial Report Assistant" :visible.sync="showSourcePanal" direction="rtl"
            :before-close="handleClosePannel" :wrapperClosable="false" :modal="false" :append-to-body="true" size="35%">
            <source-panel :source="source" />
        </el-drawer>
    </div>
</template>

<script>
import CashFlowSankey from './CashFlowSankey.vue';
import BaseForm from './BaseForm.vue';
import Tools from '../../GlobalTools';
import EditableLabel from './EditableLabel.vue';
import SourcePanel from './SourceDataForm.vue';
import axios from 'axios';
export default {
    extends: BaseForm,
    components: {
        SourcePanel,
        EditableLabel,
        CashFlowSankey // Correct the component name to match the imported name
    },
    props: {
        report: {
            type: Object,
            default: () => { }
        }
    },
    mounted() {
        this.getReportDetail();
    },
    data() {
        return {
            config: {
                title: '',
                subtitle: '',
                companyName: '',
                companyCode: '',
                reportType: '',
                endDate: '',
                currency: '',
                unit: '',
                color: '',
                months: '',
                maxleaf: 0,
            },
            form: {
                cashBegingBalance: {
                    id: 'cashBegingBalance',
                    desc: 'Cash Beging Balance',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 0,
                    type: 'desc-node-left',
                    vi: ''
                },
                caseEndingBalance: {
                    id: 'caseEndingBalance',
                    desc: 'Cash Ending Balance',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 0,
                    type: 'desc-node-right',
                    vi: ''
                },
                netCashFlow: {
                    id: 'netCashFlow',
                    desc: 'Net Cash Flow',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 4,
                    vi: '',
                    type: 'rect'
                },

                SubSumInCash: {
                    id: 'SubSumInCash',
                    desc: '',
                    v: 0,
                    weight: 0,
                    color: 'green',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashOA: {
                    id: 'SubSumIncashOA',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'blue-v1',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashIA: {
                    id: 'SubSumIncashIA',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'green-v1',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumIncashFA: {
                    id: 'SubSumIncashFA',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red-v1',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutCash: {
                    id: 'SubSumOutCash',
                    desc: '',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'

                },
                SubSumOutcashOA: {
                    id: 'SubSumOutcashOA',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutcashIA: {
                    id: 'SubSumOutcashIA',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'desc-node-sub'
                },
                SubSumOutcashFA: {
                    id: 'SubSumOutcashFA',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red',
                    yy: '',
                    sort: 3,
                    vi: '',
                    type: 'desc-node-sub'
                },
                operatingActivities: {
                    id: 'operatingActivities',
                    desc: 'Operating Activities',
                    v: 0,
                    weight: 0,
                    color: 'blue-v1',
                    yy: '',
                    sort: 1,
                    vi: '',
                    type: 'rect'
                },
                extraOAItems: [],
                investingActivities: {
                    id: 'investingActivities',
                    desc: 'Investing Activities',
                    v: 0,
                    weight: 0,
                    color: 'green-v1',
                    yy: '',
                    sort: 2,
                    vi: '',
                    type: 'rect'
                },
                extraIAItems: [],
                financingActivities: {
                    id: 'financingActivities',
                    desc: 'Financing Activities',
                    v: 0,
                    weight: 0,
                    color: 'red-v1',
                    yy: '',
                    sort: 0,
                    vi: '',
                    type: 'rect'
                },
                extraFAItems: [],

            },
            nodes: [],
            links: [],
            rules: {
                companyCode: [
                    { required: true, message: 'Please enter the company symbol', trigger: 'blur' }
                ],
                companyName: [
                    { required: true, message: 'Please enter the company name', trigger: 'blur' }
                ],
                reportType: [
                    { required: true, message: 'Please select a report type', trigger: 'change' }
                ],
                endDate: [
                    { required: true, message: 'Please select an end date', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Please enter a title', trigger: 'blur' }
                ],
                cascaderOptions: [
                    { required: true, message: 'Please select a currency', trigger: 'change' }
                ],
                months: [
                    { required: true, message: 'Please select months ended', trigger: 'blur' }
                ]
                // ... other validation rules
            }
        };

    },
    // 方法和逻辑可以根据需求添加
    methods: {
        newNode(id, desc = '', v = 0, color = 'green', yy = '', sort = 0, vi = '') {
            return {
                id,
                desc,
                v,
                weight: Math.abs(v),
                color,
                yy,
                sort,
                vi
            }
        },
        setColorAndSort(nodeId, newNode) {
            newNode.color = newNode.v > 0 ? 'green' : 'red';
            // console.log('setColorAndSort:', newNode.id, newNode.v, newNode.color);
        },
        //自动求和收入
        autoCalcNetCashflow() {
            this.form.netCashFlow.v = this.form.caseEndingBalance.v - this.form.cashBegingBalance.v;
            // console.log('autoCalcNetCashflow:', this.form.netCashFlow.v, this.form.caseEndingBalance.v, this.form.cashBegingBalance.v);
            this.form.netCashFlow.vi = this.form.netCashFlow.v + '';
            this.form.netCashFlow.weight = Math.abs(this.form.netCashFlow.v);
            this.form.netCashFlow.color = this.form.netCashFlow.v > 0 ? 'green' : 'red';
        },
        autoCalcGroupSum(groupId) {
            var sum = 0;
            switch (groupId) {
                case 'operatingActivities':
                    sum = this.form.extraOAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.operatingActivities.v = sum;
                    this.form.operatingActivities.vi = sum + '';
                    this.form.operatingActivities.weight = Math.abs(sum);
                    this.form.operatingActivities.color = 'blue-v1';
                    break;
                case 'investingActivities':
                    sum = this.form.extraIAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.investingActivities.v = sum;
                    this.form.investingActivities.vi = sum + '';
                    this.form.investingActivities.weight = Math.abs(sum);
                    this.form.investingActivities.color = 'green-v1';
                    break;
                case 'financingActivities':
                    sum = this.form.extraFAItems.reduce((sum, item) => sum + item.v, 0);
                    this.form.financingActivities.v = sum;
                    this.form.financingActivities.vi = sum + '';
                    this.form.financingActivities.weight = Math.abs(sum);
                    this.form.financingActivities.color = 'red-v1';
                    break;
                default: break;
            }
        },
        handleNodeChange(newNode, nodeId) {
            // 根据 nodeId 找到相应的 node 并更新颜色
            if (nodeId.startsWith('extra-oa')) {
                let index = nodeId.split('-')[2];
                newNode.sort = this.getFormNodeSort('operatingActivities', 1);
                this.$set(this.form.extraOAItems, index, newNode);
                newNode.color = 'blue-v1';
                this.autoCalcGroupSum('operatingActivities');
            } else if (nodeId.startsWith('extra-ia')) {
                let index = nodeId.split('-')[2];
                newNode.sort = this.getFormNodeSort('investingActivities', 2);
                this.$set(this.form.extraIAItems, index, newNode);
                newNode.color = 'green-v1';
                this.autoCalcGroupSum('investingActivities');
            } else if (nodeId.startsWith('extra-fa')) {
                let index = nodeId.split('-')[2];
                newNode.sort = this.getFormNodeSort('financingActivities', 0);
                this.$set(this.form.extraFAItems, index, newNode);
                newNode.color = 'red-v1';
                this.autoCalcGroupSum('financingActivities');
            }
            else {
                this.setColorAndSort(nodeId, newNode);
                if (this.form[nodeId]) {
                    this.form[nodeId] = newNode;
                }
                if (nodeId === 'cashBegingBalance' || nodeId === 'caseEndingBalance') {
                    this.autoCalcNetCashflow();
                }
            }
        },
        addOAItem() {
            // 添加一个新的对象到数组，每个对象代表一个新的表单项
            this.form.extraOAItems.push(this.newNode(`extra-oa-${this.form.extraOAItems.length}`));
        },
        removeOAItem(index) {
            this.form.extraOAItems.splice(index, 1);
            this.autoCalcGroupSum('operatingActivities');
        },
        addIAItem() {
            // 添加一个新的对象到数组，每个对象代表一个新的表单项
            this.form.extraIAItems.push(this.newNode(`extra-ia-${this.form.extraIAItems.length}`));
            this.autoCalcGroupSum('investingActivities');

        },
        removeIAItem(index) {
            // Removes the income item at the given index
            this.form.extraIAItems.splice(index, 1);
            this.autoCalcGroupSum('investingActivities');
        },
        addFAItem() {
            // 添加一个新的对象到数组，每个对象代表一个新的表单项
            this.form.extraFAItems.push(this.newNode(`extra-fa-${this.form.extraFAItems.length}`));
            this.autoCalcGroupSum('financingActivities');
        },
        removeFAItem(index) {
            this.form.extraFAItems.splice(index, 1);
            this.autoCalcGroupSum('financingActivities');
        },
        getFormNodeSort(id, sortDefault) {
            if (id.startsWith('extra-oa')) {
                return 3;
            }
            if (id.startsWith('extra-ia')) {
                return 2;
            }
            if (id.startsWith('extra-fa')) {
                return 1;
            }
            if (id.startsWith('netCashFlow')) {
                return 4;
            }
            if (this.form[id]) {
                return this.form[id].sort;
            }
            return sortDefault;
        },
        updateSubSumIncome(sumOA, sumIA, sumFA) {
            console.log('updateSubSumIncome:', sumOA, sumIA, sumFA);
            if (sumOA > 0) {
                if (!this.form.SubSumIncashOA || this.form.SubSumIncashOA.v !== sumOA) {
                    this.form.SubSumIncashOA = this.newNode('SubSumIncashOA', 'Operating Activities', sumOA, 'blue-v1', '', 3, sumOA + '');
                }
            } else {
                if (this.form.SubSumIncashOA)
                    this.form.SubSumIncashOA.v = 0;
            }
            if (sumIA > 0) {
                if (!this.form.SubSumIncashIA || this.form.SubSumIncashIA.v !== sumIA) {
                    this.form.SubSumIncashIA = this.newNode('SubSumIncashIA', 'Investing Activities', sumIA, 'green-v1', '', 2, sumIA + '');
                }
            } else {
                if (this.form.SubSumIncashIA) {
                    this.form.SubSumIncashIA.v = 0;
                }
            }
            if (sumFA > 0) {
                if (!this.form.SubSumIncashFA || this.form.SubSumIncashFA.v !== sumFA) {
                    this.form.SubSumIncashFA = this.newNode('SubSumIncashFA', 'Financing Activities', sumFA, 'red-v1', '', 1, sumFA + '');
                }
            } else {
                if (this.form.SubSumIncashFA) {
                    this.form.SubSumIncashFA.v = 0;
                }
            }
            let sum = sumOA + sumIA + sumFA;
            if (!this.form.SubSumInCash || this.form.SubSumInCash.v !== sum) {
                this.form.SubSumInCash = this.newNode('SubSumInCash', 'Total Cash Inflows', sum, 'gray', '', 3, sum + '');
            }
        },
        updateSubSumOutcome(sumOA, sumIA, sumFA) {
            console.log('updateSubSumOutcome:', sumOA, sumIA, sumFA);
            if (sumOA < 0) {
                if (!this.form.SubSumOutcashOA || this.form.SubSumOutcashOA.v !== sumOA) {
                    this.form.SubSumOutcashOA = this.newNode('SubSumOutcashOA', 'Operating Activities', sumOA, 'blue-v1', '', 3, sumOA + '');
                }
            } else {
                if (this.form.SubSumOutcashOA) {
                    this.form.SubSumOutcashOA.v = 0;
                }
            }
            if (sumIA < 0) {
                if (!this.form.SubSumOutcashIA || this.form.SubSumOutcashIA.v !== sumIA) {
                    this.form.SubSumOutcashIA = this.newNode('SubSumOutcashIA', 'Investing Activities', sumIA, 'green-v1', '', 2, sumIA + '');
                }
            } else {
                if (this.form.SubSumOutcashIA) {
                    this.form.SubSumOutcashIA.v = 0;
                }
            }
            if (sumFA < 0) {
                if (!this.form.SubSumOutcashFA || this.form.SubSumOutcashFA.v !== sumFA) {
                    this.form.SubSumOutcashFA = this.newNode('SubSumOutcashFA', 'Financing Activities', sumFA, 'red-v1', '', 1, sumFA + '');
                }
            } else {
                if (this.form.SubSumOutcashFA) {
                    this.form.SubSumOutcashFA.v = 0;
                }
            }
            let sum = sumOA + sumIA + sumFA;
            if (!this.form.SubSumOutCash || this.form.SubSumOutCash.v !== sum) {
                this.form.SubSumOutCash = this.newNode('SubSumOutCash', 'Total Cash Outflows', sum, 'gray', '', 3, sum + '');
            }
        },

        generateNodes() {
            const createNode = (item) => { // 使用箭头函数
                let type = item.type;
                if (['netCashFlow', 'operatingActivities', 'investingActivities', 'financingActivities'].includes(item.id)) {
                    type = 'rect';
                }
                // let sort = this.getFormNodeSort(item.id, item.sort);

                return {
                    id: item.id,
                    v: item.v,
                    weight: Math.abs(item.v),
                    // fixedValue: Math.abs(item.v),
                    color: Tools.parseNodeColor(item.color),
                    name: item.id,
                    desc: item.desc,
                    yy: item.yy,
                    vi: item.vi,
                    type: type,
                    margin: null // Initialize margin
                }

            };
            let leftleaf = 0;
            let rightleaf = 0;
            const nodes = [];
            let cashInOA = 0;
            let cashInIA = 0;
            let cashInFA = 0;
            let cashOutOA = 0;
            let cashOutIA = 0;
            let cashOutFA = 0;
            this.form.extraOAItems.forEach(item => {
                if (item.v > 0) {
                    cashInOA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutOA += item.v;
                    rightleaf++;
                }

            });
            if (cashInOA === 0 && this.form.operatingActivities.v > 0) {
                cashInOA = this.form.operatingActivities.v;
            }
            if (cashOutOA === 0 && this.form.operatingActivities.v < 0) {
                cashOutOA = this.form.operatingActivities.v;
            }

            this.form.extraIAItems.forEach(item => {
                if (item.v > 0) {
                    cashInIA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutIA += item.v;
                    rightleaf++;
                }
            });
            if (cashInIA === 0 && this.form.investingActivities.v > 0) {
                cashInIA = this.form.investingActivities.v;
            }
            if (cashOutIA === 0 && this.form.investingActivities.v < 0) {
                cashOutIA = this.form.investingActivities.v;
            }
            this.form.extraFAItems.forEach(item => {
                if (item.v > 0) {
                    cashInFA += item.v;
                    leftleaf++;
                }
                else {
                    cashOutFA += item.v;
                    rightleaf++;
                }
            });
            if (cashInFA === 0 && this.form.financingActivities.v > 0) {
                cashInFA = this.form.financingActivities.v;
            }
            if (cashOutFA === 0 && this.form.financingActivities.v < 0) {
                cashOutFA = this.form.financingActivities.v;
            }

            this.maxleaf = Math.max(leftleaf, rightleaf);
            this.updateSubSumIncome(cashInOA, cashInIA, cashInFA);
            this.updateSubSumOutcome(cashOutOA, cashOutIA, cashOutFA);
            Object.keys(this.form).forEach(key => {
                const item = this.form[key];
                // 检查是否是数字或可转换字符串并且大于0
                if (item && !isNaN(parseFloat(item.v)) && parseFloat(item.v) !== 0) {
                    if (!['cashBegingBalance', 'caseEndingBalance', 'operatingActivities', 'investingActivities', 'financingActivities'].includes(item.id)) {
                        nodes.push(createNode(item));
                    }
                }
                // 如果是数组，遍历数组
                else if (item && Array.isArray(item)) {
                    item.forEach(subItem => {
                        if (!isNaN(parseFloat(subItem.v)) && parseFloat(subItem.v) !== 0) {
                            nodes.push(createNode(subItem));
                        }
                    });
                }
            });
            return nodes;
        },
        generateLinks() {
            const createLink = (source, target, value, color, sort = 0) => {
                // console.log('createLink:', source, target, value, color);
                return {
                    source,
                    target,
                    value: Math.abs(value),
                    color: Tools.parseLinkColor(color),
                    sort
                };
            };
            var links = [];
            this.form.extraOAItems.forEach((item) => {
                if (item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashOA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashOA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            this.form.extraIAItems.forEach((item) => {
                if (item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashIA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashIA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            this.form.extraFAItems.forEach((item) => {
                if (item.v !== 0) {
                    if (item.v > 0) {
                        links.push(createLink(item.id, 'SubSumIncashFA', item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    } else {
                        links.push(createLink('SubSumOutcashFA', item.id, item.v, item.color, this.getFormNodeSort(item.id, item.sort)));
                    }
                }
            });
            if (this.getFormKeyV('SubSumIncashOA') > 0) {
                links.push(createLink('SubSumIncashOA', 'SubSumInCash', this.getFormKeyV('SubSumIncashOA'), 'blue-v1', 3));
            } else {
                // console.log('SubSumIncashOA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashOA'));
            }
            if (this.getFormKeyV('SubSumIncashIA') > 0) {
                links.push(createLink('SubSumIncashIA', 'SubSumInCash', this.getFormKeyV('SubSumIncashIA'), 'green-v1', 2));
            } else {
                // console.log('SubSumIncashIA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashIA'));
            }
            if (this.getFormKeyV('SubSumIncashFA') > 0) {
                links.push(createLink('SubSumIncashFA', 'SubSumInCash', this.getFormKeyV('SubSumIncashFA'), 'red-v1', 1));
            } else {
                // console.log('SubSumIncashFA -> SubSumInCash failed:', this.getFormKeyV('SubSumIncashFA'));
            }
            if (this.getFormKeyV('SubSumOutcashOA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashOA', this.getFormKeyV('SubSumOutcashOA'), 'blue-v1', 3));
            }
            if (this.getFormKeyV('SubSumOutcashIA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashIA', this.getFormKeyV('SubSumOutcashIA'), 'green-v1', 2));
            }
            if (this.getFormKeyV('SubSumOutcashFA') < 0) {
                links.push(createLink('SubSumOutCash', 'SubSumOutcashFA', this.getFormKeyV('SubSumOutcashFA'), 'red-v1', 1));
            }

            if (this.getFormKeyV('netCashFlow') > 0) {
                links.push(createLink('SubSumInCash', 'netCashFlow', this.getFormKeyV('netCashFlow'), 'green', 4));
                links.push(createLink('SubSumInCash', 'SubSumOutCash', this.getFormKeyAbsV('SubSumOutCash'), 'gray', 4));
            } else {
                links.push(createLink('netCashFlow', 'SubSumOutCash', this.getFormKeyV('netCashFlow'), 'red', 4));
                links.push(createLink('SubSumInCash', 'SubSumOutCash', this.getFormKeyAbsV('SubSumInCash'), 'gray', 4));
            }

            return links;
        },
        generateSankeyData() {
            // Transform form data to the format expected by the Sankey component
            try {
                this.nodes = this.generateNodes();
            } catch (e) {
                console.log('generateNodes exception:', e);
            }
            try {
                this.links = this.generateLinks();
            } catch (e) {
                console.log('generateLinks exception:', e);

            }
            // Tools.logObj('generateSankeyData Nodes:', this.nodes);
            // Tools.logObj('generateSankeyData Links:', this.links);
            return {
                title: this.config.title,
                // logo: this.config.logo,
                industry: this.config.industry,
                subtitle: this.config.subtitle,
                unit: this.config.unit,
                currency: this.config.currency,
                nodes: this.nodes,
                links: this.links,
                companyCode: this.config.companyCode,
                companyName: this.config.companyName,
                cashBegin: this.form.cashBegingBalance.v,
                cashEnd: this.form.caseEndingBalance.v,
                netCash: this.form.netCashFlow.v,
                maxleaf: this.maxleaf,
                activities: [this.form.operatingActivities, this.form.investingActivities, this.form.financingActivities]
            };
        },
        getFormateDate() {
            let endDate = new Date(this.config.endDate);

            // 使用 toLocaleDateString 方法格式化日期为英文格式
            let formattedDate = endDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
            });

            // 添加逗号和修改月份的点号
            formattedDate = formattedDate.replace(',', '').replace(' ', ' ');
            return formattedDate;
        },
        generateTitle() {
            // 如果用户没有编辑过标题，则生成新标题
            // console.log('generateTitle:', this.userHasEditedTitle, this.config.title);
            if (!this.userHasEditedTitle) {
                this.config.title = `${this.config.companyName} Cash Flow Statement ${this.getFormateDate()}`;
            }
        },
        generateSubTitle() {
            if (!this.userHasEditedTitle) {
                let subtitle = `${this.config.months} Ended, Currency: ${this.config.currency}, Unit: ${this.config.unit}`;
                // 设置标题
                this.config.subtitle = subtitle;
            }
            this.validateForm();

        },

        loadData(config, form) {
            // console.log('loadData:', config, form);
            this.userHasEditedTitle = true;
            this.config.companyName = config.companyName;
            this.config.color = config.color;
            this.config.currency = config.currency;
            this.config.endDate = config.endDate;
            this.config.reportType = config.reportType;
            this.config.subtitle = config.subtitle;
            this.config.unit = config.unit;
            this.config.maxleaf = config.maxleaf;
            this.cascaderOptions = [this.config.currency, this.config.unit];
            this.config.months = config.months ? config.months : (this.report.months ? this.report.months : '3months');
            this.config.industry = config.industry;

            this.form = form;

            // 如果加载的数据中没有 title 或 title 为空，则生成新标题
            if (Tools.isEmpty(config.title)) {
                this.userHasEditedTitle = false;
                this.generateTitle();
            } else {
                // 如果加载的数据中有 title，则使用它并设置标志为 true
                // console.log('loadData update title:', config.title);
                this.$nextTick(() => {
                    this.config.title = config.title;
                    this.userHasEditedTitle = false;
                });

            }
        },

        formateDate(date_ending) {
            // Regular expression to check if the date is in 'YYYY-MM-DD' format
            const validDateFormat = /^\d{4}-\d{2}-\d{2}$/;

            // Check if the date is already in a valid format
            if (validDateFormat.test(date_ending)) {
                return date_ending;
            } else {
                // Extract the year, month, and day from the date string
                const year = date_ending.substring(0, 4);
                const month = date_ending.substring(4, 6);
                const day = date_ending.substring(6, 8);

                // Construct a date string in the 'YYYY-MM-DD' format
                const formattedDateString = `${year}-${month}-${day}`;
                return formattedDateString;
            }
        },

        triggerDownload() {
            this.$refs.customerSankeyRef.downloadSankeyImage();
        },


        async saveReportData() {
            // 启用 loading
            this.loading = true;
            let publicReport = this.companyType === 'LC';
            try {
                const imageUrl = await this.$refs.customerSankeyRef.uploadImgToOSS();
                const formattedEndDate = Tools.formatDateToYYYYMMDD(this.config.endDate);
                const dateType = this.config.months !== '12months' ? 'Q' : 'Y';
                const reportData = {
                    company: this.config.companyName,
                    symbol: this.config.companyCode,
                    publicReport: publicReport,
                    reportType: 'CASHFLOW',
                    months: this.config.months,
                    dateType: dateType, // 你可以根据需求调整
                    dateEnding: formattedEndDate,
                    data: {
                        title: this.config.title,
                        config: { ...this.config },
                        form: this.extractFormData()
                    },
                    reviewLink: imageUrl
                };

                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                // 发送请求
                const response = await axios.post(`${apiEndpoint}/updateCompanyReport`, reportData);

                // 请求成功后取消 loading
                this.loading = false;

                if (response.data) {
                    // console.log('Report data saved successfully:', response.data);

                    // 显示弹窗，而不是简单的消息
                    this.$msgbox({
                        title: 'Operation Successful',
                        message: 'The report data has been saved. \n Please select your next action:',
                        showCancelButton: true,
                        confirmButtonText: 'Create New Report',
                        cancelButtonText: 'Return to Editing',
                        type: 'success'
                    }).then(() => {
                        // 如果用户选择新建其他报告，执行相应操作
                        // console.log('用户选择新建其他报告');
                        window.location.reload();
                    }).catch(() => {
                        // 如果用户选择继续编辑，执行相应操作
                        // console.log('用户选择继续编辑');
                    });
                }

            } catch (error) {
                // 请求失败后取消 loading
                this.loading = false;

                if (error.response) {
                    // 打印服务端返回的错误信息
                    console.error('Server error:', error.response.data);
                    // 显示错误消息
                    this.$message.error(`${error.response.data.error || 'Unknown error'}`);
                } else {
                    // 如果没有收到服务端的响应
                    console.error('Error saving report data:', error);
                    this.$message.error('Error occurred while saving the report data.');
                }
            }

        },
        async getReportDetail() {
            // console.log('getReportDetail:', this.report);
            this.config.companyCode = this.report.symbol;
            this.config.companyName = this.report.name;
            this.config.title = this.report.title;
            this.config.months = this.report.months || '3months';
            this.config.reportType = this.report.dateType;
            this.source = this.report;
            if (this.report.deadline) {
                this.loading = true;
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const params = {
                    symbol: this.report.symbol,
                    deadline: this.report.deadline,
                    status: this.report.status,
                    type: this.report.type,
                    dateType: this.report.dateType,
                    months: this.report.months,
                };
                axios.post(`${apiEndpoint}/getReportDetail`, params).then(response => {
                    this.loading = false;
                    if (response.data && response.data.result) {
                        var reportDetail = response.data.result;
                        if (reportDetail.source === 'papermoney') {
                            this.companyCode = this.report.symbol;
                            this.companyName = this.report.name;
                            this.loadData(reportDetail.data.config, reportDetail.data.form);
                        }
                    }
                }).catch(error => {
                    console.log('getReportDetail error:', error);
                    this.loading = false;
                });
            }
        },
        async validateForm() {
            if (this.$refs.config) {
                const valid = await this.$refs.config.validate();
                this.isFormValid = valid;
            }
        },

        isStepFinished(step) {
            if (step === 4) {
                return true;
            }
        }
    },
};
</script>
