<template>
    <div class="home-private">
        <div style="display: flex;">
            <div class="row-flex">
                <div class="column-flex" v-for="(item, index) in combinedItems" :key="index">
                    <el-card class="card-button" :class="{ 'new-card': item.isNew }">
                        <div @click="createNew" v-if="item.isNew"
                            style="display: flex;align-items: center;flex-direction: column;color: #7AB5FB;font-weight: bold;">
                            <i :class="item.icon" style="font-size: 48px;"></i>
                            <div class="desc">{{ item.name }}</div>
                        </div>
                        <div @click="openReportViewer(item)" v-else style="display: flex;align-items: center;flex-direction: column;">
                            <div class="title">{{ item.name }}</div>
                            <div class="desc">View and Get Insights</div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>

        <div v-if="privateCompanies.length > 4" style="display: flex; justify-content: flex-end;">
            <el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="itemsPerPage"
                :total="privateCompanies.length" layout="prev, pager, next">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            privateCompanies: [],
            currentPage: 1,
            itemsPerPage: 4
        }
    },
    mounted() {
        this.getPrivateCompanies();
    },
    computed: {
        combinedItems() {
            // 创建一个新的数组，第一项是创建新报告的选项
            let items = [{
                name: 'Create New Visual Report',
                icon: 'el-icon-plus', // 这里添加icon的类名
                isNew: true // 这是一个标记，用于区分新建选项和其他项
            }];
            // 将当前页的公司数据添加到数组中
            return items.concat(this.displayItems);
        },
        displayItems() {
            // 根据当前页码和每页显示数量来决定显示哪些公司
            let start = (this.currentPage - 1) * this.itemsPerPage;
            let end = start + this.itemsPerPage;
            return this.privateCompanies.slice(start, end);
        },
    },
    methods: {
        getPrivateCompanies() {
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.post(`${apiEndpoint}/getPrivateCompanyList`, {/* Your data here */ })
                .then(response => {
                    this.privateCompanies = response.data?.privateCompanies; // 存储响应数据
                    console.log('getPrivateCompanyList response:', this.privateCompanies);
                }
                )
                .catch(error => {
                    console.error('Error:', error);
                });

        },
        openReportViewer(company) {
            console.log('openReportViewer:', company);
            this.$emit('open-report-detail', {symbol: company.symbol, name: company.name});
        },
        createNew() {
            this.$emit('create-new');
        },
        handlePageChange(newPage) {
            this.currentPage = newPage;
        }
    }
}
</script>

<style scoped>
.recommend div:hover {
    scale: 1.02;
}

.recommend .img-grid {
    display: block;
    border-radius: 8px;
    cursor: pointer;
}

.row-flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px; /* Adjust to your gutter */
    margin-left: -10px; /* Adjust to your gutter */
}

.column-flex {
    max-width: 20%; /* Each column takes up 20% of the container width */
    padding-right: 10px; /* Adjust to your gutter */
    padding-left: 10px; /* Adjust to your gutter */
}

.recommend .desc-grid {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.recommend .desc-grid span {
    font-size: 16px;
    font-weight: 500;
    color: #777;
    cursor: pointer;

}

.recommend .desc-lastmodify {
    font-size: 14px;
    font-weight: 300;
    color: #999;
    margin-left: 12px;
    cursor: pointer;
}

.icon-image {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}

.home-private {
    width: 100%;
}

.home-private .card-button {
    /* margin-top: 24px; */
    padding: 12px;
    /* width: ; */
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}


.home-private .card-button:hover {
    scale: 1.1;
}

.home-private .card-button div {
    cursor: pointer;
}

.home-private .card-button .title {
    font-size: 20px;
    color: 333;
    font-weight: 500;
    text-align: center;
    height: 2.5em;
}

.home-private .card-button .desc {
    font-size: 16px;
    color: #666;
    font-weight: 300;
    padding-top: 8px;
}

.home-private>div:last-child {
    margin-top: 20px;
    /* 根据需要调整间距 */
}

::v-deep .el-pagination {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

::v-deep .el-pager li {
    width: 18px;
    /* 圆的直径 */
    line-height: 24px;
    /* 和高度相同来垂直居中文本 */
    text-align: center;
    /* 文本水平居中 */
    font-size: 18px;
    /* 根据需要调整字体大小 */
    margin: 4px;
    /* 两边的间隙 */
    font-weight: 500;
}

.home-private .new-card {
    color: #7AB5FB;
    font-weight: 500;
    /* 根据需要添加其他样式 */
}
</style>