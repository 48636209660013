<script>

import CustomerSankey from './IncomeSankey.vue';
import * as d3 from 'd3'
import { sankey, sankeyLinkHorizontal, sankeyCenter } from 'd3-sankey';
import Tools from '../../GlobalTools'
export default {
    name: 'CashFlowSankey',
    extends: CustomerSankey,
    data() {
        return {
            // 新的或重写的数据属性
            containerId: 'cashflow',
        };
    },
    methods: {
        drawChart(data) {
            // 获取容器元素
            let parentId = data.containerId ? data.containerId : '';
            if (parentId) {
                this.containerId = 'cashflow' + parentId;
                this.$nextTick(() => {
                    this.drawCashFlowSankey(data);
                });
            } else {
                this.drawCashFlowSankey(data);
            }

        },

        drawCashFlowSankey(data, parentId = '') {
            var unit = data.unit;
            var currency = data.currency;
            var unit_t = data.unit_t;
            // Append a background rect to the SVG


            const titleHeight = 120; // Height of the title area
            const titleMargin = 120; // Margin around the title and icon


            var nodeWidth = 80;
            var nodePadding = 260;
            if (data.maxleaf) {
                this.picHeight = Math.min(4010, Math.max(2720, data.maxleaf * nodePadding));
                this.picWidth = 4296 * this.picHeight / 2416;
            }

            var chartName = data.chatName ? data.chatName : data.symbol;
            var svgParent = `#sankeyDiagram-${this.containerId}`;
            const svgId = `svg-${chartName}`;
            d3.select(`${svgParent} svg`).remove();
            const svg = d3.select(`${svgParent}`).append('svg')
                .attr('id', svgId)
                .attr('width', this.picWidth)
                .attr('height', this.picHeight)
                .style('cursor', 'pointer');

            // Append a background rect to the SVG
            svg.append('rect')
                .attr('width', this.picWidth)
                .attr('height', this.picHeight)
                .attr('fill', '#F5F5F5');

            // 创建包含标题的组
            const titleGroup = svg.append('g')
                .attr('transform', `translate(${this.picWidth / 2}, ${titleMargin})`);

            // let titleWidth = 0;
            if (!Tools.isEmpty(data.title)) {
                // 先添加标题文本
                titleGroup.append('text')
                    .attr('x', 0)
                    .attr('y', titleHeight * 1 / 3)
                    .attr('alignment-baseline', 'middle')
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '120px')
                    .attr('fill', '#444444')
                    .attr('font-weight', 'bold')
                    .attr('font-family', 'Times New Roman')
                    .text(data.title);

            }

            if (data.subtitle) {
                // 添加副标题
                titleGroup.append('text')
                    .attr('x', 0) // 水平居中
                    .attr('y', titleHeight + 12) // 在主标题下方
                    .attr('alignment-baseline', 'middle')
                    .attr('text-anchor', 'middle') // 文本中心对齐
                    .attr('font-size', '48px') // 副标题字体大小，可以根据需要调整
                    .attr('fill', '#aaa') // 副标题字体颜色，可以根据需要调整
                    .text(data.subtitle); // 动态副标题
            }
            //底部添加官方网址
            // 首先，确定 text 的大小（根据字体大小和内容大致估计）
            const textSizeEstimate = 680; // 这个值可能需要调整

            // 计算 text 和 logo 的位置
            const xPosition = this.picWidth / 2;
            const logoWidth = 100; // 假设 logo 宽度为100，根据实际情况调整
            const totalWidth = textSizeEstimate + logoWidth + 80; // text 宽度 + logo 宽度 + 80px 间距

            // 计算 logo 的位置
            const logoX = xPosition - totalWidth / 2 - 88;
            const logoY = this.picHeight - logoWidth - 40; // 假设 logo 高度和宽度相同

            // 添加 logo
            svg.append('image')
                .attr('x', logoX)
                .attr('y', logoY)
                .attr('width', logoWidth)
                .attr('height', logoWidth)
                .attr('href', this.logoSrc); // 使用从 data 函数中获取的路径

            // 添加 text（和你的代码一样）
            svg.append('text')
                .attr('x', xPosition)
                .attr('y', this.picHeight - 60)
                .attr('text-anchor', 'middle')
                .attr('font-size', '80px')
                .attr('font-family', 'sans-serif')
                .attr('font-weight', 'bold')
                .attr('fill', '#133D64')
                .text('www.papermoney.ai');


            // 绘制图表
            if (!Tools.isArrayEmpty(data.nodes) && !Tools.isArrayEmpty(data.links)) {
                // 桑基图数据
                const graph = {
                    nodes: data.nodes,
                    links: data.links
                };
                // 定义阴影过滤器
                svg.append("defs").append("filter")
                    .attr("id", "drop-shadow")
                    .attr("height", "130%") // 使过滤器区域足够大以包含阴影
                    .append("feDropShadow")
                    .attr("dx", 0)  // 阴影的水平偏移
                    .attr("dy", 12)  // 阴影的垂直偏移
                    .attr("stdDeviation", 10) // 模糊量，增加这个值会让阴影更加柔和
                    .attr("flood-color", "#999")  // 阴影颜色
                    .attr("flood-opacity", 0.75); // 阴影的透明度
                try {
                    let vm = this; // 保存 Vue 实例的 this
                    //绘制 期前现金流和期后现金流
                    // 卡片数据
                    const cardsData = [
                        { title: 'Cash beginning balance', value: '' },
                        { title: 'Net cash flow', value: '' },
                        { title: 'Cash ending balance', value: '' }
                    ];

                    if (data.cashBegin) {
                        cardsData[0].value = vm.formatAmount(data.cashBegin, unit, currency, unit_t);
                    }

                    if (data.cashEnd) {
                        cardsData[2].value = vm.formatAmount(data.cashEnd, unit, currency, unit_t);
                    }

                    if (data.netCash) {
                        cardsData[1].value = vm.formatAmount(data.netCash, unit, currency, unit_t);
                    }
                    // 计算卡片位置
                    const cardWidth = 640; // 卡片宽度
                    const cardHeight = 160; // 卡片高度
                    const cardPadding = 48; // 卡片之间的间距

                    const cardsGroup = svg.append('g')
                        .attr('transform', `translate(${(this.picWidth - (cardWidth * cardsData.length + cardPadding * (cardsData.length - 1))) / 2}, ${titleMargin + titleHeight + 32 + 48})`);
                    // 这里的 50 是卡片与标题之间的距离，可以根据需要调整

                    cardsData.forEach((card, index) => {
                        const xPosition = index * (cardWidth + cardPadding); // 计算每个卡片的 x 位置

                        // 绘制卡片背景
                        cardsGroup.append('rect')
                            .attr('x', xPosition)
                            .attr('y', -12)
                            .attr('rx', 24) // 为矩形添加圆角
                            .attr('ry', 24)
                            .attr('width', cardWidth)
                            .attr('height', cardHeight)
                            .attr('fill', '#616161'); // 颜色可以根据需要调整

                        // 绘制卡片标题
                        cardsGroup.append('text')
                            .attr('x', xPosition + cardWidth / 2)
                            .attr('y', cardHeight / 3)
                            .attr('text-anchor', 'middle')
                            .attr('font-size', '52px') // 副标题字体大小，可以根据需要调整
                            .attr('fill', 'white') // 文本颜色
                            .text(card.title);

                        // 绘制卡片值
                        cardsGroup.append('text')
                            .attr('x', xPosition + cardWidth / 2)
                            .attr('y', cardHeight * 2 / 3 + 12)
                            .attr('text-anchor', 'middle')
                            .attr('fill', 'white') // 文本颜色
                            .attr('font-size', '52px') // 副标题字体大小，可以根据需要调整
                            .text(card.value);
                    });

                    this.drawCardSummery(svg, data.activities, titleMargin, titleHeight, unit, currency, unit_t);

                    // 创建桑基图布局
                    const margin = { top: 600, right: 360, bottom: 120, left: 360 };
                    const chart = svg.append('g')
                        .attr('transform', `translate(${margin.left}, ${margin.top + titleMargin + titleHeight * 1.5})`);

                    const chartWidth = this.picWidth - margin.left - margin.right;
                    const chartHeight = this.picHeight - titleMargin - titleHeight * 1.5 - margin.top - margin.bottom;

                    const sankeyGenerator = sankey()
                        .nodeWidth(nodeWidth)
                        .nodePadding(nodePadding)
                        .nodeAlign(sankeyCenter)
                        .size([chartWidth, chartHeight])
                        .nodeId(d => d.id)
                        .nodeSort(function (a, b) {
                            return b.sort - a.sort;
                        }).linkSort(function (a, b) {
                            return b.sort - a.sort;
                        });

                    sankeyGenerator(graph);



                    // 遍历所有节点来计算每个层级的节点数量
                    const layerNodeCounts = {};

                    graph.nodes.forEach(node => {
                        layerNodeCounts[node.depth] = (layerNodeCounts[node.depth] || 0) + 1;
                    });

                    // 基于最大层级节点数量来调整nodePadding
                    const maxNodesInALayer = Math.max(...Object.values(layerNodeCounts));

                    // 如果节点数量较少，使用较大的padding
                    nodePadding = Math.max(160, Math.min(240, 240 * 5 / maxNodesInALayer));
                    // console.log('maxNodesInALayer，nodePadding:', maxNodesInALayer, nodePadding);

                    sankeyGenerator.nodePadding(nodePadding);
                    sankeyGenerator(graph);

                    chart
                        .append('g')
                        .selectAll()
                        .data(graph.nodes)
                        .join('g')
                        .attr('class', 'node')
                        .attr('indexName', (d) => d.name)
                        .append('rect')
                        .attr('fill', (d) => d.color)
                        .attr('x', (d) => d.x0)
                        .attr('y', (d) => d.y0)
                        .attr('height', (d) => d.y1 - d.y0)
                        .attr('width', (d) => d.x1 - d.x0)
                        .append('title')
                        .text((d) => `${d.name}`)

                    chart
                        .append('g')
                        .attr('fill', 'none')
                        .selectAll()
                        .data(graph.links)
                        .join('path')
                        .attr('class', 'link')
                        .attr('d', sankeyLinkHorizontal())
                        .attr('indexName', (d) => d.source.name + '-' + d.target.name)
                        .attr('stroke', (d) => d.color)
                        .attr('stroke-width', (d) => Math.max(1, d.width)) // 确保最小宽度是2px
                        .attr('stroke-opacity', '1.0')
                        .append('title')



                    // 绘制标签
                    const nodeGroups = chart
                        .append('g')
                        .selectAll('g.node')
                        .data(graph.nodes)
                        .enter()
                        .append('g')
                        .attr('class', 'node');



                    nodeGroups
                        .append('rect')
                        .attr('fill', (d) => d.color)
                        .attr('x', (d) => d.x0)
                        .attr('y', (d) => d.y0)
                        .attr('height', (d) => d.y1 - d.y0)
                        .attr('width', (d) => d.x1 - d.x0);


                    // Append the title for each node
                    // 假设 nodeGroups 是你的节点集合
                    let txtPosY = 12;
                    nodeGroups.each(function (d) {
                        // 创建一个新的SVG组
                        let group = d3.select(this).append('g');

                        // 假设 vm.wrapText 是你用来包装文本的函数
                        let lines = vm.wrapText(d.desc, group);
                        let lineHeight = 44;
                        var fontSize = lineHeight + 'px';
                        let textBlockHeight = lines.length * lineHeight;
                        let padding = 12;
                        let startYPosition = vm.determineYPosition(d, nodePadding) - textBlockHeight + lineHeight + txtPosY;
                        const type = d.type;
                        // 绘制背景矩形，如果需要
                        if (d.type === 'rect') {
                            txtPosY = 0;
                            startYPosition = vm.determineYPosition(d, nodePadding) - textBlockHeight + lineHeight;
                            let backgroundHeight = textBlockHeight + 4 * padding + 52;
                            let backgroundWidth = lines[0].width + 4 * padding + 32;
                            let backgroundXPosition = vm.determineXPosition(d) - backgroundWidth / 2;
                            let backgroundYPosition = startYPosition - lines[0].height - 20;

                            group.append('rect')
                                .attr('x', backgroundXPosition)
                                .attr('y', backgroundYPosition)
                                .attr('width', backgroundWidth)
                                .attr('height', backgroundHeight)
                                .attr('fill', '#F5F5F5')
                                .attr('stroke', '#AAA')
                                .attr('stroke-width', 1)
                                .attr('rx', 24)
                                .attr('ry', 24)
                                .attr("filter", "url(#drop-shadow)");  // 应用阴影过滤器
                        } else {
                            txtPosY = 16;
                            startYPosition = vm.determineYPosition(d, nodePadding) - textBlockHeight + lineHeight + (lines.length) * txtPosY;
                        }
                        // 添加标题文本
                        let text = group.append('text')
                            .attr('class', 'chart-node-title')
                            .attr('x', vm.determineXPosition(d))
                            .attr('y', startYPosition)
                            .attr('fill', type === 'rect' ? d.color : Tools.parseColorLight(d.color, 0.85))
                            .attr('font-weight', type === 'rect' ? 'bold' : '400')
                            .attr('text-anchor', vm.determineTextAnchor(d))
                            .attr('alignment-baseline', 'hanging')
                            .attr('font-family', 'sans-serif')
                            .attr('font-size', fontSize);

                        lines.forEach((line, index) => {
                            text.append('tspan')
                                .attr('x', text.attr('x'))
                                .attr('dy', `${index * lineHeight}px`)
                                .text(line.text);
                        });

                        // 添加数据和margin
                        let descYPosition = vm.determineYPosition(d, nodePadding) + (lines.length)*txtPosY + 42;
                        let descText = group.append('text')
                            .attr('class', 'chart-node-desc')
                            .attr('x', vm.determineXPosition(d))
                            .attr('y', descYPosition)
                            .attr('text-anchor', vm.determineTextAnchor(d))
                            .attr('alignment-baseline', 'middle')
                            .attr('font-family', 'sans-serif')
                            .attr('font-size', fontSize)
                            .attr('fill', type === 'rect' ? d.color : Tools.parseColorLight(d.color, 0.85))
                            .attr('font-weight', type === 'rect' ? 'bold' : '400')
                            .text(vm.formatAmount(d.v, unit, currency, unit_t));

                        if (d.margin) {
                            descText.append('tspan')
                                .attr('dx', '8px')
                                .attr('dy', '-8px')
                                .attr('font-size', '40px')
                                .attr('fill', '#666')
                                .attr('font-weight', 'normal')
                                .text(`(${d.margin})`);
                        }

                        // 添加YY文本
                        if (d.yy) {
                            let yyYPosition = vm.determineYPosition(d, nodePadding) + txtPosY + 88;
                            group.append('text')
                                .attr('class', 'chart-node-margin')
                                .attr('x', vm.determineXPosition(d))
                                .attr('y', yyYPosition)
                                .attr('text-anchor', vm.determineTextAnchor(d))
                                .attr('alignment-baseline', 'middle')
                                .attr('font-size', '40px')
                                .attr('fill', '#666')
                                .text(vm.formatYY(d.yy));
                        }
                    });



                } catch (err) {
                    console.log(err);
                }
            }

            let container;

            if (parentId) {
                container = document.getElementById(parentId);
            } else {
                let containers = document.getElementsByClassName('image-container');
                container = containers.length > 0 ? containers[0] : null;
            }

            // 检查是否获取到元素，并获取其尺寸
            let containerWidth, containerHeight;
            if (container) {
                containerWidth = container.clientWidth - 16;
                containerHeight = container.clientHeight;
            } else {
                console.log('未找到具有 image-container 类的元素');
                // 在这里设置默认宽度和高度，或执行其他操作
            }

            const scaleX = containerWidth / this.picWidth;
            const scaleY = containerHeight / this.picHeight;
            const scale = Math.min(scaleX, scaleY); // 选择较小的比例以保持比例
            // 应用缩放和转换
            svg.attr('transform', 'scale(' + scale + ')');
        },
        drawCardSummery(svg, smallCardsData, titleMargin, titleHeight, unit, currency, unit_t) {
            // 假设这些是您想要添加的卡片信息
            // const smallCardsData = [
            //     { title: 'Card 1', value: 'Value 1' },
            //     { title: 'Card 2', value: 'Value 2' },
            //     { title: 'Card 3', value: 'Value 3' }
            // ];
            if (!smallCardsData)
                return;

            // 设置小卡片的宽度、高度和间距
            const smallCardWidth = 560;  // 小卡片的宽度
            const smallCardHeight = 132; // 小卡片的高度
            const smallCardSpacing = 120; // 小卡片之间的间距

            // 计算小卡片的起始位置
            const smallCardsStartX = (this.picWidth - (smallCardWidth * smallCardsData.length + smallCardSpacing * (smallCardsData.length - 1))) / 2;
            const smallCardsStartY = titleMargin + titleHeight + 280 + 44;

            // 在SVG中添加小卡片
            const smallCardsGroup = svg.append('g')
                .attr('transform', `translate(${smallCardsStartX}, ${smallCardsStartY})`);

            smallCardsData.forEach((card, index) => {
                const xPosition = index * (smallCardWidth + smallCardSpacing); // 计算每个小卡片的X位置
                // console.log('small card:', card, xPosition);
                // 绘制小卡片背景
                smallCardsGroup.append('rect')
                    .attr('x', xPosition)
                    .attr('y', 0)
                    .attr('width', smallCardWidth)
                    .attr('height', smallCardHeight)
                    .attr('stroke', Tools.parseLinkColor(card.color))
                    .attr('fill', '#F5F5F5')
                    .attr('stroke-width', 1)
                    .attr('rx', 24)
                    .attr('ry', 24)

                // 绘制小卡片标题
                smallCardsGroup.append('text')
                    .attr('x', xPosition + smallCardWidth / 2)
                    .attr('y', 52) // 标题的y位置可以根据需要调整
                    .attr('text-anchor', 'middle')
                    .attr('fill', Tools.parseColorLight(Tools.parseNodeColor(card.color), 0.85)) // 可以根据需要调整卡片背景色
                    .attr('font-size', '48px') // 标题字体大小
                    .text(card.desc + ' Net');

                // 绘制小卡片值
                smallCardsGroup.append('text')
                    .attr('x', xPosition + smallCardWidth / 2)
                    .attr('y', 108) // 值的y位置可以根据需要调整
                    .attr('text-anchor', 'middle')
                    .attr('fill', Tools.parseColorLight(Tools.parseNodeColor(card.color), 0.85)) // 可以根据需要调整卡片背景色
                    .attr('font-weight', 'bold')
                    .attr('font-size', '48px') // 值字体大小
                    .text(this.formatAmount(card.v, unit, currency, unit_t));

            });
            // 假设这是括号上方的空间
            const bracketTopPadding = 12; // 调整为所需的值
            // 计算括号的位置
            const bracketStartX = smallCardsStartX + smallCardWidth / 2;
            const bracketEndX = bracketStartX + (smallCardWidth + smallCardSpacing) * (smallCardsData.length - 1);
            const bracketY = smallCardsStartY - bracketTopPadding;

            // 中间尖头的宽度和高度
            const arrowWidth = 20; // 可以根据需要调整尖头的宽度
            const arrowHeight = 16; // 可以根据需要调整尖头的高度

            // 括号的路径
            const bracketPath = `M${bracketStartX} ${bracketY}
                         C${bracketStartX} ${bracketY - 30} ${bracketStartX + 30} ${bracketY - 40} ${bracketStartX + 50} ${bracketY - 40}
                         L${(bracketStartX + bracketEndX) / 2 - arrowWidth} ${bracketY - 40}
                         L${(bracketStartX + bracketEndX) / 2} ${bracketY - 40 - arrowHeight}
                         L${(bracketStartX + bracketEndX) / 2 + arrowWidth} ${bracketY - 40}
                         L${bracketEndX - 40} ${bracketY - 40}
                         C${bracketEndX - 30} ${bracketY - 40} ${bracketEndX} ${bracketY - 30} ${bracketEndX} ${bracketY}`;

            // 在SVG中添加括号路径
            svg.append('path')
                .attr('d', bracketPath)
                .attr('fill', 'none')
                .attr('stroke', '#AAA') // 括号的颜色
                .attr('stroke-width', '2'); // 括号的线宽
        },
    }
    // 如果需要，可以添加或重写计算属性、watchers 等
}
</script>