<template>
    <div class="industry-grid">
        <router-link class="industry-card no-underline" v-for="industry in industries" :key="industry.name"
            :to="{ path: '/list', query: { tag: industry.name } }">
            <div class="card-image-wrapper">
                <template v-if="industry.iconWeb">
                    <img :src="industry.iconWeb" alt="Industry Icon"
                        :style="{ width: industry.iconSize + 'px', height: industry.iconSize + 'px' }"
                        class="card-icon" />
                </template>

                <template v-else>
                    <i :class="industry.icon" class="card-icon"></i>
                </template>

            </div>
            <div class="card-content">
                <h3 class="card-title">{{ industry.name }}</h3>
                <p class="card-description">{{ industry.description }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
import Global from '../../GlobalValues.js';
export default {

    data() {
        return {
            industries: Global.companyIndustries,
        };
    },
    methods: {
        openCategory(tag) {
            this.$router.push({ path: '/list', query: { tag } });
        },
        viewAllTags() {
            // 新增的方法，用于切换显示所有行业

        }
    }
}
</script>

<style scoped>
.industry-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* 使用 auto-fill 和 minmax 实现响应式布局 */
    gap: 20px;
    /* 网格项之间的间隔 */
}

.no-underline {
  text-decoration: none; /* 移除下划线 */
  /* 其它你需要的样式 */
}

.industry-card {
    transition: transform 0.3s;
}

.industry-card:hover {
    transform: translateY(-5px);
    /* 鼠标悬停时上移效果 */
}


.card-icon {
    font-size: 2.5em;
    /* 调整图标大小 */
    color: #444;
    /* 图标颜色设为白色 */
}

.card-icon-web {
    width: 64px;
    height: 64px;
}

.card-image-wrapper {
    /* Or your preferred height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.card-content {
    padding: 8px 12px;
}

.card-title {
    font-size: 18px;
    /* Title size */
    color: #444;
    /* Title color */
    text-align: center;
}

.card-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    color: #777;
    padding-top: 4px;
    text-align: center;
}
</style>