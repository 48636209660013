<template>
    <div class="dialogue-message">
        <div class="icon-user">
            <div class="avatar" :style="avatarStyle">{{ avatarText }}</div> <span>You</span>
        </div>
        <div class="message-content" v-html="renderedMessage"></div>
    </div>
</template>
  
<script>
import { marked } from 'marked';

export default {
    props: {
        message: String
    },
    computed: {
        userEmail() {
            return this.$store.getters.userEmail || '';
        },
        avatarText() {
            const email = this.userEmail ? this.userEmail.split('@')[0] : '';
            return email ? email.substring(0, 2).toUpperCase() : 'My';
        },
        avatarStyle() {
            const bgColor = this.getBackgroundColor(this.avatarText.charAt(0));
            return {
                backgroundColor: bgColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                color: 'white',
                fontSize: '12px'
            };
        },
        renderedMessage() {
            return marked.parse(this.message);
        }
    },
    methods: {
        getBackgroundColor(char) {
            const colors = {
                A: "#8C736F",
                B: "#ADAAA5",
                C: "#AAB8AB",
                D: "#A08887",
                E: "#B1ACB3",
                F: "#53565C",
                G: "#93939B",
                H: "#976666",
                I: "#7A848D",
                J: "#A9B7AA",
                K: "#95A098",
                L: "#C3AAA6",
                M: "#C4C1BC",
                N: "#60656C",
                O: "#728A7A",
                P: "#A39183",
                Q: "#A39797",
                R: "#4A7056",
                S: "#597A69",
                T: "#8E6E57",
                U: "#88807D",
                V: "#2A483A",
                W: "#4E6E62",
                X: "#3B4338",
                Y: "#674D41",
                Z: "#845937"
            };
            return colors[char.toUpperCase()] || '#AAB8AB'; // 默认颜色
        }
    }
};
</script>
  
<style scoped>
.dialogue-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.dialogue-message .message-content {
    margin-left: 32px;
}

.icon-user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-user span {
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;
}



</style>
  